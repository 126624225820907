import * as React from "react";
import { Socket } from "socket.io-client";
import MultipleChoiceMsg from "components/Msgs/MultipleChoiceMsg";
import IsFirstMsgInput from "./common/isFirstMsgInput";
import NextMsgInput from "./common/NextMsgInput";
import { onDeleteFormBtn } from "./utils/onDeleteFormBtn";
import {
  onMultipleChoiceSendBtn,
  onSaveFormBtn,
  onUpdateFormBtn,
} from "./utils/onFormBtn";
import { MSG_TYPES } from "constants/msgTypes";
import * as S from "./forms.style";
import FormControlBtns from "./common/FormControlBtns";

export interface IMultipleChoiceFormProps {
  socket: Socket;
  selectedMsgTemplate;
  msgTemplateList;
}

export default function MultipleChoiceForm({
  socket,
  selectedMsgTemplate,
  msgTemplateList,
}: IMultipleChoiceFormProps) {
  const [formTitle, setFormTitle] = React.useState("");
  const [btnText, setBtnText] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [options, setOptions] = React.useState([""]);

  const [nextMsgTemplate, setNextMsgTemplate] = React.useState("");
  const [isFirstMsg, setIsFirstMsg] = React.useState(false);

  const {
    _id,
    title,
    content,
    nextTemplate,
    isFirstMsg: isFirst,
  } = selectedMsgTemplate;
  const isSavedTemplate = !!_id;

  React.useEffect(() => {
    if (!content) {
      return;
    }
    const { question, btnText, options } = content;
    setFormTitle(title);
    setQuestion(question);
    setBtnText(btnText);
    setOptions(options);

    setNextMsgTemplate(nextTemplate);
    setIsFirstMsg(isFirst);
  }, [selectedMsgTemplate]);

  const onOptionAddBtn = () => {
    if (options[options.length - 1] === "") {
      alert(
        "마지막 선택지가 빈칸일 때는 새로운 선택지를 추가할 수 없습니다. 마지막 선택지를 채워주세요."
      );
      return;
    }
    const updatedOptions = [...options, ""];
    setOptions(updatedOptions);
  };

  const formData = {
    type: MSG_TYPES.MULTIPLE_CHOICE,
    title: formTitle,
    content: {
      question,
      options,
      btnText,
    },
    nextTemplate: nextMsgTemplate,
    isFirstMsg,
  };

  const optionElements = options.map((option, index) => (
    <S.InputLayout key={index}>
      <S.Input
        value={option}
        onChange={(e) => {
          const updatedOptions = [...options];
          updatedOptions[index] = e.target.value;
          setOptions(updatedOptions);
        }}
      />
      <S.DeleteOptionBtn
        onClick={() => {
          if (options.length === 1) {
            alert("최소 한 개의 선택지가 있어야 합니다!");
            return;
          }
          const updatedOptions = [...options];
          updatedOptions.splice(index, 1);
          setOptions(updatedOptions);
        }}
      >
        삭제
      </S.DeleteOptionBtn>
    </S.InputLayout>
  ));

  return (
    <S.FormContainer>
      <S.InputLayout>
        <S.Label htmlFor="formTitle">폼 이름:</S.Label>
        <S.Input
          id="formTitle"
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value);
          }}
        />
      </S.InputLayout>
      <S.FormPreviewSection>
        <MultipleChoiceMsg
          content={{
            templateTitle: formTitle,
            question: question,
            options: options,
            btnText: btnText,
          }}
          role="concierge"
        />
      </S.FormPreviewSection>
      <S.FormInputSection>
        <S.InputLayout>
          <S.Label htmlFor="questionInput">질문 내용:</S.Label>
          <S.Input
            id="questionInput"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="btnText">버튼 이름:</S.Label>
          <S.Input
            id="btnText"
            value={btnText}
            onChange={(e) => {
              setBtnText(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.Label>선지</S.Label>
        <S.OptionAddBtn onClick={onOptionAddBtn}>선지추가</S.OptionAddBtn>
        {optionElements}
        <NextMsgInput
          currentMsgTemplateTitle={title}
          nextMsgTemplate={nextMsgTemplate}
          setNextMsgTemplate={setNextMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
        <IsFirstMsgInput
          isFirstMsg={isFirstMsg}
          setIsFirstMsg={setIsFirstMsg}
        />
        <FormControlBtns
          msgType={MSG_TYPES.MULTIPLE_CHOICE}
          socket={socket}
          isSavedTemplate={isSavedTemplate}
          formData={formData}
          id={_id}
        />
      </S.FormInputSection>
    </S.FormContainer>
  );
}
