import * as React from "react";
import * as S from "../../preopen.style";
import ImageField from "common/ImageField";
import { createPreopenLodgment } from "api/preopen";
import { useHistory } from "react-router-dom";
import { uploadPackageImg } from "api/image";

export interface ISingleProps {}

export default function Single(props: ISingleProps) {
  const [name, setName] = React.useState("");
  const [nameEN, setNameEN] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [coverImg, setCoverImg] = React.useState("");
  const [routePath, setRoutePath] = React.useState("");
  const [preference, setPreference] = React.useState(0);
  const [country, setCountry] = React.useState("");

  const history = useHistory();

  const handleChange = (e, setState) => {
    if (name === "preference") {
      setState(Number(e.target.value));
    }
    setState(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files.length ? e.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append("imgSource", file);
    const {
      data: { imgSource },
    } = await uploadPackageImg(formData);
    setCoverImg(imgSource[0]);
  };

  const handleDelete = () => {
    setCoverImg("");
  };

  const handleSubmit = async () => {
    const data = {
      name,
      globalName: {
        en: nameEN,
      },
      desc,
      coverImg,
      routePath,
      preference,
    };

    await createPreopenLodgment(data);
    history.push("/preopen");
  };

  return (
    <>
      <ImageField
        value={coverImg}
        onChange={handleFileChange}
        onDelete={handleDelete}
      />
      <S.FormSection>
        <input
          type="text"
          name="name"
          value={name}
          placeholder="숙소 이름"
          onChange={(e) => handleChange(e, setName)}
        />
        <input
          type="text"
          name="globalName"
          value={nameEN}
          placeholder="영문이름"
          onChange={(e) => handleChange(e, setNameEN)}
        />
        <input
          type="text"
          name="desc"
          value={desc}
          placeholder="숙소 인원 설정"
          onChange={(e) => handleChange(e, setDesc)}
        />
        <input
          type="number"
          name="preference"
          value={preference}
          placeholder="순서"
          onChange={(e) => handleChange(e, setPreference)}
        />
        <input
          type="text"
          name="routePath"
          value={routePath}
          placeholder="숙소 상세 페이지 경로"
          onChange={(e) => handleChange(e, setRoutePath)}
        />
        <input
          type="text"
          name="country"
          value={country}
          placeholder="국가"
          onChange={(e) => handleChange(e, setCountry)}
        />
      </S.FormSection>
      <button onClick={handleSubmit}>저장</button>
    </>
  );
}
