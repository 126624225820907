import React, { useEffect } from "react";
import useTitle from "hooks/useTitle";

export default function Home() {
  useTitle("Home");

  return (
    <div>
      <div>멋지고 위대한 사람들의</div>
      <div>위대하고 멋진 회사</div>
      <strong>finestays</strong>
    </div>
  );
}
