import { getSettlementBalance } from "api/settlementLog";
import React from "react";
import * as S from "./settlement.styled";
import Summary from "./Summary";

export interface SettlementProps {}

export default function Settlement({}: SettlementProps) {
  const [data, setData] = React.useState<any>();

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getSettlementBalance();
        setData(res);
      } catch (err) {
        alert(err);
      }
    };
    fetch();
  }, []);

  return (
    <div>
      <S.Typo as="h2">미정산금 내역</S.Typo>
      {!data ? <S.Typo>로딩중...</S.Typo> : <Summary data={data} />}
    </div>
  );
}
