import React, { useState, useEffect } from "react";
import axios from "config/axiosConfig";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { DISTRICT_ARR, POST_TYPE_ARR } from "constants/post";
import { RegisterButton, Label, Input, GeoCodeButton } from "./style";
import { Flex } from "components/style";
import useTitle from "hooks/useTitle";

export default function RegisterPost() {
  useTitle("포스트 등록");
  const history = useHistory();
  const [post, setPost] = useState({
    // id: null,
    name: null,
    type: null,
    district: null,
    latitude: null,
    longitude: null,
    images: [],
    address: null,
    phone: null,
    link: null,
  });
  const districtOptions = [];
  DISTRICT_ARR.map((el, i) => {
    districtOptions.push({ value: el, label: el });
  });

  const typeOptions = [];
  POST_TYPE_ARR.map((el, i) => {
    typeOptions.push({ value: el, label: el });
  });

  useEffect(() => {
    console.log(post);
  }, [post]);

  const registerPost = async () => {
    await axios
      .post("/posts", post)
      .then((res) => {
        console.log(res.data);
        alert("완료");

        history.push(`/post/${res.data.post.id}`);
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  const geoCode = async () => {
    const address = post.address;
    const {
      data: {
        coord: [longitude, latitude],
      },
    } = await axios.get(`/openApi/geocode/${address}`);

    if (longitude && latitude) {
      setPost({ ...post, longitude, latitude });
    }

    return;
  };

  return (
    <div>
      <h3 style={{ marginTop: 30 }} onClick={() => console.log(post)}>
        기본 정보 등록
      </h3>
      <form style={{ marginBottom: 50 }}>
        {/* <div>
          <Label>id</Label>
          <Input
            type="text"
            defaultValue={post.id}
            onChange={(e) => {
              setPost({ ...post, id: e.target.value });
            }}
          />
        </div> */}
        <div>
          <Label>이름</Label>
          <Input
            type="text"
            defaultValue={post.name}
            onChange={(e) => {
              setPost({
                ...post,
                name: e.target.value,
              });
            }}
          />
        </div>
        <Flex>
          <Label>종류</Label>
          <div style={{ width: 300, marginLeft: 20 }}>
            <Select
              onChange={(val) => {
                setPost({ ...post, type: val.value });
              }}
              options={typeOptions}
            />
          </div>
        </Flex>
        <Flex>
          <Label>지역</Label>
          <div style={{ width: 300, marginLeft: 20 }}>
            <Select
              onChange={(val) => {
                setPost({ ...post, district: val.value });
              }}
              options={districtOptions}
            />
          </div>
        </Flex>
        <div>
          <Label>전화번호</Label>
          <Input
            type="text"
            defaultValue={post.phone}
            onChange={(e) => {
              setPost({ ...post, phone: e.target.value });
            }}
          />
        </div>
        <Flex>
          <Label>주소</Label>
          <Input
            type="text"
            defaultValue={post.address}
            onChange={(e) => {
              setPost({ ...post, address: e.target.value });
            }}
          />
          <GeoCodeButton onClick={() => geoCode()}>
            위도 경도 변환
          </GeoCodeButton>
        </Flex>

        <div>
          <Label>위도</Label>
          <Input
            type="text"
            defaultValue={post.latitude}
            onChange={(e) => {
              setPost({ ...post, latitude: parseFloat(e.target.value) });
            }}
          />
        </div>
        <div>
          <Label>경도</Label>
          <Input
            type="text"
            defaultValue={post.longitude}
            onChange={(e) => {
              setPost({ ...post, longitude: parseFloat(e.target.value) });
            }}
          />
        </div>

        <div>
          <Label>링크</Label>
          <Input
            type="text"
            defaultValue={post.link}
            onChange={(e) => {
              setPost({ ...post, link: e.target.value });
            }}
          />
        </div>
      </form>

      <RegisterButton
        style={{ marginBottom: 40 }}
        onClick={() => registerPost()}
      >
        최종 등록
      </RegisterButton>
    </div>
  );
}
