import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "config/axiosConfig";
import useTitle from "hooks/useTitle";

export default function Login() {
  useTitle("로그인");
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    if (isLogin) {
      history.push("/");
      history.go(0);
    }
  }, [isLogin]);

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      history.push("/");
    }
  });

  const login = async (loginInfo) => {
    await axios
      .post(`adminUsers/login`, loginInfo)
      .then((res) => {
        console.log(res);
        localStorage.setItem("jwt", res.data.jwt);
        setIsLogin(true);
      })
      .catch((err) => {
        console.error(err);
        alert("아이디 혹은 패스워드가 잘못되었습니다.");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const loginInfo = {
      username: e.target.username.value,
      password: e.target.password.value,
    };
    return login(loginInfo);
  };

  return (
    <div>
      <h3>관리자 로그인 페이지</h3>
      <form onSubmit={onSubmit}>
        <div>Username</div>
        <input type="text" name="username" required></input>
        <div>Password</div>
        <input type="password" name="password" required></input>
        <br></br>
        <button type="submit">로그인</button>
      </form>
    </div>
  );
}
