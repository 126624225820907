import styled from "styled-components";

export const Tag = styled.div`
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  justify-content: center;
  border: 1px solid #494949;
  text-align: center;
  width: 500px;
  display: flex;
  align-self: center;
`;

export const DeleteButton = styled.div`
  background-color: red;
  border-radius: 10px;
  padding: 5px;
  width: 50px;
  color: #ffffff;
  font-weight: 700;
  margin-left: auto;
  align-self: center;
`;
