import React from "react";
import * as S from "./settlement.styled";
import {
  calculateTotalSettlementAmount,
  classifyLodgmentSettlementByPlatform,
} from "utils/settlement";
import { numberWithComma } from "utils/format";

type Props = {
  data;
};

export default function Summary({ data }: Props) {
  const { finestays, naver } = classifyLodgmentSettlementByPlatform(data);

  const totalSettlementAmount =
    finestays.settlementAmount + naver.settlementAmount;
  return (
    <div>
      {/* <S.Typo>{totalSettlementAmount}</S.Typo> */}
      <S.Typo>
        파인스테이:{" "}
        <b>
          {numberWithComma(finestays.settlementAmount)}원 | {finestays.count}건
        </b>
      </S.Typo>
      <S.Typo>
        네이버:{" "}
        <b>
          {numberWithComma(naver.settlementAmount)}원 | {naver.count}건
        </b>
      </S.Typo>
      <S.Typo id="total-settlement">
        총 미정산금액: <b>{numberWithComma(totalSettlementAmount)}원</b>
      </S.Typo>
      <S.Box>
        <S.Ul>
          <li>
            - 파인스테이는 오늘 기준으로 정산해야할 모든 금액을 표시합니다.
          </li>
          <li>
            - 네이버는 오늘 기준으로 다음주 정산일까지 포함하는 금액을
            표시합니다.
          </li>
          <li>
            - 시프시프 미확정건은 우선 제외하였습니다. 확정시 자동 반영됩니다.
          </li>
        </S.Ul>
      </S.Box>
    </div>
  );
}
