import { myAxios } from "./myAxios";

// 인터페이스 정의
export interface Inspection {
  id: string;
  title: string;
  thumb_urls: string[];
  imageLack: boolean;
  placeHold: boolean;
  placeDelete: boolean;
  category?: string;
  version: number;
  status?: string;
  reviewComment?: string;
  // 기타 필요한 필드들...
}

export interface PaginationInfo {
  totalItems: number;
  totalPages: number;
  currentPage: number;
  pageSize: number;
  totalImageLack: number;
  totalPlaceHold: number;
  totalPlaceDelete: number;
}

export interface InspectionResponse {
  data: Inspection[];
  pagination: PaginationInfo;
}

// 검수 목록 조회 - React Query와 함께 사용하기 위한 키 생성 함수
export const inspectionKeys = {
  all: ["inspections"] as const,
  lists: () => [...inspectionKeys.all, "list"] as const,
  list: (filters: { page: number; pageSize: number }) =>
    [...inspectionKeys.lists(), filters] as const,
  details: () => [...inspectionKeys.all, "detail"] as const,
  detail: (id: string) => [...inspectionKeys.details(), id] as const,
};

// 검수 목록 조회
export const getInspections = async (
  page = 1,
  pageSize = 50
): Promise<InspectionResponse> => {
  try {
    const response = await myAxios.get({
      path: `/inspections?page=${page}&pageSize=${pageSize}`,
    });

    return (
      response.data || {
        data: [],
        pagination: {
          totalItems: 0,
          totalPages: 0,
          currentPage: page,
          pageSize,
        },
      }
    );
  } catch (error) {
    console.error("Error fetching inspections:", error);
    throw error;
  }
};

// 검수 항목 업데이트
export const updateInspection = async (
  id: string,
  data: Partial<Inspection>
): Promise<Inspection> => {
  try {
    const response = await myAxios.put({
      path: `/inspections/${id}`,
      data,
    });
    return response.data;
  } catch (error) {
    console.error(`Error updating inspection ${id}:`, error);
    throw error;
  }
};
