import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import * as S from "./ChatRoom.style";
import {
  emitSendMessage,
  emitTypingAction,
} from "../../utils/socket/clientEvent";
import { Socket } from "socket.io-client";
import { useInput } from "../../hooks/useInput";
import { SEND_MSG_TYPE } from "../../constants/socketEvent";
import SmsModal from "./SmsModal";
import { myAxios } from "api/myAxios";
import { CHAT_FILE_PATH } from "constants/path";
import { compareByName } from "utils/fileProcessor";

export interface IChatRoomInputProps {
  socket: Socket;
  isFileAdderClicked;
  setIsFileAdderClicked;
  userPhone: string;
}

export default function ChatRoomInput({
  socket,
  isFileAdderClicked,
  setIsFileAdderClicked,
  userPhone,
}: IChatRoomInputProps) {
  const [msg, onMsgInput, setMsg] = useInput("");
  const [showSmsModal, setShowSmsModal] = useState(false);
  const isInputBlank = useRef<boolean>(true);

  const onMsgInputBtn = useCallback(
    (msg: string) => {
      if (!msg) {
        return;
      }
      emitSendMessage(socket, SEND_MSG_TYPE.TEXT, {
        text: msg.trimStart(),
      });
      setMsg("");
      emitTypingAction(socket, { action: "end" });
      isInputBlank.current = true;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [socket]
  );

  const onMsgInputChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onMsgInput(e);
      if (e.target.value && isInputBlank.current) {
        emitTypingAction(socket, { action: "start" });
        isInputBlank.current = false;
      }
      if (!e.target.value && !isInputBlank.current) {
        emitTypingAction(socket, { action: "end" });
        isInputBlank.current = true;
      }
    },
    [socket, onMsgInput]
  );

  const onMsgInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.nativeEvent.isComposing) {
        return;
      }
      if (e.key !== "Enter") {
        return;
      }
      if (e.shiftKey) {
        return;
      }

      e.preventDefault();
      onMsgInputBtn(msg);
    },
    [msg, onMsgInputBtn]
  );

  const onFileInput = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files.length === 0) {
        return;
      }
      if (!confirm("파일/이미지를 보내시겠습니까?")) {
        return;
      }

      const files = [...e.target.files].sort(compareByName);

      const form = new FormData();
      files.forEach((file) => {
        form.append("files", file);
      });

      const {
        data: {
          data: { files: fileUrls },
        },
      } = await myAxios.filepost({ path: CHAT_FILE_PATH, data: form });

      const fileContents = [];

      fileUrls.forEach((url: string) => {
        const parsedUrl = url.split("/");
        const name = parsedUrl[parsedUrl.length - 1];
        fileContents.push({ name, location: url });
      });
      fileContents.sort(compareByName);

      files.map((el, i) => {
        const isImg = el.type.includes("image");
        const msgType = isImg ? SEND_MSG_TYPE.IMAGE : SEND_MSG_TYPE.FILE;

        emitSendMessage(socket, msgType, fileContents[i]);
      });
    },
    [socket]
  );
  return (
    <>
      <input
        id="ImageInput"
        type="file"
        // multiple
        hidden
        onChange={onFileInput}
        accept="image/*"
      />
      <input
        id="FileInput"
        type="file"
        // multiple
        hidden
        onChange={onFileInput}
        accept=".docx, .ppt, .pptx, .txt, .pdf"
      />
      <S.FileBtnContainer
        style={{
          display: "flex",
        }}
      >
        <S.FileInputLabel className="fileBtn" htmlFor="ImageInput">
          이미지
        </S.FileInputLabel>
        <S.FileInputLabel className="fileBtn" htmlFor="FileInput">
          파일
        </S.FileInputLabel>
        <S.FileInputLabel
          className="fileBtn"
          onClick={() => {
            setShowSmsModal(!showSmsModal);
          }}
        >
          문자
        </S.FileInputLabel>
      </S.FileBtnContainer>
      <S.InputBar>
        <S.MsgInput
          onChange={onMsgInputChange}
          onKeyDown={onMsgInputKeyDown}
          value={msg}
        />
        <S.MsgInputBtn onClick={() => onMsgInputBtn(msg)}>
          <img
            src="/icon/btn_send_message.png"
            style={{ width: "100%", margin: "auto 0" }}
          />
        </S.MsgInputBtn>
      </S.InputBar>
      {showSmsModal && (
        <SmsModal setShowSmsModal={setShowSmsModal} userPhone={userPhone} />
      )}
    </>
  );
}
