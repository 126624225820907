import * as React from "react";
import { useHistory } from "react-router-dom";
import { deleteShort, getShorts } from "api/shortsposts";
import * as S from "../AccommodationRegister/accommodationRegister.style";

export interface IShortsProps {}

export default function Shorts(props: IShortsProps) {
  const history = useHistory();
  const [places, setPlaces] = React.useState([]);

  const onPlaceItem = (placeId) => {
    history.push(`/shorts/${placeId}`);
  };

  const onDeleteBtn = async (placeId) => {
    if (confirm("정말 삭제하시겠습니까?")) {
      await deleteShort(placeId);
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const fetchShorts = async () => {
      const { data } = await getShorts();
      console.log("?");
      console.log(data);
      setPlaces(data);
      console.log("되긴해");
    };
    fetchShorts();
    console.log(places);
  }, []);

  const $Places = places?.map((place) => {
    console.log(place._id, place.lodgment);
    if (!place.lodgment) return;

    return (
      <>
        <S.PlaceItem key={place._id} onClick={() => onPlaceItem(place._id)}>
          <button
            onClick={async (e) => {
              e.stopPropagation();
              onDeleteBtn(place._id);
            }}
          >
            삭제
          </button>
          <S.PlaceRepresentImg src={place?.lodgment?.coverImg || ""} />
          <div>
            <S.PlaceTitle>{place.lodgment.name}</S.PlaceTitle>
          </div>
        </S.PlaceItem>
      </>
    );
  });

  return (
    <div>
      <button onClick={() => history.push("/shorts/new")}>
        새로운 쇼츠 장소 만들기
      </button>
      <S.PlaceBox>{$Places}</S.PlaceBox>
    </div>
  );
}
