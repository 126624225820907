import React, { useCallback } from "react";

// openModal , closeModal 함수를 생성하고 이를 배열 형태로 반환한다.
export const useModalHandler = (modalStateSetter) => {
  // useCallback: 성능 최적화를 위해 사용
  const openModal = useCallback(() => {
    modalStateSetter(true);
  }, []);

  const closeModal = useCallback(() => {
    modalStateSetter(false);
  }, []);

  return [openModal, closeModal];
};
