export const MSG_TYPES = {
  SINGLE_CHOICE: "singleChoice",
  MULTIPLE_CHOICE: "multipleChoice",
  SUBJECTIVE: "subjective",
  BTN_MSG: "btnMsg",
  PHOTO_MSG: "photoMsg",
  TEXT_MSG: "text",
  IMG_MSG: "image",
  FILE_MSG: "file",
};
