import axios from "config/axiosConfig";
import axiosCrawler from "axios";
import { DISTRICT_ARR } from "constants/post";
import { useEffect } from "react";
import { useState } from "react";
import { Button } from "react-bootstrap";
import TagSelector from "./components/TagSelector";
import { InputContainer, SelectedSpot, Spot, Td, Th, Tr } from "./style";
import { stringToPrice } from "utils/price";
import { Flex } from "components/style";
import useTitle from "hooks/useTitle";

export default function AccommodationSearch() {
  useTitle("숙소 조회");
  const [searchCondition, setSearchCondition] = useState({
    checkIn: null,
    checkOut: null,
    adults: 0,
    children: 0,
    infants: 0,
    district: null,
    checkPetsAllowed: false,
    tags: [],
  });
  const [spotArray, setSpotArray] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [isSearching, setIsSearching] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [targetAccommodations, setTagetAccommodations] = useState(null);
  const [searchResult, setSearchResult] = useState(null);

  useEffect(async () => {
    console.log(targetAccommodations);
  }, [targetAccommodations]);

  const getTargetAccommodations = async () => {
    const tempSpotArray = [];
    spotArray.forEach((el, idx) => {
      if (el) {
        tempSpotArray.push(DISTRICT_ARR[idx]);
      }
    });

    const {
      data: { accommodations },
    } = await axios.get("accommodations", {
      params: {
        from: "airbnb",
        headcount:
          parseInt(searchCondition.adults) + parseInt(searchCondition.children),
        district: tempSpotArray.join(","),
        checkDayAvailable: searchCondition.checkDayAvailable,
        checkPetsAllowed: searchCondition.checkPetsAllowed,
        tags: searchCondition.tags.join(","),
      },
    });
    console.log(accommodations);
    setTagetAccommodations(accommodations);
  };

  const selectSpot = (idx) => {
    const tempSpotArray = [...spotArray];
    tempSpotArray[idx] = !tempSpotArray[idx];
    setSpotArray(tempSpotArray);
  };

  const selectAllSpot = () => {
    setSpotArray([1, 1, 1, 1, 1, 1, 1, 1, 1]);
  };

  const removeAllSpot = () => {
    setSpotArray([0, 0, 0, 0, 0, 0, 0, 0, 0]);
  };

  const checkCrawler = async () => {
    try {
      await axiosCrawler.get("http://localhost:8000");
      alert("크롤러 살아있엉");
    } catch (error) {
      alert("크롤러 죽어있엉");
    }
    return;
  };

  const crawlAirbnb = async () => {
    if (
      !searchCondition.checkIn ||
      !searchCondition.checkOut ||
      searchCondition.adults + searchCondition.children === 0
    ) {
      alert("체크인, 체크아웃, 인원 정보를 정확히 입력해주세요");
      return;
    }

    const roomIds = targetAccommodations.map(
      (accommodation) => accommodation.airbnbId
    );
    const roomCondition = {
      checkIn: searchCondition.checkIn,
      checkOut: searchCondition.checkOut,
      adults: searchCondition.adults,
      children: searchCondition.children,
      infants: searchCondition.infants,
    };
    console.log("크롤링 가자~~");
    setIsSearched(false);
    setIsSearching(true);
    const {
      data: { result },
    } = await axiosCrawler.post("http://3.34.57.23:8000/api/room-available", {
      roomIds,
      roomCondition,
    });
    setSearchResult(
      result.sort((a, b) => {
        if (a.isAvailable > b.isAvailable) {
          return 1;
        }
        if (a.isAvailable < b.isAvailable) {
          return -1;
        }
        if (stringToPrice(a.totalPrice) > stringToPrice(b.totalPrice)) {
          return 1;
        }
        return -1;
      })
    );
    setIsSearched(true);
    setIsSearching(false);
  };

  useEffect(() => {
    console.log(searchResult);
  }, [searchResult]);

  return (
    <div style={{ marginBottom: 100 }}>
      <h3>숙소 조회</h3>
      <div>
        <form>
          <InputContainer>
            <h5>숙박 일정</h5>
            <input
              type="date"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  checkIn: e.target.value,
                });
              }}
            />
            <input
              type="date"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  checkOut: e.target.value,
                });
              }}
            />
          </InputContainer>
          <InputContainer>
            <h5>숙박 인원</h5>
            <div>성인(만 13세 이상)</div>
            <input
              defaultValue={0}
              type="number"
              min="0"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  adults: e.target.value,
                });
              }}
            />
            <div>어린이(만 2~12세)</div>
            <input
              defaultValue={0}
              type="number"
              min="0"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  children: e.target.value,
                });
              }}
            />
            <div>
              유아(만 2세 미만) - 근데 유아 포함 n명 제한인 숙소도 사이트에서는
              유아 몇명인지에 따른 예약 가능여부 변화x
            </div>
            <input
              defaultValue={0}
              type="number"
              min="0"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  infants: e.target.value,
                });
              }}
            />
          </InputContainer>
          <InputContainer>
            <h5>지역</h5>
            <div style={{ marginBottom: 40 }}>
              {spotArray.map((spot, idx) => {
                if (spot)
                  return (
                    <SelectedSpot onClick={() => selectSpot(idx)}>
                      {DISTRICT_ARR[idx]}
                    </SelectedSpot>
                  );
                return (
                  <Spot onClick={() => selectSpot(idx)}>
                    {DISTRICT_ARR[idx]}
                  </Spot>
                );
              })}
              <div>
                <Button size="sm" style={{ margin: 5 }} onClick={selectAllSpot}>
                  전체 선택
                </Button>
                <Button size="sm" onClick={removeAllSpot}>
                  전체 해제
                </Button>
              </div>
            </div>
          </InputContainer>
          <InputContainer>
            <h5>반려동물이 있나요?</h5>
            <input
              type="checkbox"
              onChange={(e) => {
                setSearchCondition({
                  ...searchCondition,
                  checkPetsAllowed: e.target.checked,
                });
              }}
            />
          </InputContainer>
          <TagSelector
            searchCondition={searchCondition}
            setSearchCondition={setSearchCondition}
          />
          <Button onClick={getTargetAccommodations}>대상 숙소 확인</Button>
        </form>
      </div>

      {targetAccommodations ? (
        <Flex>
          <div style={{ padding: 40, width: "30%" }}>
            <h4>검색 대상 숙소입니다</h4>
            {targetAccommodations.map((accom) => {
              return (
                <div style={{ margin: 10 }}>
                  <div>
                    {accom.name}({accom.airbnbId})
                  </div>
                  <div style={{ fontSize: 12 }}>
                    선호도: {accom.preference}, 최대: {accom.maximumGuests}명
                  </div>
                </div>
              );
            })}
            <Button onClick={checkCrawler}>크롤러 상태 확인</Button>
            <Button style={{ marginLeft: 5 }} onClick={crawlAirbnb}>
              에어비앤비에서 검색하기
            </Button>
          </div>
          <div
            style={{
              width: "70%",
              borderLeft: "1px solid",
              marginLeft: 30,
              padding: 30,
            }}
          >
            <h4>검색 결과</h4>
            {isSearched ? (
              <table style={{ marginTop: 20 }}>
                <Th>숙소 이름</Th>
                <Th style={{ width: 100 }}>예약 가능</Th>
                <Th style={{ width: 100 }}>총 가격</Th>
                <Th>주의</Th>
                <Th style={{ width: 310 }}>비고</Th>

                {searchResult.map((el) => {
                  return (
                    <Tr>
                      <Td>
                        <a
                          href={`https://www.airbnb.co.kr/rooms/${el.roomId}?check_in=${searchCondition.checkIn}&check_out=${searchCondition.checkOut}&adults=${searchCondition.adults}&children=${searchCondition.children}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {el.name.slice(0, 16)}...{`\n(${el.roomId})`}
                        </a>
                      </Td>
                      <Td style={{ textAlign: "center" }}>{el.isAvailable}</Td>
                      <Td>{el.totalPrice}</Td>
                      <Td>{el.alert ? el.alert : "error"}</Td>
                      <Td>{el.remarks ? el.alert : "error"}</Td>
                    </Tr>
                  );
                })}
              </table>
            ) : isSearching ? (
              <div>검색 중입니다</div>
            ) : (
              <div>아직 검색하지 않았습니다</div>
            )}
          </div>
        </Flex>
      ) : null}
    </div>
  );
}
