import * as React from "react";
import PhotoMsg from "components/Msgs/PhotoMsg";
import { MSG_TYPES } from "constants/msgTypes";
import IsFirstMsgInput from "./common/isFirstMsgInput";
import { emitSendMessage } from "utils/socket/clientEvent";
import { postMsgTemplate, putMsgTemplate } from "api/msgTemplate";
import { onDeleteFormBtn } from "./utils/onDeleteFormBtn";
import { Socket } from "socket.io-client";
import * as S from "./forms.style";

export interface IPhotoMsgFormProps {
  socket: Socket;
  selectedMsgTemplate;
}

export default function PhotoMsgForm({
  socket,
  selectedMsgTemplate,
}: IPhotoMsgFormProps) {
  const [formTitle, setFormTitle] = React.useState("");
  const [photo, setPhoto] = React.useState("");
  const [text, setText] = React.useState("");

  const [isFirstMsg, setIsFirstMsg] = React.useState(false);

  const photoRef = React.useRef<HTMLInputElement>();

  const { _id, title, content, isFirstMsg: isFirst } = selectedMsgTemplate;
  const isSavedTemplate = !!_id;
  React.useEffect(() => {
    if (!content) {
      return;
    }
    const { photo, text } = content;
    setFormTitle(title);
    setPhoto(photo);
    setText(text);
    setIsFirstMsg(isFirst);
  }, [selectedMsgTemplate]);

  const onSaveFormBtn = async () => {
    if (!formTitle) {
      alert("폼 제목을 지정해주세요!");
      return;
    }
    const formData = {
      type: MSG_TYPES.PHOTO_MSG,
      title: formTitle,
      content: {
        photo: photoRef.current.files[0],
        text,
      },
      isFirstMsg,
    };
    await postMsgTemplate(formData);
    alert("저장되었습니다!");
    window.location.reload();
  };

  const onUpdateFormBtn = async () => {
    if (!formTitle) {
      alert("폼 제목을 지정해주세요!");
      return;
    }
    const formData = {
      type: MSG_TYPES.PHOTO_MSG,
      title: formTitle,
      content: {
        photo: photoRef.current.files[0],
        text,
      },
      isFirstMsg,
    };
    await putMsgTemplate(_id, formData);
    alert("수정되었습니다.");
    window.location.reload();
  };
  return <>준비중입니다,,</>;

  return (
    <S.FormContainer>
      <S.InputLayout>
        <S.Label htmlFor="formTitle">폼 이름:</S.Label>
        <S.Input
          id="formTitle"
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value);
          }}
        />
      </S.InputLayout>
      <S.FormPreviewSection>
        <PhotoMsg
          content={{
            photo:
              "https://s3.us-west-2.amazonaws.com/secure.notion-static.com/b53d6908-378f-412b-b1ca-15e184e7350f/%E1%84%89%E1%85%B3%E1%84%8F%E1%85%B3%E1%84%85%E1%85%B5%E1%86%AB%E1%84%89%E1%85%A3%E1%86%BA_2022-07-03_%E1%84%8B%E1%85%A9%E1%84%92%E1%85%AE_5.00.02.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220704%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20220704T014954Z&X-Amz-Expires=86400&X-Amz-Signature=8779b91d68d2df2755089d1361524a4c93054da3f774bb986114461f4977a02d&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22%25E1%2584%2589%25E1%2585%25B3%25E1%2584%258F%25E1%2585%25B3%25E1%2584%2585%25E1%2585%25B5%25E1%2586%25AB%25E1%2584%2589%25E1%2585%25A3%25E1%2586%25BA%25202022-07-03%2520%25E1%2584%258B%25E1%2585%25A9%25E1%2584%2592%25E1%2585%25AE%25205.00.02.png%22&x-id=GetObject",
            text: "예쁜 바다입니ㄴㅇㅁㄴ어ㅏ모너ㅏ옴ㄴ ㅇㅁ넝모나ㅓㅇ 모다!",
          }}
          role="concierge"
        />
      </S.FormPreviewSection>
      <S.FormInputSection>
        <S.InputLayout>
          <S.Label htmlFor="photo">사진 첨부:</S.Label>
          <S.Input id="photo" ref={photoRef} type="file" accept="image/*" />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="text">내용:</S.Label>
          <S.Input
            id="text"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </S.InputLayout>
        <IsFirstMsgInput
          isFirstMsg={isFirstMsg}
          setIsFirstMsg={setIsFirstMsg}
        />
        <div style={{ display: "flex" }}>
          {!isSavedTemplate && (
            <>
              <S.FormFunctionBtn onClick={onSaveFormBtn}>
                폼 저장하기
              </S.FormFunctionBtn>
              <S.FormFunctionBtn onClick={onUpdateFormBtn}>
                폼 수정하기
              </S.FormFunctionBtn>
            </>
          )}
          {isSavedTemplate && (
            <S.FormFunctionBtn onClick={() => onDeleteFormBtn(_id)}>
              폼 삭제하기
            </S.FormFunctionBtn>
          )}
        </div>
        <S.SendBtn
          onClick={() => {
            if (!confirm("메시지를 전송하시겠습니까?")) {
              return;
            }
            const content = {
              photo,
              text,
            };
            emitSendMessage(socket, MSG_TYPES.PHOTO_MSG, content);
          }}
        >
          채팅방에 보내기
        </S.SendBtn>
      </S.FormInputSection>
    </S.FormContainer>
  );
}
