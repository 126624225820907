import { NOTIFICATION_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getNotification = async (): Promise<any> => {
  const path = `${NOTIFICATION_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const postNotification = async (body): Promise<any> => {
  const path = `${NOTIFICATION_PATH}`;
  const res = await myAxios.post({ path, data: body });
  return res.data;
};

export const updateNotification = async (notification: {
  id: string;
  description: string;
  url: string;
}): Promise<any> => {
  const { id, ...data } = notification;
  const path = `${NOTIFICATION_PATH}/${id}`;
  const res = await myAxios.put({ data, path });
  return res.data;
};

export const deleteNotification = async (
  notificationId: string
): Promise<any> => {
  console.log(notificationId);
  const path = `${NOTIFICATION_PATH}/${notificationId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};
