import styled from "styled-components";

export const ImageContainer = styled.div`
  display: flex;
  overflow-x: scroll;
  margin-bottom: 30px;
`;

export const ImageBox = styled.div`
  margin-left: 20px;
  &:first-child {
    margin-left: 0;
  }
`;

export const Frame = styled.div`
  width: 150px;
  height: 150px;
`;

export const SingleImage = styled.img`
  width: 100%;
  height: auto;
`;

export const TextSection = styled.div`
  font-size: 12px;
`;

export const FormSection = styled.div``;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const Actions = styled.div``;

//Multiple

export const ContentLayout = styled.div`
  display: flex;
  justify-content: space-around;
`;
export const InputColumn = styled.div``;

export const PreviwColum = styled.div`
  max-width: 500px;
  height: 100%;
  width: 500px;
  border: 1px solid grey;
`;

const colors = {
  TEXT_BLACK: "#2b2b2b",
};
interface LayoutProps {
  backgroundColor: string;
}

export const PreviewLayout = styled.div<LayoutProps>`
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-bottom: 20vh;
  max-width: 440px;
  width: 440px;
  margin: 0 auto;
`;

export const MainPicture = styled.div`
  aspect-ratio: 360/406;
  position: relative;
  max-width: 440px;
`;

export const MainImage = styled.img`
  width: 100%;
`;

// export const MainImage = styled(Image)
// `;
// //   display: block;
// //   width: 440px;
// //   height: 100%;
// //   object-fit: cover;
// // `;
export const LogoPicture = styled.div<{ width }>`
  width: ${({ width }) => width || "185px"};
  height: 100px;
  margin: 0 auto;
  /* margin-top: 15px; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
`;
export const LogoImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ImageTextSection = styled.div`
  color: white;
  padding-left: min(25px, 5vw);
  position: absolute;
  /* bottom: min(440px, 7.3vw); */
  bottom: 100px;
  font-weight: 500;
`;
export const TitleText = styled.div`
  font-size: min(28px, 7vw);
`;
export const Paragraph = styled.div`
  font-size: min(16px, 4vw);
  margin-top: 5px;
`;

export const ContentsRow = styled.div`
  text-align: center;
  margin-top: 37px;
`;
export const Subtitle = styled.div<{ color }>`
  color: ${({ color }) => (color ? color : colors.TEXT_BLACK)};
  opacity: 0.6;
  font-size: 15px;
  font-weight: bold;
`;
export const Title = styled.div<{ color }>`
  font-size: 18px;
  font-weight: 500;
  color: ${({ color }) => (color ? color : colors.TEXT_BLACK)};
  margin-top: 5px;
  white-space: pre-wrap;
`;

export const RoomSection = styled.div`
  margin-top: 40px;
`;
export const RoomList = styled.ul`
  display: flex;
  padding-left: 20px;
  overflow-x: scroll;
  a {
    text-decoration: none;
  }
`;
export const RoomBox = styled.a`
  text-decoration: none;
  color: ${colors.TEXT_BLACK};
  margin-right: 10px;
  &:last-child {
    padding-right: 20px;
  }
`;

export const RoomInfoBox = styled.div`
  padding: 20px;
  border: 1px solid #e5e5e5;
  position: relative;
  .remove {
    position: absolute;
    right: 0;
    top: 0;
  }
`;

export const RoomInfo = styled.div<{ color }>`
  text-align: left;
  margin-top: 5px;
  color: ${({ color }) => (color ? color : colors.TEXT_BLACK)};
`;
export const NameText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
export const DescText = styled.div`
  font-size: 15px;
  margin-top: 5px;
`;

export const Description = styled.div<{ color }>`
  font-size: 14px;
  color: ${({ color }) => (color ? color : colors.TEXT_BLACK)};
  line-height: 23px;
  white-space: pre-wrap;
`;

export const HorizontalLine = styled.hr`
  width: 90%;
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 30px;
  color: ${colors.TEXT_BLACK};
  opacity: 0.6;
`;

export const RoomDescription = styled.div<{ color }>`
  font-size: min(15px, 4vw);
  color: ${({ color }) => (color ? color : colors.TEXT_BLACK)};
  opacity: 0.8;
  margin-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: pre-wrap;
`;

export const RoomPicture = styled.div`
  aspect-ratio: 216/300;
  position: relative;
`;

export const RoomImage = styled.img`
  width: 100%;
`;

export const SubmitSection = styled.div`
  margin-top: 40px;
  padding-bottom: 100px;
`;

export const MetaDataBox = styled.div`
  display: flex;
  width: 60%;
  justify-content: flex-start;
  border: 1px solid #e5e5e5;
  padding: 12px;
  position: relative;
  cursor: pointer;
  .url {
    margin-left: 30px;
  }
  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
