import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  overflow-x: scroll;
`;

export const Card = styled.div`
  padding: 8px;
  border: 1px solid #e6e6e6;
  margin: 10px;
`;

export const Typo = styled.div``;

export const Content = styled.div``;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  &.precaution {
    text-align: left;
    font-size: 13px;
  }
`;

export const List = styled.li``;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListItemText = styled.div``;

export const StyledTable = styled.div`
  /* .MuiTable-root {
    position: relative;
    min-width: 100vw;
    overflow-x: scroll;
  }
  .MuiTableCell-root {
    padding: 10px;
    width: 200px;
    max-width: 200px;
  }

  .MuiTableCell-root.MuiTableCell-head {
    font-weight: bold;
  }

  .MuiTableCell-root.edit {
    cursor: pointer;
  }

  .naver-direct input.diff-commission {
    color: red;
  } */
  height: 100vh;

  table {
    width: 100%;
  }

  table thead {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 3px solid #e6e6e6;
  }

  table thead tr th,
  table tbody tr td {
    padding: 10px;
    width: 200px;
    max-width: 200px;
  }

  table thead tr th.lodgment-id,
  table tbody tr td.lodgment-id {
    width: 100px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  // table row
  table tbody tr {
    // hover
    &:hover {
      background-color: #f5f5f5;
    }
  }

  input.diff-commission {
    color: red;
  }
`;

export const CommissionModalButton = styled.button`
  margin-left: 5px;
`;

export const CommissionTypo = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const DynamicCommissionCard = styled.div`
  margin-top: 15px;
`;

export const EditButton = styled.span`
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  margin-left: 3px;
`;

export const EditInput = styled.input`
  outline: none;
  border: none;
  text-align: center;
  width: fit-content;
  &.commission {
    font-weight: bold;
  }
`;

export const SubmitButton = styled.button`
  outline: none;
  border: none;
  background-color: #e6e6e6;
  padding: 5px 15px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  button#submit {
    background-color: black;
    color: white;
  }
`;

export const ButtonDiv = styled.div``;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: #e6e6e6;
  padding: 5px 15px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;
