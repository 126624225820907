import { myAxios } from "./myAxios";
import { PACKAGE_PATH } from "../constants/path";

export const getPackages = async (): Promise<any> => {
  const res = await myAxios.get({ path: PACKAGE_PATH });
  return res.data;
};

export const getPackage = async (packageId): Promise<any> => {
  const path = `${PACKAGE_PATH}/${packageId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const postPackage = async (data): Promise<any> => {
  const res = await myAxios.post({ data, path: PACKAGE_PATH });
  return res.data;
};

export const deletePackage = async (packageId): Promise<any> => {
  const path = `${PACKAGE_PATH}/${packageId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const putPackage = async (packageId, data): Promise<any> => {
  const path = `${PACKAGE_PATH}/${packageId}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
