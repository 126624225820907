import Modal from "common/Modal";
import * as S from "./commission.styled";
import { FaCirclePlus } from "react-icons/fa6";
import { useState } from "react";
import dayjs from "lib/dayjs";
import { updateSettlementInfoByAccommo } from "api/settlementLog";

export default function CommissionByAccommoModal({
  isOpen,
  setIsOpen,
  row,
  payload,
  isStatic,
}) {
  const { settle, _id } = row;
  console.log("row", row);

  const commissions = settle[0].commissions;

  console.log("commissions", commissions);
  const staticCommissions = commissions.filter(
    (commission) => commission.startDate === null && commission.endDate === null
  );

  const dynamicCommissions = commissions.filter(
    (commission) => commission.startDate !== null || commission.endDate !== null
  );

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "8px",
          padding: "12px",
          backgroundColor: "white",
          borderRadius: "12px",
        }}
      >
        <StaticCommissionBox
          dynamicCommissions={dynamicCommissions}
          staticCommissions={staticCommissions}
          payload={payload}
          accommoId={_id}
        />
        {!isStatic && (
          <DynamicCommissionBox
            staticCommissions={staticCommissions}
            dynamicCommissions={dynamicCommissions}
            payload={payload}
            accommoId={_id}
          />
        )}

        <S.OutlineBox>
          <S.Ul className="precaution">
            <li>- 기간 내 정산 건에 대해서는 변동 수수료가 적용돼요</li>
            <li>- 기간 외 날짜에 대해서는 모두 고정 수수료가 적용돼요</li>
          </S.Ul>
        </S.OutlineBox>
      </div>
    </Modal>
  );
}

function StaticCommissionBox({
  staticCommissions,
  dynamicCommissions,
  payload,
  accommoId,
}) {
  const [staticCommission, setStaticCommission] = useState(
    staticCommissions[0]?.commission
  );

  console.log("staticCommissions", staticCommissions);

  const onSaveCommission = async () => {
    const newPayload = {
      ...payload,
      commissions: [
        {
          commission: Number(staticCommission),
          startDate: null,
          endDate: null,
        },
        ...dynamicCommissions,
      ],
    };
    console.log("newPayload", newPayload);
    await updateSettlementInfoByAccommo(accommoId, newPayload);
    window.alert("저장되었습니다.");
  };

  return (
    <S.Box>
      <S.Title>고정 수수료</S.Title>
      <S.CommissionCard>
        <S.InputWrapper>
          <S.EditInput
            type="number"
            style={{
              border: "none",
            }}
            value={staticCommission}
            onChange={(e) => setStaticCommission(e.target.value)}
          />
          <S.EditButton onClick={onSaveCommission}>Save</S.EditButton>
        </S.InputWrapper>
      </S.CommissionCard>
    </S.Box>
  );
}

function DynamicCommissionBox({
  staticCommissions,
  dynamicCommissions,
  payload,
  accommoId,
}) {
  const [commissions, setCommissions] = useState(dynamicCommissions);

  // 수수료 추가
  const onAddCommission = () => {
    setCommissions((prev) => [
      ...prev,
      {
        commission: "",
        startDate: dayjs().format("YYYY-MM-DD"),
        endDate: dayjs().add(7, "day").format("YYYY-MM-DD"),
      },
    ]);
  };

  // 수수료 값 변경
  const onChangeCommission = (index, value) => {
    // 1. 쉼표가 포함된 경우 변환
    const newValue = parseFloat(value.replace(",", "."));

    // 2. NaN 방지
    if (isNaN(newValue)) return;

    // 3. 상태 업데이트
    setCommissions((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, commission: newValue } : item
      )
    );
  };

  // 날짜 변경
  const onChangeDate = (index, field, value) => {
    setCommissions((prev) =>
      prev.map((item, i) => (i === index ? { ...item, [field]: value } : item))
    );
  };

  // 수수료 삭제
  const onDeleteCommission = async (index) => {
    // 1. 삭제된 데이터를 반영한 새로운 commissions 배열 생성
    const updatedCommissions = commissions.filter((_, i) => i !== index);

    // 2. 상태 업데이트
    setCommissions(updatedCommissions);

    // 3. API 호출 (삭제된 후의 데이터를 서버에 즉시 반영)
    const newPayload = {
      ...payload,
      commissions: [...staticCommissions, ...updatedCommissions], // 최신 상태 반영
    };

    console.log("📝 삭제 후 업데이트할 데이터:", newPayload);

    await updateSettlementInfoByAccommo(accommoId, newPayload);
    window.alert("삭제 후 저장되었습니다.");
  };

  const onSaveCommission = async () => {
    const newPayload = {
      ...payload,
      commissions: [...staticCommissions, ...commissions],
    };
    console.log("newPayload", newPayload);
    await updateSettlementInfoByAccommo(accommoId, newPayload);
    window.alert("저장되었습니다.");
  };

  return (
    <S.Box>
      <S.Title
        style={{
          display: "flex",
          gap: "4px",
          alignItems: "center",
        }}
      >
        변동 수수료
        <FaCirclePlus
          style={{ color: "#444", cursor: "pointer" }}
          onClick={onAddCommission}
        />
      </S.Title>
      <S.CommissionList>
        {commissions.map((commission, index) => (
          <S.CommissionCard key={index}>
            <S.InputWrapper>
              <div
                style={{
                  display: "flex",
                  gap: "4px",
                  flexDirection: "column",
                }}
              >
                <S.EditInput
                  type="number"
                  style={{ border: "none" }}
                  value={commission.commission}
                  onChange={(e) => onChangeCommission(index, e.target.value)}
                  placeholder="수수료 입력"
                />
                <S.DateRange>
                  <input
                    type="date"
                    value={commission.startDate}
                    style={{ border: "none" }}
                    onChange={(e) =>
                      onChangeDate(index, "startDate", e.target.value)
                    }
                  />
                  ~
                  <input
                    type="date"
                    value={commission.endDate}
                    style={{ border: "none" }}
                    onChange={(e) =>
                      onChangeDate(index, "endDate", e.target.value)
                    }
                  />
                </S.DateRange>
              </div>
              <S.EditButton
                style={{ color: "#D22030" }}
                onClick={() => onDeleteCommission(index)}
              >
                Delete
              </S.EditButton>
              <S.EditButton onClick={onSaveCommission}>Save</S.EditButton>
            </S.InputWrapper>
          </S.CommissionCard>
        ))}
      </S.CommissionList>
    </S.Box>
  );
}
