export const TAG_CATEGORY_ARR: string[] = [
  "season",
  "nature",
  "type",
  "mood",
  "companion",
  "influencer",
  "food",
  "general",
];

export const TAG_CATEGORY_OPTION: { value: string; label: string }[] = [
  { value: "season", label: "season (계절, 월)" },
  { value: "nature", label: "nature (자연)" },
  { value: "type", label: "type (여행지, 액티비티 관련)" },
  { value: "mood", label: "mood (감성, 힐링 등 분위기)" },
  { value: "companion", label: "companion (추천 동행인)" },
  { value: "influencer", label: "influencer (인플루언서)" },
  { value: "food", label: "food (음식 종류)" },
  { value: "general", label: "general (분류가 애매한 것)" },
];
