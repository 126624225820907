import React from "react";
import Modal from "common/Modal";
import * as S from "./commission.styled";
import { formatNumber } from "utils/format";
import { updateSettlementInfo } from "api/settlementLog";
import { convertDateIntoString } from "utils/date";

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  setRowData;
  row: any;
};

export default function CommissionModal({
  isOpen,
  setIsOpen,
  setRowData,
  row,
}: Props) {
  const {
    lodgment: { _id },
  } = row;
  const [commissions, setCommissions] = React.useState<any>(row.commissions);

  const handleSubmit = async (e) => {
    try {
      const formatted = commissions.map((item) => ({
        ...item,
        commission: Number(item.commission),
      }));
      const payload = {
        commissions: formatted,
      };
      await updateSettlementInfo(_id, payload);

      const temp = { ...row };
      temp.commissions = formatted;
      setRowData(temp);
      alert("반영됐어요 👍");
    } catch (err) {
      alert("저장 실패했어요😨");
    }
  };

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    const temp = [...commissions];
    const formattedValue = name === "commission" ? formatNumber(value) : value;
    temp[index][name] = formattedValue;
    setCommissions(temp);
  };

  const handleAddCommission = () => {
    const temp = [...commissions];

    const currentDate = new Date();
    const currentDateStr = convertDateIntoString(currentDate);
    temp.push({
      startDate: currentDateStr,
      endDate: currentDateStr,
      commission: 0,
    });
    setCommissions(temp);
  };

  const handleRemoveCommission = (index) => {
    const temp = [...commissions];
    temp.splice(index, 1);
    setCommissions(temp);
  };

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen} className="basic">
      <S.Ul>
        {commissions?.map((item, index) => {
          if (!item.startDate || !item.endDate) {
            return (
              <StaticCommissionBox
                item={item}
                commissions={commissions}
                setCommissions={setCommissions}
                index={index}
                key={index}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
              />
            );
          }
          return (
            <DynamicCommissionBox
              item={item}
              commissions={commissions}
              setCommissions={setCommissions}
              index={index}
              key={index}
              handleSubmit={handleSubmit}
              handleChange={handleChange}
              handleRemoveCommission={handleRemoveCommission}
            />
          );
        })}
      </S.Ul>
      <S.Actions>
        <S.SubmitButton id="submit" onClick={handleSubmit}>
          저장
        </S.SubmitButton>
        <S.SubmitButton onClick={handleAddCommission}>
          변동 수수료 추가
        </S.SubmitButton>
      </S.Actions>
      <S.Card>
        <S.Ul className="precaution">
          <li>
            - 기간 입력시 <b>yyyy-mm-dd</b> 형태로 꼭 입력해주세요
          </li>
          <li>- 기간내 존재하는 정산건에 대해서 변동 수수료가 적용돼요</li>
          <li>- 기간 외 날짜는 모두 고정 수수료가 적용돼요</li>
        </S.Ul>
      </S.Card>
    </Modal>
  );
}

function StaticCommissionBox({
  item,
  commissions,
  setCommissions,
  index,
  handleSubmit,
  handleChange,
}) {
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>(null);

  //   const handleChange = (e) => {
  //     const { value } = e.target;
  //     setCommission(formatNumber(value));
  //   };

  React.useEffect(() => {
    if (isEditOpen) {
      inputRef.current.focus();
    }
  }, [isEditOpen]);
  return (
    <div>
      <div>
        <b>
          고정 수수료
          <S.EditButton onClick={() => setIsEditOpen(!isEditOpen)}>
            {isEditOpen ? "저장" : "편집"}
          </S.EditButton>
        </b>
      </div>
      <S.CommissionTypo>
        {isEditOpen ? (
          <S.EditInput
            name="commission"
            value={commissions?.[index]?.commission}
            onChange={(e) => handleChange(e, index)}
            ref={inputRef}
            className="commission"
          />
        ) : (
          item.commission
        )}
      </S.CommissionTypo>
    </div>
  );
}

function DynamicCommissionBox({
  item,
  commissions,
  setCommissions,
  index,
  handleSubmit,
  handleChange,
  handleRemoveCommission,
}) {
  const [isEditOpen, setIsEditOpen] = React.useState<boolean>(false);
  const commissionInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (isEditOpen) {
      commissionInputRef.current.focus();
    }
  }, [isEditOpen]);

  return (
    <S.DynamicCommissionCard>
      <li>
        <div>
          <div>
            <b>
              변동 수수료
              <S.EditButton onClick={() => setIsEditOpen(!isEditOpen)}>
                {isEditOpen ? "저장" : "편집"}
              </S.EditButton>
              <S.EditButton onClick={() => handleRemoveCommission(index)}>
                삭제
              </S.EditButton>
            </b>
          </div>
          {isEditOpen ? (
            <>
              <S.EditInput
                name="startDate"
                value={commissions[index].startDate}
                onChange={(e) => handleChange(e, index)}
              />{" "}
              ~{" "}
              <S.EditInput
                name="endDate"
                value={commissions[index].endDate}
                onChange={(e) => handleChange(e, index)}
              />
            </>
          ) : (
            <>
              {item.startDate} ~ {item.endDate}
            </>
          )}
        </div>
        <S.CommissionTypo>
          {isEditOpen ? (
            <S.EditInput
              name="commission"
              value={commissions[index].commission}
              onChange={(e) => handleChange(e, index)}
              ref={commissionInputRef}
              className="commission"
            />
          ) : (
            item.commission
          )}
        </S.CommissionTypo>
      </li>
    </S.DynamicCommissionCard>
  );
}
