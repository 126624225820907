import * as React from "react";
import { onDeleteFormBtn } from "../utils/onDeleteFormBtn";
import {
  onBtnMsgSendBtn,
  onMultipleChoiceSendBtn,
  onSaveFormBtn,
  onSingleChoiceSendBtn,
  onSubjectiveFormSendBtn,
  onTextMsgSendBtn,
  onUpdateFormBtn,
} from "../utils/onFormBtn";
import * as S from "../forms.style";
import { Socket } from "socket.io-client";
import { MSG_TYPES } from "constants/msgTypes";

export interface IFormControlBtnsProps {
  msgType: string;
  socket: Socket;
  isSavedTemplate: boolean;
  formData;
  id: string;
}

export default function FormControlBtns({
  msgType,
  socket,
  isSavedTemplate,
  formData,
  id,
}: IFormControlBtnsProps) {
  return (
    <div>
      <div style={{ display: "flex" }}>
        {!isSavedTemplate && (
          <S.FormFunctionBtn onClick={() => onSaveFormBtn(formData)}>
            폼 저장하기
          </S.FormFunctionBtn>
        )}
        {isSavedTemplate && (
          <>
            <S.FormFunctionBtn onClick={() => onUpdateFormBtn(formData, id)}>
              폼 수정하기
            </S.FormFunctionBtn>
            <S.FormFunctionBtn onClick={() => onDeleteFormBtn(id)}>
              폼 삭제하기
            </S.FormFunctionBtn>
          </>
        )}
      </div>
      <S.SendBtn
        onClick={() => {
          formData.content.templateTitle = formData.title;

          switch (msgType) {
            case MSG_TYPES.TEXT_MSG:
              onTextMsgSendBtn(socket, formData.content);
              break;
            case MSG_TYPES.SINGLE_CHOICE:
              onSingleChoiceSendBtn(socket, formData.content);
              break;
            case MSG_TYPES.MULTIPLE_CHOICE:
              onMultipleChoiceSendBtn(socket, formData.content);
              break;
            case MSG_TYPES.SUBJECTIVE:
              onSubjectiveFormSendBtn(socket, formData.content);
              break;
            case MSG_TYPES.BTN_MSG:
              onBtnMsgSendBtn(socket, formData.content);
              break;
            default:
              alert("잘못된 메시지 형식입니다. 개발자에게 문의해주세요~!");
              break;
          }
        }}
      >
        채팅방에 보내기
      </S.SendBtn>
    </div>
  );
}
