import { getHaulink, updateHaulink } from "api/haulink";
import * as S from "./haulink.style";
import * as React from "react";
import { uploadPackageImg } from "api/image";

export interface IHauLinkDetailProps {
  match;
}

export default function HauLinkDetail({ match }: IHauLinkDetailProps) {
  const { id } = match.params;
  const [lodgmentInfo, setLodgmentInfo] = React.useState(null);

  const [names, setNames] = React.useState({
    name_ko: "",
    name_en: "",
    name_cn: "",
    name_jp: "",
  });
  const [styles, setStyles] = React.useState({
    backgroundColor: "",
  });
  const [channels, setChannels] = React.useState([]);
  const [images, setImages] = React.useState([]);
  const [params, setParams] = React.useState("");
  const [haulink, setHaulink] = React.useState({
    title: {
      ko: "",
      en: "",
      cn: "",
      jp: "",
    },
    url: "",
  });
  const { title, url } = haulink;
  const { name_ko, name_en, name_cn, name_jp } = names;

  const handleFileChange = async () => {
    const formData = new FormData();
    const $img = document.querySelector("#multiple") as HTMLInputElement;
    if (!$img?.files.length) {
      alert("사진을 첨부하세요");
      return;
    }
    const fileList = [...$img.files];
    console.log($img.files);
    fileList.forEach((fileObj) => formData.append("imgSources", fileObj));
    const {
      data: { imgSources },
    } = await uploadPackageImg(formData);
    setImages([...imgSources]);
  };

  const handleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setNames({ ...names, [name]: value });
  };

  const handleLinkChange = (e) => {
    const {
      target: { id },
    } = e;
    const tempChannels = [...channels];
    if (id === "add") {
      tempChannels.push({ title: { title_ko: "", title_en: "" }, url: "" });
      setChannels(tempChannels);
      return;
    }
    if (id === "subtract") {
      tempChannels.pop();
      setChannels(tempChannels);
    }
  };
  const handleMainLinkChange = (e) => {
    const {
      target: { name, value },
    } = e;
    if (name === "url") return setHaulink({ ...haulink, [name]: value });
    haulink.title[name] = value;
    return setHaulink({ ...haulink });
  };
  const handleParamsChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setParams(value);
  };

  const handleLinkTitleChange = (e, idx) => {
    const {
      target: { name, value },
    } = e;
    const tempChannels = [...channels];
    tempChannels[idx]["title"][name] = value;
    setChannels(tempChannels);
  };

  const handleLinkTextChange = (e, idx) => {
    const {
      target: { name, value },
    } = e;
    const tempChannels = [...channels];
    tempChannels[idx][name] = value;
    setChannels(tempChannels);
  };

  const handleEdit = async (e) => {
    const payload = {
      names,
      channels,
      images,
      main_link: haulink,
      params,
      styles,
    };

    console.log("payload", payload);
    try {
      const { data } = await updateHaulink(id, payload);
      alert("수정되었습니다.");
    } catch (err) {
      console.error(err);
    }
  };

  const handleStyleChange = (e) => {
    const {
      target: { name, value },
    } = e;
    setStyles({ ...styles, [name]: value });
  };

  React.useEffect(() => {
    fetchHaulink();
  }, []);

  const fetchHaulink = async () => {
    const { data } = await getHaulink(id);
    console.log(data);

    const {
      names,
      channels,
      _id: lodgmentId,
      images,
      main_link,
      params,
    } = data;
    setNames({
      name_ko: names.name_ko,
      name_en: names.name_en,
      name_cn: names.name_cn,
      name_jp: names.name_jp,
    });
    setChannels([...channels]);
    setImages([...images]);
    setParams(params);
    setStyles(data?.styles);
    if (typeof main_link === "string")
      return setHaulink({ title: { ko: "", en: "", cn: "", jp: "" }, url: "" });
    else {
      setHaulink(main_link);
    }
  };

  return (
    <>
      <h1>상세페이지</h1>
      <S.Title>이미지(최대 5장)</S.Title>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        id="multiple"
        multiple
        required
      />
      <S.ImageSection>
        {images?.map((imageURL) => (
          <S.Picture>
            <S.Image src={imageURL} alt="lodgment-image" />
          </S.Picture>
        ))}
      </S.ImageSection>
      <S.Title>숙소 또는 인플루언서 이름</S.Title>
      <S.InputBox>
        <label htmlFor="">KOR</label>
        <input
          type="text"
          name="name_ko"
          value={name_ko}
          onChange={handleChange}
        />
      </S.InputBox>
      <S.InputBox>
        <label htmlFor="">ENG</label>
        <input
          type="text"
          name="name_en"
          value={name_en}
          onChange={handleChange}
        />
        <S.InputBox>
          <label htmlFor="">CHN</label>
          <input
            type="text"
            name="name_cn"
            value={name_cn}
            onChange={handleChange}
          />
        </S.InputBox>
        <S.InputBox>
          <label htmlFor="">JPN</label>
          <input
            type="text"
            name="name_jp"
            value={name_jp}
            onChange={handleChange}
          />
        </S.InputBox>
      </S.InputBox>
      <S.Title>하우링크뒤에 적용될 영어</S.Title>
      <div>예시) https://hautrip.com/nool</div>
      <S.InputBox>
        <label htmlFor="">영어(소문자로 띄어쓰기 없이 작성할 것)</label>
        <input
          type="text"
          name="params"
          value={params}
          onChange={handleParamsChange}
          style={{ width: "400px" }}
          required
        />
      </S.InputBox>
      <S.Title>스타일</S.Title>
      <S.InputBox>
        <label htmlFor="">배경색</label>
        <span>헥스 색상코드로 입력해주세요! 예시) #2B2B2B</span>
        <span style={{ fontSize: "13px" }}>
          입력하지 않으면 기본 색상으로 반영됩니다.
        </span>
        <input
          type="text"
          name="backgroundColor"
          value={styles?.backgroundColor}
          onChange={handleStyleChange}
        />
      </S.InputBox>
      <S.Title>메인 링크(Main Link)</S.Title>
      <textarea
        placeholder="메인링크 타이틀(한국어)"
        rows={3}
        cols={20}
        name="ko"
        value={title?.ko}
        onChange={handleMainLinkChange}
      ></textarea>
      <textarea
        placeholder="메인링크 타이틀(영어)"
        rows={3}
        cols={20}
        name="en"
        value={title?.en}
        onChange={handleMainLinkChange}
      ></textarea>
      <textarea
        placeholder="메인링크 타이틀(중국어)"
        rows={3}
        cols={20}
        name="cn"
        value={title?.cn}
        onChange={handleMainLinkChange}
      ></textarea>
      <textarea
        placeholder="메인링크 타이틀(일본어)"
        rows={3}
        cols={20}
        name="jp"
        value={title?.jp}
        onChange={handleMainLinkChange}
      ></textarea>
      <S.InputBox>
        <label htmlFor="">메인 링크 주소</label>
        <div>숙소 하나일 경우: 'https://hautrip.com/lodgment/숙소고유ID'</div>
        <div>
          숙소 여러개인 경우 : preopen 탭 {">"} 페이지 생성 후에 multiple 섹션에
          보여지는 URL 삽입{" "}
        </div>
        <input
          type="text"
          name="url"
          value={url}
          onChange={handleMainLinkChange}
          style={{ width: "400px" }}
          required
        />
      </S.InputBox>
      <S.Title>서브 링크</S.Title>
      <p>영어 번역시, 노출이 불필요한 항목은 빈칸으로 만드세요</p>
      <p>
        예시) KOR: "카카오 채팅 문의하기" ENG: "" 링크: "https://hautrip.com"
      </p>
      <button onClick={handleLinkChange} id="add">
        +
      </button>
      <button onClick={handleLinkChange} id="subtract">
        -
      </button>
      <S.LinkSection>
        {channels?.map(({ title, url }, idx) => (
          <>
            <S.LinkBox>
              <S.Number>{idx + 1} 번</S.Number>
              <S.InputsBox>
                <S.LangTitleBox>
                  <S.InputBox>
                    <label htmlFor="">KOR</label>
                    <input
                      type="text"
                      name="title_ko"
                      value={title.title_ko}
                      onChange={(e) => handleLinkTitleChange(e, idx)}
                    />
                  </S.InputBox>
                  <S.InputBox>
                    <label htmlFor="">ENG</label>
                    <input
                      type="text"
                      name="title_en"
                      value={title.title_en}
                      onChange={(e) => handleLinkTitleChange(e, idx)}
                    />
                  </S.InputBox>
                  <S.InputBox>
                    <label htmlFor="">CHN</label>
                    <input
                      type="text"
                      name="title_cn"
                      value={title.title_cn}
                      onChange={(e) => handleLinkTitleChange(e, idx)}
                    />
                  </S.InputBox>
                  <S.InputBox>
                    <label htmlFor="">JPN</label>
                    <input
                      type="text"
                      name="title_jp"
                      value={title.title_jp}
                      onChange={(e) => handleLinkTitleChange(e, idx)}
                    />
                  </S.InputBox>
                  <S.InputBox>
                    <label htmlFor="">URL</label>
                    <input
                      type="text"
                      name="url"
                      value={url}
                      onChange={(e) => handleLinkTextChange(e, idx)}
                      style={{ width: "300px" }}
                    />
                  </S.InputBox>
                </S.LangTitleBox>
              </S.InputsBox>
            </S.LinkBox>
          </>
        ))}
      </S.LinkSection>
      <S.SubmitButton onClick={handleEdit}>수정하기</S.SubmitButton>
    </>
  );
}
