import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  cloneLodgment,
  deleteLodgment,
  getLodgmentSummariesByRegion,
} from "api/lodgment";
import * as S from "./accommodationRegister.style";
import axios from "axios";
import { myAxios } from "api/myAxios";
import { redeployServer } from "api/vercel";

export interface IPackagePlaceProps {}

export default function PackagePlace(props: IPackagePlaceProps) {
  const history = useHistory();
  const [lodgments, setLodgments] = React.useState([]);
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [option, setOption] = React.useState("전체");

  const filtered = lodgments.filter(({ region }) => true);
  const [isToggleOpen, setIsToggleOpen] = React.useState(false);
  const [id, setId] = React.useState(null);
  const [count, setCount] = React.useState(0);

  const onRedeployBtn = async () => {
    if (!confirm("정말 재배포하시겠습니까? 재배포에는 3~4분이 소요됩니다."))
      return;
    await redeployServer();
  };

  React.useEffect(() => {
    const fetchUser = async () => {
      const jwt = localStorage.getItem("jwt");
      await myAxios
        .post({ path: "/adminUsers/check", data: { jwt } })
        .then((res) => {
          setIsAdmin(res.data.user.isAdmin);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchUser();
  }, []);

  const onPlaceItem = (placeId) => {
    history.push(`/accommodation/${placeId}`);
  };

  const onDeleteBtn = async (placeId, lodgmentName) => {
    if (
      window.prompt(
        `등록되어 있던 스케쥴도 모두 삭제됩니다. 신중하게 결정해주세요!! 숙소를 삭제하시려면 "${lodgmentName}"를 입력해주세요.`
      ) === lodgmentName
    ) {
      await deleteLodgment(placeId);
      window.location.reload();
    }
  };

  const sortLodgments = (
    { preference: aPreference },
    { preference: bPreference }
  ) => bPreference - aPreference;

  const handleSelectRegion = async (e) => {
    console.log(e);
    const region = e.target.value;
    setOption(region);
  };

  React.useEffect(() => {
    const option = sessionStorage.getItem("region");
    const region = option || "제주";
    setOption(region);

    history.push(`/accommodation/register?region=${region}`);
    const fetchLodgments = async () => {
      const { data } = await getLodgmentSummariesByRegion(region);
      setLodgments(data);
    };
    fetchLodgments();
    // sessionStorage.setItem("region", region);
  }, [option]);

  const handleClone = async () => {
    if (!id || !count) {
      alert("숙소 ID와 복제할 숙소 개수를 입력해주세요.");
      return;
    }
    try {
      const response = await cloneLodgment(id, count);
      alert(`${count}개 복제되었습니다.`);
      window.location.reload();
    } catch (error) {
      alert("다시 시도해주세요");
    }
    return;
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setState: React.Dispatch<React.SetStateAction<number | string>>
  ) => {
    const { value } = e.target;
    setState(value);
  };
  return (
    <div>
      <S.Header>
        <S.ButtonGroupSection>
          <button onClick={() => history.push("/accommodation/register/new")}>
            새로운 숙소 만들기
          </button>
          <button style={{ marginLeft: "2rem" }} onClick={onRedeployBtn}>
            재배포
          </button>
        </S.ButtonGroupSection>
        <S.SelectSection>
          {/* 국가 : {option}{" "}
          <select onChange={handleSelectRegion}>
            <option value="제주">제주</option>
            <option value="경주">경주</option>
            <option value="경기">경기</option>
            <option value="부산">부산</option>
            <option value="강원">강원</option>
            <option value="전체">전체</option>
          </select> */}
        </S.SelectSection>
      </S.Header>
      <S.CloneBox>
        <button onClick={() => setIsToggleOpen(!isToggleOpen)}>
          숙소 데이터 복제
        </button>
        {isToggleOpen && (
          <div>
            <label htmlFor="id">숙소 ID</label>
            <input
              type="text"
              value={id}
              id={id}
              onChange={(e) => handleChange(e, setId)}
            />
            <label htmlFor="count">복제할 숙소 개수</label>
            <input
              type="number"
              id="count"
              value={count}
              onChange={(e) => handleChange(e, setCount)}
            />
            <button onClick={handleClone}>복제</button>
          </div>
        )}
      </S.CloneBox>
      <S.PlaceBox>
        {filtered.sort(sortLodgments).map((lodgment) => (
          <Lodgment
            lodgment={lodgment}
            isAdmin={isAdmin}
            onDeleteBtn={onDeleteBtn}
            onPlaceItem={onPlaceItem}
          />
        ))}
      </S.PlaceBox>
    </div>
  );
}

function Lodgment({ lodgment, isAdmin, onDeleteBtn, onPlaceItem }) {
  return (
    <>
      <S.PlaceItem key={lodgment._id}>
        {isAdmin && (
          <button
            onClick={async (e) => {
              e.stopPropagation();
              onDeleteBtn(lodgment._id, lodgment.name);
            }}
          >
            삭제
          </button>
        )}
        <S.PlaceRepresentImg
          src={lodgment?.coverImg || ""}
          onClick={() => onPlaceItem(lodgment._id)}
        />
        <div>
          <S.PlaceTitle>{lodgment.name}</S.PlaceTitle>
          <S.IdText>고유 ID: {lodgment._id}</S.IdText>
        </div>
      </S.PlaceItem>
    </>
  );
}
