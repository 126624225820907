import * as React from "react";
import { Socket } from "socket.io-client";
import { emitResponseForm } from "utils/socket/clientEvent";
import * as S from "./msg.style";

export interface ISubjectiveFormMsgProps {
  socket?: Socket;
  content: {
    templateTitle: string;
    question: string;
    placeholder: string;
    btnText: string;
  };
  role: "user" | "concierge";
}

export default function SubjectiveFormMsg({
  socket,
  content: { templateTitle, question, placeholder, btnText },
  role,
}: ISubjectiveFormMsgProps) {
  const [answer, setAnswer] = React.useState("");
  const onSubmitClick = () => {
    if (!answer) {
      alert("답변이 입력되지 않아 메시지를 전송할 수 없습니다.");
      return;
    }
    const body = {
      title: templateTitle,
      question,
      answers: [answer],
    };
    emitResponseForm(socket, body);
  };
  return (
    <S.MsgWrapper role={role}>
      <S.FormQuestion>{question}</S.FormQuestion>
      <S.SubjectiveFormInput
        value={answer}
        placeholder={placeholder}
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
      />
      <S.BtnMsgBtn onClick={onSubmitClick}>{btnText}</S.BtnMsgBtn>
    </S.MsgWrapper>
  );
}
