import {
  deletePreopenLodgment,
  deletePreopenMultipleLodgment,
  getPreopenLodgments,
  getPreopenMultipleLodgments,
} from "api/preopen";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./preopen.style";

export interface IPreOpenProps {}

export default function PreOpen(props: IPreOpenProps) {
  const [preopenLodgments, setPreopenLodgments] = React.useState([]);
  const [preopenMultipleLodgments, setPreopenMultipleLodgments] =
    React.useState([]);
  const history = useHistory();

  React.useEffect(() => {
    const fetchLodgments = async () => {
      const res = await getPreopenLodgments();
      const response = await getPreopenMultipleLodgments();
      setPreopenLodgments(res);
      setPreopenMultipleLodgments(response);
    };

    fetchLodgments();
  }, []);

  const handleDeleteLodgment = async (e, _id) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      await deletePreopenLodgment(_id);
      setPreopenLodgments(
        preopenLodgments.filter((lodgment) => lodgment._id !== _id)
      );
      alert("삭제되었습니다.");
      return;
    }
    return;
  };

  const handleDeleteMultipleLodgment = async (e, _id) => {
    e.preventDefault();
    if (window.confirm("삭제하시겠습니까?")) {
      await deletePreopenMultipleLodgment(_id);
      setPreopenMultipleLodgments(
        preopenMultipleLodgments.filter((lodgment) => lodgment._id !== _id)
      );
      alert("삭제되었습니다.");
      return;
    }
    return;
  };

  return (
    <>
      <S.Header>
        <h3>pre-open(single)</h3>
        <a href="/preopen/create">페이지 생성하기</a>
      </S.Header>
      <S.ImageContainer>
        {preopenLodgments
          ?.sort((a, b) => b.preference - a.preference)
          .map(({ name, desc, coverImg, _id, preference }) => (
            <>
              <S.ImageBox>
                <S.Frame
                  onClick={() => history.push(`/preopen/detail/single/${_id}`)}
                >
                  <S.SingleImage src={coverImg} />
                </S.Frame>
                <S.TextSection>
                  <S.NameText>{name}</S.NameText>
                  <S.DescText>{desc}</S.DescText>
                </S.TextSection>
                <div>pre: {preference}</div>
                <button onClick={(e) => handleDeleteLodgment(e, _id)}>
                  삭제
                </button>
              </S.ImageBox>
            </>
          ))}
      </S.ImageContainer>
      <h3>pre-open(multiple)</h3>
      {preopenMultipleLodgments.map(({ name, params, _id }) => (
        <div style={{ display: "flex" }}>
          <S.MetaDataBox
            onClick={() => history.push(`preopen/detail/multiple/${_id}`)}
          >
            <div>{name}</div>
            <div className="url">URL: https://hautrip.com/{params}</div>
          </S.MetaDataBox>
          <button onClick={(e) => handleDeleteMultipleLodgment(e, _id)}>
            X
          </button>
        </div>
      ))}
    </>
  );
}
