import styled from "styled-components";

export const DeleteImageButton = styled.div`
  width: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  background-color: red;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  justify-content: center;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const ImgBox = styled.img`
  width: 300px;
  height: 300px;
  margin-bottom: 20px;
`;

export const RegisterButton = styled.div`
  width: 500px;
  height: 100px;
  background-color: royalblue;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Label = styled.label`
  width: 70px;
  font-weight: 600;
  font-size: 18px;
`;

export const Input = styled.input`
  width: 300px;
  margin-left: 20px;
`;

export const GeoCodeButton = styled.div`
  width: 150px;
  padding: 5px 0;
  border-radius: 20px;
  background-color: blue;
  margin-left: 10px;
  color: white;
  text-align: center;
`;
