import * as React from "react";
import * as S from "./accommodationRegister.style";
import axios from "config/axiosConfig";

import { uploadPackageImg } from "api/image";
import { postLodgment, putLodgment } from "api/lodgment";
import { isArrayTypeNode, resolveModuleName } from "typescript";
import Router from "next/router";
import { useHistory } from "react-router-dom";
import ImageField from "common/ImageField";
import { InputField } from "common/Input/Main";
import { uploadFile } from "utils/upload";
import Button from "common/Button";
import { EmptyFileUI, FilledFileUI } from "common/Input/components/FileUI";
import Select from "./components/Select";
import { myAxios } from "api/myAxios";

export interface AccommodationCreateProps {
  data?;
}

export default function AccommodationCreate({
  data,
}: AccommodationCreateProps) {
  const history = useHistory();

  const [name, setName] = React.useState("");
  const [enName, setEnName] = React.useState("");
  const [chName, setChName] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [coverImg, setCoverImg] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("korea");
  const [addressDetail, setAddressDetail] = React.useState("");
  const [fullAddress, setFullAddress] = React.useState("");
  const [hostPhoneNum, setHostPhoneNum] = React.useState("");
  const [naverMapLink, setNaverMapLink] = React.useState("");
  const [longitude, setLongitude] = React.useState(null);
  const [latitude, setLatitude] = React.useState(null);
  const [checkin, setCheckin] = React.useState("");
  const [checkOut, setCheckOut] = React.useState("");
  const [minimumDay, setMinimumDay] = React.useState(null);
  const [availableDates, setAvailableDates] = React.useState([]);
  const [previewPrice, setPreviewPrice] = React.useState(null);
  const [defaultHeadcount, setDefaultHeadcount] = React.useState(null);
  const [maximumHeadcount, setMaximumHeadcount] = React.useState(null);
  const [isEditorPick, setIsEditorPick] = React.useState(false);
  const [convenience, setConvenience] = React.useState([]);
  const [cautions, setCautions] = React.useState([]);
  const [additionalConvenience, setAdditionalConvenience] = React.useState([]);
  const [thanksTo, setThanksTo] = React.useState([]);
  const [instagram, setInstagram] = React.useState("");
  const [blog, setBlog] = React.useState("");
  const [airbnbId, setAirbnbId] = React.useState("");
  const [curation, setCuration] = React.useState([]);
  const [addPersonOptions, setAddPersonOptions] = React.useState([]);
  const [additionalOptions, setAdditionalOptions] = React.useState([]);
  const [preference, setPreference] = React.useState(1);
  const [price, setPrice] = React.useState<Record<string, number>>();
  const [isOpen, setIsOpen] = React.useState<boolean>();
  const [refundRuleImg, setRefundRuleImg] = React.useState<string>();
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const [isSuccessionRequired, setIsSuccessionRequired] =
    React.useState<boolean>(false);
  const [successionDiscountAmounts, setSuccessionDiscountAmounts] =
    React.useState<number[]>([]);
  const [airbnbUrl, setAirbnbUrl] = React.useState("");
  const [checkinImgs, setCheckinImgs] = React.useState([]);
  const [checkinMsg, setCheckinMsg] = React.useState("");
  const [checkoutImgs, setCheckoutImgs] = React.useState([]);
  const [checkoutMsgs, setCheckoutMsgs] = React.useState([]);
  const [checkinMsgsForDDay, setCheckinMsgsForDDay] = React.useState([]);
  const [enCheckinMsg, setEnCheckinMsg] = React.useState("");
  const [enCheckinMsgsForDDay, setEnCheckinMsgsForDDay] = React.useState([]);
  const [refundPolicy, setRefundPolicy] = React.useState({
    notice: "",
    refundStandards: [],
  });
  // update additional states 11.21
  const [headcountNotices, setHeadcountNotices] = React.useState([]);
  const [priceNotices, setPriceNotices] = React.useState([]);
  const [facilities, setFacilities] = React.useState([]);
  const [priceDetails, setPriceDetails] = React.useState([]);
  const [refundRules, setRefundRules] = React.useState({
    normalSeason: [],
    peakSeason: [],
    notices: [],
  });
  const [isEarlySettlement, setIsEarlySettlement] = React.useState<boolean>();
  const [isChargeAddPersonCostForEachDay, setIsChargeAddPersonCostForEachDay] =
    React.useState<boolean>();
  const [maxReservationCount, setMaxReservationCount] =
    React.useState<number>();
  const [events, setEvents] = React.useState([]);
  const [commission, setCommission] = React.useState<number>();

  const { normalSeason, peakSeason, notices } = refundRules;
  // Docent

  const initialValues = {
    docentContents: {
      subTitle: "",
      paragraph: "",
      images: [],
    },
    photoTipsContents: {
      subTitle: "",
      paragraph: "",
      images: [],
    },
    places: {
      name: "",
      category: "",
      summary: "",
      images: [],
      description: "",
      address: "",
    },
    photoTipsThanksTo: {
      instagramId: "",
    },
  };

  const [audio, setAudio] = React.useState("");
  const [slogan, setSlogan] = React.useState("");
  // const [docent, setDocent] = React.useState({
  //   name: "",
  //   contents: [],
  // });
  const [docentLodgmentName, setDocentLodgmentName] = React.useState("");
  const [docentContents, setDocentContents] = React.useState([
    initialValues.docentContents,
  ]);
  const [photoTips, setPhotoTips] = React.useState({});
  const [photoTipsThanksTo, setPhotoTipsThanksTo] = React.useState([
    initialValues.photoTipsThanksTo,
  ]);
  const [photoTipsContents, setPhotoTipsContents] = React.useState([
    initialValues.photoTipsContents,
  ]);
  const [places, setPlaces] = React.useState([initialValues.places]);

  const handleChangeFacilityImg = async (
    idx: number,
    key: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    const copyFacilities = [...facilities];
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("facilityImg", file);
    const {
      data: { facilityImg },
    } = await uploadPackageImg(formData);
    copyFacilities[idx][key] = facilityImg[0];
    setFacilities(copyFacilities);
  };

  const handleDeleteFacilityImg = (idx, key, e) => {
    const copyFacilities = [...facilities];
    copyFacilities[idx][key] = "";
    setFacilities(copyFacilities);
  };

  const handleChangeFacilities = (idx, key, value) => {
    const copyFacilities = [...facilities];
    copyFacilities[idx][key] = value;
    setFacilities(copyFacilities);
  };

  const handleChangeCheckinImgs = async (event) => {
    const file = event.target.files.length ? event.target.files[0] : "";
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("checkinImg", file);

    const { data } = await uploadPackageImg(formData);
    const { checkinImg } = data;

    const updatedCheckinImgs = [...checkinImgs, ...checkinImg];
    setCheckinImgs(updatedCheckinImgs);
  };

  const handleSubtractCheckinImgs = async () => {
    const updatedCheckinImgs = [...checkinImgs];
    updatedCheckinImgs.pop();
    setCheckinImgs(updatedCheckinImgs);
  };

  const handleChangeCheckoutImgs = async (event) => {
    const file = event.target.files.length ? event.target.files[0] : "";
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("checkoutImg", file);

    const { data } = await uploadPackageImg(formData);
    const { checkoutImg } = data;

    const updatedCheckoutImgs = [...checkoutImgs, ...checkoutImg];
    setCheckoutImgs(updatedCheckoutImgs);
  };

  const handleSubtractCheckoutImgs = async () => {
    const updatedCheckoutImgs = [...checkoutImgs];
    updatedCheckoutImgs.pop();
    setCheckoutImgs(updatedCheckoutImgs);
  };

  const handleAddFacilitiesEl = () => {
    const copyFacilities = [...facilities];
    copyFacilities.push({ name: "", img: "" });
    setFacilities(copyFacilities);
  };

  const handleSubtractFacilitiesEl = () => {
    const copyFacilities = [...facilities];
    copyFacilities.pop();
    setFacilities(copyFacilities);
  };

  const handleAddHeadcountNotice = () => {
    const copyHeadcountNotice = [...headcountNotices];
    copyHeadcountNotice.push("");
    setHeadcountNotices(copyHeadcountNotice);
  };

  const handleSubtractHeadcountNotice = () => {
    const copyHeadcountNotice = [...headcountNotices];
    copyHeadcountNotice.pop();
    setHeadcountNotices(copyHeadcountNotice);
  };

  const handleChangeHeadcountNotices = (idx, e) => {
    const copyHeadcountNotice = [...headcountNotices];
    copyHeadcountNotice[idx] = e.target.value;
    setHeadcountNotices(copyHeadcountNotice);
  };
  const handleAddPriceNotice = () => {
    const copyPriceNotice = [...priceNotices];
    copyPriceNotice.push("");
    setPriceNotices(copyPriceNotice);
  };

  const handleSubtractPriceNotice = () => {
    const copyPriceNotice = [...priceNotices];
    copyPriceNotice.pop();
    setPriceNotices(copyPriceNotice);
  };

  const handleChangePriceNotices = (idx, e) => {
    const copyPriceNotice = [...priceNotices];
    copyPriceNotice[idx] = e.target.value;
    setPriceNotices(copyPriceNotice);
  };

  const handleChangePriceDetails = (e, idx, key) => {
    const copyPriceDetails = [...priceDetails];
    copyPriceDetails[idx][key] = e.target.value;
    setPriceDetails(copyPriceDetails);
  };

  const handleAddPriceDetails = () => {
    const copyPriceDetails = [...priceDetails];
    copyPriceDetails.push({ title: "", desc: "" });
    setPriceDetails(copyPriceDetails);
  };

  const handleSubtractPriceDetails = () => {
    const copyPriceDetails = [...priceDetails];
    copyPriceDetails.pop();
    setPriceDetails(copyPriceDetails);
  };
  const onCoverImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files.length ? e.target.files[0] : "";
      if (!file) {
        return;
      }
      const formData = new FormData();
      formData.append("coverImg", file);
      const {
        data: { coverImg },
      } = await uploadPackageImg(formData);
      setCoverImg(coverImg[0]);
    } catch (err) {
      console.dir(err);
    }
  };

  const handleAddNormalSeason = () => {
    const copyNormalSeason = [...normalSeason];
    copyNormalSeason.push({ dDayBefore: "", refundPercentage: "" });
    setRefundRules({ ...refundRules, normalSeason: copyNormalSeason });
  };

  const handleSubtractNormalSeason = () => {
    const copyNormalSeason = [...normalSeason];
    copyNormalSeason.pop();
    setRefundRules({ ...refundRules, normalSeason: copyNormalSeason });
  };
  const handleAddPeakSeason = () => {
    const copyPeakSeason = [...peakSeason];
    copyPeakSeason.push({ dDayBefore: "", refundPercentage: "" });
    setRefundRules({ ...refundRules, peakSeason: copyPeakSeason });
  };
  const handleSubtractPeakSeason = () => {
    const copyPeakSeason = [...peakSeason];
    copyPeakSeason.pop();
    setRefundRules({ ...refundRules, peakSeason: copyPeakSeason });
  };

  const handleAddRefundRulesNotice = () => {
    const copyRefundRulesNotice = [...notices];
    copyRefundRulesNotice.push("");
    setRefundRules({ ...refundRules, notices: copyRefundRulesNotice });
  };
  const handleSubtractRefundRulesNotice = () => {
    const copyRefundRulesNotice = [...notices];
    copyRefundRulesNotice.pop();
    setRefundRules({ ...refundRules, notices: copyRefundRulesNotice });
  };

  const handleAddCheckinMsgsForDDay = () =>
    setCheckinMsgsForDDay([...checkinMsgsForDDay, ""]);
  const handleSubtractCheckinMsgsForDDay = () => {
    const updatedCheckinMsgs = [...checkinMsgsForDDay];
    updatedCheckinMsgs.pop();
    setCheckinMsgsForDDay(updatedCheckinMsgs);
  };

  const handleAddEnCheckinMsgsForDDay = () =>
    setEnCheckinMsgsForDDay([...enCheckinMsgsForDDay, ""]);
  const handleSubtractEnCheckinMsgsForDDay = () => {
    const updatedCheckinMsgs = [...enCheckinMsgsForDDay];
    updatedCheckinMsgs.pop();
    setEnCheckinMsgsForDDay(updatedCheckinMsgs);
  };

  const handleAddCheckoutMsgs = () => setCheckoutMsgs([...checkoutMsgs, ""]);
  const handleSubtractCheckoutMsgs = () => {
    const updatedCheckoutMsgs = [...checkoutMsgs];
    updatedCheckoutMsgs.pop();
    setCheckoutMsgs(updatedCheckoutMsgs);
  };

  const handleChangeNormalSeason = (e, idx, key) => {
    const copyNormalSeason = [...normalSeason];
    copyNormalSeason[idx][key] = e.target.value;
    setRefundRules({ ...refundRules, normalSeason: copyNormalSeason });
  };

  const handleChangePeakSeason = (e, idx, key) => {
    const copyPeakSeason = [...peakSeason];
    copyPeakSeason[idx][key] = e.target.value;
    setRefundRules({ ...refundRules, peakSeason: copyPeakSeason });
  };

  const handleChangeRefundRulesNotice = (idx, e) => {
    const copyNotice = [...notices];
    copyNotice[idx] = e.target.value;
    setRefundRules({ ...refundRules, notices: copyNotice });
  };

  const handleChangeCheckinMsgsForDDay = (idx, e) => {
    const updatedMsgs = [...checkinMsgsForDDay];
    updatedMsgs[idx] = e.target.value;
    setCheckinMsgsForDDay(updatedMsgs);
  };
  const handleChangeEnCheckinMsgsForDDay = (idx, e) => {
    const updatedMsgs = [...enCheckinMsgsForDDay];
    updatedMsgs[idx] = e.target.value;
    setEnCheckinMsgsForDDay(updatedMsgs);
  };
  const handleChangeCheckoutMsgs = (idx, e) => {
    const updatedMsgs = [...checkoutMsgs];
    updatedMsgs[idx] = e.target.value;
    setCheckoutMsgs(updatedMsgs);
  };

  const geoCode = async () => {
    const address = fullAddress;

    const isKorean = country === "korea";

    const {
      data: {
        coord: { latitude, longitude },
      },
    } = await myAxios.get({
      path: `/openApi/geocode/${address}${isKorean ? "" : "?isAbroad=true"}`,
    });

    if (longitude && latitude) {
      setLatitude(latitude);
      setLongitude(longitude);
    }

    return;
  };

  const decreaseAvailableDate = (idx) => {
    const tempAddingAvailableDates = [...availableDates];
    if (idx !== null) {
      tempAddingAvailableDates.splice(idx, 1);
    } else {
      tempAddingAvailableDates.pop();
    }

    setAvailableDates(tempAddingAvailableDates);
  };

  const increaseAvailableDate = () => {
    const tempAddingAvailableDates = [...availableDates];
    tempAddingAvailableDates.push({
      date: "",
      price: "",
    });
    setAvailableDates(tempAddingAvailableDates);
  };

  const onChangeAvailableDate = (type, idx, text) => {
    const tempAvailableDate = [...availableDates];
    // if (type == "date") {
    //   tempAvailableDate[idx][type] = convertUTC(text);
    //   // tempAvailableDate[idx][type] = text;

    // } else {
    tempAvailableDate[idx][type] = text;
    // }
    setAvailableDates(tempAvailableDate);
  };
  const decreaseCautions = (idx) => {
    const tempAddingCautions = [...cautions];
    if (idx !== null) {
      tempAddingCautions.splice(idx, 1);
    } else {
      tempAddingCautions.pop();
    }

    setCautions(tempAddingCautions);
  };

  const increaseCautions = () => {
    const tempAddingCautions = [...cautions];
    tempAddingCautions.push({
      title: "",
      description: "",
    });

    setCautions(tempAddingCautions);
  };
  const onChangeCautions = (type, idx, text) => {
    const tempCaution = [...cautions];
    tempCaution[idx][type] = text;
    setCautions(tempCaution);
  };

  const decreaseAdditionalConvenience = (idx) => {
    const tempAdditionalConvenience = [...additionalConvenience];

    tempAdditionalConvenience.pop();

    setAdditionalConvenience(tempAdditionalConvenience);
  };

  const subtractEachAdditionalConvenience = (e, idx) => {
    const tempAdditionalConvenience = [...additionalConvenience];
    tempAdditionalConvenience.splice(idx, 1);
    setAdditionalConvenience(tempAdditionalConvenience);
  };

  const increaseAdditionalConvenience = () => {
    const tempAdditionalConvenience = [...additionalConvenience];
    tempAdditionalConvenience.push({
      title: "",
      description: "",
    });

    setAdditionalConvenience(tempAdditionalConvenience);
  };
  const onChangeAdditionalConvenience = (type, idx, text) => {
    const tempAdditionalConvenience = [...additionalConvenience];
    tempAdditionalConvenience[idx][type] = text;
    setAdditionalConvenience(tempAdditionalConvenience);
  };

  const onRefundRuleImg = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("refundRuleImg", file);
    const {
      data: { refundRuleImg },
    } = await uploadPackageImg(formData);
    setRefundRuleImg(refundRuleImg[0]);
  };

  const onThanksDeleteBtn = (index) => {
    const updatedThanks = [...thanksTo];
    updatedThanks.splice(index, 1);
    setThanksTo(updatedThanks);
  };

  const onConvenienceDeleteBtn = (index) => {
    const updatedConvenience = [...convenience];
    updatedConvenience.splice(index, 1);
    setConvenience(updatedConvenience);
  };

  const decreaseAddPersonOptions = (idx) => {
    const tempAddingAddPersonOptions = [...addPersonOptions];
    if (idx !== null) {
      tempAddingAddPersonOptions.splice(idx, 1);
    } else {
      tempAddingAddPersonOptions.pop();
    }

    setAddPersonOptions(tempAddingAddPersonOptions);
  };

  const increaseAddPersonOptions = () => {
    const tempAddingAddPersonOptions = [...addPersonOptions];
    tempAddingAddPersonOptions.push({
      age: "",
      price: "",
    });

    setAddPersonOptions(tempAddingAddPersonOptions);
  };

  const onChangeAddPersonOptions = (type, idx, text) => {
    const tempAddingPersonOptions = [...addPersonOptions];
    tempAddingPersonOptions[idx][type] = text;
    setAddPersonOptions(tempAddingPersonOptions);
  };

  const decreaseAdditionalOptions = (idx) => {
    const tempAddingAdditionalOptions = [...additionalOptions];
    if (idx !== null) {
      tempAddingAdditionalOptions.splice(idx, 1);
    } else {
      tempAddingAdditionalOptions.pop();
    }

    setAdditionalOptions(tempAddingAdditionalOptions);
  };

  const increaseAdditionalOptions = () => {
    const tempAddingAdditionalOptions = [...additionalOptions];
    tempAddingAdditionalOptions.push({
      title: "",
      description: "",
      defaultPrice: "",
      addedPrice: "",
      defaultProvisionCount: "",
    });

    setAdditionalOptions(tempAddingAdditionalOptions);
  };

  const onChangeAdditionalOptions = (type, idx, text) => {
    const tempAddingAdditionalOptions = [...additionalOptions];
    tempAddingAdditionalOptions[idx][type] = text;
    setAdditionalOptions(tempAddingAdditionalOptions);
  };

  const decreaseCuration = (idx) => {
    const tempAddingCuration = [...curation];
    if (idx !== null) {
      tempAddingCuration.splice(idx, 1);
    } else {
      tempAddingCuration.pop();
    }

    setCuration(tempAddingCuration);
  };

  const moveCurationUp = (idx) => {
    const tempAddingCuration = [...curation];
    if (idx > 0) {
      tempAddingCuration.splice(idx - 1, 0, tempAddingCuration[idx]);
      tempAddingCuration.splice(idx + 1, 1);
      setCuration(tempAddingCuration);
    }
  };

  const moveCurationDown = (idx) => {
    const tempAddingCuration = [...curation];
    if (idx < tempAddingCuration.length) {
      tempAddingCuration.splice(idx + 2, 0, tempAddingCuration[idx]);
      tempAddingCuration.splice(idx, 1);

      setCuration(tempAddingCuration);
    }
  };

  const increaseCuration = () => {
    const tempAddingCuration = [...curation];
    tempAddingCuration.push({
      title: "",
      description: "",
      imgs: [],
    });

    setCuration(tempAddingCuration);
  };
  const onChangeCuration = (type, idx, text) => {
    const tempCuration = [...curation];
    tempCuration[idx][type] = text;
    setCuration(tempCuration);
  };
  const onPlaceImageDelete = async (index: number, idx: number) => {
    if (!confirm("정말 삭제하시겠습니까?")) {
      return;
    }
    const updatedCuration = [...curation];
    updatedCuration[idx].imgs.splice(index, 1);
    setCuration(updatedCuration);
  };
  const moveCurationImageUp = (index, idx) => {
    const tempAddingCuration = [...curation];
    if (index > 0) {
      tempAddingCuration[idx].imgs.splice(
        index - 1,
        0,
        tempAddingCuration[idx].imgs[index]
      );
      tempAddingCuration[idx].imgs.splice(index + 1, 1);
      setCuration(tempAddingCuration);
    }
  };

  const moveCurationImageDown = (index, idx) => {
    const tempAddingCuration = [...curation];
    if (index < tempAddingCuration[idx].imgs.length) {
      tempAddingCuration[idx].imgs.splice(
        index + 2,
        0,
        tempAddingCuration[idx].imgs[index]
      );
      tempAddingCuration[idx].imgs.splice(index, 1);

      setCuration(tempAddingCuration);
    }
  };

  // Docent
  const uploadFilesAndPopulateData = async (e, state, setState) => {
    const { images } = await uploadFile(e);
    const contents = images.map((image) => ({
      subTitle: "",
      paragraph: "",
      image: image,
    }));
    setState([...state, ...contents]);
  };

  const handleAudioChange = async (e) => {
    try {
      const { images: audios } = await uploadFile(e);
      if (!audios?.[0]) {
        throw new Error();
      }
      setAudio(audios?.[0]);
    } catch (error) {
      alert(
        "업로드하는데 문제가 발생했습니다. 다시 시도해주세요! 계속 실패하면 KEVIN에게!"
      );
    }
  };

  const handleImageChangeInArrayOfObjects = async (e, idx, state, setState) => {
    const temp = [...state];
    const { images } = await uploadFile(e);
    temp[idx].images = images;
    setState(temp);
    return;
  };

  const handleRemoveArrayOfElements = (
    imageIdx,
    contentIdx,
    key,
    state,
    setState
  ) => {
    const temp = [...state];
    temp[contentIdx][key].splice(imageIdx, 1);
    setState(temp);
  };

  const handleChange = (e, setState) => {
    const { value } = e.target;
    setState(value);
  };

  const handleArrayItemChange = (e, idx, state, setState) => {
    const { value, name } = e.target;
    const temp = [...state];
    temp[idx][name] = value;
    setState(temp);
  };

  const handlePlaceCategoryChange = (e, idx, state, setState) => {
    const { value, name } = e.target;
    const temp = [...state];
    const changedName = name.slice(0, 8);
    temp[idx][changedName] = value;
    setState(temp);
  };

  const handleArrayItemRemove = (e, idx, state, setState) => {
    const temp = [...state];
    temp.splice(idx, 1);
    setState(temp);
  };

  const handleObjChange = (e, state, setState) => {
    const { value, name } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleArrayChange = (type: string, state, setState, stateName) => {
    if (type === "add") {
      setState([...state, initialValues[stateName]]);
      return;
    }
    setState(state.slice(0, state.length - 1));
  };

  const handleSelectCountry = (e) => {
    const { value } = e.target;
    setCountry(value);
  };

  const handleSelect = (e) => {
    setCountry(e.target.value);
  };

  React.useEffect(() => {
    console.log("docentContents", docentContents);
  }, [docentContents]);

  React.useEffect(() => {
    console.log("places", places);
  }, [places]);

  const [accommodationIdForUpdate, setAccommodationIdForUpdate] =
    React.useState("");

  const [region, setRegion] = React.useState("");
  console.log(region);
  const onRegionChange = (e) => setRegion(e.target.value);

  const REGIONS = ["제주", "경상", "수도권", "강원", "전라", "충청"];

  const onSubmit = async () => {
    try {
      const data = {
        name,
        globalName: { en: enName, ch: chName },
        coverImg,
        description,
        city,
        country,
        addressDetail,
        fullAddress,
        hostPhoneNum,
        naverMapLink,
        longitude,
        latitude,
        instagram,
        blog,
        airbnbId,
        checkin,
        checkOut,
        minimumDay,
        availableDates,
        defaultHeadcount,
        maximumHeadcount,
        isEditorPick,
        convenience,
        additionalConvenience,
        cautions,
        thanksTo,
        curation,
        addPersonOptions,
        additionalOptions,
        previewPrice,
        preference,
        price,
        isOpen,
        refundRuleImg,
        isVisible,
        isSuccessionRequired,
        successionDiscountAmounts,
        airbnbUrl,
        checkinMsg,
        enCheckinMsg,
        checkinImgs,
        checkoutMsgs,
        checkoutImgs,
        checkinMsgsForDDay,
        enCheckinMsgsForDDay,
        facilities,
        headcountNotices,
        priceNotices,
        priceDetails,
        refundRules,
        isEarlySettlement,
        isChargeAddPersonCostForEachDay,
        maxReservationCount,
        docentLodgmentName,
        docents: docentContents,
        slogan,
        audio: {
          file: audio,
        },
        photoTips: {
          contents: photoTipsContents,
          photoTipsThanksTo: photoTipsThanksTo,
        },
        places,
        events,
        commission,
        region,
      };
      if (accommodationIdForUpdate) {
        await putLodgment(accommodationIdForUpdate, data);
        alert("숙소 정보가 수정되었습니다!");
      } else {
        const res = await postLodgment(data);
        alert("숙소 데이터가 생성되었습니다!");
        const lodgmentId = res.data._id;
        history.replace(`/accommodation/${lodgmentId}`);
      }
    } catch (err) {}
  };

  React.useEffect(() => {
    console.log(country);
  }, [country]);
  React.useEffect(() => {
    if (data) {
      const arrayOfKeys = Object.keys(data?.refundRules || {});
      if (arrayOfKeys.includes("notice")) {
        const filteresRefundRules = {
          normalSeason,
          peakSeason,
          notices,
        };
        setRefundRules(filteresRefundRules);
      }
      if (arrayOfKeys.includes("notices")) {
        setRefundRules(data.refundRules);
      }
      setName(data.name);
      setEnName(data.globalName?.en);
      setChName(data.globalName?.ch);
      setCoverImg(data.coverImg);
      setDescription(data.description);
      setCity(data.city);
      setCountry(data?.country || "korea");
      setAddressDetail(data.addressDetail);
      setFullAddress(data.fullAddress);
      setNaverMapLink(data.naverMapLink);
      setHostPhoneNum(data.hostPhoneNum);
      setLongitude(data.longitude);
      setLatitude(data.latitude);
      setInstagram(data.instagram);
      setBlog(data.blog);
      setAirbnbId(data.airbnbId);
      setCheckin(data.checkin);
      setCheckOut(data.checkOut);
      setMinimumDay(data.minimumDay);
      setAvailableDates(data.availableDates);
      setDefaultHeadcount(data.defaultHeadcount);
      setMaximumHeadcount(data.maximumHeadcount);
      setIsEditorPick(data.isEditorPick);
      setConvenience(data.convenience);
      setAdditionalConvenience(data.additionalConvenience);
      setCautions(data.cautions);
      // setRefundPolicy(data.refundRules);
      setThanksTo(data.thanksTo);
      setCuration(data.curation);
      setAddPersonOptions(data.addPersonOptions);
      setAdditionalOptions(data.additionalOptions);
      setAccommodationIdForUpdate(data._id);
      setPreviewPrice(data.previewPrice);
      setPreference(data.preference);
      setPrice(data.price);
      setIsOpen(data.isOpen);
      setRefundRuleImg(data.refundRuleImg);
      setIsVisible(data.isVisible);
      setIsSuccessionRequired(data.isSuccessionRequired);
      setSuccessionDiscountAmounts(data.successionDiscountAmounts || []);
      setAirbnbUrl(data.airbnbUrl);
      setCheckinImgs(data.checkinImgs);
      setCheckinMsg(data.checkinMsg);
      setCheckinMsgsForDDay(data.checkinMsgsForDDay);
      setEnCheckinMsg(data.enCheckinMsg);
      setEnCheckinMsgsForDDay(data.enCheckinMsgsForDDay);
      setCheckoutMsgs(data.checkoutMsgs);
      setCheckoutImgs(data.checkoutImgs);
      // additional
      // setRefundRules(data.refundRules);
      setFacilities(data.facilities);
      setHeadcountNotices(data.headcountNotices);
      setPriceNotices(data.priceNotices);
      setPriceDetails(data.priceDetails);
      setIsEarlySettlement(data.isEarlySettlement);
      setIsChargeAddPersonCostForEachDay(data.isChargeAddPersonCostForEachDay);
      setMaxReservationCount(data.maxReservationCount);
      setDocentLodgmentName(data.docentLodgmentName);
      setSlogan(data.slogan);
      setAudio(data?.audio?.file || "");
      setDocentContents(data.docents || []);
      setPhotoTipsContents(data.photoTips?.contents || []);
      setPhotoTipsThanksTo(data.photoTips?.photoTipsThanksTo || []);
      setPlaces(data.places || []);
      setEvents(data.events);
      setCommission(data.commission);
      setRegion(data.region);
    }
  }, [data]);
  return (
    <div>
      <button
        onClick={onSubmit}
        style={{
          width: 300,
          height: 200,
          fontSize: 50,
          position: "fixed",
          right: 0,
          bottom: 0,
        }}
      >
        {accommodationIdForUpdate ? "수정하기" : "저장하기"}
      </button>

      <S.CreateLayout>
        <S.LeftColumn>
          <S.InputLayout>
            <S.InputLabel>숙소 이름: </S.InputLabel>
            <S.Input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>숙소 이름(영어): </S.InputLabel>
            <S.Input
              type="text"
              value={enName}
              onChange={(e) => setEnName(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>숙소 이름(중국어): </S.InputLabel>
            <S.Input
              type="text"
              value={chName}
              onChange={(e) => setChName(e.target.value)}
              required
            />
          </S.InputLayout>

          <S.InputLayout>
            <S.InputLabel>지역: </S.InputLabel>
            <select onChange={onRegionChange}>
              <option>선택</option>
              {REGIONS.map((Region) => (
                <option selected={Region === region} value={Region}>
                  {Region}
                </option>
              ))}
            </select>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>에어비앤비 연동 url</S.InputLabel>
            <div
              onClick={() =>
                navigator.clipboard.writeText(
                  `https://hwik.io/api/lodgments/${data?._id}/iCal?userPhone=01062885845`
                )
              }
            >{`https://hwik.io/api/lodgments/${data?._id}/iCal?userPhone=01062885845`}</div>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>에어비앤비 달력 가져오기 url: </S.InputLabel>
            <S.Input
              type="text"
              value={airbnbUrl}
              onChange={(e) => setAirbnbUrl(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>
              국가 : {country}{" "}
              <select onChange={handleSelect}>
                <option>선택</option>
                <option value="korea">한국</option>
                <option value="japan">일본</option>
                <option value="vietnam">베트남</option>
              </select>
            </S.InputLabel>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputHeader>
              <span>편의시설 등록 / 항목 추가 : </span>
              <S.Button onClick={handleAddFacilitiesEl}>+</S.Button>
              <S.Button onClick={handleSubtractFacilitiesEl}>-</S.Button>
            </S.InputHeader>
            <S.InputsContainer>
              {facilities?.map((facility, idx) => (
                <S.InputsBox key={idx}>
                  <ImageField
                    value={facility.img}
                    onChange={(e) => handleChangeFacilityImg(idx, "img", e)}
                    onDelete={(e) => handleDeleteFacilityImg(idx, "img", e)}
                    id={idx}
                    key={idx}
                  />
                  <S.InputField
                    type="text"
                    placeholder="시설 이름"
                    value={facility.name}
                    onChange={(e) =>
                      handleChangeFacilities(idx, "name", e.target.value)
                    }
                  />
                </S.InputsBox>
              ))}
            </S.InputsContainer>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputHeader>
              <span>인원 규정 유의사항</span>
              <S.Button onClick={handleAddHeadcountNotice}>+</S.Button>
              <S.Button onClick={handleSubtractHeadcountNotice}>-</S.Button>
            </S.InputHeader>
            {headcountNotices?.map((el, idx) => (
              <S.Textarea
                placeholder={`인원 규정 유의사항${idx + 1}`}
                value={headcountNotices[idx]}
                onChange={(e) => handleChangeHeadcountNotices(idx, e)}
              />
            ))}
          </S.InputLayout>
          <S.InputLayout>
            <S.InputHeader>
              <span>인원 요금 안내 유의사항</span>
              <S.Button onClick={handleAddPriceNotice}>+</S.Button>
              <S.Button onClick={handleSubtractPriceNotice}>-</S.Button>
            </S.InputHeader>
            {priceNotices?.map((el, idx) => (
              <S.Textarea
                placeholder={`인원 요금 안내 유의사항${idx + 1}`}
                value={priceNotices[idx]}
                onChange={(e) => handleChangePriceNotices(idx, e)}
              />
            ))}
          </S.InputLayout>
          <S.InputLayout>
            <S.InputHeader>
              <span>
                이용 요금 안내 가격 세부 사항 설정 (테이블에 들어갈 내용)
              </span>
              <S.Button onClick={handleAddPriceDetails}>+</S.Button>
              <S.Button onClick={handleSubtractPriceDetails}>-</S.Button>
              <S.InputsContainer>
                {priceDetails?.map(({ title, desc }, idx) => (
                  <S.InputsBox>
                    <S.InputField
                      type="text"
                      placeholder="ex)평수기 or 성수기"
                      value={title}
                      onChange={(e) =>
                        handleChangePriceDetails(e, idx, "title")
                      }
                    />
                    <S.InputField
                      type="number"
                      placeholder="가격(숫자만 입력)"
                      value={desc}
                      onChange={(e) => handleChangePriceDetails(e, idx, "desc")}
                    />
                  </S.InputsBox>
                ))}
              </S.InputsContainer>
            </S.InputHeader>
          </S.InputLayout>
          <S.InputLayout>
            <h3 style={{ width: "900px" }}>환불 규정</h3>
            <span>평수기, 성수기 구분 필요없으면 평수기에만 입력해주세요</span>
            <S.InputHeader>
              <span>평수기</span>
              <S.Button onClick={handleAddNormalSeason}>+</S.Button>
              <S.Button onClick={handleSubtractNormalSeason}>-</S.Button>
              <S.InputsContainer>
                {normalSeason?.map(({ dDayBefore, refundPercentage }, idx) => (
                  <S.InputsBox>
                    <S.InputField
                      type="number"
                      placeholder="ex) 당일 ~전까지 "
                      value={dDayBefore}
                      onChange={(e) =>
                        handleChangeNormalSeason(e, idx, "dDayBefore")
                      }
                    />
                    <S.InputField
                      type="number"
                      placeholder="%환불"
                      value={refundPercentage}
                      onChange={(e) =>
                        handleChangeNormalSeason(e, idx, "refundPercentage")
                      }
                    />
                  </S.InputsBox>
                ))}
              </S.InputsContainer>
            </S.InputHeader>
            <S.InputHeader>
              <span>성수기</span>
              <S.Button onClick={handleAddPeakSeason}>+</S.Button>
              <S.Button onClick={handleSubtractPeakSeason}>-</S.Button>
              <S.InputsContainer>
                {peakSeason?.map(({ dDayBefore, refundPercentage }, idx) => (
                  <S.InputsBox>
                    <S.InputField
                      type="number"
                      placeholder="ex) 당일 ~전까지 "
                      value={dDayBefore}
                      onChange={(e) =>
                        handleChangePeakSeason(e, idx, "dDayBefore")
                      }
                    />
                    <S.InputField
                      type="number"
                      placeholder="%환불"
                      value={refundPercentage}
                      onChange={(e) =>
                        handleChangePeakSeason(e, idx, "refundPercentage")
                      }
                    />
                  </S.InputsBox>
                ))}
              </S.InputsContainer>
            </S.InputHeader>
            <S.InputHeader>
              <span>환불 유의사항</span>
              <S.Button onClick={handleAddRefundRulesNotice}>+</S.Button>
              <S.Button onClick={handleSubtractRefundRulesNotice}>-</S.Button>
              {notices?.map((el, idx) => (
                <S.Textarea
                  placeholder={`환불 유의사항${idx + 1}`}
                  value={notices[idx]}
                  onChange={(e) => handleChangeRefundRulesNotice(idx, e)}
                />
              ))}
            </S.InputHeader>
          </S.InputLayout>

          <S.InputLayout>
            {coverImg && (
              <img
                src={coverImg}
                style={{
                  width: "10rem",
                }}
              />
            )}
            <br />
            <S.InputLabel htmlFor="coverImg">커버 이미지: </S.InputLabel>
            <S.Input
              id="coverImg"
              type="file"
              accept="image/*"
              onChange={onCoverImg}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>설명: </S.InputLabel>
            <S.Textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>도시 주소: </S.InputLabel>
            <S.Input
              type="text"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>세부 주소(상세페이지용): </S.InputLabel>
            <S.Input
              type="text"
              value={addressDetail}
              onChange={(e) => setAddressDetail(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>풀 주소: </S.InputLabel>
            <S.Input
              type="text"
              value={fullAddress}
              onChange={(e) => setFullAddress(e.target.value)}
              required
            />
            <button onClick={() => geoCode()}>위도 경도 변환</button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>네이버 지도 링크: </S.InputLabel>
            <S.Input
              type="text"
              value={naverMapLink}
              onChange={(e) => setNaverMapLink(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>선호도(얼마나 위에 보여주는지): </S.InputLabel>
            <S.Input
              type="number"
              value={preference}
              onChange={(e) => setPreference(parseInt(e.target.value))}
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>위도: </S.InputLabel>
            <S.Input
              type="number"
              value={latitude}
              onChange={(e) => setLatitude(parseFloat(e.target.value))}
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>경도: </S.InputLabel>
            <S.Input
              type="number"
              value={longitude}
              onChange={(e) => setLongitude(parseFloat(e.target.value))}
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>인스타그램 주소: </S.InputLabel>
            <S.Input
              type="text"
              value={instagram}
              onChange={(e) => setInstagram(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>블로그(홈페이지) 주소: </S.InputLabel>
            <S.Input
              type="text"
              value={blog}
              onChange={(e) => setBlog(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>호스트 전화번호 : </S.InputLabel>
            <S.Input
              type="text"
              value={hostPhoneNum}
              onChange={(e) => setHostPhoneNum(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>에어비앤비 아이디 ID: </S.InputLabel>
            <S.Input
              type="text"
              value={airbnbId}
              onChange={(e) => setAirbnbId(e.target.value)}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>체크인 시간: </S.InputLabel>
            <S.Input
              type="text"
              value={checkin}
              onChange={(e) => setCheckin(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>체크아웃 시간: </S.InputLabel>
            <S.Input
              type="text"
              value={checkOut}
              onChange={(e) => setCheckOut(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>최소 숙박 기간: </S.InputLabel>
            <S.Input
              type="number"
              value={minimumDay}
              onChange={(e) => setMinimumDay(parseInt(e.target.value))}
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>대표 가격(보여지는 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={previewPrice}
              onChange={(e) => setPreviewPrice(parseInt(e.target.value))}
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>평일 가격(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.weekday}
              onChange={(e) =>
                setPrice({
                  ...price,
                  weekday: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>주말 가격(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.weekend}
              onChange={(e) =>
                setPrice({
                  ...price,
                  weekend: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>성수기 가격(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.peakSeason}
              onChange={(e) =>
                setPrice({
                  ...price,
                  peakSeason: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>사용자 정의 가격(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.custom}
              onChange={(e) =>
                setPrice({
                  ...price,
                  custom: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>사용자 정의 가격2(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.secondCustom}
              onChange={(e) =>
                setPrice({
                  ...price,
                  secondCustom: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>사용자 정의 가격3(실제 결제 가격) :</S.InputLabel>
            <S.Input
              type="number"
              value={price?.thirdCustom}
              onChange={(e) =>
                setPrice({
                  ...price,
                  thirdCustom: parseInt(e.target.value),
                })
              }
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              required
            />
          </S.InputLayout>
          <br />
          <br />
          <S.InputLayout>
            <S.InputLabel>기본 숙박 인원: </S.InputLabel>
            <S.Input
              type="number"
              value={defaultHeadcount}
              onChange={(e) => setDefaultHeadcount(parseInt(e.target.value))}
              required
              onWheel={() => (document.activeElement as HTMLElement).blur()}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>최대 숙박 인원: </S.InputLabel>
            <S.Input
              type="number"
              value={maximumHeadcount}
              onChange={(e) => setMaximumHeadcount(parseInt(e.target.value))}
              required
              onWheel={() => (document.activeElement as HTMLElement).blur()}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>최대 예약 가능 박수: </S.InputLabel>
            <S.Input
              type="number"
              value={maxReservationCount}
              onChange={(e) => setMaxReservationCount(parseInt(e.target.value))}
              required
              onWheel={() => (document.activeElement as HTMLElement).blur()}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>수수료: </S.InputLabel>
            <S.Input
              type="number"
              value={commission}
              onChange={(e) => setCommission(parseFloat(e.target.value))}
              required
              onWheel={() => (document.activeElement as HTMLElement).blur()}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>에디터 픽: </S.InputLabel>
            <button onClick={() => setIsEditorPick(!isEditorPick)}>
              {isEditorPick ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>예약 날짜 공개: </S.InputLabel>
            <button onClick={() => setIsOpen(!isOpen)}>
              {isOpen ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>노출 여부: </S.InputLabel>
            <button onClick={() => setIsVisible(!isVisible)}>
              {isVisible ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>연박 필수 여부: </S.InputLabel>
            <button
              onClick={() => setIsSuccessionRequired(!isSuccessionRequired)}
            >
              {isSuccessionRequired ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>빠른 정산 특약: </S.InputLabel>
            <button onClick={() => setIsEarlySettlement(!isEarlySettlement)}>
              {isEarlySettlement ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>인원 추가 비용 중복 부과 여부: </S.InputLabel>
            <button
              onClick={() =>
                setIsChargeAddPersonCostForEachDay(
                  !isChargeAddPersonCostForEachDay
                )
              }
            >
              {isChargeAddPersonCostForEachDay ? "✅" : "❌"}
            </button>
          </S.InputLayout>
          <br />
          <br />
          <div>
            <div
              style={{
                display: "flex",
                width: "25rem",
                justifyContent: "space-between",
              }}
            >
              <h3>연박 할인 금액</h3>
              <div>
                <button
                  style={{ height: 30, width: 30, marginLeft: 0, padding: 0 }}
                  onClick={() => {
                    const updatedDiscountAmounts =
                      successionDiscountAmounts.slice(
                        0,
                        successionDiscountAmounts.length - 1
                      );
                    setSuccessionDiscountAmounts(updatedDiscountAmounts);
                  }}
                >
                  -
                </button>
                <button
                  onClick={() =>
                    setSuccessionDiscountAmounts([
                      ...successionDiscountAmounts,
                      0,
                    ])
                  }
                  style={{ height: 30, width: 30, marginLeft: 5, padding: 0 }}
                >
                  +
                </button>
              </div>
            </div>
            <div>
              {successionDiscountAmounts?.map((discountAmount, index) => (
                <div
                  style={{
                    display: "flex",
                    width: "20rem",
                    justifyContent: "space-between",
                  }}
                >
                  <p style={{ fontSize: "1.5rem" }}>{index + 2}박</p>
                  <input
                    type="number"
                    onWheel={() =>
                      (document.activeElement as HTMLElement).blur()
                    }
                    value={discountAmount}
                    onChange={(e) => {
                      const updatedDiscountAmounts = [
                        ...successionDiscountAmounts,
                      ];
                      updatedDiscountAmounts[index] = parseInt(
                        e.target.value,
                        10
                      );
                      setSuccessionDiscountAmounts(updatedDiscountAmounts);
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          <br />
          <br />
          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>주의 사항</label>
            <button
              onClick={decreaseCautions}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseCautions}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>
          {cautions?.map((el, idx) => {
            return (
              <div>
                <label style={{ width: 130, marginRight: 10 }}>
                  주의 사항{idx + 1}
                </label>
                <S.Textarea
                  placeholder="주의사항 제목"
                  value={cautions[idx].title}
                  onChange={(e) => {
                    onChangeCautions("title", idx, e.target.value);
                  }}
                />

                <S.Textarea
                  placeholder="주의사항 상세"
                  value={cautions[idx].description}
                  onChange={(e) => {
                    onChangeCautions("description", idx, e.target.value);
                  }}
                />
                <button onClick={(e) => decreaseCautions(idx)}>-</button>
              </div>
            );
          })}

          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>
              환불 관련 조항
            </label>
            {/* <S.InputLayout>
              <S.InputLabel>환불 주의 사항: </S.InputLabel>
              <S.Textarea
                value={refundRules.notice}
                onChange={(e) =>
                  onChangeRefunds("notice", null, null, e.target.value)
                }
                required
              />
            </S.InputLayout> */}
            {/* <button
              onClick={decreaseRefunds}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseRefunds}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button> */}
          </div>
          <S.InputLayout>
            {refundRuleImg && (
              <img
                src={refundRuleImg}
                style={{
                  width: "10rem",
                }}
              />
            )}
            <br />
            <S.InputLabel htmlFor="refundRuleImg">
              환불 규정 이미지:{" "}
            </S.InputLabel>
            <S.Input
              id="refundRuleImg"
              type="file"
              accept="image/*"
              onChange={onRefundRuleImg}
            />
            <button onClick={() => setRefundRuleImg("")}>이미지 제거</button>
          </S.InputLayout>
          <h3 style={{ fontSize: "1.3rem", marginTop: "2rem" }}>
            자동 전송 메시지
          </h3>
          <S.InputLayout>
            <S.InputLabel>예약 확정 시 자동 발송 메시지: </S.InputLabel>
            <S.Textarea
              value={checkinMsg}
              onChange={(e) => setCheckinMsg(e.target.value)}
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>
              예약 확정 시 자동 발송 메시지(english):{" "}
            </S.InputLabel>
            <S.Textarea
              value={enCheckinMsg}
              onChange={(e) => setEnCheckinMsg(e.target.value)}
            />
          </S.InputLayout>
          <S.InputLayout>
            {checkinImgs?.length > 0 &&
              checkinImgs?.map((img) => (
                <img
                  src={img}
                  style={{
                    width: "10rem",
                    marginRight: "0.5rem",
                  }}
                />
              ))}
            <br />
            <S.InputLabel htmlFor="checkinImg">
              체크인 시 자동 전송 이미지:{" "}
            </S.InputLabel>
            <S.Input
              id="checkinImg"
              type="file"
              accept="image/*"
              onChange={handleChangeCheckinImgs}
            />
            <button onClick={handleSubtractCheckinImgs}>
              체크인 이미지 제거 -
            </button>
          </S.InputLayout>
          <S.InputHeader>
            <span>체크인 시 자동 전송 메시지</span>
            <S.Button onClick={handleAddCheckinMsgsForDDay}>+</S.Button>
            <S.Button onClick={handleSubtractCheckinMsgsForDDay}>-</S.Button>
            {checkinMsgsForDDay?.map((_, idx) => (
              <S.Textarea
                placeholder={`체크인 자동 메시지${idx + 1}`}
                value={checkinMsgsForDDay[idx]}
                onChange={(e) => handleChangeCheckinMsgsForDDay(idx, e)}
              />
            ))}
          </S.InputHeader>
          <S.InputHeader>
            <span>체크인 시 자동 전송 메시지(english)</span>
            <S.Button onClick={handleAddEnCheckinMsgsForDDay}>+</S.Button>
            <S.Button onClick={handleSubtractEnCheckinMsgsForDDay}>-</S.Button>
            {enCheckinMsgsForDDay?.map((_, idx) => (
              <S.Textarea
                placeholder={`체크인 자동 메시지${idx + 1}`}
                value={enCheckinMsgsForDDay[idx]}
                onChange={(e) => handleChangeEnCheckinMsgsForDDay(idx, e)}
              />
            ))}
          </S.InputHeader>

          <S.InputLayout>
            {checkoutImgs?.length > 0 &&
              checkoutImgs.map((img) => (
                <img
                  src={img}
                  style={{
                    width: "10rem",
                    marginRight: "0.5rem",
                  }}
                />
              ))}
            <br />
            <S.InputLabel htmlFor="checkoutImg">
              체크아웃 시 자동 전송 이미지:{" "}
            </S.InputLabel>
            <S.Input
              id="checkoutImg"
              type="file"
              accept="image/*"
              onChange={handleChangeCheckoutImgs}
            />
            <button onClick={handleSubtractCheckoutImgs}>
              체크아웃 이미지 제거 -
            </button>
          </S.InputLayout>
          <S.InputHeader>
            <span>체크아웃 시 자동 전송 메시지</span>
            <S.Button onClick={handleAddCheckoutMsgs}>+</S.Button>
            <S.Button onClick={handleSubtractCheckoutMsgs}>-</S.Button>
            {checkoutMsgs?.map((_, idx) => (
              <S.Textarea
                placeholder={`체크아웃 자동 메시지${idx + 1}`}
                value={checkoutMsgs[idx]}
                onChange={(e) => handleChangeCheckoutMsgs(idx, e)}
              />
            ))}
          </S.InputHeader>
          <S.InputLayout>
            {/* </S.InputLayout>
          {refundRules.refundStandards.map((el, idx) => {
            return (
              <div>
                <label style={{ width: 130, marginRight: 10 }}>
                  환불 주의 사항{idx + 1}
                </label>
                <input
                  type="number"
                  placeholder="환불 몇일 전"
                  value={refundRules.refundStandards[idx].dDayBefore}
                  onChange={(e) => {
                    onChangeRefunds(
                      "refundStandards",
                      idx,
                      "dDayBefore",
                      parseInt(e.target.value)
                    );
                  }}
                />
                <input
                  type="number"
                  placeholder="환불 퍼센트"
                  value={refundRules.refundStandards[idx].refundPercentage}
                  onChange={(e) => {
                    onChangeRefunds(
                      "refundStandards",
                      idx,
                      "refundPercentage",
                      parseInt(e.target.value)
                    );
                  }}
                />
                <button onClick={(e) => decreaseRefunds(idx)}>-</button>
              </div>
            );
          })}
          <S.InputLayout> */}
            <S.InputLabel>출처(thanksTo)</S.InputLabel>
            <button
              onClick={() => {
                setThanksTo([...thanksTo, ""]);
              }}
            >
              +
            </button>
            <br />
            {thanksTo?.map((thanks, index) => (
              <div style={{ display: "flex" }}>
                <S.Input
                  type="text"
                  value={thanks}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedThanks = [...thanksTo];
                    updatedThanks[index] = e.target.value;
                    setThanksTo(updatedThanks);
                  }}
                />
                <button onClick={() => onThanksDeleteBtn(index)}>삭제</button>
              </div>
            ))}
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>주요 편의시설</S.InputLabel>
            <button
              onClick={() => {
                setConvenience([...convenience, ""]);
              }}
            >
              +
            </button>
            <br />
            {convenience.map((el, index) => (
              <div style={{ display: "flex" }}>
                <S.Input
                  type="text"
                  value={el}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const updatedConvenience = [...convenience];
                    updatedConvenience[index] = e.target.value;
                    setConvenience(updatedConvenience);
                  }}
                />
                <button onClick={() => onConvenienceDeleteBtn(index)}>
                  삭제
                </button>
              </div>
            ))}
          </S.InputLayout>
          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>편의 시설</label>
            <button
              onClick={decreaseAdditionalConvenience}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseAdditionalConvenience}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>
          {additionalConvenience?.map((el, idx) => {
            return (
              <div>
                <label style={{ width: 130, marginRight: 10 }}>
                  편의 시설{idx + 1}
                </label>
                <textarea
                  placeholder="편의시설 제목"
                  value={additionalConvenience[idx].title}
                  onChange={(e) => {
                    onChangeAdditionalConvenience("title", idx, e.target.value);
                  }}
                  rows={3}
                  cols={15}
                />

                <textarea
                  placeholder="편의시설 상세"
                  value={additionalConvenience[idx].description}
                  onChange={(e) => {
                    onChangeAdditionalConvenience(
                      "description",
                      idx,
                      e.target.value
                    );
                  }}
                  rows={3}
                  cols={60}
                />
                <button
                  onClick={(e) => subtractEachAdditionalConvenience(e, idx)}
                >
                  -
                </button>
              </div>
            );
          })}

          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>
              추가 인원 옵션
            </label>
            <button
              onClick={decreaseAddPersonOptions}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseAddPersonOptions}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>
          {addPersonOptions?.map((el, idx) => {
            return (
              <div>
                <label style={{ width: 130, marginRight: 10 }}>
                  추가 인원 옵션{idx + 1}
                </label>
                <input
                  type="text"
                  placeholder="나이 기준 (ex.8세 미만)"
                  value={addPersonOptions[idx].age}
                  onChange={(e) => {
                    onChangeAddPersonOptions("age", idx, e.target.value);
                  }}
                />
                <input
                  type="number"
                  placeholder="추가 인원당 가격"
                  value={addPersonOptions[idx].price}
                  onChange={(e) => {
                    onChangeAddPersonOptions(
                      "price",
                      idx,
                      parseInt(e.target.value)
                    );
                  }}
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                />
                <button onClick={(e) => decreaseAddPersonOptions(idx)}>
                  -
                </button>
              </div>
            );
          })}
          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>
              숙박 추가 옵션(자쿠지,바베큐 등)
            </label>
            <button
              onClick={decreaseAdditionalOptions}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseAdditionalOptions}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>
          {additionalOptions?.map((el, idx) => {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <label style={{ width: 130, marginRight: 10 }}>
                  추가 시설 옵션{idx + 1}
                </label>

                <input
                  type="text"
                  placeholder="추가 옵션 이름(ex.바베큐)"
                  value={additionalOptions[idx].title}
                  onChange={(e) => {
                    onChangeAdditionalOptions("title", idx, e.target.value);
                  }}
                />
                <textarea
                  placeholder="설명"
                  value={additionalOptions[idx].description}
                  onChange={(e) => {
                    onChangeAdditionalOptions(
                      "description",
                      idx,
                      e.target.value
                    );
                  }}
                  cols={40}
                  rows={5}
                />
                <input
                  type="number"
                  placeholder="고정 가격"
                  value={additionalOptions[idx].defaultPrice}
                  onChange={(e) => {
                    onChangeAdditionalOptions(
                      "defaultPrice",
                      idx,
                      parseInt(e.target.value)
                    );
                  }}
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                />
                <input
                  type="number"
                  placeholder="추가가격"
                  value={additionalOptions[idx].addedPrice}
                  onChange={(e) => {
                    onChangeAdditionalOptions(
                      "addedPrice",
                      idx,
                      parseInt(e.target.value)
                    );
                  }}
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                />
                <input
                  type="number"
                  placeholder="고정 인원 수 (추가 비용 없는 인원 수)"
                  value={additionalOptions[idx].defaultProvisionCount}
                  onChange={(e) => {
                    onChangeAdditionalOptions(
                      "defaultProvisionCount",
                      idx,
                      parseInt(e.target.value)
                    );
                  }}
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                />
                <button onClick={(e) => decreaseAdditionalOptions(idx)}>
                  -
                </button>
              </div>
            );
          })}
          <hr />
          <div>
            <h3>진행중인 이벤트</h3>
            <button
              onClick={() => {
                setEvents([
                  ...events,
                  {
                    title: "",
                    description: "",
                    image: "",
                    notice: "",
                  },
                ]);
              }}
            >
              +
            </button>
            <button
              onClick={() => {
                setEvents(events.slice(0, events.length - 1));
              }}
            >
              -
            </button>
            <div>
              {events?.map((_, index) => (
                <div
                  style={{ border: "0.1rem solid black", width: "fit-content" }}
                >
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "25%" }}>이벤트 제목:</p>{" "}
                    <textarea
                      onChange={(e) => {
                        const updatedEvents = [...events];
                        updatedEvents[index].title = e.target.value;
                        setEvents(updatedEvents);
                      }}
                      value={events[index].title}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "25%" }}>이벤트 내용:</p>{" "}
                    <textarea
                      style={{ width: "29.5rem" }}
                      onChange={(e) => {
                        const updatedEvents = [...events];
                        updatedEvents[index].description = e.target.value;
                        setEvents(updatedEvents);
                      }}
                      value={events[index].description}
                    />
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "25%" }}>이벤트 사진:</p>{" "}
                    <input
                      type="file"
                      onChange={async (e) => {
                        const file = e.target.files.length
                          ? e.target.files[0]
                          : "";
                        if (!file) {
                          return;
                        }
                        const formData = new FormData();
                        formData.append("eventImg", file);
                        const {
                          data: { eventImg },
                        } = await uploadPackageImg(formData);

                        const updatedEvents = [...events];
                        updatedEvents[index].image = eventImg[0];
                        setEvents(updatedEvents);
                      }}
                    />
                    {events[index].image && (
                      <>
                        <img
                          style={{ width: "10rem" }}
                          src={events[index].image}
                        />
                        <button
                          onClick={() => {
                            const updatedEvents = [...events];
                            updatedEvents[index].image = "";
                            setEvents(updatedEvents);
                          }}
                        >
                          삭제
                        </button>
                      </>
                    )}
                  </div>
                  <div style={{ display: "flex" }}>
                    <p style={{ width: "25%" }}>이벤트 안내사항(주의사항):</p>{" "}
                    <textarea
                      style={{ width: "29.5rem" }}
                      onChange={(e) => {
                        const updatedEvents = [...events];
                        updatedEvents[index].notice = e.target.value;
                        setEvents(updatedEvents);
                      }}
                      value={events[index].notice}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <hr />
          <S.SubTitle>Docent</S.SubTitle>
          <InputField>
            <S.InputBox>
              <InputField.Label label="숙소 또는 객실 이름" />
              <InputField.TextArea
                name="name"
                value={docentLodgmentName}
                placeholder="도슨트 컨텐츠에 보여질 이름을 입력해주세요."
                onChange={(e) => handleChange(e, setDocentLodgmentName)}
              />
            </S.InputBox>
            <S.InputBox>
              <InputField.Label label="슬로건" />
              <InputField.TextArea
                name="slogan"
                value={slogan}
                placeholder="숙소의 슬로건을 입력해주세요."
                onChange={(e) => handleChange(e, setSlogan)}
              />
            </S.InputBox>
            <S.InputBox>
              <InputField.Label label="음성파일" />
              <InputField.File
                name="audio"
                value={audio}
                accept="audio/*"
                onChange={handleAudioChange}
              />
            </S.InputBox>
            <S.AudioBox>
              <S.Audio src={audio} controls />
            </S.AudioBox>
            <S.SubTitleBox>
              <S.ItemTitle>숙소 이야기 디테일</S.ItemTitle>
              <S.ButtonGroup>
                <Button
                  onClick={() =>
                    handleArrayChange(
                      "add",
                      docentContents,
                      setDocentContents,
                      "docentContents"
                    )
                  }
                >
                  항목 추가하기
                </Button>
                <Button
                  onClick={() =>
                    handleArrayChange(
                      "remove",
                      docentContents,
                      setDocentContents,
                      "docentContents"
                    )
                  }
                >
                  항목 제거하기
                </Button>
              </S.ButtonGroup>
              {/* <Button>
                <InputField.Label label="이미지 여러개 불러오기" />
              </Button> */}
              {/* <InputField.File
                name="image"
                id="이미지 여러개 불러오기"
                display="none"
                multiple={true}
                onChange={(e) =>
                  uploadFilesAndPopulateData(
                    e,
                    docentContents,
                    setDocentContents
                  )
                }
              /> */}
            </S.SubTitleBox>
            {docentContents.map(
              ({ subTitle, paragraph, images }, contentIdx) => (
                <S.DocentContentBox>
                  <S.FigureColumn>
                    <InputField.TextArea
                      name="subTitle"
                      value={subTitle || ""}
                      placeholder="소제목(없으면 빈칸으로 두세요!)"
                      onChange={(e) => {
                        handleArrayItemChange(
                          e,
                          contentIdx,
                          docentContents,
                          setDocentContents
                        );
                      }}
                    />
                    <InputField.TextArea
                      name="paragraph"
                      value={paragraph || ""}
                      placeholder="이야기를 입력해주세요(없으면 빈칸으로 두세요!)"
                      onChange={(e) => {
                        handleArrayItemChange(
                          e,
                          contentIdx,
                          docentContents,
                          setDocentContents
                        );
                      }}
                    />
                    <S.DocentFigureContainer>
                      {images.length > 0 &&
                        images.map((image, imageIdx) => (
                          <S.DocentFigure>
                            <FilledFileUI
                              name="image"
                              value={image || ""}
                              onRemove={() =>
                                handleRemoveArrayOfElements(
                                  imageIdx,
                                  contentIdx,
                                  "images",
                                  docentContents,
                                  setDocentContents
                                )
                              }
                            />
                          </S.DocentFigure>
                        ))}
                    </S.DocentFigureContainer>
                    {!images.length && (
                      <S.DocentFigure>
                        <EmptyFileUI id={`docentImage-${contentIdx}`} />
                      </S.DocentFigure>
                    )}
                    <InputField.File
                      name="image"
                      id={`docentImage-${contentIdx}`}
                      display="none"
                      multiple={true}
                      onChange={(e) =>
                        handleImageChangeInArrayOfObjects(
                          e,
                          contentIdx,
                          docentContents,
                          setDocentContents
                        )
                      }
                    />
                  </S.FigureColumn>
                  <S.ActionColumn>
                    <Button
                      onClick={(e) =>
                        handleArrayItemRemove(
                          e,
                          contentIdx,
                          docentContents,
                          setDocentContents
                        )
                      }
                    >
                      삭제
                    </Button>
                  </S.ActionColumn>
                </S.DocentContentBox>
              )
            )}
            <S.SubTitleBox>
              <S.ItemTitle>포토 레퍼런스</S.ItemTitle>
              <S.ButtonGroup>
                <Button
                  onClick={() =>
                    handleArrayChange(
                      "add",
                      photoTipsContents,
                      setPhotoTipsContents,
                      "photoTipsContents"
                    )
                  }
                >
                  항목 추가하기
                </Button>
                <Button
                  onClick={() =>
                    handleArrayChange(
                      "remove",
                      photoTipsContents,
                      setPhotoTipsContents,
                      "photoTipsContents"
                    )
                  }
                >
                  항목 제거하기
                </Button>
              </S.ButtonGroup>
            </S.SubTitleBox>
            <InputField.Label label="인스타그램 아이디" />
            <Button
              onClick={() =>
                handleArrayChange(
                  "add",
                  photoTipsThanksTo,
                  setPhotoTipsThanksTo,
                  "photoTipsThanksTo"
                )
              }
            >
              +
            </Button>
            <Button
              onClick={() =>
                handleArrayChange(
                  "remove",
                  photoTipsThanksTo,
                  setPhotoTipsThanksTo,
                  "photoTipsThanksTo"
                )
              }
            >
              -
            </Button>
            {photoTipsThanksTo?.map(({ instagramId }, idx) => (
              <S.OptionList>
                <S.OptionItem>
                  <InputField.Input
                    type="text"
                    name="instagramId"
                    value={instagramId}
                    onChange={(e) =>
                      handleArrayItemChange(
                        e,
                        idx,
                        photoTipsThanksTo,
                        setPhotoTipsThanksTo
                      )
                    }
                    backgroundColor="#F2F0E8"
                  />
                </S.OptionItem>
              </S.OptionList>
            ))}
            {photoTipsContents.map(
              ({ subTitle, paragraph, images }, contentIdx) => (
                <S.DocentContentBox>
                  <S.FigureColumn>
                    <InputField.TextArea
                      name="subTitle"
                      value={subTitle || ""}
                      placeholder="소제목(없으면 빈칸으로 두세요!)"
                      onChange={(e) => {
                        handleArrayItemChange(
                          e,
                          contentIdx,
                          photoTipsContents,
                          setPhotoTipsContents
                        );
                      }}
                    />
                    <InputField.TextArea
                      name="paragraph"
                      value={paragraph || ""}
                      placeholder="이야기를 입력해주세요(없으면 빈칸으로 두세요!)"
                      onChange={(e) => {
                        handleArrayItemChange(
                          e,
                          contentIdx,
                          photoTipsContents,
                          setPhotoTipsContents
                        );
                      }}
                    />
                    <S.DocentFigureContainer>
                      {images.length > 0 &&
                        images.map((image, imageIdx) => (
                          <S.DocentFigure>
                            <FilledFileUI
                              name="image"
                              value={image || ""}
                              onRemove={() =>
                                handleRemoveArrayOfElements(
                                  imageIdx,
                                  contentIdx,
                                  "images",
                                  photoTipsContents,
                                  setPhotoTipsContents
                                )
                              }
                            />
                          </S.DocentFigure>
                        ))}
                    </S.DocentFigureContainer>
                    {!images.length && (
                      <S.DocentFigure>
                        <EmptyFileUI id={`photoTipsImage-${contentIdx}`} />
                      </S.DocentFigure>
                    )}
                    <InputField.File
                      name="image"
                      id={`photoTipsImage-${contentIdx}`}
                      display="none"
                      multiple={true}
                      onChange={(e) =>
                        handleImageChangeInArrayOfObjects(
                          e,
                          contentIdx,
                          photoTipsContents,
                          setPhotoTipsContents
                        )
                      }
                    />
                  </S.FigureColumn>
                  <S.ActionColumn>
                    <Button
                      onClick={(e) =>
                        handleArrayItemRemove(
                          e,
                          contentIdx,
                          photoTipsContents,
                          setPhotoTipsContents
                        )
                      }
                    >
                      삭제
                    </Button>
                  </S.ActionColumn>
                </S.DocentContentBox>
              )
            )}
            <S.SubTitleBox>
              <S.ItemTitle>장소 추천</S.ItemTitle>
              <S.ButtonGroup>
                <Button
                  onClick={() =>
                    handleArrayChange("add", places, setPlaces, "places")
                  }
                >
                  항목 추가하기
                </Button>
                <Button
                  onClick={() =>
                    handleArrayChange("remove", places, setPlaces, "places")
                  }
                >
                  항목 제거하기
                </Button>
              </S.ButtonGroup>
            </S.SubTitleBox>
            <S.SubTitleBox>
              <S.ItemTitle>장소 추천</S.ItemTitle>
              <S.ButtonGroup>
                <Button
                  onClick={() =>
                    handleArrayChange("add", places, setPlaces, "places")
                  }
                >
                  항목 추가하기
                </Button>
                <Button
                  onClick={() =>
                    handleArrayChange("remove", places, setPlaces, "places")
                  }
                >
                  항목 제거하기
                </Button>
              </S.ButtonGroup>
            </S.SubTitleBox>
            {places.map(
              (
                { name, category, summary, images, description, address },
                contentIdx
              ) => (
                <S.DocentContentBox>
                  <S.InputContainer>
                    <S.DocentFigureContainer>
                      {images.length > 0 &&
                        images.map((image, imageIdx) => (
                          <S.DocentFigure>
                            <FilledFileUI
                              name="image"
                              value={image || ""}
                              onRemove={() =>
                                handleRemoveArrayOfElements(
                                  imageIdx,
                                  contentIdx,
                                  "images",
                                  places,
                                  setPlaces
                                )
                              }
                            />
                          </S.DocentFigure>
                        ))}
                      {!images.length && (
                        <S.DocentFigure>
                          <EmptyFileUI id={`placeImage-${contentIdx}`} />
                        </S.DocentFigure>
                      )}
                    </S.DocentFigureContainer>
                    <InputField.File
                      name="image"
                      id={`placeImage-${contentIdx}`}
                      display="none"
                      multiple={true}
                      onChange={(e) =>
                        handleImageChangeInArrayOfObjects(
                          e,
                          contentIdx,
                          places,
                          setPlaces
                        )
                      }
                    />
                    <InputField.Input
                      type="text"
                      name="name"
                      value={name || ""}
                      placeholder="식당 이름"
                      onChange={(e) =>
                        handleArrayItemChange(e, contentIdx, places, setPlaces)
                      }
                      backgroundColor="#F2F0E8"
                    />
                    <InputField.Input
                      type="text"
                      name="summary"
                      value={summary}
                      placeholder="요약"
                      onChange={(e) =>
                        handleArrayItemChange(e, contentIdx, places, setPlaces)
                      }
                      backgroundColor="#F2F0E8"
                    />
                    <InputField.Input
                      type="text"
                      name="address"
                      value={address}
                      placeholder="네이버 주소"
                      onChange={(e) =>
                        handleArrayItemChange(e, contentIdx, places, setPlaces)
                      }
                      backgroundColor="#F2F0E8"
                    />
                    <InputField.TextArea
                      name="description"
                      value={description}
                      placeholder="설명을 입력하세요"
                      onChange={(e) =>
                        handleArrayItemChange(e, contentIdx, places, setPlaces)
                      }
                      rows={5}
                      cols={50}
                    />
                    <S.OptionList>
                      <S.OptionItem>
                        <InputField.Input
                          type="radio"
                          name={`category${contentIdx}`}
                          value="맛집"
                          checked={category === "맛집"}
                          id="맛집"
                          onChange={(e) =>
                            handlePlaceCategoryChange(
                              e,
                              contentIdx,
                              places,
                              setPlaces
                            )
                          }
                        />
                        <InputField.Label label="맛집" />
                      </S.OptionItem>
                      <S.OptionItem>
                        <InputField.Input
                          type="radio"
                          name={`category${contentIdx}`}
                          value="카페"
                          checked={category === "카페"}
                          id="카페"
                          onChange={(e) =>
                            handlePlaceCategoryChange(
                              e,
                              contentIdx,
                              places,
                              setPlaces
                            )
                          }
                        />
                        <InputField.Label label="카페" />
                      </S.OptionItem>
                      <S.OptionItem>
                        <InputField.Input
                          type="radio"
                          name={`category${contentIdx}`}
                          value="여행지"
                          checked={category === "여행지"}
                          id="여행지"
                          onChange={(e) =>
                            handlePlaceCategoryChange(
                              e,
                              contentIdx,
                              places,
                              setPlaces
                            )
                          }
                        />
                        <InputField.Label label="여행지" />
                      </S.OptionItem>
                    </S.OptionList>
                  </S.InputContainer>
                </S.DocentContentBox>
              )
            )}
          </InputField>
        </S.LeftColumn>
        <S.RightColumn>
          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>
              숙소 상세페이지 디테일 설명
            </label>
            <button
              onClick={decreaseCuration}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increaseCuration}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>
          {curation?.map((el, idx) => {
            // const [images, setImages] = React.useState(curation[idx].imgs);
            return (
              <div>
                <div>
                  <button onClick={(e) => decreaseCuration(idx)}>-</button>
                  <button onClick={(e) => moveCurationUp(idx)}>
                    한칸 위로 올리기
                  </button>
                  <button onClick={(e) => moveCurationDown(idx)}>
                    한칸 아래로 내리기
                  </button>
                </div>
                <label style={{ width: 130, marginRight: 10 }}>
                  상세페이지 디테일{idx + 1}
                </label>

                <S.Textarea
                  placeholder="제목(객실,거실과 부엌공간 등)"
                  value={curation[idx].title}
                  onChange={(e) => {
                    onChangeCuration("title", idx, e.target.value);
                  }}
                />
                <S.Textarea
                  placeholder="설명"
                  value={curation[idx].description}
                  onChange={(e) => {
                    onChangeCuration("description", idx, e.target.value);
                  }}
                />
                <S.InputLayout>
                  <S.InputLabel>사진: </S.InputLabel>
                  <S.Input
                    type="file"
                    accept="image/*"
                    id={`image-${idx}`}
                    multiple
                    required
                  />
                  <button
                    onClick={async () => {
                      const formData = new FormData();
                      const $img = document.querySelector(
                        `#image-${idx}`
                      ) as HTMLInputElement;
                      if (!$img?.files.length) {
                        alert("사진을 첨부해야 합니다.");
                        return;
                      }
                      const fileArray = [...$img.files];
                      fileArray.forEach((file, fileIndex) => {
                        formData.append("imgSrcs", file);
                      });
                      const {
                        data: { imgSrcs },
                      } = await uploadPackageImg(formData);
                      const newDetailItems = imgSrcs.map((src) => ({
                        src,
                        description: "",
                      }));

                      const updatedCuration = [...curation];
                      updatedCuration[idx].imgs = [
                        ...curation[idx].imgs,
                        ...newDetailItems,
                      ];
                      setCuration(updatedCuration);
                      $img.value = "";
                    }}
                  >
                    추가하기
                  </button>
                </S.InputLayout>
                <S.PlaceImageContainer>
                  {curation[idx].imgs.map((image, index) => (
                    <S.PlaceImageItem>
                      <img
                        src={image.src}
                        style={{
                          width: "180px",
                          height: "230px",
                          borderRadius: "10px",
                          objectFit: "cover",
                        }}
                      />
                      세부 설명:{" "}
                      <S.Textarea
                        onChange={(e) => {
                          const updatedCuration = [...curation];
                          updatedCuration[idx].imgs[index].description =
                            e.target.value;
                          setCuration(updatedCuration);
                        }}
                        value={image.description}
                      />
                      <br />
                      <button
                        onClick={() => {
                          onPlaceImageDelete(index, idx);
                        }}
                      >
                        삭제
                      </button>
                      <button onClick={(e) => moveCurationImageUp(index, idx)}>
                        이미지 한칸 위로 올리기
                      </button>
                      <button
                        onClick={(e) => moveCurationImageDown(index, idx)}
                      >
                        이미지 한칸 아래로 내리기
                      </button>
                    </S.PlaceImageItem>
                  ))}
                </S.PlaceImageContainer>
              </div>
            );
          })}
        </S.RightColumn>
      </S.CreateLayout>
    </div>
  );
}
