import styled from "styled-components";
import {
  updateCollaboration,
  getCollaborationList,
  getSubmissionCountByCollaborationId,
} from "api/collaboration";
import { useState, useEffect } from "react";
import Badge from "../ManageCollaboration/Badge";
import { formatDateWithDay } from "../utils";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

export default function SortCollaboration() {
  const [collaborations, setCollaborations] = useState([]);

  useEffect(() => {
    const fetchCollaborations = async () => {
      const res = await getCollaborationList(
        "confirmed&status=revoked&includeSelecting=false"
      );

      // lodgment가 특정 ID인 항목을 제외
      const filteredCollaborations = res.filter(
        (collaboration) =>
          collaboration.lodgment._id !== "66b1d9a3c9a021c9b103805f" // 결제테스트
      );

      // 정렬
      const sortedCollaborations = filteredCollaborations.sort((a, b) => {
        const orderA = a.order ?? -1; // null을 -1로 취급
        const orderB = b.order ?? -1; // null을 -1로 취급

        // order 우선 정렬
        if (orderA !== -1 && orderB !== -1) {
          return orderA - orderB;
        }

        // order가 -1인 경우, applicationEndDate 기준으로 끼워넣기
        if (orderA === -1 && orderB === -1) {
          const dateA = a.applicationEndDate
            ? new Date(a.applicationEndDate)
            : new Date(0); // null 값을 가장 작은 값으로
          const dateB = b.applicationEndDate
            ? new Date(b.applicationEndDate)
            : new Date(0); // null 값을 가장 작은 값으로
          return dateA - dateB;
        }

        // order가 -1인 항목은 applicationEndDate로 정렬
        if (orderA === -1) {
          const dateA = a.applicationEndDate
            ? new Date(a.applicationEndDate)
            : new Date(0);
          const dateB = b.applicationEndDate
            ? new Date(b.applicationEndDate)
            : new Date(0);
          return dateA - dateB;
        }

        if (orderB === -1) {
          const dateA = a.applicationEndDate
            ? new Date(a.applicationEndDate)
            : new Date(0);
          const dateB = b.applicationEndDate
            ? new Date(b.applicationEndDate)
            : new Date(0);
          return dateA - dateB;
        }

        return 0; // 기본적으로 같다고 가정
      });

      // 병렬로 submission count 업데이트
      await Promise.all(
        sortedCollaborations.map(async (collaboration) => {
          const submissionCount = await getSubmissionCountByCollaborationId(
            collaboration._id
          );
          collaboration.submissionCount = submissionCount.submissionCount; // 필드 업데이트
        })
      );

      setCollaborations(sortedCollaborations);
    };

    fetchCollaborations();
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedCollaborations = [...collaborations];
    const [movedItem] = reorderedCollaborations.splice(result.source.index, 1);
    reorderedCollaborations.splice(result.destination.index, 0, movedItem);

    setCollaborations(reorderedCollaborations);
  };

  console.log(collaborations);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = confirm("수정하시겠어요?");
      if (!res) return;

      const formData = collaborations.map((collaboration, index) => ({
        _id: collaboration._id,
        order: index,
      }));

      await Promise.all(
        formData.map(async (data) => {
          try {
            await updateCollaboration(data._id, data);
          } catch (error) {
            console.error(`협업 ID ${data._id} 업데이트 실패:`, error);
            throw error; // 에러를 상위로 전파
          }
        })
      );

      alert("수정이 완료되었습니다.");
      window.location.reload();
    } catch (error) {
      console.error("수정 중 오류 발생:", error);
      alert("수정에 실패했어요. 다시 시도해주세요.");
    }
  };

  return (
    <Container>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Title>협업 정렬</Title>
        <S.EditButton onClick={handleSubmit}>수정 완료</S.EditButton>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="collaborationList">
          {(provided) => (
            <CollaborationFlex
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {collaborations.map((collaboration, index) => (
                <Draggable
                  key={collaboration._id || `temp-${index}`}
                  draggableId={collaboration._id || `temp-${index}`}
                  index={index}
                >
                  {(provided) => (
                    <CollaborationItem
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <DragHandle>☰</DragHandle>
                      <Badge collaboration={collaboration} />
                      <InfoSection>
                        <span>{collaboration.lodgment.name}</span>
                      </InfoSection>
                      <InfoSection>{collaboration.bundle}</InfoSection>
                      <DateRange>
                        <span>
                          {formatDateWithDay(collaboration.benefitStartDate)} ~{" "}
                          {formatDateWithDay(collaboration.benefitEndDate)}
                        </span>
                      </DateRange>
                      <SubmissionCount count={collaboration.submissionCount}>
                        {collaboration.submissionCount}건
                      </SubmissionCount>
                    </CollaborationItem>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </CollaborationFlex>
          )}
        </Droppable>
      </DragDropContext>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  width: fit-content;
`;

const Title = styled.h5`
  font-size: 24px;
  margin: 0;
  color: #333;
`;

const CollaborationFlex = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
  gap: 20px;
`;

const CollaborationItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  user-select: none; /* 드래그 중 텍스트 선택 방지 */
  box-shadow: ${(props) =>
    props.isDragging ? "0px 4px 6px rgba(0,0,0,0.1)" : "none"};
  transition: background-color 0.2s ease;
`;

const DragHandle = styled.div`
  color: #94a3b8;
  cursor: grab;
  padding: 4px;

  &:hover {
    color: #64748b;
  }
`;

const InfoSection = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #475569;
  font-size: 14px;

  span {
    font-size: 16px;
    font-weight: 600;
  }

  svg {
    color: #64748b;
    width: 16px;
    height: 16px;
  }
`;

const DateRange = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #64748b;
  font-size: 13px;
  background: #f8fafc;
  padding: 6px 12px;
  border-radius: 6px;
`;

const SubmissionCount = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin-left: auto;
  background: ${(props) => (props.count > 0 ? "#eff6ff" : "#fef2f2")};
  color: ${(props) => (props.count > 0 ? "#3b82f6" : "#ef4444")};
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  font-size: 13px;
`;

const S = {
  StatusContainer: styled.div`
    display: flex;
    gap: 24px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
  `,

  StatusItem: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 14px;
      color: #888;
      margin: 0;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }
  `,

  EditButton: styled.div`
    padding: 8px 16px;
    background-color: #f7c531;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    width: fit-content;
  `,
};
