import React from "react";
import * as S from "./ChatRoom.style";
import { MsgSender } from "../../type/chat.type";
import { MsgContent } from "../../type/chat.type";
import MsgContainer from "./MsgContainer";
import { Socket } from "socket.io-client";
import { deleteMessage } from "api/messages";

export interface IMsgProps {
  socket: Socket;
  messageId: string;
  type: string;
  sender: MsgSender;
  content: MsgContent;
  createdAt: string;
  onZoom: (imgSrc: string) => void;
}

export default function Msg({
  socket,
  messageId,
  sender: { role },
  type,
  content,
  createdAt,
  onZoom,
}: IMsgProps) {
  return (
    <>
      <S.MsgAligner role={role}>
        <MsgContainer
          socket={socket}
          role={role}
          type={type}
          content={content}
          onZoom={onZoom}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <span
            onClick={async () => {
              if (
                !confirm(
                  `메시지를 삭제하시겠습니까? \n ${
                    content.text || content.name
                  }`
                )
              )
                return;
              await deleteMessage(messageId);
              window.location.reload();
            }}
            style={{
              fontSize: "0.7rem",
              border: "0.1rem solid black",
              padding: "0.2rem",
              height: "fit-content",
            }}
          >
            삭제
          </span>
        </div>
      </S.MsgAligner>
    </>
  );
}
