import * as React from "react";
import * as S from "./ChatRoom.style";

export interface IImgZoomProps {
  onClose: () => void;
  zoomImgSrc: string;
}

export default function ImgZoom({ onClose, zoomImgSrc }: IImgZoomProps) {
  return (
    <S.Overlay>
      <S.ImgWrapper>
        <S.ZoomHeader>
          <S.CloseBtn src="/icon/btn_cancel_wh.png" onClick={onClose} />
        </S.ZoomHeader>
        <S.ZoomBody>
          <S.ZoomImage src={zoomImgSrc} />
          <S.DownLoadBtn href={zoomImgSrc}>이미지 저장</S.DownLoadBtn>
        </S.ZoomBody>
      </S.ImgWrapper>
    </S.Overlay>
  );
}
