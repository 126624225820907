const ADMIN_PHONE: string[] = [
  "01999999999",
  "01027448018",
  "01071899943",
  "01098018312",
  "01111111112",
  "01098226845",
  "01111111111",
  "01099526853",
  "01074403125",
  "01098948408",
  "01071406296",
  "01054452907",
  "01099530017",
  "01062885845",
];

export default ADMIN_PHONE;
