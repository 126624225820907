import { createSettlementLog } from "api/settlementLog";
import * as React from "react";
import { useHistory, useParams } from "react-router-dom";
import * as S from "./settlementLog.style";

export interface ISettlementLogCreateProps {}

export interface Params {
  lodgmentId: string;
}

export default function SettlementLogCreate(props: ISettlementLogCreateProps) {
  const history = useHistory();
  const { lodgmentId } = useParams<Params>();

  const [sender, setSender] = React.useState("");
  const [lodgmentName, setLodgmentName] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [date, setDate] = React.useState("");

  const onSubmit = async () => {
    if (
      !sender ||
      !lodgmentId ||
      !paymentMethod ||
      !amount ||
      !phoneNum ||
      !date
    ) {
      alert("모든 필드를 입력해주세요");
      return;
    }
    const data = {
      sender,
      lodgment: lodgmentId,
      paymentMethod,
      amount: Number(amount),
      phoneNum,
      date,
    };
    await createSettlementLog(data);
    history.push("/settlementLog");
  };

  return (
    <div>
      <S.InputLine>
        <S.InputTitle>구매자: </S.InputTitle>
        <S.Input value={sender} onChange={(e) => setSender(e.target.value)} />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>결제수단</S.InputTitle>
        <S.Input
          value={paymentMethod}
          onChange={(e) => setPaymentMethod(e.target.value)}
        />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>입금액: </S.InputTitle>
        <S.Input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="ex. 100000"
        />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>전화번호: </S.InputTitle>
        <S.Input
          value={phoneNum}
          placeholder="ex. 010-6288-5845"
          onChange={(e) => setPhoneNum(e.target.value)}
        />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>입금 날짜(정산일): </S.InputTitle>
        <S.Input
          placeholder="ex. 2022-09-14"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
      </S.InputLine>
      <button onClick={onSubmit}>저장하기</button>
    </div>
  );
}
