import { myAxios } from "./myAxios";
import { FORM_RESPONSE_PATH } from "../constants/path";
import { IFormResponse } from "type/form.type";

interface IFormResponsesData {
  msg: string;
  formResponses: IFormResponse[];
}

export const getFormResponsesByConsult = async (
  consultId: string
): Promise<IFormResponsesData> => {
  const path = FORM_RESPONSE_PATH + `?consult=${consultId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const deleteFormResponse = async (responseId) => {
  const path = FORM_RESPONSE_PATH + `/${responseId}`;
  await myAxios.delete({ path });
};
