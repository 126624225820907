import styled from "styled-components";

export const InputLayout = styled.div`
  margin: 0.3rem;
`;
export const InputLabel = styled.label``;
export const Input = styled.input``;
export const Textarea = styled.textarea`
  width: 30rem;
  height: 10rem;
`;

export const PlaceImageItem = styled.div`
  border: 0.1rem solid black;
  width: fit-content;
  margin: 0.3rem;
`;

export const PlaceItem = styled.div`
  width: 15rem;
  height: 20rem;
  border: 0.1rem solid black;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.3rem;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`;
export const PlaceRepresentImg = styled.img`
  width: 180px;
  height: 230px;
  margin-right: 1rem;
  -webkit-user-drag: none;
`;
export const PlaceTitle = styled.h3`
  height: 2rem;
  font-size: 0.9rem;
  word-wrap: break-word;
  overflow-y: auto;
`;

export const PlaceBox = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
`;

export const PlaceImageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100vw;
`;
