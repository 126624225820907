export const DISTRICT_ARR: string[] = [
  "제주시",
  "애월",
  "한림/협재/한경",
  "성산/우도",
  "중문/서귀포",
  "대정/안덕",
  "남원/위미",
  "표선",
  "함덕/구좌/조천",
];

export const SPOT_2_ARRAY: string[] = [
  "제주시 도심",
  "제주시 동부",
  "제주시 서부",
  "서귀포시 도심",
  "서귀포시 동부",
  "서귀포시 서부",
  "한라산/오름/섬",
];

export const POST_TYPE_ARR: string[] = ["여행지", "카페", "맛집", "액티비티"];

export const AMENITY_ARR: string[] = [
  "키즈존",
  "펫존",
  "주차가능",
  "예약가능",
  "1인 가능",
  "매장 내 동물",
  "비건",
  "배리어프리",
  "시니어존",
  "웨이팅",
  "야외 테라스",
];

export const ALERGIC_INGREDIENT_ARR: string[] = [
  "난류",
  "우유",
  "메밀",
  "땅콩",
  "대두",
  "밀",
  "고등어",
  "게",
  "새우",
  "돼지고기",
  "복숭아",
  "토마토",
  "아황산류",
  "호두",
  "닭고기",
  "쇠고기",
  "오징어",
  "조개류",
];

export const INFLUENCER_ARR: string[] = [
  "진영 커플",
  "혀니",
  "여밍",
  "지쌍",
  "트래블오일",
  "써머진",
  "균샘",
  "컬러니",
  "러브푸키",
  "차니포토",
];

export default {
  DISTRICT_ARR,
  SPOT_2_ARRAY,
  POST_TYPE_ARR,
  AMENITY_ARR,
  INFLUENCER_ARR,
};
