import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
  overflow-x: scroll;
`;

export const Card = styled.div`
  padding: 8px;
  border: 1px solid #e6e6e6;
  margin: 10px;
`;

export const Typo = styled.div``;

export const Content = styled.div``;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  &.precaution {
    text-align: left;
    font-size: 13px;
  }
`;

export const List = styled.li``;

export const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListItemText = styled.div``;

export const StyledTable = styled.div`
  height: 100vh;

  table {
    width: 100%;
  }

  table thead {
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 3px solid #e6e6e6;
  }

  table thead tr th,
  table tbody tr td {
    padding: 10px;
    width: fit-content;
    max-width: 200px;
    white-space: nowrap;
  }

  table thead tr th {
    text-align: center;
  }

  table thead tr th.accommo-id,
  table tbody tr td.accommo-id {
    width: 100px;
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  table thead tr th.accommo-name,
  table tbody tr td.accommo-name {
    width: 250px;
    max-width: 250px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  table tbody tr td.isEarlySettlement {
    text-align: center;
  }

  table tbody tr {
    &:hover {
      background-color: #f0f0f0;
    }
  }

  table tbody tr.isEmpty {
    background-color: #fafafa;
  }

  table tbody tr.isDifferent {
    background-color: #fff5f5;
  }

  table tbody input {
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 4px;
  }

  input.diff-commission {
    color: red;
  }
`;

export const CommissionModalButton = styled.button<{
  isStatic?: boolean;
}>`
  background-color: ${({ isStatic }) => (isStatic ? "#dce8ff" : "#FFE8E5")};
  border: none;
  border-radius: 8px;
`;

export const CommissionTypo = styled.div`
  font-size: 20px;
  font-weight: bold;
`;

export const SubmitButton = styled.button`
  outline: none;
  border: none;
  background-color: #e6e6e6;
  padding: 5px 15px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  margin: 0 auto;
  button#submit {
    background-color: black;
    color: white;
  }
`;

export const ButtonDiv = styled.div``;

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: #e6e6e6;
  padding: 5px 15px;
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
`;

export const Box = styled.div`
  padding: 16px;
  border-radius: 8px;
  background: #f9f9f9;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const OutlineBox = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const Title = styled.p`
  font-size: 16px;
  font-weight: 600;
  color: #444;
  margin-bottom: 0px;
  text-align: left;
`;

export const CommissionValue = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #007bff;
`;

export const CommissionList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const CommissionCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 6px;
  gap: 12px;
`;

export const DateRange = styled.span`
  text-align: left;
  font-size: 14px;
  color: #555;
  flex: 1;
`;

export const InputWrapper = styled.div`
  width: 100%;
  padding: 8px 12px;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 12px;
  font-size: 14px;
  background-color: white;
  display: flex;
  gap: 8px;
`;

export const EditInput = styled.input`
  border: 0px;
  padding: 6px;
  font-size: 20px;
  font-weight: 600;
  flex-grow: 1;
`;

export const EditButton = styled.button`
  color: #007bff;
  padding: 6px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  background-color: white;
`;
