import { CLIENT_EVENT } from "../../constants/socketEvent";
import { Socket } from "socket.io-client";
import {
  Msg,
  FileMsg,
  ITypingInfo,
  SingleChoiceMsg,
  MultipleChoiceMsg,
  SubjectiveMsg,
  PhotoMsg,
  BtnMsg,
} from "../../type/chat.type";

export const joinRoom = (
  socket: Socket,
  consultId: string,
  conciergeId: string
): void => {
  socket.emit(CLIENT_EVENT.JOIN_ROOM, { consultId, conciergeId });
};

export const emitSendMessage = (
  socket: Socket,
  type: string,
  content:
    | Msg
    | FileMsg
    | BtnMsg
    | SingleChoiceMsg
    | MultipleChoiceMsg
    | SubjectiveMsg
    | PhotoMsg
) => {
  socket.emit(CLIENT_EVENT.SEND_MSG, {
    message: {
      type,
      content,
    },
  });
};

export const emitTypingAction = (socket: Socket, { action }: ITypingInfo) => {
  socket.emit(CLIENT_EVENT.TYPING_ACTION, { action });
};

export const emitResponseForm = (socket: Socket, body) => {
  socket.emit(CLIENT_EVENT.RESPONSE_FORM, body);
};
