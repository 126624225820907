import dayjs from "dayjs";
import "dayjs/locale/ko"; 

dayjs.locale("ko");

export const isTodayBetweenDates = (
  startDate,
  endDate,
  inclusiveStart = false, // 시작 날짜 포함 여부
  inclusiveEnd = true // 종료 날짜 포함 여부
) => {
  const today = dayjs().format("YYYY-MM-DD"); // 오늘의 날짜만 포맷팅
  const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
  const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

  const startCondition = inclusiveStart
    ? formattedStartDate <= today
    : formattedStartDate < today;
  const endCondition = inclusiveEnd
    ? today <= formattedEndDate
    : today < formattedEndDate;

  return startCondition && endCondition;
};

// 오늘이 두 날짜 사이에 있는지 확인하는 함수 (초과 && 이하 기본값)
export const getRemainingDays = (endDate) => {
  const today = dayjs().startOf("day"); // 오늘 날짜를 연/월/일로 맞춤
  const targetDate = dayjs(endDate).startOf("day"); // 비교할 날짜도 연/월/일로 맞춤

  // 두 날짜의 차이를 일(day) 단위로 계산
  const diffDays = targetDate.diff(today, "day");

  // 남은 날짜를 반환 (음수가 될 경우 그대로 반환)
  return diffDays;
};

export const formatDateWithDay = (dateString) => {
  return dayjs(dateString).format("YY.MM.DD (ddd)");
};
