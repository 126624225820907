import { useState } from "react";
import ManageCollaboration from "./ManageCollaboration";
import VerifyChannel from "./VerifyChannel";
import SortCollaboration from "./SortCollaboration";

const TAB_LIST = [
  { id: "채널 인증 검토", label: "채널 인증 검토" },
  { id: "협업 관리", label: "협업 관리" },
  { id: "협업 정렬", label: "협업 정렬" },
];

const Review = () => {
  const [activeTab, setActiveTab] = useState("채널 인증 검토");

  const renderTabContent = () => {
    switch (activeTab) {
      case "채널 인증 검토":
        return <VerifyChannel />;
      case "협업 관리":
        return <ManageCollaboration />;
      case "협업 정렬":
        return <SortCollaboration />;
      default:
        return <VerifyChannel />;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "200px",
          borderRadius: "6px",
          border: "1px solid #ddd",
          height: "fit-content",
        }}
      >
        <h4
          style={{
            margin: 0,
            padding: "12px 16px",
            borderBottom: "1px solid #ddd",
            fontSize: "16px",
            color: "#3182F7",
            fontWeight: "bold",
          }}
        >
          파인리뷰
        </h4>

        <div>
          {TAB_LIST.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              style={{
                padding: "10px 16px",
                cursor: "pointer",
                backgroundColor:
                  activeTab === tab.id ? "#3182F7" : "transparent",
                color: activeTab === tab.id ? "white" : "#333",
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <div style={{ padding: "15px", flex: 1 }}>{renderTabContent()}</div>
    </div>
  );
};

export default Review;
