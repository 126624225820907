import { myAxios } from "./myAxios";
import { PACKAGE_IMAGE_PATH, CLEANING_IMAGE_PATH } from "../constants/path";

export const uploadPackageImg = async (data): Promise<any> => {
  const res = await myAxios.post({ path: PACKAGE_IMAGE_PATH, data });
  return res.data;
};

export const uploadCleaningImg = async (data): Promise<any> => {
  const res = await myAxios.post({ path: CLEANING_IMAGE_PATH, data });
  return res.data;
};
