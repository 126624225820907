import { useRouter } from "next/router";
import * as React from "react";
import styled from "styled-components";

export const Header = styled.div<{ promotionStyle }>`
  width: 100%;
  position: sticky;
  z-index: 3;
  top: 0;
  justify-content: center;
  display: flex;
  align-items: center;
  padding-top: calc(1rem + constant(safe-area-inset-top));
  padding-top: calc(1rem + env(safe-area-inset-top));
  padding-bottom: 1rem;
  background-color: white;
  margin-bottom: ${({ promotionStyle }) =>
    promotionStyle === "none" ? "none" : "1rem"};
  border-bottom: ${({ promotionStyle }) =>
    promotionStyle === "none" ? "none" : "0.1rem solid #efefef"};
`;

export const BackIcon = styled.img`
  width: 0.6rem;
`;

export const Title = styled.h2`
  font-size: 1rem;
  font-weight: 400;
  width: fit-content;
  margin: 0;
`;

export const HomeIcon = styled.img`
  width: 1.5rem;
`;

export interface IBasicHeaderProps {
  title: string;
  onBackBtn?;
  promotionStyle?;
  prevPath?;
  isHomeIconDisplay?;
  isBackIconDisplay?;
}

export default function BasicHeader({
  title,
  onBackBtn,
  promotionStyle,
  isHomeIconDisplay,
  isBackIconDisplay,
}: IBasicHeaderProps) {
  const router = useRouter();
  const [prevPath, setPrevPath] = React.useState("");
  React.useEffect(() => {
    const prevPath = sessionStorage.getItem("prevPath");
    setPrevPath(prevPath);
  }, []);

  return (
    <Header promotionStyle={promotionStyle}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "90%",
          margin: "0 auto",
        }}
      >
        <BackIcon
          style={{ opacity: isBackIconDisplay || "1" }}
          onClick={() => {
            if (onBackBtn) {
              return onBackBtn();
            }
            if (!prevPath) {
              router.push("/home");
              return;
            }
            router.back();
          }}
          src="https://hau-package-img.s3.ap-northeast-2.amazonaws.com/arrow-back.png"
        />
        <Title>{title}</Title>
        <HomeIcon
          style={{ opacity: isHomeIconDisplay || "1" }}
          onClick={() => router.push("/home")}
          src="https://hau-package-img.s3.ap-northeast-2.amazonaws.com/home.png"
        />
      </div>
    </Header>
  );
}
