import { CORPORATION_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getCorporations = async (): Promise<any> => {
  const path = `${CORPORATION_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const createCorporations = async (data): Promise<any> => {
  const path = `${CORPORATION_PATH}`;
  const res = await myAxios.post({ path, data });
  return res.data;
};

export const deleteCorporations = async (id): Promise<any> => {
  const path = `${CORPORATION_PATH}/${id}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const updateCorporation = async (id, data): Promise<any> => {
  const path = `${CORPORATION_PATH}/${id}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
