import styled from "styled-components";

interface IMsgProps {
  role?: string;
}
export const ChatPageContainer = styled.div`
  display: flex;
`;

export const ChatSection = styled.div`
  max-width: 440px;
  width: 440px;
  /* height: 100vh; */
  min-height: 80vh;
  margin: 0;
  background-color: white;
  overflow-x: hidden;
`;

export const ChatRoomTitle = styled.p`
  font-weight: bold;
  width: fit-content;
  margin: 0 auto;
`;

export const ChatRoomWrapper = styled.div`
  width: 100%;
  height: 100vh;
`;

export const ChatRoomHeader = styled.div`
  position: absolute;
  max-width: 440px;
  top: 0;
  height: 4.7rem;
  padding: 2rem 1rem 0 1rem;
  background-color: white;
  z-index: 10;
`;

export const ChatRoomInput = styled.div`
  position: absolute;
  max-width: 440px;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  bottom: 0;
  height: 4.8rem;
  background-color: black;
`;

export const BackBtn = styled.button`
  border: none;
  background-color: white;
  height: 2rem;
  width: 2rem;
  cursor: pointer;
`;

export const HeaderLogo = styled.img`
  width: 4.5rem;
  height: fit-content;
  margin: 0.3rem 40% 0 33%;
`;

export const ChatRoomHeaderText = styled.p`
  width: max-content;
  color: #009dff;
  margin: auto;
  font-weight: bolder;
  font-size: 1rem;
`;

export const ChatRoom = styled.div`
  /* height: 100vh; */
  position: relative;
`;

export const MsgContainer = styled.div`
  width: 100%;
  height: 80vh;
  padding: 5.2rem 0 6rem 0;
  overflow-y: scroll;
  background-color: #eeeeee;
  position: relative;
`;

export const MsgAligner = styled.div`
  display: flex;
  justify-content: ${({ role }: IMsgProps) =>
    role === "concierge" && "flex-end"};
`;

export const MsgSender = styled.p`
  display: flex;
  margin: 0.5rem;
  align-items: center;
  width: max-content;
`;

export const MsgSenderImg = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #d9d9d9;
  border: 1px solid black;
  border-radius: 100rem;
`;

export const MsgSenderName = styled.p`
  font-size: 1rem;
  font-weight: bolder;
  width: max-content;
  height: max-content;
  margin-left: 0.3rem;
`;

export const MsgInputContainer = styled.div`
  height: 4.8rem;
  background-color: black;
  padding: 1rem;
`;

export const FileAddButton = styled.img`
  width: 10%;
  font-size: 1.5rem;
  font-weight: 200;
  margin: 0 2% 0 0;
  cursor: pointer;
`;

export const MsgInputWrapper = styled.div`
  width: 88%;
  border: 2px lightgray solid;
  border-radius: 2rem;
  padding: 0 0.3rem 0 0.8rem;
  display: flex;
`;

export const MsgInput = styled.textarea`
  outline: none;
  height: 5rem;
  outline: none;
  margin: 0rem 0.3rem 0 0;
  border: none;
  font-size: 0.8rem;
  width: 100%;
  :focus {
    outline: none;
  }
`;

export const MsgInputBtn = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  width: 2.5rem;
  margin: 0.1rem 0 0.1rem 0;
  border-radius: 4rem;
  color: white;
  cursor: pointer;
`;

export const BtnMenu = styled.div`
  position: absolute;
  bottom: 4.3rem;
  left: 0.3rem;
  width: fit-content;
  background-color: white;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
`;

export const FileUploadBtn = styled.div`
  width: fit-content;
  text-align: center;
  padding: 0.2rem 0 0 0;
  /* background-color: lightgreen; */
  :hover {
    cursor: pointer;
  }
`;

export const FileUploadIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  :hover {
    cursor: pointer;
  }
`;

export const ConciergeTypingInfo = styled.div`
  position: fixed;
  bottom: 4.8rem;
  text-align: right;
  font-size: 0.8rem;
  width: 440px;
  margin: 0 0 0.5rem 0;
  padding: 0 1rem 0 0;
`;

export const ScrollDownBtn = styled.div`
  position: fixed;
  width: 100px;
  height: 100px;
  top: 60%;
  left: 35%;
  background-color: red;
`;

interface MsgWrapperProp {
  isPhotoMsg?: boolean;
}
export const MsgWrapper = styled.div<MsgWrapperProp>`
  max-width: 75%;
  margin: 1rem 2rem 0 2rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
  border-radius: 0.8rem;
  padding: 0.7rem 1.2rem;
  width: max-content;
  padding: ${({ isPhotoMsg }) => (isPhotoMsg ? "" : "0.7rem 1.2rem")};
  background-color: ${({ role }: IMsgProps) =>
    role === "concierge" ? "#ffffff" : "#c9c9f0"};
`;

export const TextMsg = styled.div`
  max-width: 20rem;
  word-break: break-all;
  white-space: pre-line;
`;

export const ImgMsg = styled.img`
  min-width: 10rem;
  width: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
`;

export const FileMsg = styled.div`
  /* border: 2px black solid; */
  padding: 0.1rem;
  width: 17rem;
  display: flex;
`;

export const FileDownloadImg = styled.div`
  width: 2rem;
  height: 2rem;
`;

export const FileLink = styled.div`
  width: max-content;
  color: white;
  background-color: gray;
`;

export const DownBtnWrapper = styled.div`
  position: absolute;
  bottom: 4.8rem;
  height: 3.2rem;
  max-width: 440px;
  width: 100%;
  align-items: center;
`;

export const DownBtn = styled.div`
  cursor: pointer;
  position: absolute;
  text-align: center;
  height: 3.2rem;
  opacity: 60%;
  background-color: gray;
  color: white;
  padding: 1.1rem 0 0 0;
`;

// 이미지 줌
export const Overlay = styled.div`
  position: fixed;
  max-width: 440px;
  height: 100vh;
  margin: 0 auto;
  z-index: 100;
  background-color: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export const ZoomHeader = styled.header`
  height: 3.3rem;
  padding: 0.6rem 0 0 1rem;
  margin-top: 1.5rem;
`;

export const ZoomBody = styled.div`
  height: 93vh;
  width: 440px;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
`;

export const CloseBtn = styled.img`
  width: 2rem;
  height: 2rem;
  color: white;
  cursor: pointer;
`;

export const ImgWrapper = styled.div`
  height: 100%;
`;

export const ZoomImage = styled.img`
  width: 100%;
  margin: 0 0 1.5rem 0;
  padding: 0;
`;

export const DownLoadBtn = styled.a`
  text-decoration-line: none;
  color: white;
  cursor: pointer;
`;

// 버튼 메시지
export const MsgForm = styled.form`
  background-color: lightblue;
  padding: 10px;
`;
export const BtnMsgContainer = styled.div`
  display: flex;
`;
export const MsgPreviewContainer = styled.div`
  width: 300px;
  height: 500px;
  background-color: lightyellow;
`;
export const MsgPreviewBtn = styled.div`
  width: max-content;
  background-color: black;
  color: white;
`;
export const MsgPreviewText = styled.p``;

export const BtnMsg = styled.div`
  width: max-content;
  max-width: 100%;
  word-break: break-all;
  margin: 0.3rem 0;
`;
export const BtnMsgBtn = styled.div`
  a {
    font-size: 1.05rem;
    text-decoration: none;
    color: white;
  }
  text-align: center;
  font-weight: 600;
  min-width: 12rem;
  text-decoration: none;
  padding: 0.7rem 3rem;
  margin: 1.5rem 0 0 0;
  border-radius: 0.8rem;
  background-color: #4c4cce;
  color: white;
`;

export const SmsModalWrapper = styled.div`
  background-color: white;
  border: 1px solid black;
  width: 400px;
  position: fixed;
  top: 30%;
  margin: 0 auto;
  padding: 1rem 3rem;
  input {
    width: 100%;
  }
  textarea {
    width: 100%;
    height: 10rem;
    padding: 0.6rem;
  }
`;

export const SmsModalButton = styled.button`
  width: 7rem;
  height: 2rem;
`;

export const RightWingSection = styled.div``;

export const FileBtnContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #dbdbdb;
`;

export const FileInputLabel = styled.label`
  border-right: 0.05rem solid black;
  padding: 0.5rem;
  cursor: pointer;
  :hover {
    background-color: #c0c0c0;
  }
`;

export const InputBar = styled.div`
  display: flex;
  border: 0.1rem solid black;
`;
