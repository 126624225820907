import { LODGMENT_PATH } from "../constants/path";
import { myAxios } from "./myAxios";

export const postLodgment = async (body): Promise<any> => {
  const path = `${LODGMENT_PATH}`;
  const res = await myAxios.post({ path, data: body });
  return res.data;
};

export const getLodgments = async (): Promise<any> => {
  const path = `${LODGMENT_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getLodgmentSummariesByRegion = async (
  region: string
): Promise<any> => {
  const path = `${LODGMENT_PATH}` + `/region/summary?region=${region}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getLodgment = async (accommodationId: string): Promise<any> => {
  const path = `${LODGMENT_PATH}/${accommodationId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const putLodgment = async (
  accommodationId: string,
  data
): Promise<any> => {
  const path = `${LODGMENT_PATH}/${accommodationId}`;
  const res = await myAxios.put({ data, path });
  return res.data;
};
export const deleteLodgment = async (accommodationId: string): Promise<any> => {
  const path = `${LODGMENT_PATH}/${accommodationId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const getPartnerLodgments = async (): Promise<any> => {
  const path = `${LODGMENT_PATH}/partner`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const createGlobalLodgments = async (body): Promise<any> => {
  const path = `${LODGMENT_PATH}/global`;
  const res = await myAxios.post({ path, data: body });
  return res.data;
};

export const cloneLodgment = async (
  accommodationId: string,
  count: string | number
): Promise<any> => {
  const path = `${LODGMENT_PATH}/clone/${accommodationId}?count=${count}`;
  const res = await myAxios.post({ path });
  return res.data;
};
