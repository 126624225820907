import { css } from "styled-components";

export const defaultButton = css`
  outline: none;
  border: none;
  font-weight: bold;
  display: inline-block;
  padding: 10px 12px;
  width: fit-content;
`;

export const defaultInputWrapper = css`
  border-radius: 5px;
  height: 30px;
`;

export const defaultInput = css`
  background: none;
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  line-height: 30px;
  font-size: 16px;
  padding: 0 10px;
  letter-spacing: normal;
  text-decoration: none;
  &:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
  }
  /* &.hDrrxZ:-webkit-autofill {
    box-shadow: 0 0 0 1000px white inset;
  } */
`;

export const defaultSubTitle = css`
  font-weight: 700;
  font-size: 25px;
`;
