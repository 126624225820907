export default [
  { name: "노루하루-모실", _id: "62fdff7050fdf497fe7fd169" },
  { name: "노루하루-선실", _id: "6321f60a2a6ff32cce6f6910" },
  { name: "노루하루-담실", _id: "6321f6122a6ff32cce6f6912" },
  { name: "그날의하도", _id: "631991b3e19010ee743869ee" },
  { name: "스페이스농농-A동", _id: "62eb60a8fd87e0c2eda7a9b1" },
  { name: "스페이스농농-B동", _id: "637727085fec9a09e1b2e303" },
  { name: "보홀", _id: "62eb916f600233e09f7d7234" },
  { name: "프롬나드 제주", _id: "6315cb59e19010ee74385cd5" },
  { name: "하도생각나-아꼽동", _id: "635fdba36b4312c861fe5a7e" },
  { name: "하도생각나-곱닥동", _id: "635fdff66b4312c861fe5c22" },
  { name: "하도생각나-또똣동", _id: "636155938eed14899f9fed54" },
  { name: "하도생각나-소랑동", _id: "63616857a3148a771f843d8d" },
  { name: "섭재", _id: "636be568f07fe118d0a7422b" },
  { name: "담제", _id: "6371f39bceb9b414e2d3b101" },
  { name: "하도생각나-곱닥동", _id: "635fdff66b4312c861fe5c22" },
  { name: "비에노즈 풀빌라", _id: "62f3655fe778173a5ce08fba" },
];
