import Button from "react-bootstrap/Button";
import TimePicker from "react-time-picker";
import { WorktimeBox } from "../style";
import { Flex, FlexStart } from "components/style";
import { useState } from "react";
export default function WorkTimeEditor({
  post,
  setPost,
  setOpeningHours,
  addingOpenHour,
  addingCloseHour,
  addingBreakStartHour,
  addingBreakEndHour,
  addingLastOrder,
  addingNotice,
  setAddingOpenHour,
  setAddingCloseHour,
  setAddingBreakStartHour,
  setAddingBreakEndHour,
  setAddingLastOrder,
  setAddingNotice,
  addingHourDays,
  handleAddingHourDaysClick,
  handleAddingHourDoneClick,
  offDays,
  handleOffDaysClick,
  handleOffDaysDoneClick,
  openingHours,
}) {
  const defaultOpeningHour = [...Array(7)].map(() => {
    return {
      open: null,
      close: null,
      breacStart: null,
      breakEnd: null,
      lastOrder: null,
      off: false,
    };
  });
  const [foldContainer, setFoldContainer] = useState(true);
  const days = [
    { label: "월요일", value: 0 },
    { label: "화요일", value: 1 },
    { label: "수요일", value: 2 },
    { label: "목요일", value: 3 },
    { label: "금요일", value: 4 },
    { label: "토요일", value: 5 },
    { label: "일요일", value: 6 },
  ];

  return (
    <div style={{ marginTop: 60 }}>
      <Flex>
        <h2>영업시간!!</h2>
        <Button
          style={{ height: 30, width: 60, marginLeft: 10 }}
          size="sm"
          onClick={() => setFoldContainer(!foldContainer)}
        >
          {foldContainer ? "펼치기" : "접기"}
        </Button>
      </Flex>
      {!foldContainer ? (
        <div>
          <div>
            <Flex>
              <h5>시간 등록하기</h5>
              <button
                style={{ margin: "0 0 10px 20px" }}
                onClick={() => {
                  setOpeningHours(defaultOpeningHour);
                }}
              >
                초기화
              </button>
            </Flex>
            <WorktimeBox>
              <FlexStart>
                <div
                  style={{
                    marginRight: 10,
                  }}
                >
                  <div>영업 시작</div>
                  <TimePicker
                    onChange={(val) => {
                      setAddingOpenHour(val);
                    }}
                    value={addingOpenHour}
                    disableClock={true}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <div>영업 끝</div>
                  <TimePicker
                    onChange={(val) => {
                      setAddingCloseHour(val);
                    }}
                    value={addingCloseHour}
                    disableClock={true}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <div>브레이크 타임 시작</div>
                  <TimePicker
                    onChange={(val) => {
                      setAddingBreakStartHour(val);
                    }}
                    value={addingBreakStartHour}
                    disableClock={true}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <div>브레이크 타임 끝</div>
                  <TimePicker
                    onChange={(val) => {
                      setAddingBreakEndHour(val);
                    }}
                    value={addingBreakEndHour}
                    disableClock={true}
                  />
                </div>
                <div style={{ marginRight: 10 }}>
                  <div>라스트오더</div>
                  <TimePicker
                    onChange={(val) => {
                      setAddingLastOrder(val);
                    }}
                    value={addingLastOrder}
                    disableClock={true}
                  />
                </div>
              </FlexStart>
              <Flex style={{ marginTop: 10 }}>
                <div>
                  <div>요일 선택</div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {days.map((el, idx) => {
                      return (
                        <div
                          key={"adding_" + idx.toString()}
                          style={{
                            marginRight: 20,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            style={{ marginRight: 10 }}
                            checked={addingHourDays[idx]}
                            onChange={(e) => {
                              handleAddingHourDaysClick(e, idx);
                            }}
                            value={addingHourDays[idx]}
                          />
                          <div>{el.label}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <Button
                  style={{ marginLeft: 170, marginTop: 20, width: 70 }}
                  size="sm"
                  onClick={() => {
                    handleAddingHourDoneClick();
                  }}
                  variant="primary"
                >
                  추가
                </Button>
              </Flex>
            </WorktimeBox>
            <WorktimeBox>
              <h6>휴무 인 날 체크하기</h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                {days.map((el, idx) => {
                  return (
                    <div
                      style={{
                        marginRight: 20,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      key={"offdays_" + idx.toString()}
                    >
                      <input
                        type="checkbox"
                        style={{ marginRight: 10 }}
                        checked={offDays[idx]}
                        onChange={(e) => {
                          handleOffDaysClick(e, idx);
                        }}
                        value={offDays[idx]}
                      />
                      <div>{el.label}</div>
                    </div>
                  );
                })}
                <Button
                  style={{ marginLeft: 165 }}
                  disabled={!offDays.find((el) => el === true)}
                  size="sm"
                  onClick={() => {
                    handleOffDaysDoneClick();
                  }}
                  variant="primary"
                >
                  휴무일 추가
                </Button>
              </div>
            </WorktimeBox>
            <WorktimeBox>
              <h6>전체 공지 수정</h6>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <textarea
                  style={{ width: 700, whiteSpace: "pre-line" }}
                  onChange={(e) => {
                    setAddingNotice(e.target.value);
                  }}
                  value={addingNotice}
                />

                <Button
                  style={{ marginLeft: 50 }}
                  disabled={!addingNotice?.length}
                  size="sm"
                  onClick={() => {
                    setPost({ ...post, openingHoursNotice: addingNotice });
                  }}
                  variant="primary"
                >
                  전체 공지 수정
                </Button>
              </div>
            </WorktimeBox>
          </div>
          <div>
            <h5 style={{ marginTop: 30 }}>영업시간 보기</h5>
            {openingHours?.map((el, idx) => {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    marginBottom: 10,
                  }}
                  key={"openinghour_" + idx.toString()}
                >
                  <div
                    style={{
                      marginRight: 20,
                      fontWeight: "700",
                      fontSize: 15,
                    }}
                  >
                    {idx === 0
                      ? "월요일"
                      : idx === 1
                      ? "화요일"
                      : idx === 2
                      ? "수요일"
                      : idx === 3
                      ? "목요일 "
                      : idx === 4
                      ? "금요일"
                      : idx === 5
                      ? "토요일"
                      : "일요일"}
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <div style={{ marginRight: 10 }}>영업 시작</div>
                    <div>{el?.open ? el?.open : "----"}</div>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>영업 끝</div>
                    <div>{el?.close ? el?.close : "----"}</div>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>휴무 여부</div>
                    <div>{el?.off ? "⭕" : "❌"}</div>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>브레이크 타임 시작</div>
                    <div>{el?.breakStart ? el?.breakStart : "----"}</div>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>브레이크 타임 끝</div>
                    <div>{el?.breakEnd ? el?.breakEnd : "----"}</div>
                  </div>
                  <div style={{ marginRight: 10 }}>
                    <div style={{ marginRight: 10 }}>라스트오더</div>
                    <div>{el?.lastOrder ? el?.lastOrder : "----"}</div>
                  </div>
                </div>
              );
            })}
            <h5 style={{ marginTop: 20 }}>전체 공지</h5>
            <WorktimeBox
              style={{
                whiteSpace: "pre-line",
              }}
            >
              {post.openingHoursNotice ? post.openingHoursNotice : "없음"}
            </WorktimeBox>
          </div>
        </div>
      ) : null}
    </div>
  );
}
