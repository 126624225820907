import { useState, Dispatch, SetStateAction } from "react";

import { HTMLWritingEvent } from "../type/event.type";

type OnChange = (event: HTMLWritingEvent) => void;

export const useInput = (
  initialState: string
): [string, OnChange, Dispatch<SetStateAction<string>>] => {
  const [value, setValue] = useState(initialState);
  const onChange: OnChange = (event) => {
    const {
      target: { value },
    } = event;
    setValue(value);
  };
  return [value, onChange, setValue];
};
