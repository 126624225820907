import * as React from "react";
import * as S from "./msg.style";

export interface IPhotoMsgProps {
  content: {
    photo: string;
    text: string;
  };
  role: "user" | "concierge";
}

export default function PhotoMsg({
  content: { photo, text },
  role,
}: IPhotoMsgProps) {
  return (
    <S.MsgWrapper isPhotoMsg role={role}>
      <S.Photo src={photo} />
      <S.PhotoDescription>
        <p style={{ margin: 0 }}>{text}</p>
      </S.PhotoDescription>
    </S.MsgWrapper>
  );
}
