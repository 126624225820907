import { IoIosClose } from "react-icons/io";
import CreatableSelect from "react-select/creatable";
import Button from "react-bootstrap/Button";
import { TagContainer, Tag, TagResetButton } from "../style";
import { Flex } from "components/style";
import axios from "config/axiosConfig";
import { useEffect, useState } from "react";
import { TAG_CATEGORY_ARR, TAG_CATEGORY_OPTION } from "constants/tag";

export default function TagEditor({ post, setPost }) {
  const [foldContainer, setFoldContainer] = useState(true);
  const [tags, setTags] = useState([]);
  const [showTags, setShowTags] = useState({
    season: false,
    nature: false,
    food: false,
    type: false,
    mood: false,
    companion: false,
    influencer: false,
    general: false,
  });
  const [addingTags, setAddingTags] = useState([]);
  const [foodOption, setFoodOption] = useState(null);

  useEffect(async () => {
    const {
      data: { tags },
    } = await axios.get("/posttags");

    setTags(tags);
  }, []);

  useEffect(() => {
    setAddingTags(post.tags);
  }, []);

  useEffect(() => {
    if (!addingTags.length) return;
    setPost({ ...post, tags: addingTags });
    console.log(addingTags);
  }, [addingTags]);

  const onClickTag = (tag) => {
    const tempAddingTags = [...addingTags];
    const tagNameArr = tempAddingTags.map((el) => el.name);
    const index = tagNameArr.indexOf(tag.name);
    if (index < 0) {
      //태그 추가
      if (tag.category === "food") {
        // 음식 대분류 없으면 추가
        if (tagNameArr.indexOf(tag.option) < 0) {
          tempAddingTags.push(tags.filter((el) => el.name === tag.option)[0]);
        }
      }
      tempAddingTags.push(tag);
    } else {
      // 음식 대분류에 해당하는게 하나만 있었으면 대분류 삭제
      tempAddingTags.splice(index, 1);
      if (tag.category === "food") {
        //태그 삭제
        if (
          tempAddingTags.filter((el) => el.option === tag.option).length === 0
        ) {
          const optionIndex = tagNameArr.indexOf(tag.option);
          tempAddingTags.splice(optionIndex, 1);
        }
      }
    }

    setAddingTags(tempAddingTags);
  };

  const onClickTagShow = (category) => {
    switch (category) {
      case "season":
        setShowTags({ ...showTags, season: !showTags.season });
        break;
      case "nature":
        setShowTags({ ...showTags, nature: !showTags.nature });
        break;
      case "food":
        if (showTags.food) {
          setFoodOption(null);
        }
        setShowTags({ ...showTags, food: !showTags.food });

        break;
      case "type":
        setShowTags({ ...showTags, type: !showTags.type });
        break;
      case "mood":
        setShowTags({ ...showTags, mood: !showTags.mood });
        break;
      case "companion":
        setShowTags({ ...showTags, companion: !showTags.companion });
        break;
      case "influencer":
        setShowTags({ ...showTags, influencer: !showTags.influencer });
        break;
      case "general":
        setShowTags({ ...showTags, general: !showTags.general });
        break;
      default:
        break;
    }
  };

  const resetTags = async () => {
    const reset = window.confirm("해당 포스트의 태그를 초기화 하시겠습니까?");
    if (!reset) return;

    setAddingTags([]);
  };

  const deleteTagFromPost = (tag) => {
    const tempAddingTags = [...addingTags];
    const tagNames = tempAddingTags.map((el) => el.name);
    const index = tagNames.indexOf(tag.name);
    tempAddingTags.splice(index, 1);
    setAddingTags(tempAddingTags);
  };

  return (
    <div style={{ marginTop: 60 }}>
      <Flex>
        <h2>태그 추가!!</h2>
        <Button
          style={{ height: 30, width: 60, marginLeft: 10 }}
          size="sm"
          onClick={() => setFoldContainer(!foldContainer)}
        >
          {foldContainer ? "펼치기" : "접기"}
        </Button>
      </Flex>

      {!foldContainer ? (
        <div>
          <div>
            <h5 style={{ margin: "10px 10px 10px 0", display: "inline-block" }}>
              🌸 {post.name}의 태그들 🌸
            </h5>
            <TagResetButton style={{ margin: 10 }} onClick={resetTags}>
              초기화
            </TagResetButton>
            {addingTags.length ? (
              <>
                <TagContainer>
                  {addingTags.map((tag) => {
                    return (
                      <Tag key={tag.name + "tagmap"}>
                        {`${tag.name} (${tag.category})`}
                        <IoIosClose
                          onClick={() => {
                            deleteTagFromPost(tag);
                          }}
                          cursor={"pointer"}
                          size={22}
                          color={"#494949"}
                          style={{ marginTop: -5, marginRight: -8 }}
                        />
                      </Tag>
                    );
                  })}
                </TagContainer>
              </>
            ) : (
              <div>아직 등록된 태그가 없어요</div>
            )}
          </div>

          <div>
            <h5 style={{ marginTop: 30 }}>추가할 태그를 선택하세요</h5>
            {TAG_CATEGORY_OPTION.map((category) => {
              if (category.value !== "food") {
                return (
                  <div>
                    <div style={{ marginTop: 10 }}>
                      <span
                        onClick={() => {
                          onClickTagShow(category.value);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {category.label}
                      </span>
                    </div>
                    <Flex
                      style={
                        showTags[category.value]
                          ? {
                              flexFlow: "wrap",
                            }
                          : {
                              display: "none",
                            }
                      }
                    >
                      {tags
                        .filter((tag) => tag.category === category.value)
                        .map((tag) => {
                          return (
                            <Tag
                              onClick={() => {
                                onClickTag(tag);
                              }}
                              style={{
                                backgroundColor: addingTags
                                  .map((el) => el.name)
                                  .includes(tag.name)
                                  ? "skyblue"
                                  : "#e2e2e2",
                                cursor: "pointer",
                              }}
                            >
                              {tag.name}
                            </Tag>
                          );
                        })}
                    </Flex>
                  </div>
                );
              } else {
                return (
                  <div>
                    <div style={{ marginTop: 10 }}>
                      <span
                        onClick={() => {
                          onClickTagShow(category.value);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        {category.label}
                      </span>
                    </div>
                    <Flex
                      style={
                        showTags[category.value]
                          ? {
                              flexFlow: "wrap",
                            }
                          : {
                              display: "none",
                            }
                      }
                    >
                      {tags
                        .filter(
                          (tag) => tag.category === "food" && tag.option === ""
                        )
                        .map((tag) => {
                          return (
                            <div
                              onClick={() => {
                                setFoodOption(tag.name);
                              }}
                              variant="success"
                              style={{
                                padding: 10,
                                backgroundColor:
                                  tag.name === foodOption
                                    ? "orange"
                                    : "#e2e2e2",
                                cursor: "pointer",
                              }}
                            >
                              {tag.name}
                            </div>
                          );
                        })}
                    </Flex>
                    <Flex
                      style={
                        foodOption
                          ? {
                              flexFlow: "wrap",
                            }
                          : {
                              display: "none",
                            }
                      }
                    >
                      {tags
                        .filter((tag) => tag.option === foodOption)
                        .map((tag) => {
                          return (
                            <Tag
                              onClick={() => {
                                onClickTag(tag);
                              }}
                              style={{
                                backgroundColor: addingTags
                                  .map((el) => el.name)
                                  .includes(tag.name)
                                  ? "skyblue"
                                  : "#e2e2e2",
                                cursor: "pointer",
                              }}
                            >
                              {tag.name}
                            </Tag>
                          );
                        })}
                    </Flex>
                  </div>
                );
              }
            })}
            {/* <div>food</div> */}
          </div>
        </div>
      ) : null}
    </div>
  );
}
