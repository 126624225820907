import { createCorporations, getCorporations } from "api/corporation";
import { uploadPackageImg } from "api/image";
import ImageField from "common/ImageField";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./promotion.style";

export interface IPromotionCreateProps {}

export default function PromotionCreate(props: IPromotionCreateProps) {
  const [corporations, setCorporations] = React.useState([]);
  const [corporationCount, setCorporationCount] = React.useState(0);

  const history = useHistory();
  const handleChange = (e, idx) => {
    const {
      target: { value, name },
    } = e;
    const tempInputs = [...corporations];
    tempInputs[idx][name] = value;
    setCorporations(tempInputs);
  };

  const handleChangeImg = async (e, idx) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) return;
    const formData = new FormData();
    formData.append("corporateLogo", file);
    const {
      data: { corporateLogo },
    } = await uploadPackageImg(formData);
    const tempInputs = [...corporations];
    tempInputs[idx].img = corporateLogo[0];
    setCorporations(tempInputs);
  };

  const handleDeleteImg = (idx) => {
    const tempInputs = [...corporations];
    tempInputs[idx].img = "";
    setCorporations(tempInputs);
  };

  const handleCorporations = (e) => {
    const {
      target: { id },
    } = e;
    console.log(id);
    const tempCorporations = [...corporations];
    if (id === "add") {
      tempCorporations.push({ img: "", name: "", nameEN: "" });
      setCorporations(tempCorporations);
      setCorporationCount(corporationCount + 1);
    }
    if (id === "subtract") {
      tempCorporations.pop();
      setCorporations(tempCorporations);
      if (corporationCount === 0) return;
      setCorporationCount(corporationCount - 1);
    }
  };

  const handleChangeImages = async () => {
    const formData = new FormData();
    const $img = document.querySelector("#multiple") as HTMLInputElement;
    if (!$img?.files.length) {
      alert("사진을 첨부해야 합니다.");
      return;
    }
    const fileArray = [...$img.files];
    fileArray.forEach((file, fileIndex) => {
      formData.append("imgSrcs", file);
    });
    const {
      data: { imgSrcs },
    } = await uploadPackageImg(formData);
    const newItems = imgSrcs.map((img) => ({
      img,
      name: "",
      nameEN: "",
    }));
    setCorporations([...corporations, ...newItems]);
    $img.value = "";
  };

  const handleSubmit = async () => {
    const data = corporations;
    try {
      await createCorporations(data);
      history.push("/promotion/detail");
    } catch (err) {
      console.error(err);
      alert("다시 시도해주세요");
    }
  };

  return (
    <>
      <button onClick={handleCorporations} id="add">
        +
      </button>
      <button onClick={handleCorporations} id="subtract">
        -
      </button>
      <S.CountSpan>
        추가한 기업: <span>{corporationCount}</span> 곳
      </S.CountSpan>
      <S.CorporationSection>
        {" "}
        {/* {corporations.map(({ img, name, nameEN }, idx) => (
          <S.InputContainer>
            <ImageField
              value={img}
              onChange={(e) => handleChangeImg(e, idx)}
              onDelete={() => handleDeleteImg(idx)}
              id={idx}
              key={idx}
            />
            <S.TextContainer>
              <S.InputBox>
                <label>기업명 | {idx + 1}번째</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e, idx)}
                />
              </S.InputBox>
              <S.InputBox>
                <label>기업명(영어)</label>
                <input
                  type="text"
                  name="nameEN"
                  value={nameEN}
                  onChange={(e) => handleChange(e, idx)}
                />
              </S.InputBox>
            </S.TextContainer>
          </S.InputContainer>
        ))} */}
        <input type="file" accept="image/*" id="multiple" multiple required />
        <button onClick={handleChangeImages}>등록한 사진 추가하기</button>
        {corporations.map(({ img, name, nameEN }, idx) => (
          <S.InputContainer>
            <S.Figure>
              <S.Img src={img} alt="image"></S.Img>
            </S.Figure>
            <S.TextContainer>
              <S.InputBox>
                <label>기업명 | {idx + 1}번째</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={(e) => handleChange(e, idx)}
                />
              </S.InputBox>
              <S.InputBox>
                <label>기업명(영어)</label>
                <input
                  type="text"
                  name="nameEN"
                  value={nameEN}
                  onChange={(e) => handleChange(e, idx)}
                />
              </S.InputBox>
            </S.TextContainer>
          </S.InputContainer>
        ))}
      </S.CorporationSection>
      <S.SubmitButton onClick={handleSubmit}>저장하기</S.SubmitButton>
    </>
  );
}
