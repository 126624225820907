import { getUsersWithConnectedAccounts } from "api/user";
import ADMIN_PHONE from "constants/admin";
import * as React from "react";
import styled from "styled-components";

export interface IConnectedHostAccountsProps {}

const TH = styled.th`
  border: 1px solid black;
  background-color: black;
  color: white;
  text-align: center;
`;

const TD = styled.td`
  border: 0.5px solid black;
  padding: 0.5rem;
  text-align: center;
`;

export default function ConnectedHostAccounts(
  props: IConnectedHostAccountsProps
) {
  const [users, setUsers] = React.useState([]);

  const PLATFORM = {
    AIRBNB: "에어비앤비",
    NAVER_BOOKING: "네이버 예약",
    INSTAGRAM_DM: "인스타그램 DM",
  };

  React.useEffect(() => {
    //
    const fetchUsersWithConnectedAccounts = async () => {
      const users = await getUsersWithConnectedAccounts();
      const userWithOutAdmin = users.filter(
        ({ phone }) => !ADMIN_PHONE.includes(phone)
      );
      setUsers(userWithOutAdmin);
    };
    fetchUsersWithConnectedAccounts();
  }, []);
  return (
    <div>
      <table>
        <tr>
          <TH>호스트명</TH>
          <TH>전화번호</TH>
          <TH>에어비앤비</TH>
          <TH>네이버 예약</TH>
          <TH>인스타 DM</TH>
          <TH>운영숙소</TH>
        </tr>
        {users.map((user) => {
          const {
            connectedAccounts,
            username,
            nickname,
            phone,
            hostingLodgments,
          } = user;

          const getAccountByPlatformType = (targetPlatformType) =>
            connectedAccounts?.find(
              ({ platformType }) => platformType === targetPlatformType
            );
          const airbnbAccount = getAccountByPlatformType(PLATFORM.AIRBNB);
          const naverAccount = getAccountByPlatformType(PLATFORM.NAVER_BOOKING);
          const instagramAccount = getAccountByPlatformType(
            PLATFORM.INSTAGRAM_DM
          );

          return (
            <tr>
              <TD>{username || nickname}</TD>
              <TD>{phone}</TD>
              <TD>
                아이디: {airbnbAccount?.username || "미등록"}
                <br />
                비밀번호: {airbnbAccount?.password || "미등록"}
              </TD>
              <TD>
                아이디: {naverAccount?.username || "미등록"}
                <br />
                비밀번호: {naverAccount?.password || "미등록"}
              </TD>
              <TD>
                아이디: {instagramAccount?.username || "미등록"}
                <br />
                비밀번호: {instagramAccount?.password || "미등록"}
              </TD>
              <TD>
                {hostingLodgments?.map(({ name }) => name).join(", ") || "없음"}
              </TD>
            </tr>
          );
        })}
      </table>
    </div>
  );
}
