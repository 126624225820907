import { TagBox, Tag } from "../style";
import Select from "react-select";
import { AIRBNB_TAG, AIRBNB_TAGS_FOR_SELECT } from "constants/accommodation";
import { useState } from "react";
import { useEffect } from "react";
import { Flex } from "components/style";
import { Button } from "react-bootstrap";

export default function TagSelector({ searchCondition, setSearchCondition }) {
  const [selectValues, setSelectValues] = useState([]);

  const addTagsToCondition = (e) => {
    e.preventDefault();

    const values = selectValues
      .filter((el) => !searchCondition.tags.includes(el.value))
      .map((el) => el.value);

    setSearchCondition({
      ...searchCondition,
      tags: [...searchCondition.tags, ...values],
    });

    setSelectValues([]);
  };

  const deleteTagFromCondition = (tag) => {
    const tempTags = [...searchCondition.tags];
    const idx = tempTags.indexOf(tag);
    tempTags.splice(idx, 1);
    setSearchCondition({ ...searchCondition, tags: tempTags });
  };

  return (
    <div style={{ marginBottom: 40 }}>
      <h4>관련 태그</h4>
      <TagBox>
        {searchCondition.tags.map((tag) => {
          return (
            <Tag>
              <Flex>
                <div style={{ margin: 7, marginLeft: 13 }}>{tag}</div>
                <div
                  onClick={() => {
                    deleteTagFromCondition(tag);
                  }}
                  style={{ marginRight: 7, color: "black" }}
                >
                  x
                </div>
              </Flex>
            </Tag>
          );
        })}
      </TagBox>
      <Select
        options={AIRBNB_TAGS_FOR_SELECT}
        isMulti
        isClearable
        onChange={(val) => {
          setSelectValues(val);
        }}
        value={selectValues}
      />
      <Button onClick={addTagsToCondition}>태그 추가하기</Button>
    </div>
  );
}
