import * as React from "react";
import * as S from "../forms.style";

export interface INextMsgInputProps {
  currentMsgTemplateTitle;
  nextMsgTemplate;
  setNextMsgTemplate;
  msgTemplateList;
}

export default function NextMsgInput({
  currentMsgTemplateTitle,
  nextMsgTemplate,
  setNextMsgTemplate,
  msgTemplateList,
}: INextMsgInputProps) {
  const onFormSelect = (e) => {
    setNextMsgTemplate(e.target.value);
  };
  return (
    <>
      <S.InputLayout>
        <S.Label>next 폼:</S.Label>
        <S.FormSelect
          value={nextMsgTemplate}
          name="nextForm"
          onChange={onFormSelect}
        >
          <option value="">없음</option>
          {msgTemplateList.map(({ _id, title }) => {
            if (currentMsgTemplateTitle === title) {
              return;
            }
            return (
              <option key={title} value={_id}>
                {title}
              </option>
            );
          })}
        </S.FormSelect>
      </S.InputLayout>
    </>
  );
}
