import { PUSH_PATH } from "constants/path";

import { myAxios } from "./myAxios";

export const sendPush = async (data): Promise<any> => {
  const path = `${PUSH_PATH}/send`;
  const res = await myAxios.post({ path, data });
  return res.data;
};

export const getPushHistory = async (): Promise<any> => {
  const path = `${PUSH_PATH}/history`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const sendHostPushMessageByPhone = async (phone, data): Promise<any> => {
  const path = `${PUSH_PATH}/host/phone/${phone}`;
  const res = await myAxios.post({ path, data });
  return res.data.msg;
};
