import { uploadPackageImg } from "api/image";
import Button from "common/Button";
import ImageField from "common/ImageField";
import { InputField } from "common/Input/Main";
import * as React from "react";
import * as S from "../../preopen.style";
import Preview from "./Preview";
import { uploadFile } from "utils/upload";
import { createPreopenMultipleLodgment } from "api/preopen";
import { useHistory } from "react-router-dom";
export interface IMultipleProps {}

/*eslint-disable*/
export default function Multiple(props: IMultipleProps) {
  const history = useHistory();
  const [lodgmentName, setLodgmentName] = React.useState("");
  const [params, setParams] = React.useState("");
  const [globalName, setGlobalName] = React.useState({
    en: "",
  });
  const [styles, setStyles] = React.useState({
    color: "",
    backgroundColor: "",
  });
  const [images, setImages] = React.useState({
    banner: "",
    logo: "",
  });
  const [mainTitle, setMainTitle] = React.useState({
    row1: "",
    row2: "",
    desc: "",
  });
  const [paragraphs, setParagraphs] = React.useState([
    {
      name: "",
      desc: "",
    },
    {
      name: "",
      desc: "",
    },
  ]);
  const [rooms, setRooms] = React.useState([]);

  const data = {
    lodgmentName,
    params,
    globalName,
    styles,
    images,
    mainTitle,
    paragraphs,
    rooms,
  };

  const handleChange = (e, setState) => {
    const { value } = e.target;
    setState(value);
  };

  const handleFileChange = async (e, setState, key) => {
    const file = e.target.files.length ? e.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append("images", file);
    const {
      data: { images },
    } = await uploadPackageImg(formData);
    setState((prev) => ({
      ...prev,
      [key]: images[0],
    }));
  };

  const handleArrayFileChange = async (e, setState, index, key) => {
    const file = e.target.files.length ? e.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append("images", file);
    const {
      data: { images },
    } = await uploadPackageImg(formData);
    setState((prev) => {
      const newArr = [...prev];
      newArr[index][key] = images[0];
      return newArr;
    });
  };

  const removeArrayFile = (e, setState, index, key) => {
    setState((prev) => {
      const newArr = [...prev];
      newArr[index][key] = "";
      return newArr;
    });
  };

  const removeFile = (e, setState, key) => {
    setState((prev) => ({
      ...prev,
      [key]: "",
    }));
  };

  const handleObjectChange = (e, setState, key) => {
    const { value } = e.target;
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const handleArrayChange = (e, setState, index, key) => {
    const { value } = e.target;
    setState((prev) => {
      const newArr = [...prev];
      newArr[index][key] = value;
      return newArr;
    });
  };

  const removeArray = (e, setState, index) => {
    setState((prev) => {
      const newArr = [...prev];
      newArr.splice(index, 1);
      return newArr;
    });
  };

  const handleArrayObjectChange = (e, setState, index, key, subKey) => {
    const { value } = e.target;
    setState((prev) => {
      const newArr = [...prev];
      newArr[index][key][subKey] = value;
      return newArr;
    });
  };

  const handleMultipleFileChange = async (e) => {
    const { images } = await uploadFile(e);
    const forms = images.map((image) => ({
      name: "",
      desc: "",
      src: image,
      routePath: "",
      globalName: { en: "" },
    }));
    setRooms((prev) => [...prev, ...forms]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const requestData = {
      name: lodgmentName,
      params,
      globalName,
      styles,
      images,
      mainTitle,
      paragraphs,
      rooms,
    };
    try {
      const res = await createPreopenMultipleLodgment(requestData);
      alert("성공적으로 등록되었습니다.");
      history.push("/preopen");
    } catch (error) {
      console.log(error);
      alert("에러가 발생했습니다. 다시 시도해주세요.");
    }
  };

  return (
    <S.ContentLayout>
      <S.InputColumn>
        <InputField>
          <InputField.Label label="숙소명" />
          <InputField.Input
            value={lodgmentName}
            onChange={(e) => handleChange(e, setLodgmentName)}
            name="lodgmentName"
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="영어 이름" />
          <InputField.Input
            value={globalName.en}
            onChange={(e) => handleObjectChange(e, setGlobalName, "en")}
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="URL 주소명" />
          <span>
            URL을 정해주세요.
            <br />
            <b>소문자, 숫자, 하이픈(-)으로만</b> 작성해주세요! ex) <br />
            https://hautrip.com/<b>seodammiga-example</b>
          </span>
          <InputField.Input
            value={params}
            onChange={(e) => handleChange(e, setParams)}
            backgroundColor="#F3F3F3"
          />
          <div>스타일</div>
          <InputField.Label label="배경색" />
          <InputField.Input
            value={styles.backgroundColor}
            onChange={(e) =>
              handleObjectChange(e, setStyles, "backgroundColor")
            }
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="글자색" />
          <InputField.Input
            value={styles.color}
            onChange={(e) => handleObjectChange(e, setStyles, "color")}
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="메인 배너" />
          <ImageField
            value={images.banner}
            onChange={(e) => handleFileChange(e, setImages, "banner")}
            onDelete={(e) => removeFile(e, setImages, "banner")}
            id="banner"
          />
          <div>메인 타이틀</div>
          <InputField.Label label="1행" />
          <InputField.Input
            value={mainTitle.row1}
            onChange={(e) => handleObjectChange(e, setMainTitle, "row1")}
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="2행" />
          <InputField.Input
            value={mainTitle.row2}
            onChange={(e) => handleObjectChange(e, setMainTitle, "row2")}
            backgroundColor="#F3F3F3"
          />
          <InputField.Label label="설명" />
          <InputField.Input
            value={mainTitle.desc}
            onChange={(e) => handleObjectChange(e, setMainTitle, "desc")}
            backgroundColor="#F3F3F3"
          />

          <InputField.Label label="부제" />
          <InputField.TextArea
            value={paragraphs[0].name}
            onChange={(e) => handleArrayChange(e, setParagraphs, 0, "name")}
          />
          <InputField.Label label="로고 이미지" />
          <ImageField
            value={images.logo}
            onChange={(e) => handleFileChange(e, setImages, "logo")}
            onDelete={(e) => removeFile(e, setImages, "logo")}
            id="logo"
          />
          <InputField.Label label="설명" />
          <InputField.TextArea
            value={paragraphs[0].desc}
            onChange={(e) => handleArrayChange(e, setParagraphs, 0, "desc")}
          />
          <InputField.Label label="객실소개 부제" />
          <InputField.Input
            value={paragraphs[1].name}
            backgroundColor="#F3F3F3"
            onChange={(e) => handleArrayChange(e, setParagraphs, 1, "name")}
          />
          <InputField.Label label="설명" />
          <InputField.TextArea
            value={paragraphs[1].desc}
            onChange={(e) => handleArrayChange(e, setParagraphs, 1, "desc")}
          />
          <div>객실 상세 정보</div>
          <InputField.File onChange={handleMultipleFileChange} multiple />
          {rooms?.map(({ name, desc, src, routePath }, index) => (
            <S.RoomInfoBox>
              <button
                className="remove"
                onClick={(e) => removeArray(e, setRooms, index)}
              >
                X
              </button>
              <InputField.Label label="객실명" />
              <InputField.Input
                value={name}
                onChange={(e) => handleArrayChange(e, setRooms, index, "name")}
                backgroundColor="#F3F3F3"
              />
              <InputField.Label label="영어 이름" />
              <InputField.Input
                value={rooms[index].globalName.en}
                onChange={(e) =>
                  handleArrayObjectChange(
                    e,
                    setRooms,
                    index,
                    "globalName",
                    "en"
                  )
                }
                backgroundColor="#F3F3F3"
              />
              <InputField.Label label="설명" />
              <span> 예시) 제주 제주시 | 2인(최대 3인) </span>
              <InputField.Input
                value={desc}
                id={`room-${index}`}
                onChange={(e) => handleArrayChange(e, setRooms, index, "desc")}
                backgroundColor="#F3F3F3"
              />
              <InputField.Label label="이미지" />
              <ImageField
                value={src}
                onChange={(e) =>
                  handleArrayFileChange(e, setRooms, index, "src")
                }
                onDelete={(e) => removeArrayFile(e, setRooms, index, "src")}
                id={index}
              />
              <InputField.Label label="URL 주소명" />
              <span>
                객실 상세주소를 입력하세요! 예시) /lodgment/숙소고유ID
                (https://hautrip.com 제외한 부분)
              </span>
              <InputField.Input
                value={routePath}
                onChange={(e) =>
                  handleArrayChange(e, setRooms, index, "routePath")
                }
                backgroundColor="#F3F3F3"
              />
            </S.RoomInfoBox>
          ))}
        </InputField>
        <S.SubmitSection>
          <Button onClick={handleSubmit}>저장하기</Button>
        </S.SubmitSection>
      </S.InputColumn>
      <S.PreviwColum>
        <div>URL 주소: https://hautrip.com/{params}</div>
        <Preview {...data} />
      </S.PreviwColum>
    </S.ContentLayout>
  );
}
