import React, { useEffect, useState } from "react";
import ChatRoomItem from "./ChatRoomItem";
import {
  getConsultsWithSorting,
  getOrCreateLodgmentConsult,
} from "../../api/consult";
import { IConsult } from "../../type/consult.type";
import * as S from "./ChatRoomList.style";
import ADMIN_PHONE from "constants/admin";
import { searchUser } from "api/user";
import styled from "styled-components";
import { CHAT_PATH } from "constants/path";

const FINESTAYS_LODGMENT_ID = "653296a1d9a904a81f6ba556";

export interface IChatRoomListProps {}

export default function ChatList(props: IChatRoomListProps) {
  const [consults, setConsults] = useState<IConsult[]>([]);
  const [showAdmin, setShowAdmin] = useState<boolean>(false);
  const [value, setValue] = useState<string>("");
  const [result, setResult] = useState<any[]>([]);

  useEffect(() => {
    const fetchConsults = async () => {
      console.log("fetch start");
      const { consults } = await getConsultsWithSorting();
      console.log("consults", consults);
      const lodgmentConsults = consults.filter(({ lodgment }) => lodgment);
      setConsults(lodgmentConsults);
    };
    fetchConsults();
  }, []);

  const clickShowAdmin = (event: React.MouseEvent<HTMLButtonElement>) => {
    const target = event.target as HTMLInputElement;
    setShowAdmin(target.checked);
  };

  const reloadConsults = async () => {
    const { consults } = await getConsultsWithSorting();
    setConsults(consults);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    const res = await searchUser(value);
    console.log("res", res);
    setResult(res);
  };

  React.useEffect(() => {
    if (value === "") {
      setResult([]);
    }
  }, [value]);

  return (
    <S.ChatRoomListWrap>
      <S.TopMenu>
        <div>
          <label htmlFor="show-admin" style={{ marginRight: 4 }}>
            관리자 채팅
          </label>
          <input type="checkbox" checked={showAdmin} onClick={clickShowAdmin} />
        </div>
        <div className="user-search">
          <form onSubmit={handleSearch}>
            <input value={value} onChange={(e) => setValue(e.target.value)} />
            <button>검색</button>
          </form>
          <div className="result-box">
            {result.map((user) => (
              <SearchResultItem user={user} />
            ))}
          </div>
        </div>
        <button
          style={{
            padding: 6,
            backgroundColor: "skyblue",
            color: "white",
            border: "none",
          }}
          onClick={reloadConsults}
        >
          새로고침
        </button>
      </S.TopMenu>
      <S.ChatRoomListContainer style={{ marginTop: "2rem" }}>
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {consults.map((consult) => {
            const {
              _id,
              userPhone,
              date,
              newMsgCount,
              lastMsg,
              memo,
              username,
              nickname,
              lodgmentName,
              userPhoneNum,
            } = consult;
            const isAdmin = ADMIN_PHONE.includes(userPhone);

            return (
              <ChatRoomItem
                key={_id}
                username={username}
                nickname={nickname}
                isAdmin={isAdmin}
                consultId={_id}
                userPhoneNum={userPhoneNum}
                date={date}
                newMsgCount={newMsgCount}
                lastMsg={lastMsg}
                memo={memo}
                lodgmentName={lodgmentName}
              />
            );
          })}
        </div>
      </S.ChatRoomListContainer>
    </S.ChatRoomListWrap>
  );
}

function SearchResultItem({ user }) {
  const handleClick = async (user) => {
    const consult = await getOrCreateLodgmentConsult({
      user: user._id,
      lodgment: FINESTAYS_LODGMENT_ID,
    });
    window.open(`${CHAT_PATH}/${consult._id}`, "_blank");
  };

  return (
    <ItemBox key={user._id}>
      <span>{user.username}</span>
      <span>{user.phone}</span>
      <Button onClick={() => handleClick(user)}>채팅방 개설</Button>
    </ItemBox>
  );
}

export const ItemBox = styled.div`
  border: 1px solid black;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
`;

export const Button = styled.button`
  border: 1px solid black;
  outline: none;
  background-color: black;
  padding: 4px 8px;
  color: white;
  margin-left: 8px;
`;
