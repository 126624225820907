import * as React from "react";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";
import * as S from "../input.style";

export interface IEmptyFileUIProps {
  id?;
}

export function EmptyFileUI({ id }: IEmptyFileUIProps) {
  return (
    <S.PlusIcon htmlFor={id}>
      <IoMdAdd />
    </S.PlusIcon>
  );
}

export interface IFilledFileUIProps {
  name?: string;
  value?: string;
  onRemove: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export function FilledFileUI({ name, value, onRemove }: IFilledFileUIProps) {
  return (
    <>
      <S.UploadedImage src={value} />
      <S.ActionRemoveBox onClick={onRemove}>
        <AiOutlineClose />
      </S.ActionRemoveBox>
    </>
  );
}
