import * as React from "react";
import { useParams } from "react-router-dom";
import { getShort } from "api/shortsposts";
import ShortsCreate from "./create";

export interface IShortsDetailProps {
  shortsPostId: string;
}

export interface params {
  shortsPostId: string;
}

export default function ShortsDetail({}: IShortsDetailProps) {
  const { shortsPostId } = useParams<params>();
  const [place, setPlace] = React.useState();

  React.useEffect(() => {
    console.log("dm");
    const fetchPlace = async () => {
      const { data } = await getShort(shortsPostId);
      console.log(data, "kkk");
      // data.availableDates = data.availableDates.map((item) => {
      //   const newDate = {
      //     date: new Date(item.date).toISOString().slice(0, 10),
      //     price: item.price,
      //   };
      //   return newDate;
      // });
      setPlace(data);
    };
    fetchPlace();
  }, []);

  return <ShortsCreate data={place} />;
}
