import { COLLABORATION_BUNDLE_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const confirmCollaborationsByBundleId = async (bundleId: string) => {
  const path = `${COLLABORATION_BUNDLE_PATH}/confirm/${bundleId}`;
  const res = await myAxios.put({ path });
  return res.data;
};

export const rejectCollaborationsByBundleId = async (
  bundleId: string,
  reason: string
) => {
  const path = `${COLLABORATION_BUNDLE_PATH}/reject/${bundleId}`;
  const res = await myAxios.put({ path, data: { reason } });
  return res.data;
};

export const updateCollaborationsByBundleId = async (id, data) => {
  const path = COLLABORATION_BUNDLE_PATH + `/${id}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
