import Modal from "react-modal";

export default function DeleteModal({
  isModalVisible,
  setIsModalVisible,
  deleteTag,
}) {
  return (
    <Modal
      isOpen={isModalVisible}
      onRequestClose={() => {
        setIsModalVisible(!isModalVisible);
      }}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          width: 600,
          height: 150,
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <div>삭제 할거야?</div>
      <div
        style={{
          display: "flex",
          marginTop: 20,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          style={{
            backgroundColor: "red",
            borderRadius: 10,
            padding: 5,
            width: 100,
            color: "#ffffff",
            fontWeight: "700",
            alignSelf: "center",
            marginRight: 10,
            textAlign: "center",
            cursor: "pointer",
          }}
          onClick={() => {
            deleteTag();
          }}
        >
          ㅇㅇ 삭제함
        </div>
        <div
          style={{
            backgroundColor: "gray",
            borderRadius: 10,
            padding: 5,
            width: 50,
            color: "#ffffff",
            fontWeight: "700",
            cursor: "pointer",
            textAlign: "center",
            alignSelf: "center",
          }}
          onClick={() => {
            setIsModalVisible(!isModalVisible);
          }}
        >
          취소
        </div>
      </div>
    </Modal>
  );
}
Modal.setAppElement("#root");
