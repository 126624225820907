import { getHauData } from "api/hauData";
import * as React from "react";

export interface IHauDataProps {}

export default function HauData(props: IHauDataProps) {
  const [hauData, setHauData] = React.useState<Record<any, any>>();
  React.useEffect(() => {
    const fetchHauData = async () => {
      const hauData = await getHauData();
      setHauData(hauData);
    };
    fetchHauData();
  }, []);

  if (!hauData) return <></>;
  return (
    <div>
      {hauData.map((data) => (
        <div>
          <h3>{data.title}</h3>
          {data.contents.map((content) => (
            <div>
              <h4>{content.title}</h4>
              <p
                style={{
                  whiteSpace: "pre-line",
                }}
              >
                {content.data}
              </p>
            </div>
          ))}
          <br />
          <br />
        </div>
      ))}
    </div>
  );
}
