import { isElementInArray } from "./array";
import { convertDateIntoString, getBetweenDates } from "./date";

const FALLBACK_COMMISSION = 0;

const REAL_NAVER_COMMISSION = {
  directSearchCommission: "0.00",
  algorithmCommission: "3.96",
};

interface Lodgment {
  _id: string;
  name: string;
  isChargeAddPersonCostForEachDay: boolean;
}

interface Commission {
  startDate: string | null;
  endDate: string | null;
  commission: number;
}

interface NaverCommissionInfo {
  directSearchCommission: number;
  algorithmCommission: number;
}

interface PlatformCommission {
  naver: NaverCommissionInfo;
  [key: string]: any;
}

interface Settlement {
  accountNumber: string;
  bank: string;
  commission: number;
  commissions: Commission[];
  createdAt: string;
  depositor: string;
  isEarlySettlement: boolean;
  lodgment: string;
  vat: number;
  platformCommissions: PlatformCommission;
  [key: string]: any;
}

interface ReservaionBasicInfo {
  name: string;
  start: string;
  end: string;
  price: number;
  defaultHeadcount: number;
  [key: string]: any;
}
interface Reservation {
  payOID: string;
  lodgment: Lodgment;
  settlement: Settlement;
  basicInfo: ReservaionBasicInfo;
  userInfo: {
    name: string;
    phone: string;
  };
  naverCommissionRate: string;
  settlementDate: string;
  status: string;
  totalPrice: number;
  [key: string]: any;
}

// naver 수수료 계산 default 0.00 , 3.96 string 타입
const calculateNaverCommission = (
  naverCommissionRate: string,
  direct,
  algorithm
) => {
  if (naverCommissionRate === REAL_NAVER_COMMISSION.directSearchCommission) {
    return direct;
  }
  if (naverCommissionRate === REAL_NAVER_COMMISSION.algorithmCommission) {
    return algorithm;
  }
  return 3.3;
};

const calculateDefaultCommission = (
  commissions: Commission[],
  settlementDate: string
) => {
  if (commissions?.length === 0 || !commissions) return 3.3;
  const dynamicCommissionInfo = commissions
    .filter((item) => item.startDate && item.endDate)
    .find((item) => {
      const startDate = new Date(item.startDate);
      const endDate = new Date(item.endDate);
      const targetDate = new Date(settlementDate);
      return targetDate >= startDate && targetDate <= endDate;
    });

  const staticCommissionInfo = commissions.find(
    (item) => !item.startDate && !item.endDate
  );
  const commissionInfo = dynamicCommissionInfo || staticCommissionInfo;
  return commissionInfo?.commission;
};

export const calculateLodgmentCommission = (reservation: Reservation) => {
  if (
    reservation?.settlement?.commissions?.length === 0 ||
    !reservation?.settlement?.commissions
  ) {
    console.log("commissions is empty", reservation);
  }
  const { payOID, settlement, naverCommissionRate, settlementDate } =
    reservation;

  // 파인스테이 변동,고정 수수료 고려한 계산
  const defaultCommission = calculateDefaultCommission(
    settlement?.commissions,
    settlementDate
  );

  // 네이버 예약
  if (payOID?.includes("naver")) {
    const directSearchCommission =
      settlement?.platformCommissions?.naver?.directSearchCommission ||
      FALLBACK_COMMISSION;
    const algorithmCommission =
      settlement?.platformCommissions?.naver?.algorithmCommission ||
      FALLBACK_COMMISSION;
    return calculateNaverCommission(
      naverCommissionRate,
      directSearchCommission,
      algorithmCommission
    );
  }

  // 파인스테이 예약
  return defaultCommission || FALLBACK_COMMISSION;
};

// 부분환불된 예약건 + 예약건 모두 포함하여 계산
// 네이버 예약건의 경우 네이버 수수료율로 계산
// 0.00 , 3.96
export const calculateSettlementAmount = (
  reservation: Reservation,
  reservations?: Reservation[]
) => {
  const {
    totalPrice,
    status,
    partiallyRefundedAmount,
    partiallyRefundedAmountForHost = 0,
    payOID,
    payBackPrice,
    payBackPriceForHost = 0,
    settlement,
    basicInfo,
    naverCommissionRate = "0.00", // 부분환불된 네이버 예약건의 수수료율이 반영되지 않는 상황
  } = reservation;

  // 파인스테이 자체 쿠폰 할인금액
  const selfCouponDiscount = basicInfo?.couponDiscount || 0;
  // 파인스테이 자체 코드 할인금액
  const selfCodeDiscount = basicInfo?.codeDiscount || 0;

  const refundPrice = payBackPriceForHost || payBackPrice || 0;

  let totalSellingPrice = 0;
  const isNaver = payOID?.includes("naver");

  const defaultCommission = calculateLodgmentCommission(reservation);
  const finalVat = Number(defaultCommission * ((settlement?.vat || 10) / 100)); //  부가세는 수수료의 10%
  const parsedCommission = defaultCommission + finalVat; // 최종 수수료율

  if (!totalPrice) return 0;

  if (!isNaver) {
    // 파인스테이 부분환불 + 확정건
    if (status === "confirmed" && payBackPrice) {
      totalSellingPrice =
        totalPrice + selfCouponDiscount + selfCodeDiscount - refundPrice;
      return totalSellingPrice - totalSellingPrice * (parsedCommission / 100);
    }
    // 파인스테이 확정건
    if (status === "confirmed") {
      totalSellingPrice = totalPrice + selfCouponDiscount + selfCodeDiscount;
      return totalSellingPrice - totalSellingPrice * (parsedCommission / 100);
    }
    // 파인스테이 부분환불 + 취소 (호스트 정산건 존재) // 해당건은 거절데이터에 쌓임
    if (status === "partiallyRefunded") {
      const cancelledAmount =
        partiallyRefundedAmountForHost || partiallyRefundedAmount; // 취소금액
      totalSellingPrice =
        totalPrice + selfCouponDiscount + selfCodeDiscount - cancelledAmount;
      // 만일 단순부분환불(예약은 확정)이면, 이 거절데이터에 대한 정산금액은 0원이다.
      // 그러나 부분환불된 예약취소건이면, 취소금액만큼 정산금액에서 빼주면 안된다.
      const isConfirmedDataExist = reservations
        ?.filter(({ status }) => status === "confirmed")
        ?.find((reservation) => reservation.payOID === payOID);
      if (isConfirmedDataExist) {
        return 0;
      }
      return totalSellingPrice - totalSellingPrice * (parsedCommission / 100);
    }
    // 파인스테이 100%환불 + 취소
    if (status === "refused") {
      return 0;
    }
    return 0;
  }

  if (isNaver) {
    // 네이버 확정건
    if (status === "confirmed") {
      return totalPrice - totalPrice * (parsedCommission / 100);
    }
    // 네이버 100% 환불 + 취소
    if (
      status === "partiallyRefunded" &&
      totalPrice === partiallyRefundedAmount
    ) {
      return 0;
    }
    // 네이버 부분환불 + 취소 (호스트 정산건 존재할 수 있음)
    if (
      status === "partiallyRefunded" &&
      totalPrice !== partiallyRefundedAmount
    ) {
      // 환불금액 = partiallyRefundedAmount(고객이 받는돈)
      // null이거나 0이면 totalPrice만큼 정산금액을 계산
      if (!partiallyRefundedAmount) {
        return totalPrice - totalPrice * (parsedCommission / 100);
      }
      // 부분환불된 금액만큼 정산금액에서 빼준다.

      if (partiallyRefundedAmount) {
        const totalSellingPrice = totalPrice - partiallyRefundedAmount;
        return totalSellingPrice - totalSellingPrice * (parsedCommission / 100);
      }
    }
    // 0% 환불되어 취소된 경우밖에 없음
    if (status === "refused") {
      return totalPrice - totalPrice * (parsedCommission / 100);
    }
  }
};

export const calculateTotalSettlementAmount = (reservations: Reservation[]) => {
  return reservations.reduce((acc, cur) => {
    return acc + Math.floor(calculateSettlementAmount(cur, reservations));
    // return acc + calculateSettlementAmount(cur);
  }, 0);
};

//   const calculateSales = (reservation) => {
//     if (reservation.status === "partiallyRefunded")
//       return (
//         ((reservation.totalPrice - reservation.partiallyRefundedAmount) *
//           reservation.lodgment.commission) /
//         100
//       );
//     return (reservation.totalPrice * reservation.lodgment.commission) / 100;
//   };

//   const calculateSettlement = (reservation) => {
//     if (reservation.status === "partiallyRefunded")
//       return (
//         reservation.totalPrice -
//         reservation.partiallyRefundedAmount -
//         calculateSales(reservation)
//       );
//     return reservation.totalPrice - calculateSales(reservation);
//   };

export const getDiscountKeyInfos = (reservation: Reservation) => {
  const { basicInfo } = reservation;
  const {
    name,
    defaultHeadcount,
    headcountTypedByUser,
    price,
    start,
    end,
    codeValue,
    codeDiscount, // 호스트에게는 자체 코드할인 내역이 보이면 안된다.
    ...discountInfo
  } = basicInfo;
  const keys = Object.keys(discountInfo);
  return keys;
};

export const getSelectedAdditionalOptions = (reservation: Reservation) => {
  const { additionalOptions } = reservation;
  const selectedOptions = additionalOptions.filter(
    (option) => option.isSelected
  );
  return selectedOptions;
};

// 추가옵션 합산
export const calculateSelectedAdditionalOptions = (
  reservation: Reservation
) => {
  const selectedOptions = getSelectedAdditionalOptions(reservation);
  const totalHeadcount = getSelectedHeadcount(reservation);
  const defaultHeadcount = reservation.basicInfo.defaultHeadcount;

  // 인원 추가에 따른 추가옵션 이용료 부과
  return selectedOptions.reduce((acc, opt) => {
    const optPrice: number = opt.defaultPrice;

    // defaultProvisionCount가 없으면 초과 인원 조건 없다는 뜻
    // naver에서는 defaultProvisionCount가 없다.
    const exceededHeadcount = totalHeadcount - opt?.defaultProvisionCount || 0;

    // 인원 초과 없다는 뜻
    if (exceededHeadcount === 0) {
      return (acc += optPrice);
    }

    if (exceededHeadcount > 0) {
      return (acc += opt.addedPrice * exceededHeadcount);
    }
  }, 0);
};

// 인원옵션 합산
// 2박 이상일 때 인원옵션 추가금액 계산

// naver 모두 price, age, count 필드 있음
// 무료이면 price 필드 자체가 없을 수 있음
export const calculatePersonOptions = (reservation: Reservation) => {
  const { lodgment } = reservation;
  // 기본일 때
  const selectedOptions = getSelectedPersonOptions(reservation);
  const oneDayPrice = selectedOptions.reduce((acc, opt) => {
    const sellingPrice = opt?.price || 0;
    return acc + sellingPrice * opt.count;
  }, 0);

  const isNaver = reservation.payOID.includes("naver");

  if (!isNaver && lodgment?.isChargeAddPersonCostForEachDay) {
    const { startDate, endDate } = reservation.basicInfo;
    const dates = getBetweenDates(startDate, endDate);
    return oneDayPrice * dates.length;
  }
  return oneDayPrice;
};

export const getSelectedPersonOptions = (reservation: Reservation) => {
  const { addPersonOptions } = reservation;
  const selectedOptions = addPersonOptions.filter(
    (option) => option.count >= 1
  );
  return selectedOptions;
};

export const getSelectedHeadcount = (reservation: Reservation) => {
  const selectedPersonOptions = getSelectedPersonOptions(reservation);
  const defaultHeadcount = reservation.basicInfo.defaultHeadcount;

  const totalHeadcount = selectedPersonOptions.reduce((acc, opt) => {
    return (acc += opt?.count || 0);
  }, defaultHeadcount);

  return totalHeadcount;
};

// 취소 금액 구하기
export const calculateCancelledAmount = (reservation: Reservation) => {
  const {
    totalPrice,
    partiallyRefundedAmount,
    partiallyRefundedAmountForHost = 0,
    payBackPrice,
    payBackPriceForHost = 0,
    payOID,
    status,
    basicInfo,
  } = reservation;

  const isNaver = payOID.includes("naver");
  const codeDiscount = basicInfo?.codeDiscount || 0;

  if (isNaver) {
    // 100 % 환불된 경우 또는 (~99% 환불된 경우)
    if (status === "partiallyRefunded") {
      return partiallyRefundedAmount;
    }
    // 0% 환불해준 경우 (거절) 0원
    if (status === "refused") {
      return 0;
    }
    // 확정된 경우
    if (status === "confirmed") {
      return 0;
    }
  }

  // 정산할 때는 코드할인금액은 제외해야 한다.
  if (!isNaver) {
    // 100% 환불된 경우
    if (status === "refused") {
      return totalPrice + codeDiscount;
    }
    // 부분환불(취소)
    if (status === "partiallyRefunded") {
      return partiallyRefundedAmountForHost || partiallyRefundedAmount;
    }
    // 부분환불 확정건
    if (status === "confirmed") {
      return payBackPriceForHost || payBackPrice || 0;
    }
  }
};

// 환불해주고 남은 금액 구하기(부분환불된 경우에 대한 것) 환불금액 = 환불을 제외한 나머지 금액
export const calculateRemainAfterRefund = (reservation: Reservation) => {
  const {
    totalPrice,
    partiallyRefundedAmount,
    partiallyRefundedAmountForHost = 0,
    payBackPrice = 0,
    payBackPriceForHost = 0,
    payOID,
    status,
    basicInfo,
  } = reservation;

  const isNaver = payOID.includes("naver");
  const codeDiscount = basicInfo?.codeDiscount || 0;

  const refundPrice = payBackPriceForHost || payBackPrice || 0;

  if (isNaver) {
    if (status === "partiallyRefunded") {
      return totalPrice - partiallyRefundedAmount;
    }

    // 0% 환불
    if (status === "refused") {
      return totalPrice - (partiallyRefundedAmount || 0);
    }
    // 확정은 환불개념이 없음
    if (status === "confirmed") {
      return totalPrice;
    }
    return totalPrice;
  }
  if (!isNaver) {
    // 부분환불된 확정건
    if (status === "confirmed" && refundPrice) {
      return totalPrice + codeDiscount - refundPrice;
    }
    if (status === "partiallyRefunded") {
      return (
        totalPrice +
        codeDiscount -
        (partiallyRefundedAmountForHost || partiallyRefundedAmount || 0)
      );
    }
    // 100% 환불했으니까 환불후 남은 금액은 0원이지
    if (status === "refused") {
      return 0;
    }
    // confirm
    return totalPrice + codeDiscount;
  }
};

export const determineStatus = (reservation: Reservation) => {
  const {
    status,
    payOID,
    partiallyRefundedAmount,
    partiallyRefundedAmountForHost,
    totalPrice,
    basicInfo,
  } = reservation;
  const isNaver = payOID?.includes("naver");

  if (isNaver) {
    if (totalPrice === partiallyRefundedAmount) {
      return "결제취소";
    }
    if (status !== "confirmed" && totalPrice !== partiallyRefundedAmount) {
      return "부분환불";
    }
    return "결제완료";
  }

  if (!isNaver) {
    if (status === "confirmed") {
      return "결제완료";
    }
    if (status === "refused") {
      return "결제취소";
    }
    if (status === "partiallyRefunded") {
      return "부분환불";
    }
  }
  //
};

// 숙소 단위로 정산금액 계산(제출용)
// 은행, 계좌번호, 정산금액, 숙소명, 법인
export const fakeClassifyLodgmentSettlement = (data) => {
  const a = data.reduce((acc, obj) => {
    const {
      lodgment,
      settlement: { bank, accountNumber },
    } = obj;
    const existingObj = acc[lodgment._id];
    if (existingObj) {
      acc[lodgment._id].정산금액 += calculateSettlementAmount(obj, data);
    } else {
      acc[lodgment._id] = {
        은행: bank,
        계좌번호: accountNumber,
        정산금액: calculateSettlementAmount(obj),
        숙소명: lodgment.name,
        법인: "(주)바카티오",
      };
    }
    return acc;
  }, {});
  return Object.values(a);
};

// 엄밀히 정의하면, 계좌번호가 동일하면 같은 숙소로 본다.
export const classifyLodgmentSettlement = (data, settlementDate) => {
  const month = settlementDate.split("-")[1];
  const day = settlementDate.split("-")[2];

  const a = data.reduce((acc, obj) => {
    const {
      lodgment,
      settlement: { bank, accountNumber },
    } = obj;

    const trimmed = lodgment.name.replace(/(\s*)/g, "");
    const parsedName = trimmed.split(/-|:/)[0];
    const accountKey = `${parsedName}-${bank}-${accountNumber}`;

    const existingObj = acc[accountKey];

    // 정산금액이 0원이면 제외
    if (Math.floor(calculateSettlementAmount(obj, data)) === 0) return acc;

    if (existingObj) {
      acc[accountKey].정산금액 += Math.floor(
        calculateSettlementAmount(obj, data)
      );
    } else {
      acc[accountKey] = {
        은행: bank || "",
        계좌번호: accountNumber || "",
        정산금액: Math.floor(calculateSettlementAmount(obj, data)),
        숙소명: `${parsedName}${month}${day}`, // 숙소 대표이름
        법인: "(주)바카티오",
      };
    }
    return acc;
  }, {});
  return Object.values(a);
};

export const checkNaverCouponUsed = (reservation: Reservation) => {
  // null인경우
  if (reservation.coupons) {
    return null;
  }
  // 빈 배열인 경우
  if (isElementInArray(reservation.coupons)) {
    return null;
  }
  return reservation.coupons;
};
const getTwoWeeksLater = () => {
  const currentDate = new Date();
  const twoWeeksDate = new Date();
  twoWeeksDate.setDate(currentDate.getDate() + 11);
  twoWeeksDate.setHours(23, 59, 59);
  return twoWeeksDate;
};

export const classifyLodgmentSettlementByPlatform = (data) => {
  const currentDate = new Date();
  const twoWeeksLater = getTwoWeeksLater();

  const finestays = data.filter(({ payOID }) => !payOID.includes("naver"));
  const navers = data
    .filter(({ payOID }) => payOID.includes("naver"))
    .filter((item) => {
      const settlementDate: Date = new Date(item.settlementDate);
      return settlementDate <= twoWeeksLater;
    });

  const fintestaysSettlement = calculateTotalSettlementAmount(finestays);
  const naverSettlement = calculateTotalSettlementAmount(navers);
  return {
    finestays: {
      settlementAmount: fintestaysSettlement,
      count: finestays.length,
    },
    naver: {
      settlementAmount: naverSettlement,
      count: navers.length,
    },
  };
};

export const checkStaticCommission = (data) => {
  const { commissions } = data;
  // commissions.length === 1 이면서, startDate, endDate === null 이면
  if (commissions.length === 1) {
    const { startDate, endDate } = commissions[0];
    if (!startDate && !endDate) {
      return true;
    }
  }
  return false;
};
