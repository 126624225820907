import React from "react";
import styled from "styled-components";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdAdd } from "react-icons/io";

// !!! deprecated
const Figure = styled.figure<{ isValue: string }>`
  width: 100px;
  height: 100px;
  border: 2px solid black;
  border-style: ${({ isValue }) => (isValue ? "none" : "dashed")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const DeleteAction = styled.span<{ buttonDisplay }>`
  font-size: 15px;
  position: absolute;
  top: -10px;
  right: -10px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  svg {
    pointer-events: none;
  }
  display: ${({ buttonDisplay }) => buttonDisplay};
`;

const AddAction = styled.label`
  font-size: 15px;
  cursor: pointer;
`;

export interface IImageFieldProps {
  value?;
  onChange?;
  onDelete?;
  id?;
  buttonDisplay?;
}

// deprecated
export default function ImageField({
  value,
  onChange,
  onDelete,
  id,
  buttonDisplay = "block",
}: IImageFieldProps) {
  return (
    <Figure isValue={value}>
      {value && (
        <FilledField
          value={value}
          onDelete={onDelete}
          buttonDisplay={buttonDisplay}
        />
      )}
      {!value && <EmptyField id={id} />}
      <input
        type="file"
        accept="image/*"
        id={`${id}`}
        name="image"
        key={`${id}`}
        style={{ display: "none" }}
        onChange={onChange}
      />
    </Figure>
  );
}

function FilledField({ value: src, onDelete, buttonDisplay }) {
  return (
    <>
      <Image src={src} />
      <DeleteAction onClick={onDelete} buttonDisplay={buttonDisplay}>
        <AiOutlineClose />
      </DeleteAction>
    </>
  );
}

function EmptyField({ id }) {
  return (
    <>
      <AddAction htmlFor={`${id}`}>
        <IoMdAdd />
      </AddAction>
    </>
  );
}
