import * as React from "react";
import { useParams } from "react-router-dom";
import { getPackagePlace } from "api/packagePlace";
import PackagePlaceCreate from "./create";

export interface IPackagePlaceDetailProps {
  placeId: string;
}

export interface params {
  placeId: string;
}

export default function PackagePlaceDetail({}: IPackagePlaceDetailProps) {
  const { placeId } = useParams<params>();
  const [place, setPlace] = React.useState();

  React.useEffect(() => {
    const fetchPlace = async () => {
      const { data } = await getPackagePlace(placeId);
      setPlace(data);
    };
    fetchPlace();
  }, []);

  return <PackagePlaceCreate data={place} />;
}
