import {
  getCleaningSchedules,
  getCleaningSchedulesByLodgmentId,
} from "api/cleaningSchedule";
import partnerLodgments from "constants/partnerLodgments";
import * as React from "react";
import { useHistory } from "react-router-dom";

export interface ICleaningSchedulePageProps {}

export default function CleaningSchedulePage(
  props: ICleaningSchedulePageProps
) {
  const history = useHistory();

  const [cleaningSchedules, setCleaningSchedules] = React.useState([]);
  const [selectedLodgmentId, setSelectedLodgmentId] = React.useState(
    partnerLodgments[0]._id
  );

  React.useEffect(() => {
    const fetchCleaningSchedules = async () => {
      const schedules = await getCleaningSchedulesByLodgmentId(
        selectedLodgmentId
      );
      setCleaningSchedules(schedules);
    };
    fetchCleaningSchedules();
  }, [selectedLodgmentId]);

  const ScheduleItem = ({ schedule }) => {
    const {
      _id,
      date,
      isComplete,
      lodgment,
      manager,
      photos,
      startTime,
      endTime,
    } = schedule;

    return (
      <div
        style={{
          width: "fit-content",
          border: "0.1rem solid black",
          borderRadius: "1rem",
          padding: "1.3rem",
          margin: "0.5rem",
          cursor: "pointer",
        }}
        onClick={() => history.push(`/cleaningSchedule/${_id}`)}
      >
        <h4>{lodgment.name}</h4>
        <div>청소일자: {date}</div>
        <div>
          {startTime} ~ {endTime}
        </div>
        <div>매니저: {manager}</div>
        <div>청소 {isComplete ? "완료" : "미완료"}</div>
        <div>{photos.length ? "사진 등록 O" : "사진 등록 X"}</div>
      </div>
    );
  };

  return (
    <div>
      <h3>청소 스케줄 관리</h3>
      <select onChange={(e) => setSelectedLodgmentId(e.target.value)}>
        {partnerLodgments.map((lodgment) => (
          <option value={lodgment._id}>{lodgment.name}</option>
        ))}
      </select>
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {cleaningSchedules.map((schedule) => (
          <ScheduleItem schedule={schedule} />
        ))}
      </div>
    </div>
  );
}
