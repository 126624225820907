import React from "react";
import {
  createUser,
  getUserByPhone,
  addHostingLodgment,
  updateHostingLodgmentOrder,
} from "../../api/user";
import {
  createFinehostUser,
  getFinehostUserByPhone,
  updateFinehostUser,
} from "../../api/finehostUser";
import { updateWebsite } from "../../api/website";
import * as S from "./index.style";
import HostingDraggableList from "./HostingDraggableList";
import { BsChevronDoubleDown, BsChevronDoubleUp } from "react-icons/bs";

import Loading from "common/Loading";
import doOnce from "utils/doOnce";
import _ from "lodash";
import FadeSnackbar from "common/FadeSnackbar";
import { getAccommoByLodgmentId } from "api/accommo";
import { getWebsiteByAccommoId } from "api/website";

export default function HostRegister() {
  const [userPhone, setUserPhone] = React.useState("");
  const [userInfo, setUserInfo] = React.useState(null);
  const [finehostUserInfo, setFinehostUserInfo] = React.useState(null);
  const [hostingList, setHostingList] = React.useState([]);
  const [lodgmentId, setLodgmentId] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUpdated, setIsUpdated] = React.useState(false);

  const [accommo, setAccommo] = React.useState(null);
  const [website, setWebsite] = React.useState(null);

  const onSubmitUserPhone = async () => {
    if (!userPhone) return;

    try {
      // finestay 사용자 정보 가져오기
      const res = await getUserByPhone(userPhone);
      const finestayUserExists = !!res.user;

      if (finestayUserExists) {
        setUserInfo(res.user);
        setHostingList(res.user.hostingLodgments);
      } else {
        setUserInfo(null);
      }

      // finehost 사용자 정보 가져오기
      const finehostRes = await getFinehostUserByPhone(userPhone);
      const finehostUserExists = !!finehostRes.user;

      if (finehostUserExists) {
        setFinehostUserInfo(finehostRes.user);
      } else {
        setFinehostUserInfo(null);
      }

      // 둘 다 없는 경우 처리
      if (!finestayUserExists && !finehostUserExists) {
        const isUserCreationConfirmed = confirm(
          "파인스테이 및 파인호스트 유저 정보가 없습니다. 계정을 생성하시겠어요?"
        );
        if (isUserCreationConfirmed) {
          alert("계정 생성을 진행합니다.");
          await createUser(userPhone);
          await createFinehostUser(userPhone);
        }
      }
      // finestay 유저만 없는 경우 처리
      else if (!finestayUserExists && finehostUserExists) {
        const isUserCreationConfirmed = confirm(
          "파인스테이 유저 정보가 없습니다. 계정을 생성하시겠어요?"
        );
        if (isUserCreationConfirmed) {
          alert("계정 생성을 진행합니다.");
          await createUser(userPhone);
        }
      }
      // finehost 유저만 없는 경우 처리
      else if (finestayUserExists && !finehostUserExists) {
        const isUserCreationConfirmed = confirm(
          "파인호스트 유저 정보가 없습니다. 계정을 생성하시겠어요?"
        );
        if (isUserCreationConfirmed) {
          alert("계정 생성을 진행합니다.");
          await createFinehostUser(userPhone);
        }
      }
    } catch (error) {
      console.log("error", error);
      // 공통적인 에러 처리
      setUserInfo(null);
      setFinehostUserInfo(null);
      alert("유저 정보를₩ 가져오는 중 오류가 발생했습니다.");
    }
  };

  const onClickLodgmentIdBtn = doOnce(async () => {
    if (!lodgmentId) return;
    if (!userPhone) return;

    const finestayHostings = !userInfo?.hostingLodgments?.some(
      (lodgment) => lodgment._id === lodgmentId
    );

    const finehostHostings = !finehostUserInfo?.hostingLodgments?.some(
      (lodgment) => lodgment === lodgmentId
    );
    try {
      setIsLoading(true);
      // 파인스테이에 숙소 등록 시도
      {
        if (finestayHostings) {
          try {
            const data = await addHostingLodgment(userInfo._id, {
              lodgmentId,
              phone: userPhone,
            });
            alert(
              `${data?.addedLodgmentName} 숙소가 성공적으로 파인스테이에 등록되었습니다!`
            );
          } catch (error) {
            alert(
              `파인스테이에 숙소 등록 실패: ${
                error.response?.data?.msg || "오류가 발생했습니다."
              }`
            );
          }
        }
      }

      // 파인호스트에 숙소 등록 시도
      {
        if (finehostHostings) {
          try {
            const finehostData = await updateFinehostUser(
              finehostUserInfo._id,
              {
                hostingLodgments: [
                  ...finehostUserInfo.hostingLodgments,
                  lodgmentId,
                ],
              }
            );
            console.log(finehostData);
            alert("숙소가 성공적으로 파인호스트에 등록되었습니다!");
          } catch (error) {
            alert(
              `파인호스트에 숙소 등록 실패: ${
                error.response?.data?.msg || "오류가 발생했습니다."
              }`
            );
          }
        }
      }

      if (!finestayHostings && !finehostHostings) {
        alert("이미 등록된 숙소입니다.");
      }
      onSubmitUserPhone();
    } catch (error) {
      // 예상치 못한 전체 오류 처리
      alert(
        `전체 작업 중 오류가 발생했습니다: ${
          error.response?.data?.msg || "오류가 발생했습니다."
        }`
      );
    } finally {
      // 로딩 상태 해제 및 폼 초기화
      // setLodgmentId("");
      // setUserPhone("");
      setIsLoading(false);
    }
  });

  const onClickWebsiteRegisterBtn = doOnce(async () => {
    if (!lodgmentId) return;
    if (!userPhone) return;

    try {
      setIsLoading(true);

      // Accommo 가져오기
      let accommoRes;
      try {
        accommoRes = await getAccommoByLodgmentId(lodgmentId);
        setAccommo(accommoRes);
      } catch (error) {
        alert("생성된 accommo가 없습니다.");
        return; // 오류 발생 시 후속 작업을 중단
      }

      // Website 가져오기
      let websiteRes;
      try {
        console.log("accomres._id", accommoRes._id);
        websiteRes = await getWebsiteByAccommoId(accommoRes._id);
        setWebsite(websiteRes);
      } catch (error) {
        alert("생성된 website가 없습니다.");
        return; // 오류 발생 시 후속 작업을 중단
      }

      // 호스트 등록 시도
      try {
        if (websiteRes) {
          const res = await updateWebsite(websiteRes._id, {
            userIds: [...websiteRes.userIds, userInfo._id],
          });
          alert("호스트 등록이 완료되었습니다.");
        }
      } catch (error) {
        alert("호스트 등록을 실패하였습니다.");
      }
    } finally {
      setIsLoading(false); // 로딩 상태 해제
    }
  });

  // Function to update list on drop
  const handleDrop = async (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    const updatedList = [...hostingList];
    // Remove dragged item
    const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
    // Add dropped item
    updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
    // Update State
    setHostingList(updatedList);
    updateHostingLodgment(updatedList);
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const updateHostingLodgment = async (updatedList) => {
    setIsUpdated(false);
    const payload = {
      hostingLodgments: updatedList,
    };
    await updateHostingLodgmentOrder(userInfo._id, payload);
    setIsUpdated(true);
  };

  return (
    <div
      style={{
        paddingBottom: "50px",
      }}
    >
      <h1>Hostings</h1>
      <div>
        <S.Form>
          <S.InputContainer>
            <S.HelperText>호스팅중인 숙소 목록을 알 수 있어요</S.HelperText>
            <S.InputBox>
              <input
                value={userPhone}
                maxLength={11}
                onChange={(e) => setUserPhone(e.target.value)}
                placeholder="호스트번호"
              />
              <button onClick={onSubmitUserPhone}>확인</button>
            </S.InputBox>
          </S.InputContainer>
          <S.InputContainer>
            <S.HelperText>호스팅 숙소를 추가하세요</S.HelperText>
            <S.InputBox>
              <input
                value={lodgmentId}
                onChange={(e) => setLodgmentId(e.target.value)}
                placeholder="숙소 ID"
              />
              <button onClick={onClickLodgmentIdBtn}>등록</button>
            </S.InputBox>
          </S.InputContainer>
          <S.InputContainer>
            <S.HelperText>
              해당 숙소가 포함된 웹사이트에 호스트 등록을 진행하시겠습니까?
            </S.HelperText>
            <S.InputBox>
              <input
                value={lodgmentId}
                onChange={(e) => setLodgmentId(e.target.value)}
                placeholder="숙소 ID"
              />
              <button onClick={onClickWebsiteRegisterBtn}>등록</button>
            </S.InputBox>
          </S.InputContainer>
        </S.Form>
        <br />
        {userInfo && (
          <div style={{ marginBottom: "15vh" }}>
            <div>
              <span>이름</span>: {userInfo.username || userInfo.nickname}{" "}
            </div>
            <div>
              <span>가입 시점</span>:
              {userInfo.createdAt.toLocaleString().split("T")[0]}{" "}
            </div>

            <div>
              <span>운영 숙소</span>:{hostingList.length}개
            </div>
            <S.ScrollHandleButton onClick={scrollToBottom}>
              <span className="scrollText">맨 아래로 Click!</span>
              <BsChevronDoubleDown />
            </S.ScrollHandleButton>
            <S.HostingList>
              <S.HostingListTitle>호스팅 목록</S.HostingListTitle>
              <S.HostingListSubTitle>
                드래그를 통해서 호스팅 순서를 변경할 수 있어요
              </S.HostingListSubTitle>
              <HostingDraggableList
                hostingList={hostingList}
                setHostingList={setHostingList}
                onDrop={handleDrop}
                userId={userInfo._id}
              />
            </S.HostingList>
            <S.ScrollHandleButton onClick={scrollToTop}>
              <span className="scrollText">맨 위로 Click!</span>
              <BsChevronDoubleUp />
            </S.ScrollHandleButton>
          </div>
        )}
        <S.HostingListTitle>파인호스트 계정에 연결된 숙소</S.HostingListTitle>
        {finehostUserInfo ? (
          finehostUserInfo.hostingLodgments?.map((lodgmentId) => (
            <div key={lodgmentId}>
              <span>{lodgmentId}</span>
            </div>
          ))
        ) : (
          <div>연결된 숙소가 없습니다.</div>
        )}
      </div>
      {isLoading && <Loading />}
      {isUpdated && <FadeSnackbar message={"순서가 저장되었습니다😊"} />}
    </div>
  );
}
