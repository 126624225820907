import * as React from "react";
import * as S from "./msg.style";

export interface ITextMsgProps {
  content: {
    text: string;
  };
  role: "user" | "concierge";
}

export default function TextMsg({ content: { text }, role }: ITextMsgProps) {
  return (
    <S.MsgWrapper role={role}>
      <S.TextMsg>{text}</S.TextMsg>
    </S.MsgWrapper>
  );
}
