import { LODGMENT_RESERVATION_PATH } from "./../constants/path";
import { myAxios } from "./myAxios";

export const createOtherPlatformReservation = async (data) => {
  const path = LODGMENT_RESERVATION_PATH + "/otherPlatform";
  const res = await myAxios.post({ path, data });
  return res.data.data;
};

export const putLodgmentReservation = async (reservationId, data) => {
  const path = LODGMENT_RESERVATION_PATH + `/${reservationId}`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};

export const getLodgmentReservationByLodgmentId = async (lodgmentId) => {
  const path = LODGMENT_RESERVATION_PATH + "/withLodgmentId" + `/${lodgmentId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getAllReservationsForSettlement = async () => {
  const path = LODGMENT_RESERVATION_PATH + "/getAllForSettlement";
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const cancelLodgmentReservation = async (reservationId) => {
  const path = LODGMENT_RESERVATION_PATH + "/cancelById" + `/${reservationId}`;
  const res = await myAxios.delete({ path });
  return res.data.data;
};

export const getLodgmentReservationById = async (reservationId) => {
  const path = LODGMENT_RESERVATION_PATH + `/${reservationId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export interface DatePayload {
  // 변경할 체크인 날짜(스트링)
  updatedStart: string;
  // 변경할 체크아웃 날짜(스트링)
  updatedEnd: string;
}
export const changeLodgmentReservationDate = async (
  reservationId,
  datePayload: DatePayload
) => {
  const path = LODGMENT_RESERVATION_PATH + "/changeDate" + `/${reservationId}`;
  const res = await myAxios.put({ path, data: datePayload });
  return res.data.data;
};

export const changeLodgmentReservationRoom = async (reservationId, data) => {
  const path = LODGMENT_RESERVATION_PATH + "/changeRoom" + `/${reservationId}`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};
