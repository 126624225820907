import * as React from "react";
import { Socket } from "socket.io-client";
import SingleChoiceMsg from "components/Msgs/SingleChoiceMsg";
import NextMsgInput from "./common/NextMsgInput";
import IsFirstMsgInput from "./common/isFirstMsgInput";
import { onDeleteFormBtn } from "./utils/onDeleteFormBtn";
import {
  onSaveFormBtn,
  onSingleChoiceSendBtn,
  onUpdateFormBtn,
} from "./utils/onFormBtn";
import { MSG_TYPES } from "constants/msgTypes";
import * as S from "./forms.style";
import FormControlBtns from "./common/FormControlBtns";

export interface ISingleChoiceFormProps {
  socket: Socket;
  selectedMsgTemplate;
  msgTemplateList;
}

export default function SingleChoiceForm({
  socket,
  selectedMsgTemplate,
  msgTemplateList,
}: ISingleChoiceFormProps) {
  const [formTitle, setFormTitle] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [options, setOptions] = React.useState([""]);

  const [nextMsgTemplate, setNextMsgTemplate] = React.useState("");
  const [isFirstMsg, setIsFirstMsg] = React.useState(false);

  const {
    _id,
    title,
    content,
    nextTemplate,
    isFirstMsg: isFirst,
  } = selectedMsgTemplate;
  const isSavedTemplate = !!_id;

  React.useEffect(() => {
    if (!content) {
      return;
    }
    const { question, options } = content;
    setFormTitle(title);
    setQuestion(question);
    setOptions(options);
    setNextMsgTemplate(nextTemplate);
    setIsFirstMsg(isFirst);
  }, [selectedMsgTemplate]);

  const onOptionAddBtn = () => {
    if (options[options.length - 1] === "") {
      alert(
        "마지막 선택지가 빈칸일 때는 새로운 선택지를 추가할 수 없습니다. 마지막 선택지를 채워주세요."
      );
      return;
    }
    const updatedOption = [...options, ""];
    setOptions(updatedOption);
  };

  const formData = {
    type: MSG_TYPES.SINGLE_CHOICE,
    title: formTitle,
    content: {
      question,
      options,
    },
    nextTemplate: nextMsgTemplate,
    isFirstMsg,
  };

  const optionElements = options.map((option, index) => {
    return (
      <S.InputLayout key={index}>
        <S.Input
          value={option}
          onChange={(e) => {
            const updatedOptions = [...options];
            updatedOptions[index] = e.target.value;
            setOptions(updatedOptions);
          }}
        />
        <S.DeleteOptionBtn
          onClick={() => {
            if (options.length === 1) {
              alert("최소 한 개의 선택지가 있어야 합니다!");
              return;
            }
            const updatedOptions = [...options];
            updatedOptions.splice(index, 1);
            setOptions(updatedOptions);
          }}
        >
          삭제
        </S.DeleteOptionBtn>
      </S.InputLayout>
    );
  });

  return (
    <S.FormContainer>
      <S.InputLayout>
        <S.Label htmlFor="formTitle">폼 이름:</S.Label>
        <S.Input
          id="formTitle"
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value);
          }}
        />
      </S.InputLayout>
      <S.FormPreviewSection>
        <SingleChoiceMsg
          content={{
            templateTitle: formTitle,
            question,
            options,
          }}
          role="concierge"
        />
      </S.FormPreviewSection>
      <S.FormInputSection>
        <S.InputLayout>
          <S.Label htmlFor="questionInput">질문 내용:</S.Label>
          <S.Input
            id="questionInput"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.Label>선지</S.Label>
        <S.OptionAddBtn onClick={onOptionAddBtn}>선지추가</S.OptionAddBtn>
        {optionElements}
        <NextMsgInput
          currentMsgTemplateTitle={title}
          nextMsgTemplate={nextMsgTemplate}
          setNextMsgTemplate={setNextMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
        <IsFirstMsgInput
          isFirstMsg={isFirstMsg}
          setIsFirstMsg={setIsFirstMsg}
        />
        <FormControlBtns
          msgType={MSG_TYPES.SINGLE_CHOICE}
          socket={socket}
          isSavedTemplate={isSavedTemplate}
          formData={formData}
          id={_id}
        />
      </S.FormInputSection>
    </S.FormContainer>
  );
}
