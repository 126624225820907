import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Badge,
  Spinner,
  Alert,
  Modal,
  Form,
  Table,
  Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import {
  useInspections,
  useUpdateInspection,
} from "../../hooks/useInspections";
import { useLocation, useHistory } from "react-router-dom";

// 전체 컨테이너 스타일 수정
const NotionContainer = styled(Container)`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica,
    "Apple Color Emoji", Arial, sans-serif;
  color: #37352f;
  max-width: 100%;
  margin: 0 auto;
  padding: 40px 20px;
  width: 100%;
`;

// 헤더 스타일
const NotionHeader = styled.div`
  margin-bottom: 40px;
`;

const NotionTitle = styled.h1`
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 8px;
  color: #37352f;
`;

const NotionSubtitle = styled.p`
  font-size: 16px;
  color: #6b7280;
  margin-bottom: 4px;
`;

// 테이블 스타일 수정
const NotionTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 0;
  font-size: 14px;
  table-layout: fixed;
  min-width: 100%;
`;

// 테이블 컨테이너 추가 (가로 스크롤 지원)
const TableContainer = styled.div`
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 20px;
  border: 1px solid #e5e7eb;
  border-radius: 4px;

  @media (min-width: 1200px) {
    overflow-x: visible;
  }

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
`;

const NotionThead = styled.thead`
  border-bottom: 1px solid #e5e7eb;

  th {
    padding: 10px 8px;
    text-align: left;
    font-weight: 500;
    color: #6b7280;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    white-space: nowrap;
  }

  th:first-child {
    width: 5%;
    text-align: center;
  }

  th:nth-child(2) {
    width: 45%;
  }

  th:nth-child(3) {
    width: 15%;
  }

  th:nth-child(4) {
    width: 10%;
  }

  th:nth-child(5) {
    width: 8%;
    text-align: center;
  }

  th:nth-child(6) {
    width: 8%;
    text-align: center;
  }

  th:nth-child(7) {
    width: 9%;
    text-align: center;
  }
`;

const NotionTbody = styled.tbody`
  tr {
    border-bottom: 1px solid #e5e7eb;

    &:hover {
      background-color: #f9fafb;
    }
  }

  td {
    padding: 12px 8px;
    vertical-align: top;
  }

  td:first-child {
    text-align: center;
    font-size: 14px;
    color: #6b7280;
    font-weight: 500;
  }
`;

// 테이블 이미지 컨테이너 스타일 수정
const TableImageContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 10px;
  padding: 10px 0;
  max-width: 100%;
  position: relative;

  &::-webkit-scrollbar {
    height: 6px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #d1d5db;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #9ca3af;
  }
`;

// 이미지 래퍼 추가
const ImageWrapper = styled.div`
  position: relative;
  height: 120px;
  min-width: 160px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.03);

    .image-actions {
      opacity: 1;
    }
  }
`;

// 테이블 이미지 스타일 수정
const TableImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  cursor: pointer;
`;

// 대표 이미지 배지
const MainImageBadge = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: rgba(16, 185, 129, 0.9);
  color: white;
  font-size: 10px;
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 3px;
  z-index: 2;
`;

// 이미지 액션 버튼 컨테이너
const ImageActions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  transition: opacity 0.2s;
  z-index: 2;
`;

// 이미지 액션 버튼
const ImageActionButton = styled.button`
  background: none;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 2px 5px;
  border-radius: 3px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &:disabled {
    color: #9ca3af;
    cursor: not-allowed;

    &:hover {
      background-color: transparent;
    }
  }
`;

// 선택된 이미지 스타일 개선
const SelectedImage = styled.img`
  max-width: 100%;
  max-height: 85vh;
  object-fit: contain;
  margin: 0 auto;
  display: block;
  border-radius: 4px;
`;

// 이미지 컨테이너 수정
const SelectedImageContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #000;
`;

// 배지 스타일 수정
const NotionBadge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  background-color: ${(props) =>
    props.color === "success" ? "#e6f6ef" : "#fff7e6"};
  color: ${(props) => (props.color === "success" ? "#0c6b58" : "#b54708")};
`;

// 페이지네이션 스타일 수정
const PaginationWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr auto 1fr; // 3열 그리드로 변경
  align-items: center;
  background-color: rgba(255, 255, 255, 0.95);
  padding: 20px;
  box-shadow: 0 -1px 3px rgba(0, 0, 0, 0.05);
  z-index: 1000;
  width: 100%;
  margin: 0;
`;

// 페이지네이션 정보 컨테이너 수정
const PaginationInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 300px; // 최대 너비 제한
`;

// 통계 정보 스타일
const StatsInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  font-size: 14px;
  color: #6b7280;
`;

// 통계 항목 스타일
const StatItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  span.count {
    font-weight: 600;
    color: ${(props) => props.color || "#111827"};
  }

  span.label {
    color: #6b7280;
  }
`;

// 페이지네이션 컨트롤 컨테이너 수정
const PaginationControls = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  justify-self: flex-end; // 오른쪽 정렬

  .pagination {
    margin-bottom: 0;
    display: flex;
    gap: 4px;
  }

  .page-item .page-link {
    border: none;
    color: #6b7280;
    background: transparent;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 4px;

    &:hover {
      background-color: #f3f4f6;
      color: #111827;
    }
  }

  .page-item.active .page-link {
    background-color: #f3f4f6;
    color: #111827;
    font-weight: 600;
    box-shadow: none;
  }

  .page-item.disabled .page-link {
    color: #d1d5db;
    background-color: transparent;
    cursor: not-allowed;
  }
`;

// 스크롤 탑 버튼 스타일 수정
const ScrollTopButton = styled.button`
  background-color: #f3f4f6;
  color: #6b7280;
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s;

  &:hover {
    background-color: #e5e7eb;
    color: #111827;
  }
`;

// 알림 스타일 수정
const NotionAlert = styled(Alert)`
  border: 1px solid #e5e7eb;
  border-radius: 4px;
  background-color: ${(props) =>
    props.variant === "danger" ? "#fee2e2" : "#ecfdf5"};
  color: ${(props) => (props.variant === "danger" ? "#b91c1c" : "#065f46")};
  padding: 12px 16px;
  font-size: 14px;
  margin-bottom: 20px;
  box-shadow: none;
`;

// 모달 스타일 정의
const NotionModal = styled(Modal)`
  .modal-content {
    border-radius: 8px;
    border: none;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    border-bottom: 1px solid #e5e7eb;
    padding: 16px 24px;
  }

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
  }

  .btn-close {
    font-size: 12px;
  }

  .modal-body {
    padding: 24px;
  }

  .modal-footer {
    border-top: 1px solid #e5e7eb;
    padding: 16px 24px;
  }
`;

// 이미지 모달 스타일 (기존 코드 유지)
const NotionImageModal = styled(Modal)`
  .modal-content {
    border: none;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    overflow: hidden;
    background-color: #000;
  }

  .modal-header {
    border-bottom: 1px solid #333;
    padding: 16px 24px;
    background-color: #000;
  }

  .modal-title {
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }

  .modal-body {
    padding: 0;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 85vh;
  }

  .btn-close {
    background: none;
    opacity: 1;
    font-size: 24px;
    color: #fff;
    box-shadow: none;

    &:hover {
      color: #e5e7eb;
    }
  }
`;

// 체크박스 스타일 추가
const StyledCheckbox = styled.input`
  width: 18px;
  height: 18px;
  cursor: pointer;
  margin: 0 auto;
  display: block;
`;

// Snackbar 스타일 컴포넌트 수정
const Snackbar = styled.div`
  position: fixed;
  bottom: 80px;
  right: 24px;
  padding: 14px 20px;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 400px;
  z-index: 1050;
  background-color: ${(props) =>
    props.variant === "danger"
      ? "#fee2e2"
      : props.variant === "info"
      ? "#e0f2fe"
      : props.variant === "warning"
      ? "#fef3c7"
      : "#ecfdf5"};
  color: ${(props) =>
    props.variant === "danger"
      ? "#b91c1c"
      : props.variant === "info"
      ? "#0369a1"
      : props.variant === "warning"
      ? "#92400e"
      : "#065f46"};
  border-left: 4px solid
    ${(props) =>
      props.variant === "danger"
        ? "#ef4444"
        : props.variant === "info"
        ? "#0ea5e9"
        : props.variant === "warning"
        ? "#f59e0b"
        : "#10b981"};
  transition: all 0.3s ease-in-out;
  transform: ${(props) => (props.show ? "translateY(0)" : "translateY(100px)")};
  opacity: ${(props) => (props.show ? "1" : "0")};
  pointer-events: ${(props) => (props.show ? "all" : "none")};
`;

// SnackbarMessage 스타일 개선
const SnackbarMessage = styled.div`
  flex: 1;
  margin-right: 12px;
  font-size: 14px;
  font-weight: 500;
`;

// SnackbarIcon 컴포넌트 추가
const SnackbarIcon = styled.div`
  margin-right: 12px;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SnackbarClose = styled.button`
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font-size: 18px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Inspection = () => {
  const location = useLocation();
  const history = useHistory();

  // URL에서 페이지 정보 가져오기
  const getInitialPage = () => {
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get("page");
    return page ? parseInt(page, 10) : 1;
  };

  const [currentPage, setCurrentPage] = useState(getInitialPage);
  const [itemsPerPage] = useState(50);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [reviewComment, setReviewComment] = useState("");
  const [updateStatus, setUpdateStatus] = useState({
    success: false,
    message: "",
    error: false,
    show: false,
  });
  const [activeUsers, setActiveUsers] = useState(0);
  const [editingItemId, setEditingItemId] = useState(null);
  const [editingImages, setEditingImages] = useState([]);
  const [selectedImageInfo, setSelectedImageInfo] = useState({
    url: "",
    index: 0,
    itemId: null,
  });

  // React Query 훅 사용
  const {
    data: inspectionData,
    isLoading,
    isError,
    error: queryError,
  } = useInspections(currentPage, itemsPerPage);

  console.log(inspectionData);

  const updateMutation = useUpdateInspection();

  // 데이터 추출
  const items = inspectionData?.data || [];
  const pagination = inspectionData?.pagination || {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: itemsPerPage,
  };

  // Simulate active users (in a real app, this would come from a WebSocket)
  React.useEffect(() => {
    setActiveUsers(Math.floor(Math.random() * 5) + 1);
    const interval = setInterval(() => {
      setActiveUsers(Math.floor(Math.random() * 5) + 1);
    }, 30000);
    return () => clearInterval(interval);
  }, []);

  // URL 변경 감지하여 페이지 상태 업데이트
  useEffect(() => {
    setCurrentPage(getInitialPage());
  }, [location.search]);

  const handleImageClick = (url, index, itemId) => {
    setSelectedImage(url);
    setSelectedImageInfo({
      url,
      index,
      itemId,
    });
    setShowImageModal(true);
  };

  const handleReviewClick = (item) => {
    setCurrentItem(item);
    setReviewComment("");
    setShowReviewModal(true);
  };

  // 체크박스 변경 핸들러 추가
  const handlePhotoChangeNeededToggle = (item) => {
    const newValue = !item.isPhotoChangeNeeded;

    // 낙관적 UI 업데이트를 위한 상태 설정 (show: true 추가)
    setUpdateStatus({
      success: true,
      message: `사진 교체 여부가 '${
        newValue ? "필요" : "불필요"
      }'로 변경되었습니다.`,
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기 (show: false로 설정)
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: item._id,
        data: {
          isPhotoChangeNeeded: newValue,
          version: item?.version || 0,
        },
      },
      {
        onError: (err) => {
          // 에러 처리
          console.error("Error updating photo change status:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg || "업데이트 중 오류가 발생했습니다.";

          // 버전 충돌 에러 처리 (show: true 추가)
          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  const handleApprove = async (item) => {
    try {
      // 낙관적 UI 업데이트를 위한 상태 설정
      setUpdateStatus({
        success: true,
        message: "승인되었습니다.",
        error: false,
        show: true,
      });

      // 3초 후 메시지 제거
      setTimeout(
        () => setUpdateStatus((prev) => ({ ...prev, show: false })),
        3000
      );

      // 뮤테이션 실행
      updateMutation.mutate(
        {
          id: item._id,
          data: {
            status: "approved",
            version: item?.version || 0,
          },
        },
        {
          onError: (err) => {
            console.error("Error approving item:", err);

            // 서버에서 반환한 에러 메시지 사용
            const errorMessage =
              err.response?.data?.msg || "승인 중 오류가 발생했습니다.";

            if (err.response && err.response.status === 409) {
              setUpdateStatus({
                success: false,
                message: errorMessage,
                error: true,
                show: true,
              });
            } else {
              setUpdateStatus({
                success: false,
                message: errorMessage,
                error: true,
                show: true,
              });
            }
          },
        }
      );
    } catch (err) {
      console.error("Error in handleApprove:", err);
    }
  };

  const handleSubmitReview = async () => {
    if (!reviewComment.trim()) {
      alert("검토 의견을 입력해주세요.");
      return;
    }

    try {
      // 낙관적 UI 업데이트를 위한 상태 설정
      setUpdateStatus({
        success: true,
        message: "검토 요청이 등록되었습니다.",
        error: false,
        show: true,
      });

      // 3초 후 메시지 제거
      setTimeout(
        () => setUpdateStatus((prev) => ({ ...prev, show: false })),
        3000
      );

      // 뮤테이션 실행
      updateMutation.mutate(
        {
          id: currentItem.id,
          data: {
            status: "needs_review",
            reviewComment,
            version: currentItem.version || 0,
          },
        },
        {
          onSuccess: () => {
            setShowReviewModal(false);
          },
          onError: (err) => {
            console.error("Error submitting review:", err);

            // 서버에서 반환한 에러 메시지 사용
            const errorMessage =
              err.response?.data?.msg ||
              "검토 요청 등록 중 오류가 발생했습니다.";

            if (err.response && err.response.status === 409) {
              setUpdateStatus({
                success: false,
                message: errorMessage,
                error: true,
                show: true,
              });
            } else {
              setUpdateStatus({
                success: false,
                message: errorMessage,
                error: true,
                show: true,
              });
            }
            setShowReviewModal(false);
          },
        }
      );
    } catch (err) {
      console.error("Error in handleSubmitReview:", err);
      setShowReviewModal(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    history.replace(`?page=${pageNumber}`);
    setCurrentPage(pageNumber);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const { totalPages } = pagination;

    // 처음 페이지로 이동 버튼
    paginationItems.push(
      <Pagination.First
        key="first"
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
      />
    );

    // 이전 페이지로 이동 버튼
    paginationItems.push(
      <Pagination.Prev
        key="prev"
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
    );

    // 페이지 번호 표시 로직
    // 현재 페이지 주변 5개 페이지만 표시
    const startPage = Math.max(1, currentPage - 2);
    const endPage = Math.min(totalPages, currentPage + 2);

    // 첫 페이지 표시
    if (startPage > 1) {
      paginationItems.push(
        <Pagination.Item key={1} onClick={() => handlePageChange(1)}>
          1
        </Pagination.Item>
      );
      if (startPage > 2) {
        // 첫 페이지와 시작 페이지 사이의 중간 페이지로 이동하는 Ellipsis
        const middlePage = Math.floor((1 + startPage) / 2);
        paginationItems.push(
          <Pagination.Ellipsis
            key="ellipsis1"
            onClick={() => handlePageChange(middlePage)}
          />
        );
      }
    }

    // 페이지 번호 표시
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    // 마지막 페이지 표시
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        // 끝 페이지와 마지막 페이지 사이의 중간 페이지로 이동하는 Ellipsis
        const middlePage = Math.floor((endPage + totalPages) / 2);
        paginationItems.push(
          <Pagination.Ellipsis
            key="ellipsis2"
            onClick={() => handlePageChange(middlePage)}
          />
        );
      }
      paginationItems.push(
        <Pagination.Item
          key={totalPages}
          onClick={() => handlePageChange(totalPages)}
        >
          {totalPages}
        </Pagination.Item>
      );
    }

    // 다음 페이지로 이동 버튼
    paginationItems.push(
      <Pagination.Next
        key="next"
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    );

    // 마지막 페이지로 이동 버튼
    paginationItems.push(
      <Pagination.Last
        key="last"
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
      />
    );

    return paginationItems;
  };

  // Snackbar 닫기 핸들러 추가
  const handleCloseSnackbar = () => {
    setUpdateStatus((prev) => ({ ...prev, show: false }));
  };

  // 삭제 필요 여부 토글 핸들러 추가
  const handleDeletionNeededToggle = (item) => {
    const newValue = !item.isDeletionNeeded;

    // 낙관적 UI 업데이트를 위한 상태 설정
    setUpdateStatus({
      success: true,
      message: `삭제 필요 여부가 '${
        newValue ? "필요" : "불필요"
      }'로 변경되었습니다.`,
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: item._id,
        data: {
          isDeletionNeeded: newValue,
          version: item?.version || 0,
        },
      },
      {
        onError: (err) => {
          console.error("Error updating deletion needed status:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg || "업데이트 중 오류가 발생했습니다.";

          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  // 이미지 순서 변경 핸들러 수정
  const handleMoveImage = (itemId, currentIndex, direction) => {
    // 현재 편집 중인 아이템이 아니면 초기화
    if (editingItemId !== itemId) {
      const item = items.find((i) => i._id === itemId);
      if (item && item.thumb_urls) {
        setEditingItemId(itemId);
        setEditingImages([...item.thumb_urls]);

        // 여기서 바로 이미지 순서를 변경하지 않고 상태 업데이트 후 실행되도록 함
        setTimeout(() => {
          moveImageAfterStateUpdate(itemId, currentIndex, direction);
        }, 0);
        return;
      }
    }

    // 이미 같은 아이템을 편집 중이면 바로 순서 변경
    moveImageAfterStateUpdate(itemId, currentIndex, direction);
  };

  // 상태 업데이트 후 이미지 순서 변경을 처리하는 함수
  const moveImageAfterStateUpdate = (itemId, currentIndex, direction) => {
    const item = items.find((i) => i._id === itemId);
    if (!item || !item.thumb_urls) return;

    // 현재 아이템의 이미지 배열 가져오기
    const currentImages = [...item.thumb_urls];
    const newIndex = direction === "left" ? currentIndex - 1 : currentIndex + 1;

    // 범위 체크
    if (newIndex < 0 || newIndex >= currentImages.length) return;

    // 순서 변경
    [currentImages[currentIndex], currentImages[newIndex]] = [
      currentImages[newIndex],
      currentImages[currentIndex],
    ];

    // 변경사항 저장
    saveImageChanges(itemId, currentImages);
  };

  // 이미지 삭제 핸들러 수정
  const handleDeleteImage = (itemId, index) => {
    // 삭제 확인 알림
    if (
      !window.confirm(
        "이미지를 삭제하시겠습니까? 이 작업은 되돌릴 수 없습니다."
      )
    ) {
      return; // 사용자가 취소를 선택한 경우
    }

    // 현재 아이템의 이미지 배열 직접 가져오기
    const item = items.find((i) => i._id === itemId);
    if (!item || !item.thumb_urls) return;

    // 이미지 삭제
    const newImages = [...item.thumb_urls];
    newImages.splice(index, 1);

    // 변경사항 저장
    saveImageChanges(itemId, newImages);
  };

  // 이미지 변경사항 저장 수정
  const saveImageChanges = (itemId, newImages) => {
    const item = items.find((i) => i._id === itemId);
    if (!item) return;

    // 현재 편집 중인 아이템 ID와 이미지 업데이트
    setEditingItemId(itemId);
    setEditingImages(newImages);

    // 낙관적 UI 업데이트를 위한 상태 설정
    setUpdateStatus({
      success: true,
      message: "이미지가 업데이트되었습니다.",
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: itemId,
        data: {
          thumb_urls: newImages,
          version: item?.version || 0,
        },
      },
      {
        onSuccess: () => {
          // 성공 시 편집 상태 초기화
          setEditingItemId(null);
          setEditingImages([]);
        },
        onError: (err) => {
          console.error("Error updating images:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg ||
            "이미지 업데이트 중 오류가 발생했습니다.";

          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  // Handle placeDelete toggle
  const handlePlaceDeleteToggle = (item) => {
    const newValue = !item.placeDelete;

    // 낙관적 UI 업데이트를 위한 상태 설정
    setUpdateStatus({
      success: true,
      message: `장소 삭제 여부가 '${
        newValue ? "필요" : "불필요"
      }'로 변경되었습니다.`,
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: item._id,
        data: {
          placeDelete: newValue,
          version: item?.version || 0,
        },
      },
      {
        onError: (err) => {
          console.error("Error updating place deletion status:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg || "업데이트 중 오류가 발생했습니다.";

          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  // Handle placeHold toggle
  const handlePlaceHoldToggle = (item) => {
    const newValue = !item.placeHold;

    // 낙관적 UI 업데이트를 위한 상태 설정
    setUpdateStatus({
      success: true,
      message: `장소 보류 여부가 '${
        newValue ? "필요" : "불필요"
      }'로 변경되었습니다.`,
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: item._id,
        data: {
          placeHold: newValue,
          version: item?.version || 0,
        },
      },
      {
        onError: (err) => {
          console.error("Error updating place hold status:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg || "업데이트 중 오류가 발생했습니다.";

          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  // Handle imageLack toggle
  const handleImageLackToggle = (item) => {
    const newValue = !item.imageLack;

    // 낙관적 UI 업데이트를 위한 상태 설정
    setUpdateStatus({
      success: true,
      message: `사진 부족 여부가 '${
        newValue ? "부족" : "충분"
      }'으로 변경되었습니다.`,
      error: false,
      show: true,
    });

    // 3초 후 메시지 숨기기
    setTimeout(
      () => setUpdateStatus((prev) => ({ ...prev, show: false })),
      3000
    );

    // 뮤테이션 실행
    updateMutation.mutate(
      {
        id: item._id,
        data: {
          imageLack: newValue,
          version: item?.version || 0,
        },
      },
      {
        onError: (err) => {
          console.error("Error updating image lack status:", err);

          // 서버에서 반환한 에러 메시지 사용
          const errorMessage =
            err.response?.data?.msg || "업데이트 중 오류가 발생했습니다.";

          if (err.response && err.response.status === 409) {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          } else {
            setUpdateStatus({
              success: false,
              message: errorMessage,
              error: true,
              show: true,
            });
          }
        },
      }
    );
  };

  if (isLoading && items.length === 0) {
    return (
      <NotionContainer>
        <div className="text-center py-5">
          <Spinner
            animation="border"
            role="status"
            style={{ color: "#6b7280" }}
          >
            <span className="visually-hidden">로딩 중...</span>
          </Spinner>
          <p className="mt-3" style={{ color: "#6b7280" }}>
            데이터를 불러오는 중입니다...
          </p>
        </div>
      </NotionContainer>
    );
  }

  if (isError && items.length === 0) {
    return (
      <NotionContainer>
        <NotionAlert variant="danger">
          {queryError?.message || "데이터를 불러오는 중 오류가 발생했습니다."}
          <Button
            variant="outline-danger"
            className="ms-3"
            size="sm"
            onClick={() => window.location.reload()}
          >
            다시 시도
          </Button>
        </NotionAlert>
      </NotionContainer>
    );
  }

  return (
    <NotionContainer>
      <NotionHeader>
        <NotionTitle>검수 관리</NotionTitle>
        {/* <NotionSubtitle>
          현재 {activeUsers}명의 사용자가 검수 작업 중입니다.
        </NotionSubtitle> */}
        <NotionSubtitle>
          전체 {pagination.totalItems}개 항목 중{" "}
          {(pagination.currentPage - 1) * pagination.pageSize + 1}-
          {Math.min(
            pagination.currentPage * pagination.pageSize,
            pagination.totalItems
          )}
          번 항목을 표시합니다.
        </NotionSubtitle>
      </NotionHeader>

      {/* NotionAlert 대신 Snackbar 사용 */}
      <Snackbar
        variant={updateStatus.error ? "danger" : "success"}
        show={updateStatus.show && updateStatus.message}
      >
        <SnackbarIcon>{updateStatus.error ? "⚠️" : "✅"}</SnackbarIcon>
        <SnackbarMessage>{updateStatus.message}</SnackbarMessage>
        <SnackbarClose onClick={handleCloseSnackbar}>×</SnackbarClose>
      </Snackbar>

      {items && items.length > 0 ? (
        <>
          <TableContainer>
            <NotionTable>
              <NotionThead>
                <tr>
                  <th>번호</th>
                  <th>사진</th>
                  <th>업체명</th>
                  <th>카테고리</th>
                  <th>장소 삭제</th>
                  <th>장소 보류</th>
                  <th>사진 부족</th>
                </tr>
              </NotionThead>
              <NotionTbody>
                {items.map((item, index) => (
                  <tr key={item?.id || `${currentPage}-${index}`}>
                    <td>
                      {(pagination.currentPage - 1) * pagination.pageSize +
                        index +
                        1}
                    </td>
                    <td>
                      <TableImageContainer>
                        {item?.thumb_urls && item?.thumb_urls?.length > 0 ? (
                          item?.thumb_urls?.map((url, imgIndex) => (
                            <ImageWrapper key={imgIndex}>
                              {imgIndex === 0 && (
                                <MainImageBadge>대표</MainImageBadge>
                              )}
                              <TableImage
                                src={url}
                                alt={`Thumbnail ${imgIndex + 1}`}
                                onClick={() =>
                                  handleImageClick(url, imgIndex, item._id)
                                }
                              />
                              <ImageActions className="image-actions">
                                <ImageActionButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMoveImage(item._id, imgIndex, "left");
                                  }}
                                  disabled={imgIndex === 0}
                                  title="왼쪽으로 이동"
                                >
                                  ←
                                </ImageActionButton>
                                <ImageActionButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleDeleteImage(item._id, imgIndex);
                                  }}
                                  title="삭제"
                                >
                                  🗑️
                                </ImageActionButton>
                                <ImageActionButton
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleMoveImage(
                                      item._id,
                                      imgIndex,
                                      "right"
                                    );
                                  }}
                                  disabled={
                                    imgIndex === item.thumb_urls.length - 1
                                  }
                                  title="오른쪽으로 이동"
                                >
                                  →
                                </ImageActionButton>
                              </ImageActions>
                            </ImageWrapper>
                          ))
                        ) : (
                          <p style={{ color: "#9ca3af", fontStyle: "italic" }}>
                            이미지가 없습니다.
                          </p>
                        )}
                      </TableImageContainer>
                    </td>
                    <td style={{ fontWeight: "500" }}>
                      {item?.title || "제목 없음"}
                    </td>
                    <td style={{ color: "#4b5563" }}>
                      {item?.category || "미분류"}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <StyledCheckbox
                        type="checkbox"
                        checked={item?.placeDelete || false}
                        onChange={() => handlePlaceDeleteToggle(item)}
                        title={item?.placeDelete ? "삭제 필요" : "삭제 불필요"}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <StyledCheckbox
                        type="checkbox"
                        checked={item?.placeHold || false}
                        onChange={() => handlePlaceHoldToggle(item)}
                        title={item?.placeHold ? "보류 필요" : "보류 불필요"}
                      />
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <StyledCheckbox
                        type="checkbox"
                        checked={item?.imageLack || false}
                        onChange={() => handleImageLackToggle(item)}
                        title={item?.imageLack ? "사진 부족" : "사진 충분"}
                      />
                    </td>
                  </tr>
                ))}
              </NotionTbody>
            </NotionTable>
          </TableContainer>

          <PaginationWrapper>
            <PaginationInfo>
              <NotionSubtitle>
                전체 {pagination.totalItems}개 항목 중{" "}
                {(pagination.currentPage - 1) * pagination.pageSize + 1}-
                {Math.min(
                  pagination.currentPage * pagination.pageSize,
                  pagination.totalItems
                )}
                번 항목
              </NotionSubtitle>
              <StatsInfo>
                <StatItem color="#ef4444">
                  <span className="count">
                    {pagination.totalPlaceDelete || 0}
                  </span>
                  <span className="label">장소 삭제</span>
                </StatItem>
                <StatItem color="#f59e0b">
                  <span className="count">
                    {pagination.totalPlaceHold || 0}
                  </span>
                  <span className="label">장소 보류</span>
                </StatItem>
                <StatItem color="#3b82f6">
                  <span className="count">
                    {pagination.totalImageLack || 0}
                  </span>
                  <span className="label">사진 부족</span>
                </StatItem>
              </StatsInfo>
            </PaginationInfo>

            <Pagination>{renderPaginationItems()}</Pagination>

            <PaginationControls>
              <ScrollTopButton onClick={scrollToTop} title="맨 위로 이동">
                ↑ 맨 위로
              </ScrollTopButton>
            </PaginationControls>
          </PaginationWrapper>
        </>
      ) : (
        <Snackbar
          variant="info"
          show={true}
          style={{ position: "static", margin: "20px 0" }}
        >
          <SnackbarIcon>ℹ️</SnackbarIcon>
          <SnackbarMessage>검수할 항목이 없습니다.</SnackbarMessage>
        </Snackbar>
      )}

      {/* 이미지 모달 */}
      <NotionImageModal
        show={showImageModal}
        onHide={() => setShowImageModal(false)}
        size="xl"
        centered
        fullscreen="lg-down"
        dialogClassName="image-modal-dialog"
      >
        <Modal.Header closeButton>
          <Modal.Title>이미지 상세보기</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <SelectedImageContainer>
            <SelectedImage src={selectedImage} alt="Selected" />
          </SelectedImageContainer>
        </Modal.Body>
      </NotionImageModal>

      {/* 리뷰 모달 */}
      <NotionModal
        show={showReviewModal}
        onHide={() => setShowReviewModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>검토 의견 등록</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label style={{ fontWeight: "500", color: "#4b5563" }}>
                검토 의견
              </Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={reviewComment}
                onChange={(e) => setReviewComment(e.target.value)}
                placeholder="수정이 필요한 사항을 자세히 입력해주세요."
                style={{
                  border: "1px solid #e5e7eb",
                  borderRadius: "4px",
                  padding: "10px",
                  fontSize: "14px",
                }}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setShowReviewModal(false)}
            style={{
              backgroundColor: "#f3f4f6",
              color: "#4b5563",
              border: "none",
              fontSize: "14px",
            }}
          >
            취소
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmitReview}
            style={{
              backgroundColor: "#0f766e",
              border: "none",
              fontSize: "14px",
            }}
          >
            제출
          </Button>
        </Modal.Footer>
      </NotionModal>
    </NotionContainer>
  );
};

export default Inspection;
