import React from "react";

import CommissionTableRow from "./CommissionTableRow";
import * as S from "./commission.styled";

export default function CommissionTable({ data }) {
  const order = (arr) => {
    arr.sort((a, b) => {
      if (a.lodgment?.name < b.lodgment?.name) {
        return -1;
      }
      if (a.lodgment?.name > b.lodgment?.name) {
        return 1;
      }
      return 0;
    });
    return arr;
  };

  return (
    <S.StyledTable>
      <table aria-label="simple table">
        <thead>
          <tr>
            <th className="lodgment-id">숙소ID</th>
            <th align="left">숙소명</th>
            <th align="left">일반 수수료(전)</th>
            <th align="left">일반 수수료(수정)</th>
            <th align="left">부가세</th>
            <th align="left">조기 정산 여부</th>
            <th align="left">네이버 직접 수수료</th>
            <th align="left">네이버 알고리즘 수수료</th>
            <th align="left">은행</th>
            <th align="left">계좌번호</th>
            <th align="left">예금주명</th>
            <th align="left">수정</th>
          </tr>
        </thead>
        <tbody>
          {order(data).map((row) => (
            <CommissionTableRow row={row} />
          ))}
        </tbody>
      </table>
    </S.StyledTable>
  );
}
