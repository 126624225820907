import { useState } from "react";
import IndividualPush from "./Individual";
import AuthGroupPush from "./AuthGroup";

const TAB_LIST = [
  { id: "개별 유저", label: "개별 유저" },
  { id: "인증/미인증 유저", label: "인증/미인증 유저" },
];

const Push = () => {
  const [activeTab, setActiveTab] = useState("개별 유저");

  const renderTabContent = () => {
    switch (activeTab) {
      case "개별 유저":
        return <IndividualPush />;
      case "인증/미인증 유저":
        return <AuthGroupPush />;
      default:
        return <IndividualPush />;
    }
  };

  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          width: "200px",
          borderRadius: "6px",
          border: "1px solid #ddd",
          height: "fit-content",
        }}
      >
        <h4
          style={{
            margin: 0,
            padding: "12px 16px",
            borderBottom: "1px solid #ddd",
            fontSize: "16px",
            color: "#3182F7",
            fontWeight: "bold",
          }}
        >
          PUSH
        </h4>

        <div>
          {TAB_LIST.map((tab) => (
            <div
              key={tab.id}
              onClick={() => setActiveTab(tab.id)}
              style={{
                padding: "10px 16px",
                cursor: "pointer",
                backgroundColor:
                  activeTab === tab.id ? "#3182F7" : "transparent",
                color: activeTab === tab.id ? "white" : "#333",
              }}
            >
              {tab.label}
            </div>
          ))}
        </div>
      </div>

      <div style={{ padding: "15px", flex: 1 }}>{renderTabContent()}</div>
    </div>
  );
};

export default Push;
