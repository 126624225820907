import React, { useState, useEffect } from "react";
import axios from "config/axiosConfig";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Flex } from "components/style";
import useTitle from "hooks/useTitle";

export default function PostList() {
  useTitle("포스트 목록");
  const history = useHistory();

  const [posts, setPosts] = useState([]);
  const [allPosts, setAllPosts] = useState([]);

  const [typeFilter, setTypeFilter] = useState(null);

  useEffect(async () => {
    const {
      data: { posts },
    } = await axios.get("posts");

    setPosts(posts);
    setAllPosts(posts);
  }, []);

  const typeOptions = [
    { value: null, label: "전체" },
    { value: "맛집", label: "맛집" },
    { value: "카페", label: "카페" },
    { value: "여행지", label: "여행지" },
    { value: "액티비티", label: "액티비티" },
  ];

  const onlyPostsWithTags = () => {
    let arr = [...allPosts].filter((el) => el.tags?.length);

    setPosts(arr);
  };

  const onlyPostsWithoutTags = () => {
    let arr = [...allPosts].filter((el) => !el.tags?.length);

    setPosts(arr);
  };

  const renderItem = (p) => {
    return (
      <Flex
        key={p.name}
        style={{
          width: 500,
          padding: 20,
          border: "1px solid #e9e9e9",
          borderRadius: 10,
          justifyContent: "flex-start",
          alignItems: "center",
          display: "flex",
          marginBottom: 20,
          cursor: "pointer",
        }}
        onClick={() => {
          const win = window.open(`/post/${p.id}`, "_blank");
        }}
      >
        <img
          style={{ width: 100, height: 100, marginRight: 20 }}
          src={p.images.length === 0 ? null : p.images[0].url}
          alt=""
        />
        <div>
          {p.name} {" ("}
          {p.id}
          {")"}
        </div>
      </Flex>
    );
  };

  const sortById = () => {
    let arr = [...posts];
    arr = arr.sort((a, b) => {
      return a.id.substring(5) - b.id.substring(5);
    });
    setPosts(arr);
  };

  useEffect(() => {
    console.log("포스트 바꼈어요", posts);
  }, [posts]);

  if (posts) {
    return (
      <div>
        <div>타입 필터</div>
        <div style={{ width: 200, marginBottom: 20 }}>
          <Select
            options={typeOptions}
            onChange={(val) => {
              setTypeFilter(val.value);
            }}
          />
        </div>
        <div
          style={{
            borderRadius: 10,
            width: 100,
            padding: 5,
            backgroundColor: "LightSteelBlue",
            textAlign: "center",
            marginBottom: 10,
            cursor: "pointer",
            fontWeight: "700",
            color: "white",
          }}
          onClick={() => {
            sortById();
          }}
        >
          id로 정렬
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <div
            onClick={() => {
              setPosts([...allPosts]);
            }}
            style={{
              cursor: "pointer",
              borderRadius: 10,
              backgroundColor: "blue",
              textAlign: "center",
              padding: 10,
              width: 150,
              marginBottom: 20,
              color: "White",
              marginRight: 10,
            }}
          >
            전체보기
          </div>
          <div
            onClick={() => {
              onlyPostsWithTags();
            }}
            style={{
              cursor: "pointer",
              borderRadius: 10,
              backgroundColor: "purple",
              textAlign: "center",
              padding: 10,
              width: 150,
              marginBottom: 20,
              color: "White",
              marginRight: 10,
            }}
          >
            태그 있는 거 만 보기
          </div>

          <div
            onClick={() => {
              onlyPostsWithoutTags();
            }}
            style={{
              cursor: "pointer",
              borderRadius: 10,
              backgroundColor: "green",
              textAlign: "center",
              padding: 10,
              width: 150,
              marginBottom: 20,
              color: "White",
            }}
          >
            태그 없는거 만 보기
          </div>
        </div>

        {posts.map((p, idx) => {
          if (!typeFilter) {
            return renderItem(p);
          } else {
            if (p.type == typeFilter) {
              return renderItem(p);
            } else {
              return null;
            }
          }
        })}
      </div>
    );
  } else {
    return null;
  }
}
