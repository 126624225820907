import { MSG_TEMPLATE_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getMsgTemplates = async (): Promise<any> => {
  const res = await myAxios.get({ path: MSG_TEMPLATE_PATH });
  return res.data;
};

export const postMsgTemplate = async (data): Promise<any> => {
  const res = await myAxios.post({ data, path: MSG_TEMPLATE_PATH });
  return res.data;
};

export const deleteMsgTemplate = async (templateId): Promise<any> => {
  const path = `${MSG_TEMPLATE_PATH}/${templateId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const putMsgTemplate = async (templateId, data): Promise<any> => {
  const path = `${MSG_TEMPLATE_PATH}/${templateId}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
