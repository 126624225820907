import styled from "styled-components";
import { IMsgProps } from "screens/ChatRoom/Msg";

export const FormContainer = styled.div`
  width: 100%;
  height: 26rem;
`;

export const FormPreviewSection = styled.div`
  width: 100%;
  border: 0.1rem black solid;
  height: 13rem;
  overflow: scroll;
  background-color: #ededed;
`;

export const FormInputSection = styled.div`
  width: 100%;
  height: 18rem;
  border: 0.1rem black solid;
  overflow: scroll;
`;

export const InputLayout = styled.div`
  display: flex;
  padding: 0.5rem;
  font-weight: bold;
`;

export const Input = styled.input``;

export const Textarea = styled.textarea`
  white-space: pre-line;
`;
export const Label = styled.label`
  width: 5rem;
  font-size: 0.8rem;
  margin-right: 0.3rem;
`;

export const OptionAddBtn = styled.div`
  font-size: 0.8rem;
  padding: 0 0.5rem;
  width: fit-content;
  border: 0.1rem black solid;
  border-radius: 1rem;

  :hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
`;

export const DeleteOptionBtn = styled.div`
  margin-left: 1rem;
  color: red;
  :hover {
    cursor: pointer;
  }
`;

export const FormSelect = styled.select``;

export const FormFunctionBtn = styled.div`
  width: fit-content;
  font-size: 0.8rem;
  margin: 0.2rem;
  padding: 0rem 0.6rem;
  border: 0.1rem black solid;
  border-radius: 1rem;
  :hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
`;

export const SendBtn = styled.div`
  background-color: #323232;
  width: fit-content;
  padding: 0.5rem;
  margin-top: 1.5rem;
  font-weight: bolder;
  color: white;
  :hover {
    background-color: gray;
    cursor: pointer;
  }
`;
