import { Flex, FlexBetween } from "components/style";
import styled from "styled-components";

export const ChatRoomListWrap = styled.div``;

export const TopMenu = styled(FlexBetween)`
  .result-box {
    position: absolute;
  }
`;

export const ChatRoomListContainer = styled(Flex)`
  width: fit-content;
  overflow-x: scroll;
`;

const palette = [
  "#FFC5C5",
  "#FFEBC5",
  "#FFF7AD",
  "#E5FFC5",
  "#C5F4FF",
  "#C5D1FF",
  "#D9D9D9",
  "#D9D9D9",
  "#D9D9D9",
];

interface IChatRoomListContaingerProps {
  idx: number;
}

export const ChatRoomList = styled.div`
  width: 340px;
  background-color: ${({ idx }: IChatRoomListContaingerProps) => palette[idx]};
`;

export const ChatRoomItemContainer = styled.div`
  cursor: pointer;
  border: 2px black solid;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  width: 300px;
  background-color: white;
`;

export const Category = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  text-align: center;
`;

export const NewMsgCount = styled.div`
  background-color: red;
  color: white;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1.5rem;
  text-align: center;
`;
