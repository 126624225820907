import { getLodgment } from "api/lodgment";
import {
  changeLodgmentReservationRoom,
  getLodgmentReservationById,
} from "api/lodgmentReservation";
import * as React from "react";

export interface IChangeRoomModalProps {
  lodgmentReservationId: string;
  setIsChangeRoomModalOn;
  setReservationIdForModal;
}

export default function ChangeRoomModal({
  lodgmentReservationId,
  setIsChangeRoomModalOn,
  setReservationIdForModal,
}: IChangeRoomModalProps) {
  const [lodgmentReservation, setLodgmentReservation] =
    React.useState<Record<any, any>>();
  const [updatedLodgmentId, setUpdatedLodgmentId] = React.useState("");

  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const fetchLodgmentReservation = async () => {
      const lodgmentReservationData = await getLodgmentReservationById(
        lodgmentReservationId
      );
      setLodgmentReservation(lodgmentReservationData);
    };
    fetchLodgmentReservation();
  }, [lodgmentReservationId]);

  const closeModal = () => {
    setReservationIdForModal("");
    setIsChangeRoomModalOn(false);
  };

  const onInnerContainer = (e) => e.stopPropagation();

  const onSubmit = async () => {
    try {
      if (
        !confirm(
          "금액 상관 없이 예약 가능 날짜만 서로 맞다면 변경됩니다. 페이플 결제 로그는 수정되지 않고 처음 결제했던 숙소의 정보로 남아 있게 됩니다. 변경 시 호스트 및 유저 및 슬랙 알림이 모두 발생합니다."
        )
      ) {
        return;
      }
      // 유효하지 않은 Id일 경우 500 반환해서 catch로 이어짐
      await getLodgment(updatedLodgmentId);

      const payload = {
        updatedLodgmentId,
        isSecretPrice: checked,
      };

      const { isSuccess } = await changeLodgmentReservationRoom(
        lodgmentReservationId,
        payload
      );

      if (!isSuccess) {
        return alert(
          "예약 불가능한 날짜로 변경을 시도하여 변경에 실패했습니다🥲"
        );
      }

      window.location.reload();
    } catch {
      alert("유효하지 않은 숙소 Id입니다🥲");
    }
  };

  if (!lodgmentReservation?.lodgment) {
    return <div></div>;
  }

  return (
    <div
      onClick={closeModal}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(2px)",
        width: "100vw",
        height: "100vh",
        zIndex: 1,
      }}
    >
      <div
        onClick={onInnerContainer}
        style={{
          backgroundColor: "white",
          position: "fixed",
          top: "25%",
          left: 0,
          right: 0,
          margin: "0 auto",
          width: "fit-content",
          height: "fit-content",
          padding: "3rem 6rem 3rem 6rem",
          zIndex: 2,
        }}
      >
        <div
          onClick={closeModal}
          style={{
            cursor: "pointer",
            fontSize: "2rem",
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          X
        </div>
        <div>
          <h3
            style={{ fontSize: "2rem", margin: "0 auto", width: "fit-content" }}
          >
            📆 예약 객실 변경
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.5rem",
              marginTop: "2rem",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <label>숙소명:</label>&nbsp;
                <div>{lodgmentReservation.lodgment.name}</div>
              </div>
              <div style={{ display: "flex" }}>
                <label>고객명:</label>&nbsp;
                <div>{lodgmentReservation.userInfo.name}</div>
              </div>
              <div style={{ display: "flex" }}>
                <label>예약 날짜:</label>&nbsp;
                <div>
                  {lodgmentReservation.basicInfo.start} ~{" "}
                  {lodgmentReservation.basicInfo.end}
                </div>
              </div>
              <br />
              <div style={{ display: "flex" }}>
                <label>변경할 숙소 Id:</label>&nbsp;
                <input
                  value={updatedLodgmentId}
                  onChange={(e) => setUpdatedLodgmentId(e.target.value)}
                />
              </div>
              <div>
                지금 변경하려는 상품이 시프시프인가요?{" "}
                <input
                  type="checkbox"
                  checked={checked}
                  name="secret-price"
                  id="secret-price"
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
              </div>
              <button onClick={onSubmit} style={{ marginTop: "2rem" }}>
                저장하기
              </button>
            </div>
          </div>
          <br />* 변경할 날짜에 기존 예약 내역이 없는지 확인하고 변경하세요!
          <br />* 슬랙, 유저, 호스트 알림이 모두 발생합니다!
        </div>
      </div>
    </div>
  );
}
