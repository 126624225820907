import { SMART_PLACE } from "constants/path";
import { myAxios } from "./myAxios";

// const NAVER_URL = {
//   DEVELOPMENT: "https://booking-test-agency.io.naver.com",
//   PRODUCTION: "https://api.booking.naver.com",
// };

export const getNaverBusinesses = async (): Promise<any> => {
  const path = `${SMART_PLACE}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const deleteNaverBusiness = async (businessId: string): Promise<any> => {
  const path = `${SMART_PLACE}/${businessId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};
