import * as React from "react";
import * as S from "../AccommodationRegister/accommodationRegister.style";
import axios from "config/axiosConfig";

import { uploadShortsVideo } from "api/videos";
import { postShort, putShort } from "api/shortsposts";

export interface ShortsCreateProps {
  data?;
}

export default function ShortsCreate({ data }: ShortsCreateProps) {
  const [videos, setVideos] = React.useState([]);
  const [lodgment, setLodgment] = React.useState("");
  const [preference, setPreference] = React.useState(1);
  console.log(videos, "비디오지");
  console.log(data);

  const imgRef = React.useRef<HTMLInputElement>();

  const decreasevideos = (idx) => {
    const tempAddingvideos = [...videos];
    if (idx !== null) {
      tempAddingvideos.splice(idx, 1);
    } else {
      tempAddingvideos.pop();
    }

    setVideos(tempAddingvideos);
  };

  const movevideosUp = (idx) => {
    const tempAddingvideos = [...videos];
    if (idx > 0) {
      tempAddingvideos.splice(idx - 1, 0, tempAddingvideos[idx]);
      tempAddingvideos.splice(idx + 1, 1);
      setVideos(tempAddingvideos);
    }
  };

  const movevideosDown = (idx) => {
    const tempAddingvideos = [...videos];
    if (idx < tempAddingvideos.length) {
      tempAddingvideos.splice(idx + 2, 0, tempAddingvideos[idx]);
      tempAddingvideos.splice(idx, 1);

      console.log("hello");
      setVideos(tempAddingvideos);
    }
  };
  const increasevideos = () => {
    const tempAddingvideos = [...videos];
    tempAddingvideos.push({
      src: "",
      desc: "",
      thanksTo: "",
    });

    setVideos(tempAddingvideos);
  };
  const onChangevideos = (type, idx, text) => {
    const tempvideos = [...videos];
    tempvideos[idx][type] = text;
    setVideos(tempvideos);
  };
  const onPlaceImageDelete = async (idx: number) => {
    if (!confirm("정말 삭제하시겠습니까?")) {
      return;
    }
    const updatedvideos = [...videos];
    updatedvideos.splice(idx, 1);
    setVideos(updatedvideos);
  };

  const [shortsIdForUpdate, setShortsIdForUpdate] = React.useState("");

  const onSubmit = async () => {
    try {
      const data = {
        videos,
        lodgment,
        preference,
      };
      // data.availableDates = data.availableDates.map((item) => {
      //   const newDate = {
      //     date: new Date(item.date),
      //     price: item.price,
      //   };
      //   return newDate;
      // });
      console.log(data);
      if (shortsIdForUpdate) {
        await putShort(shortsIdForUpdate, data);
      } else {
        await postShort(data);
      }

      alert("저장/수정완료되었습니다.");
    } catch (err) {}
  };
  React.useEffect(() => {
    if (data?._id) {
      setVideos(data.videos);
      setLodgment(data.lodgment._id);
      setShortsIdForUpdate(data._id);
      setPreference(data.preference);
    }
    console.log(data, "데이타야");
  }, [data]);
  const onVideo = async (e: React.ChangeEvent<HTMLInputElement>, idx) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("videos", file);
    const {
      data: { videos },
    } = await uploadShortsVideo(formData);
    onChangevideos("src", idx, videos[0]);
  };
  return (
    <div>
      <button
        onClick={() => {
          onSubmit();
          console.log(lodgment);
        }}
        style={{
          width: 300,
          height: 200,
          fontSize: 50,
          position: "fixed",
          right: 0,
          bottom: 0,
        }}
      >
        {shortsIdForUpdate ? "수정하기" : "저장하기"}
      </button>

      <div style={{ display: "flex" }}>
        <div style={{ width: "40rem" }}>
          <h3>숙소에 대한 정보</h3>
          <h3>숙소 이름 : {data?.lodgment.name}</h3>
          <img src={data?.lodgment?.coverImg} style={{ width: "30rem" }} />
          <S.InputLayout>
            <S.InputLabel>숙소 아이디: </S.InputLabel>
            <S.Input
              type="text"
              value={lodgment}
              onChange={(e) => setLodgment(e.target.value)}
              required
            />
          </S.InputLayout>
          <S.InputLayout>
            <S.InputLabel>선호도 : 얼마나 앞에 보여줄건가요? </S.InputLabel>
            <S.Input
              type="number"
              value={preference}
              onChange={(e) => setPreference(parseInt(e.target.value))}
              required
            />
          </S.InputLayout>
        </div>
        {/* <div>{data ? data.lodgment.name : null}</div> */}
        <div>
          <div style={{ marginBottom: 10 }}>
            <label style={{ width: "20rem", fontSize: 23 }}>
              쇼츠 url 디테일설명 원저자
            </label>
            <button
              onClick={decreasevideos}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </button>
            <button
              onClick={increasevideos}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </button>
          </div>

          {videos?.map((el, idx) => {
            console.log(videos[idx]);
            // const [images, setImages] = React.useState(videos[idx].imgs);

            return (
              <div>
                <S.InputLayout>
                  {videos[idx].src && (
                    <video
                      autoPlay
                      src={videos[idx].src}
                      style={{
                        width: "10rem",
                      }}
                    />
                  )}
                  <br />
                  <S.InputLabel htmlFor="videos[idx].src">
                    비디오:{" "}
                  </S.InputLabel>
                  <S.Input
                    id="videos[idx].src"
                    type="file"
                    accept="video/mp4,video/mkv,video/avi,video/wmv,video/x-m4v,video/*"
                    onChange={(e) => onVideo(e, idx)}
                  />
                </S.InputLayout>
                <S.InputLayout>
                  <S.InputLabel>설명: </S.InputLabel>
                  <S.Textarea
                    value={videos[idx].desc}
                    onChange={(e) =>
                      onChangevideos("desc", idx, e.target.value)
                    }
                    required
                  />
                </S.InputLayout>
                <S.InputLayout>
                  <S.InputLabel>출처: </S.InputLabel>
                  <S.Textarea
                    value={videos[idx].thanksTo}
                    onChange={(e) =>
                      onChangevideos("thanksTo", idx, e.target.value)
                    }
                    required
                  />
                </S.InputLayout>
                <button
                  onClick={() => {
                    onPlaceImageDelete(idx);
                  }}
                >
                  동영상 삭제하기
                </button>
                <button
                  onClick={() => {
                    movevideosUp(idx);
                  }}
                >
                  한칸 올리기
                </button>
                <button
                  onClick={() => {
                    movevideosDown(idx);
                  }}
                >
                  한칸 내리기
                </button>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
