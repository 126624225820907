import * as React from "react";

export interface ILoadingProps {}

import styled, { keyframes } from "styled-components";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border-top: 3px solid #d4d4d4;
  border-right: 3px solid #d4d4d4;
  border-bottom: 3px solid #d4d4d4;
  border-left: 6px solid black;
  background: transparent;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 auto;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  height: 10vh;
  background-color: transparent;
  z-index: 10000;
  z-index: 11;
`;

const Content = styled.div`
  width: fit-content;
  margin: auto;
`;

const Text = styled.p`
  color: #4c4cce;
  font-weight: bolder;
  width: fit-content;
  margin-bottom: 1.7rem;
`;

export default function Loading({}: ILoadingProps) {
  return (
    <Container className="Loading">
      <Content>
        <Spinner />
      </Content>
    </Container>
  );
}
