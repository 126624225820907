import { deleteHaulink, getHaulinks } from "api/haulink";
import * as React from "react";
import * as S from "./haulink.style";

export interface IHauLinkProps {}

export default function HauLink(props: IHauLinkProps) {
  const [lodgments, setLodgments] = React.useState([]);

  React.useEffect(() => {
    fetchHaulinks();
  }, []);

  const fetchHaulinks = async () => {
    const res = await getHaulinks();
    setLodgments(res.data);
  };

  const handleDelete = async (id) => {
    if (window.confirm("정말 삭제하시겠습니까?")) {
      try {
        await deleteHaulink(id);
        setLodgments(lodgments.filter(({ _id }) => _id !== id));
        alert("삭제되었습니다.");
      } catch (err) {
        console.error(err);
        alert("다시 시도해주세요");
      }
    }
    return;
  };

  return (
    <>
      <S.Header>
        <h2>HauLink로 대박나는 링크 리스트</h2>
        <S.RouteLink href="/haulink/create">하우링크 추가하기</S.RouteLink>
      </S.Header>
      {lodgments?.map(({ names, _id: id, images, channels }) => (
        <S.LodgmentBox>
          <S.LodgmentInfo>
            <S.LodgmentName>{names?.name_ko}</S.LodgmentName>
            <div>
              이미지 <b>{images.length}</b>개
            </div>
            <div style={{ marginLeft: "5px" }}>
              채널 <b>{channels.length}</b>개
            </div>
          </S.LodgmentInfo>
          <S.RouteLink href={`/haulink/detail/${id}`}>상세 정보</S.RouteLink>
          <S.Button onClick={() => handleDelete(id)}>삭제</S.Button>
        </S.LodgmentBox>
      ))}
    </>
  );
}
