import * as React from "react";
import SingleChoiceForm from "./SingleChoiceForm";
import MultipleChoiceForm from "./MultipleChoiceForm";
import SubjectiveForm from "./SubjectiveForm";
import PhotoMsgForm from "./PhotoMsgForm";
import BtnMsgForm from "./BtnMsgForm";
import { MSG_TYPES } from "constants/msgTypes";
import { Socket } from "socket.io-client";
import TextMsgForm from "./TextMsgForm";

export interface IFormsProps {
  socket: Socket;
  selectedMsgTemplate;
  msgTemplateList: string[];
}

export default function Forms({
  socket,
  selectedMsgTemplate,
  msgTemplateList,
}: IFormsProps) {
  switch (selectedMsgTemplate.type) {
    case MSG_TYPES.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceForm
          socket={socket}
          selectedMsgTemplate={selectedMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
      );

    case MSG_TYPES.SUBJECTIVE:
      return (
        <SubjectiveForm
          socket={socket}
          selectedMsgTemplate={selectedMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
      );

    case MSG_TYPES.PHOTO_MSG:
      return (
        <PhotoMsgForm
          socket={socket}
          selectedMsgTemplate={selectedMsgTemplate}
        />
      );

    case MSG_TYPES.BTN_MSG:
      return (
        <BtnMsgForm socket={socket} selectedMsgTemplate={selectedMsgTemplate} />
      );
    case MSG_TYPES.TEXT_MSG:
      return (
        <TextMsgForm
          socket={socket}
          selectedMsgTemplate={selectedMsgTemplate}
        />
      );

    default:
      return (
        <SingleChoiceForm
          socket={socket}
          selectedMsgTemplate={selectedMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
      );
  }
}
