import { addDays } from "date-fns";

// export const addDays = (stringDate, day) => {
//   const time = new Date(stringDate).getTime() + day * 24 * 60 * 60 * 1000;
//   const result = new Date(time).toISOString().slice(0, 10);
//   return result;
// };

export const subDays = (stringDate, day) => {
  const time = new Date(stringDate).getTime() - day * 24 * 60 * 60 * 1000;
  const result = new Date(time).toISOString().slice(0, 10);

  return result;
};

export const subDate = (biggerDate, smallerDate) => {
  const time = new Date(biggerDate).getTime() - new Date(smallerDate).getTime();
  const result = time / (24 * 60 * 60 * 1000);

  return result;
};

export const utcToKst = (utc) => {
  const kst = new Date(new Date(utc).getTime() + 9 * 60 * 60 * 1000);
  return kst;
};

export const convertDateIntoString = (date) => {
  if (!date) {
    return "";
  }

  const Year = date.getFullYear();
  let Month = date.getMonth() + 1;
  let Date = date.getDate();
  Month = Month < 10 ? `0${Month}` : Month;
  Date = Date < 10 ? `0${Date}` : Date;

  return `${Year}-${Month}-${Date}`;
};

export const daysBetween = (startDate, endDate) => {
  const ONE_DAY = 1000 * 60 * 60 * 24;

  const StartDate = new Date(startDate);
  const EndDate = new Date(endDate);

  const differenceMs = Math.abs(EndDate.getTime() - StartDate.getTime());
  const days = Math.floor(differenceMs / ONE_DAY);

  return days;
};

export const convertDateIntoYYMMDD = (strfDate) => {
  const dateRegex = /\b\w{3}\s\w{3}\s\d{1,2}\s\d{4}\b/;
  if (!dateRegex.test(strfDate)) return "";

  const [month, day, year] = strfDate.split(" ").slice(1, 4);

  const MONTH_MAPPER = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };
  return `${year.slice(2, 4)}${MONTH_MAPPER[month]}${day}`;
};

export const getCurrentkrTime = (time) => {
  // 1. 현재 시간(Locale)
  const curr = time ? new Date(time) : new Date();

  // 2. UTC 시간 계산
  // getTimezoneOffset : PC 설정 시간대로부터 UTC시간까지의 차이를 분단위로 표현
  // getTime은 밀리초 반환
  const utc = curr.getTime() + curr.getTimezoneOffset() * 60 * 1000;

  // 3. UTC to KST (UTC + 9시간)
  const KR_TIME_DIFF = 9 * 60 * 60 * 1000;
  const kr_curr = new Date(utc + KR_TIME_DIFF);

  return kr_curr;
};

export const getBetweenDates = (strfStartDate, strfEndDate) => {
  const dateArray = [];
  let startDate: Date = getCurrentkrTime(strfStartDate);
  const endDate: Date = getCurrentkrTime(strfEndDate);

  while (startDate <= addDays(endDate, -1)) {
    const addedDate = startDate.toISOString().split("T")[0];
    dateArray.push(addedDate);
    startDate = addDays(startDate, 1);
  }
  return dateArray;
};
