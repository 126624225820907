import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const FlexStart = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const FlexBetween = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
