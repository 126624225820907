import styled from "styled-components";
import { getRemainingDays, isTodayBetweenDates, compareDates } from "../utils";
import dayjs from "dayjs";
import "dayjs/locale/ko";

dayjs.locale("ko");

const Badge = ({ collaboration }) => {
  const color = {
    red: {
      bgColor: "#FF5C48",
      textColor: "white",
    },
    orange: {
      bgColor: "#FFE8E5",
      textColor: "#FF5C48",
    },
    blue: {
      bgColor: "#DCE8FF",
      textColor: "#3182F7",
    },
    gray: {
      bgColor: "#F3F4F6",
      textColor: "#B0B9C2",
    },
    null: {
      bgColor: "#FF8791",
      textColor: "#D22030",
    },
  };

  const handleDetail = () => {
    const diffDays = getRemainingDays(collaboration?.applicationEndDate);

    // collaboration의 상태에 따른 로직 처리
    if (collaboration?.status === "enrolling") {
      return {
        text: "검토중",
        ...color.orange,
      };
    }

    if (collaboration?.status === "cancelled") {
      return {
        text: "취소",
        ...color.gray,
      };
    }

    if (collaboration?.status === "revoked") {
      return {
        text: "철회",
        ...color.gray,
      };
    }

    if (diffDays > 0) {
      return {
        text: `${diffDays}일 남음`,
        ...color.red,
      };
    } else if (diffDays === 0) {
      return {
        text: "오늘 마감",
        ...color.red,
      };
    } else if (
      isTodayBetweenDates(
        collaboration?.applicationEndDate,
        collaboration?.selectionAnnouncementDate
      ) &&
      collaboration?.status === "confirmed"
    ) {
      return {
        text: "선정중",
        ...color.orange,
      };
    } else {
      if (collaboration?.status === "ongoing") {
        if (
          isTodayBetweenDates(
            collaboration?.applicationEndDate,
            collaboration?.benefitEndDate
          )
        ) {
          return {
            text: "선정 완료",
            ...color.blue,
          };
        } else if (compareDates(dayjs(), collaboration?.uploadDate)) {
          return {
            text: "업로드 대기중",
            ...color.blue,
          };
        } else {
          return {
            text: "업로드 지연",
            ...color.orange,
          };
        }
      } else if (collaboration?.status === "completed") {
        return {
          text: "업로드 완료",
          ...color.blue,
        };
      }
    }
    return {
      text: "오류",
      ...color.null,
    };
  };

  const { text, bgColor, textColor } = handleDetail();

  return (
    <S.Badge bgColor={bgColor} textColor={textColor}>
      {text}
    </S.Badge>
  );
};

export default Badge;

const S = {
  Badge: styled.div`
    background-color: ${(props) => props.bgColor || "#ff5c48"};
    color: ${(props) => props.textColor || "white"};
    font-weight: 600;
    padding: 4px 8px;
    font-size: 12px;
    border-radius: 8px;
    line-height: 18px;
    width: fit-content;
    height: fit-content;
    white-space: nowrap;
  `,
};
