import { formatDateWithDay } from "../utils";
import Badge from "./Badge";
import { useHistory } from "react-router-dom";

export default function CollaborationCard({ collaboration }) {
  const history = useHistory();

  const handleClick = () => {
    console.log("click");
    history.push(`/review/collaboration/${collaboration._id}`);
  };
  return (
    <div
      style={{
        border: "1px solid #e9ecef",
        padding: "12px",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        gap: "4px",
      }}
      onClick={() => {
        handleClick();
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          {collaboration.lodgment.name}
        </div>
        <Badge collaboration={collaboration} />
      </div>
      <div
        style={{
          color: "#888",
        }}
      >
        {collaboration._id}
      </div>
      <img
        src={collaboration.lodgment.coverImg}
        alt="lodgment"
        style={{
          width: "100%",
          height: "200px",
          objectFit: "cover",
          borderRadius: "8px",
        }}
      />
      <div>
        {formatDateWithDay(collaboration.benefitStartDate)} ~{" "}
        {formatDateWithDay(collaboration.benefitEndDate)}
      </div>
    </div>
  );
}
