import * as React from "react";
import * as S from "./packagePlace.style";
import { uploadPackageImg } from "api/image";
import { postPackagePlace, putPackagePlace } from "api/packagePlace";

export interface IPackagePlaceCreateProps {
  data?;
}

export default function PackagePlaceCreate({ data }: IPackagePlaceCreateProps) {
  const [title, setTitle] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [notice, setNotice] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [tip, setTip] = React.useState("");
  const [images, setImages] = React.useState([]);

  const [placeIdForUpdate, setPlaceIdForUpdate] = React.useState("");

  const imgRef = React.useRef<HTMLInputElement>();

  const onPlaceImageDelete = async (index: number) => {
    if (!confirm("정말 삭제하시겠습니까?")) {
      return;
    }
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const onSubmit = async () => {
    try {
      if (images.length === 0) {
        alert("힌 개 이상의 이미지를 첨부해주세요~");
        return;
      }
      const data = {
        title,
        category,
        notice,
        description,
        tip,
        images,
      };
      if (placeIdForUpdate) {
        await putPackagePlace(placeIdForUpdate, data);
      } else {
        await postPackagePlace(data);
      }
      window.location.replace("/packagePlace");
    } catch (err) {}
  };

  React.useEffect(() => {
    if (data) {
      setTitle(data.title);
      setCategory(data.category);
      setNotice(data.notice);
      setDescription(data.description);
      setTip(data.tip);
      setImages(data.images);
      setPlaceIdForUpdate(data._id);
    }
  }, [data]);

  return (
    <div>
      <S.InputLayout>
        <S.InputLabel>제목: </S.InputLabel>
        <S.Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>카테고리: </S.InputLabel>
        <S.Input
          value={category}
          onChange={(e) => setCategory(e.target.value)}
          required
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>주의사항: </S.InputLabel>
        <S.Input
          value={notice}
          onChange={(e) => setNotice(e.target.value)}
          required
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>설명: </S.InputLabel>
        <S.Textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>팁: </S.InputLabel>
        <S.Textarea
          value={tip}
          onChange={(e) => setTip(e.target.value)}
          required
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>사진: </S.InputLabel>
        <S.Input type="file" accept="image/*" ref={imgRef} required />
        <button
          onClick={async () => {
            const formData = new FormData();
            if (!imgRef.current?.files.length) {
              alert("사진을 첨부해야 합니다.");
              return;
            }
            formData.append("placeImage", imgRef.current.files[0]);
            const {
              data: { placeImage },
            } = await uploadPackageImg(formData);

            setImages([
              ...images,
              {
                imgUrl: placeImage[0],
                source: "",
              },
            ]);
            imgRef.current.value = "";
          }}
        >
          추가하기
        </button>
      </S.InputLayout>
      <S.PlaceImageContainer>
        {images.map((image, index) => (
          <S.PlaceImageItem>
            <img
              src={image.imgUrl}
              style={{
                width: "180px",
                height: "230px",
                borderRadius: "10px",
                objectFit: "cover",
              }}
            />
            출처:{" "}
            <S.Input
              onChange={(e) => {
                const updatedImages = [...images];
                updatedImages[index] = {
                  ...updatedImages[index],
                  source: e.target.value,
                };
                setImages(updatedImages);
              }}
              value={image.source}
            />
            <br />
            <button
              onClick={() => {
                onPlaceImageDelete(index);
              }}
            >
              삭제
            </button>
          </S.PlaceImageItem>
        ))}
      </S.PlaceImageContainer>
      <button onClick={onSubmit}>저장하기</button>
    </div>
  );
}
