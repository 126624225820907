import React, { useEffect, useState } from "react";
import axios from "config/axiosConfig";
import { compareTime } from "utils/compare";
import { format } from "date-fns";
import Calendar from "react-calendar";
import * as S from "./ConsultReservations.style";
import useTitle from "hooks/useTitle";
import ADMIN_PHONE from "constants/admin";

export default function ConsultReservations() {
  useTitle("상담 예약 캘린더");

  const [consults, setConsults] = useState([]);
  const [todayConsults, setTodayConsults] = useState([]);

  // 달력 날짜 선택시에
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateConsults, setSelectedDateConsults] = useState([]);

  useEffect(() => {
    const initConsults = async () => {
      await axios
        .get("consultReservations", { params: { before: 10, after: 15 } })
        .then((res) => {
          const data = res.data.consultReservations;
          setConsults(data);
        });
    };

    initConsults();
  }, []);

  useEffect(() => {
    console.log(consults);
  }, [consults]);

  useEffect(() => {
    const filterToday = () => {
      const today = format(new Date(), "yyyy-MM-dd");
      let arr = [...consults].filter((el) => el.date === today);
      arr = arr.sort(compareTime);
      setTodayConsults(arr);
    };
    if (consults?.length) {
      filterToday();
    }
  }, [consults]);

  useEffect(() => {
    const filterCurDate = () => {
      const curDate = format(new Date(selectedDate), "yyyy-MM-dd");
      let arr = [...consults].filter((el) => el.date === curDate);
      setSelectedDateConsults(arr);
    };
    if (selectedDate) {
      filterCurDate();
    }
  }, [selectedDate]);

  const handleDayClick = (day) => {
    setSelectedDate(day);
  };

  const checkHasConsult = (day) => {
    const curDate = format(new Date(day), "yyyy-MM-dd");
    if (consults.find((el) => el.date === curDate)) {
      return true;
    } else {
      return false;
    }
  };

  const checkIsAdmin = (phone) => {
    // return false;
    if (ADMIN_PHONE.find((el) => el === phone)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        style={{
          marginRight: 10,

          width: selectedDate ? "80%" : "100%",
        }}
      >
        <h2>오늘 날짜 상담 이에요 </h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "flex-start",
            alignItems: "center",
            border: "1px solid #e9e9e9",
            borderRadius: 10,
            padding: 10,
            // height: 2342423424,
          }}
        >
          {todayConsults.length ? (
            <>
              {todayConsults.map((el, idx) => {
                const isAdmin = checkIsAdmin(el.userPhone);
                return (
                  <div
                    style={{
                      marginRight: 10,
                      justifyContent: "flex-start",
                      alignItems: "center",
                      border: el.isManualPlan
                        ? "2px solid YellowGreen"
                        : "1px solid #e9e9e9",
                      borderRadius: 10,
                      padding: 10,
                      cursor: "pointer",
                      backgroundColor: isAdmin ? "#e9e9e9" : "#ffffff",
                      marginBottom: 10,
                    }}
                    key={"today_" + idx.toString()}
                    onClick={() => {
                      const win = window.open(
                        `/consult/${el.consult}`,
                        "_blank"
                      );
                      win.focus();
                    }}
                  >
                    <div
                      style={{
                        fontSize: 18,
                        fontWeight: "700",
                        textAlign: "center",
                      }}
                    >
                      {el.time}
                    </div>
                    <div
                      style={{
                        fontSize: 15,
                        fontWeight: "400",
                        textAlign: "center",
                      }}
                    >
                      📞 {el.userPhone}
                    </div>
                    {/* <div
                      style={{
                        color: "#0F59E0",
                        fontSize: 12,
                        fontWeight: "500",
                        textAlign: "right",
                        marginTop: 5,
                      }}
                    >
                      이 계획 바로가기(누르세용❤️)
                    </div> */}
                  </div>
                );
              })}
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                fontSize: 15,
                fontWeight: "400",
                color: "#494949",
                marginTop: 5,
              }}
            >
              상담 예약이 없어요
            </div>
          )}
        </div>

        <h2 style={{ marginTop: 50, marginBottom: 20 }}>달력으로 보시죠^^</h2>

        <S.Calendar>
          <Calendar
            tileContent={({ activeStartDate, date, view }) => {
              const hasConsults = checkHasConsult(date);
              if (hasConsults) {
                return (
                  <div
                    style={{
                      backgroundColor: "#009dff",
                      width: 10,
                      height: 10,
                      borderRadius: 100,
                      alignSelf: "center",
                      justifySelf: "center",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: 5,
                      borderWidth: 0,
                    }}
                  ></div>
                );
              } else {
                return <div style={{ width: 10, height: 10, marginTop: 5 }} />;
              }
            }}
            onClickDay={handleDayClick}
            value={selectedDate}
          />
        </S.Calendar>
      </div>

      {selectedDate && (
        <div
          style={{
            // width: 100,
            height: "100%",
            // flex: 1,
            position: "relative",
            width: selectedDate ? "10%" : 0,
          }}
        >
          <div
            style={{
              position: "fixed",
              right: 10,
              top: 100,
              borderRadius: 10,
              border: "1px solid #494949",
              padding: 10,
              paddingLeft: 20,
              paddingRight: 20,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#fdfdfd",
              zIndex: 2000,
              height: "80%",
              overflow: "scroll",
            }}
          >
            <h3>선택한 날짜의 일정들!</h3>
            {selectedDateConsults?.length ? (
              <div style={{}}>
                {selectedDateConsults.map((el, idx) => {
                  const isAdmin = checkIsAdmin(el.userPhone);
                  return (
                    <div
                      style={{
                        justifyContent: "flex-start",
                        alignItems: "center",
                        border: el.isManualPlan
                          ? "2px solid YellowGreen"
                          : "1px solid #e9e9e9",
                        borderRadius: 10,
                        padding: 10,
                        cursor: "pointer",
                        marginBottom: 10,
                        backgroundColor: isAdmin ? "#e9e9e9" : "#ffffff",
                      }}
                      onClick={() => {
                        const win = window.open(
                          `/consult/${el.consult}`,
                          "_blank"
                        );
                        win.focus();
                      }}
                      key={"selected_" + idx.toString()}
                    >
                      <div
                        style={{
                          fontSize: 18,
                          fontWeight: "700",
                          textAlign: "center",
                        }}
                      >
                        {el.time}
                      </div>
                      <div
                        style={{
                          fontSize: 15,
                          fontWeight: "400",
                          textAlign: "center",
                        }}
                      >
                        📞 {el.userPhone}
                      </div>
                      {/* <div
                        style={{
                          color: "#0F59E0",
                          fontSize: 12,
                          fontWeight: "500",
                          textAlign: "center",
                          marginTop: 5,
                        }}
                      >
                        이 계획 바로가기(누르세용❤️)
                      </div> */}
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  fontSize: 15,
                  fontWeight: "400",
                  color: "#494949",
                }}
              >
                상담 예약이 없어요
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
