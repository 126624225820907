import { MSG_TYPES } from "constants/msgTypes";
import {
  deleteMsgTemplate,
  postMsgTemplate,
  putMsgTemplate,
} from "api/msgTemplate";
import { emitSendMessage } from "utils/socket/clientEvent";
import {
  btnMsgValidator,
  multipleChoiceValidator,
  singleChoiceValidator,
  subjectiveFormValidator,
  textMsgFormValidator,
} from "./formDataValidator";

export const onSaveFormBtn = async (formData) => {
  if (!formData.title) {
    alert("폼 제목을 지정해주세요!");
    return;
  }
  await postMsgTemplate(formData);
  alert("저장되었습니다!");
  window.location.reload();
};

export const onUpdateFormBtn = async (formData, id) => {
  if (!formData.title) {
    alert("폼 제목을 지정해주세요!");
    return;
  }
  await putMsgTemplate(id, formData);
  alert("수정되었습니다.");
  window.location.reload();
};

export const onDeleteFormBtn = async (templateId) => {
  if (!confirm("정말 삭제하시겠습니까?")) {
    return;
  }
  await deleteMsgTemplate(templateId);
  alert("삭제되었습니다!");
  window.location.reload();
};

const createOnSendBtn = (msgType, validator: (content) => boolean) => {
  if (!(msgType && validator)) {
    throw Error("msgType과 validator를 넘겨주세요.");
  }

  return (socket, content) => {
    if (!validator(content)) {
      alert("모든 필드를 올바르게 작성했는지 확인해주세요!");
      return;
    }

    if (!confirm("메시지를 전송하시겠습니까?")) {
      return;
    }
    emitSendMessage(socket, msgType, content);
  };
};

export const onSingleChoiceSendBtn = createOnSendBtn(
  MSG_TYPES.SINGLE_CHOICE,
  singleChoiceValidator
);
export const onMultipleChoiceSendBtn = createOnSendBtn(
  MSG_TYPES.MULTIPLE_CHOICE,
  multipleChoiceValidator
);
export const onSubjectiveFormSendBtn = createOnSendBtn(
  MSG_TYPES.SUBJECTIVE,
  subjectiveFormValidator
);
export const onTextMsgSendBtn = createOnSendBtn(
  MSG_TYPES.TEXT_MSG,
  textMsgFormValidator
);

export const onBtnMsgSendBtn = createOnSendBtn(
  MSG_TYPES.BTN_MSG,
  btnMsgValidator
);
