import { COLLABORATION_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getCollaborationList = async (status?: string) => {
  const res = await myAxios.get({
    path: status
      ? `${COLLABORATION_PATH}?status=${status}`
      : COLLABORATION_PATH,
  });
  return res.data;
};

export const getCollaboration = async (collaborationId: string) => {
  const res = await myAxios.get({
    path: `${COLLABORATION_PATH}/${collaborationId}`,
  });
  return res.data;
};

export const getSubmissionsByCollaborationId = async (
  collaborationId,
  status?: string[]
) => {
  const statusQuery =
    status?.length > 0 ? `?status=${status.join("&status=")}` : "";
  const path =
    COLLABORATION_PATH + `/submission/${collaborationId}${statusQuery}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const confirmCollaboration = async (collaborationId: string) => {
  const path = `${COLLABORATION_PATH}/confirm/${collaborationId}`;
  const res = await myAxios.put({ path });
  return res.data;
};

export const rejectCollaboration = async (
  collaborationId: string,
  reason: string
) => {
  const path = `${COLLABORATION_PATH}/reject/${collaborationId}`;
  const res = await myAxios.put({ path, data: { reason } });
  return res.data;
};

export const cancelCollaboration = async (collaborationId: string) => {
  const path = `${COLLABORATION_PATH}/cancel/${collaborationId}`;
  const res = await myAxios.put({ path });
  return res.data;
};

export const updateCollaboration = async (id, data) => {
  const path = COLLABORATION_PATH + `/${id}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
