import { convertDateIntoYYMMDD } from "./date";

export function exportTableToCSV(filename, maxColumns = 100) {
  const csv = [];
  const rows = document.querySelectorAll("table tr");

  for (let i = 0; i < rows.length; i++) {
    const row = [],
      cols = rows[i].querySelectorAll<HTMLTableElement>("td, th");

    const numCols = Math.min(maxColumns, cols.length);
    for (let j = 0; j < numCols; j++) {
      row.push(cols[j].innerText.replaceAll(",", "."));
    }

    csv.push(row.join(","));
  }

  // Download CSV file
  downloadCSV(csv.join("\n"), filename);
}

export function exportMultipleTableToCSV(lodgmentLogs, date, maxColumns = 100) {
  const tables = document.querySelectorAll("table ");

  tables.forEach((table, index) => {
    const csv = [];
    const rows = table.querySelectorAll("tr");

    for (let i = 0; i < rows.length; i++) {
      const row = [],
        cols = rows[i].querySelectorAll<HTMLTableElement>("td, th");

      const numCols = Math.min(maxColumns, cols.length);
      for (let j = 0; j < numCols; j++) {
        row.push(cols[j].innerText.replaceAll(",", "."));
      }

      csv.push(row.join(","));
    }

    const lodgmentName = lodgmentLogs[index][0].basicInfo.name;

    const filename = `finestays x ${lodgmentName} ${convertDateIntoYYMMDD(
      date
    )} 정산건`;

    downloadCSV(csv.join("\n"), filename);
  });
}

// Download CSV file
// downloadCSV(csv.join("\n"), filename);
// }

function downloadCSV(csv, filename) {
  const csvFile = new Blob([csv], { type: "text/csv" });

  const downloadLink = document.createElement("a");
  downloadLink.download = filename;
  downloadLink.href = window.URL.createObjectURL(csvFile);
  downloadLink.style.display = "none";
  document.body.appendChild(downloadLink);
  downloadLink.click();
  console.log("end");
}
