import * as React from "react";
import * as S from "./RightWing.style";
import { MSG_TYPES } from "constants/msgTypes";

export interface IFormListProps {
  msgTemplates;
  setSelectedMsgTemplate;
}

export default function FormList({
  msgTemplates,
  setSelectedMsgTemplate,
}: IFormListProps) {
  const onFormItemClick = (e) => {
    const formInfo = JSON.parse(e.target.dataset.form);
    setSelectedMsgTemplate(formInfo);
  };

  return (
    <>
      <S.FormListContainer>
        <S.FormList>
          <S.FormListTitle>기본 Form Palette</S.FormListTitle>
          <S.FormItemContainer>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.SINGLE_CHOICE,
                title: "",
                content: {
                  question: "",
                  options: [""],
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              객관식 단일 선택
            </S.FormItem>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.MULTIPLE_CHOICE,
                title: "",
                content: {
                  question: "",
                  options: [""],
                  btnText: "",
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              객관식 중복 선택
            </S.FormItem>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.SUBJECTIVE,
                title: "",
                content: {
                  question: "",
                  placeholder: "",
                  btnText: "",
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              단답형 주관식
            </S.FormItem>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.PHOTO_MSG,
                title: "",
                content: {
                  photo: "",
                  text: "",
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              사진 + 글
            </S.FormItem>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.BTN_MSG,
                title: "",
                content: {
                  text: "",
                  btnLink: "",
                  btnText: "",
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              글 + 링크
            </S.FormItem>
            <S.FormItem
              data-form={JSON.stringify({
                type: MSG_TYPES.TEXT_MSG,
                title: "",
                content: {
                  text: "",
                },
                nextTemplate: "",
              })}
              onClick={onFormItemClick}
            >
              텍스트
            </S.FormItem>
          </S.FormItemContainer>
        </S.FormList>
        <S.FormList>
          <S.FormListTitle>관리자 생성 Form Palette</S.FormListTitle>
          <S.FormItemContainer>
            {msgTemplates.map((item) => (
              <S.FormItem
                key={item._id}
                data-form={JSON.stringify(item)}
                onClick={onFormItemClick}
              >
                {item.title}
              </S.FormItem>
            ))}
          </S.FormItemContainer>
        </S.FormList>
      </S.FormListContainer>
    </>
  );
}
