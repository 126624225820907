import styled from "styled-components";

export const Card = styled.div``;

export const CardMedia = styled.div``;

export const ListItemContent = styled.div``;

export const List = styled.div``;

export const ListItem = styled.div`
  border: 1px solid black;
  padding: 8px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ListItemButton = styled.button`
  svg {
    width: 20px;
    height: 20px;
  }
`;

export const ListItemText = styled.div``;

export const ListItemIcon = styled.div``;

export const ListItemMedia = styled.div``;

export const ListItemAction = styled.div``;

export const ListSection = styled.div`
  width: 50%;
`;
