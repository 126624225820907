import { myAxios } from "./myAxios";
import { USER_PATH } from "../constants/path";
import { IUser } from "type/user.type";

interface IUserData {
  msg: string;
  user: IUser;
}

export const createUser = async (phone: string) => {
  const path = USER_PATH + `/phone/${phone}`;
  const res = await myAxios.post({ path });
  return res.data;
};

export const updateUser = async (userId, data) => {
  const path = USER_PATH + `/${userId}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};

export const getUser = async (userId: string): Promise<IUserData> => {
  const path = USER_PATH + `/${userId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getUserByPhone = async (phone: string): Promise<IUserData> => {
  const path = USER_PATH + `/phone/${phone}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getUsersWithConnectedAccounts = async () => {
  const path = USER_PATH + "/hostConnectedAccounts";
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const addHostingLodgment = async (userId: string, data: any) => {
  const path = USER_PATH + `/${userId}/addHostingLodgment`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};

export const deleteHostingLodgment = async (userId, lodgmentId, index) => {
  const path = `${USER_PATH}/${userId}/hosting?lodgmentId=${lodgmentId}&index=${index}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const updateHostingLodgmentOrder = async (userId, data) => {
  const path = `${USER_PATH}/${userId}/hostingOrder`;
  const res = await myAxios.put({ path, data });
  return res.data;
};

export const searchUser = async (value: string) => {
  const path = `${USER_PATH}/search?username=${value}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const verifyFineReviewUser = async (
  userPhone: string,
  mission: string
) => {
  const path = `${USER_PATH}/review/verify/${userPhone}/${mission}`;
  const res = await myAxios.put({ path });
  return res.data;
};

export const rejectFineReviewUser = async (
  userPhone: string,
  mission: string,
  data: any
) => {
  const path = `${USER_PATH}/review/reject/${userPhone}/${mission}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};

export const getRequestedVerificationUsers = async () => {
  const path = `${USER_PATH}/review/verify`;
  const res = await myAxios.get({ path });
  console.log(res);
  return res.data;
};
