import { IMsg } from "../type/chat.type";
import { myAxios } from "./myAxios";
import { MSGS_PATH } from "../constants/path";

interface IMsgData {
  msg: string;
  messages: IMsg[];
  lastIndex: number;
}

export const getMessages = async (consultId: string): Promise<IMsgData> => {
  const path = MSGS_PATH + `?consult=${consultId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const deleteMessage = async (messageId: string) => {
  const path = MSGS_PATH + `/${messageId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const getMessagesByLastIndex = async (
  consultId: string,
  lastIndex: number
): Promise<IMsgData> => {
  const path = MSGS_PATH + `?consult=${consultId}` + `&lastIndex=${lastIndex}`;
  const res = await myAxios.get({ path });
  return res.data;
};
