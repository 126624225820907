import { uploadFile } from "react-s3";
import { s3ConfigPostImg } from "config/s3Config";
import { FlexStart } from "components/style";
import { AddImageButton } from "./style";
import { useState } from "react";
import { Button } from "react-bootstrap";

export default function PostImageAdder({ post, setPost }) {
  const [addingImages, setAddingImages] = useState([
    { files: [], source: null },
  ]);

  const decreaseImgInput = () => {
    if (addingImages.length > 1) {
      const tempAddingImages = [...addingImages];
      tempAddingImages.pop();
      setAddingImages(tempAddingImages);
    }
  };
  const increaseImgInput = () => {
    const tempAddingImages = [...addingImages];
    tempAddingImages.push({ file: null, source: null });
    setAddingImages(tempAddingImages);
  };

  const addImagesToS3AndPost = async () => {
    try {
      s3ConfigPostImg["dirName"] = post.id ? post.id : "no-postId";

      let tempImages = [...post.images];
      let tempAddingImages = [];
      for (const addingImage of addingImages) {
        if (addingImage.files.length === 0) {
          continue;
        }
        for (const file of addingImage.files) {
          const res = await uploadFile(file, s3ConfigPostImg);
          tempAddingImages.push({
            url: `https://${res.bucket}.s3.amazonaws.com/${res.key}`,
            source: addingImage.source,
          });
        }
      }
      setPost({ ...post, images: [...tempAddingImages, ...tempImages] });
    } catch (error) {
      alert("에러 발생");
      console.error(error);
    }
  };

  return (
    <>
      <div style={{ marginBottom: 30, marginTop: 30 }}>
        <FlexStart>
          <h3>사진 추가하기</h3>
          <Button
            onClick={decreaseImgInput}
            style={{ height: 38, width: 38, marginLeft: 20 }}
          >
            -
          </Button>
          <Button
            onClick={increaseImgInput}
            style={{ height: 38, width: 38, marginLeft: 4 }}
          >
            +
          </Button>
        </FlexStart>
        {addingImages.map((el, idx) => {
          return (
            <div style={{ marginTop: 10 }}>
              <div>
                <label>
                  사진{idx + 1} :
                  <input
                    type="file"
                    multiple
                    style={{ marginLeft: 20 }}
                    onChange={(e) => {
                      const tempAddingImages = [...addingImages];
                      tempAddingImages[idx].files = e.target.files;
                      setAddingImages(tempAddingImages);
                    }}
                  />
                </label>
              </div>
              <div>
                <label>
                  출처{idx + 1} :
                  <input
                    type="text"
                    style={{ width: 500, marginLeft: 20 }}
                    onChange={(e) => {
                      const tempAddingImages = [...addingImages];
                      tempAddingImages[idx].source = e.target.value;
                      setAddingImages(tempAddingImages);
                    }}
                  />
                </label>
              </div>
            </div>
          );
        })}
      </div>
      <AddImageButton onClick={addImagesToS3AndPost}>
        사진 추가하기
      </AddImageButton>
    </>
  );
}
