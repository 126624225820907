import * as React from "react";
import ChatRoom from "./ChatRoom";
import { useParams } from "react-router-dom";

export interface IChatRoomProps {}

export default function Chatroom(props: IChatRoomProps) {
  const { consultId } = useParams<{ consultId: string }>();
  const conciergeId = "999999";

  if (!conciergeId) {
    return <>로그인이 필요한 페이지입니다.</>;
  }

  return (
    <>
      <ChatRoom consultId={consultId as string} conciergeId={conciergeId} />
    </>
  );
}
