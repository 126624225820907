import React from "react";

import styled from "styled-components";

interface TransitionBoxProps {
  opacity: number;
}

const TransitionBox = styled.div<TransitionBoxProps>`
  opacity: ${(props) => props.opacity};
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  width: fit-content;
  margin: 0 auto;
  border-radius: 5px;
  transition: opacity 0.5s ease-in-out;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10000;
`;

export default function FadeSnackbar({ message }: any) {
  const [opacity, setOpacity] = React.useState(100);

  const fadeHandler = () => {
    if (opacity > 96) {
      // 초기부터 바로 사라지는게 시작되면 메시지 전달할 시간이 부족할듯하여 초반에는 투명도를 1씩 천천히 변화 시켰다.
      setTimeout(() => {
        setOpacity(opacity - 1);
      }, 100);
    } else if (opacity > 5)
      // 초기 약 0.4초후에는 부드럽게 사라지도록 구현했다.
      setTimeout(() => {
        setOpacity(opacity - 8);
      }, 50);
  };

  React.useEffect(() => {
    fadeHandler();
  }, [fadeHandler]);

  return (
    <TransitionBox opacity={opacity}>
      <div>{message}</div>
    </TransitionBox>
  );
}
