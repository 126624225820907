import { myAxios } from "./myAxios";
import { PACKAGE_PLACE_PATH } from "constants/path";

export const getPackagePlaces = async (): Promise<any> => {
  const path = `${PACKAGE_PLACE_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getPackagePlace = async (placeId: string): Promise<any> => {
  const path = `${PACKAGE_PLACE_PATH}/${placeId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const postPackagePlace = async (data): Promise<any> => {
  const res = await myAxios.post({ data, path: PACKAGE_PLACE_PATH });
  return res.data;
};

export const putPackagePlace = async (placeId: string, data): Promise<any> => {
  const path = `${PACKAGE_PLACE_PATH}/${placeId}`;
  const res = await myAxios.put({ data, path });
  return res.data;
};

export const deletePackagePlace = async (placeId: string): Promise<any> => {
  const path = `${PACKAGE_PLACE_PATH}/${placeId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};
