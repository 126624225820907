import * as React from "react";
import BtnMsg from "components/Msgs/BtnMsg";
import IsFirstMsgInput from "./common/isFirstMsgInput";
import {
  onBtnMsgSendBtn,
  onDeleteFormBtn,
  onSaveFormBtn,
  onUpdateFormBtn,
} from "./utils/onFormBtn";
import { Socket } from "socket.io-client";
import { MSG_TYPES } from "constants/msgTypes";
import * as S from "./forms.style";
import FormControlBtns from "./common/FormControlBtns";

export interface IBtnMsgFormProps {
  socket: Socket;
  selectedMsgTemplate;
}

export default function BtnMsgForm({
  socket,
  selectedMsgTemplate,
}: IBtnMsgFormProps) {
  const [formTitle, setFormTitle] = React.useState("");
  const [text, setText] = React.useState("");
  const [btnLink, setBtnLink] = React.useState("");
  const [btnText, setBtnText] = React.useState("");

  const [isFirstMsg, setIsFirstMsg] = React.useState(false);

  const { _id, title, content, isFirstMsg: isFirst } = selectedMsgTemplate;
  const isSavedTemplate = !!_id;

  React.useEffect(() => {
    if (!content) {
      return;
    }
    const { text, btnLink, btnText } = content;
    setFormTitle(title);
    setText(text);
    setBtnLink(btnLink);
    setBtnText(btnText);
    setIsFirstMsg(isFirst);
  }, [selectedMsgTemplate]);

  const formData = {
    type: MSG_TYPES.BTN_MSG,
    title: formTitle,
    content: {
      text,
      btnLink,
      btnText,
    },
    isFirstMsg,
  };

  return (
    <S.FormContainer>
      <S.InputLayout>
        <S.Label htmlFor="formTitle">폼 이름:</S.Label>
        <S.Input
          id="formTitle"
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value);
          }}
        />
      </S.InputLayout>
      <S.FormPreviewSection>
        <BtnMsg
          content={{
            text,
            btnLink,
            btnText,
          }}
          role="concierge"
        />
      </S.FormPreviewSection>
      <S.FormInputSection>
        <S.InputLayout>
          <S.Label htmlFor="text">내용:</S.Label>
          <S.Input
            id="text"
            value={text}
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="btnLink">링크 주소:</S.Label>
          <S.Input
            id="btnLink"
            value={btnLink}
            onChange={(e) => {
              setBtnLink(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="btnText">버튼 텍스트:</S.Label>
          <S.Input
            id="btnText"
            value={btnText}
            onChange={(e) => {
              setBtnText(e.target.value);
            }}
          />
        </S.InputLayout>
        <IsFirstMsgInput
          isFirstMsg={isFirstMsg}
          setIsFirstMsg={setIsFirstMsg}
        />
        <FormControlBtns
          msgType={MSG_TYPES.BTN_MSG}
          socket={socket}
          isSavedTemplate={isSavedTemplate}
          formData={formData}
          id={_id}
        />
      </S.FormInputSection>
    </S.FormContainer>
  );
}
