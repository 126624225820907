import * as React from "react";
import { useParams } from "react-router-dom";
import { getLodgment } from "api/lodgment";
import AccommodationCreate from "./create";

export interface IAccommodationDetailProps {
  accommodationId: string;
}

export interface params {
  accommodationId: string;
}

export default function AccommodationDetail({}: IAccommodationDetailProps) {
  const { accommodationId } = useParams<params>();
  const [place, setPlace] = React.useState();

  React.useEffect(() => {
    const fetchPlace = async () => {
      const { data } = await getLodgment(accommodationId);
      // data.availableDates = data.availableDates.map((item) => {
      //   const newDate = {
      //     date: new Date(item.date).toISOString().slice(0, 10),
      //     price: item.price,
      //   };
      //   return newDate;
      // });
      setPlace(data);
    };
    fetchPlace();
  }, []);

  return <AccommodationCreate data={place} />;
}
