import { getCommissionsByAccommo } from "api/settlementLog";
import React from "react";
import * as S from "../../components/Commission/commission.styled";
import { Spinner } from "react-bootstrap";
import CommissionByAccommoTable from "components/Commission/CommissionByAccommoTable";

export interface CommissionProps {}

export default function Commission({}: CommissionProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]);

  React.useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getCommissionsByAccommo();
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetch();
  }, []);

  if (isLoading)
    return (
      <div
        style={{
          position: "fixed", // 화면 전체를 덮도록 fixed 사용
          top: 0,
          left: 0,
          width: "100vw", // 뷰포트 전체 너비
          height: "100vh", // 뷰포트 전체 높이
          backgroundColor: "rgba(0, 0, 0, 0.5)", // 반투명 배경
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999, // 다른 요소 위에 배치
        }}
      >
        <Spinner animation="border" variant="primary" />
      </div>
    );

  return (
    <S.Container>
      <CommissionByAccommoTable data={data} />
    </S.Container>
  );
}
