import { IConsult } from "../type/consult.type";
import { myAxios } from "./myAxios";
import { CONSULTS_PATH } from "../constants/path";

interface IConsultsData {
  msg: string;
  consults: IConsult[];
}

interface IConsultData {
  consult: IConsult;
}

export const getConsults = async (): Promise<IConsultsData> => {
  const path = CONSULTS_PATH;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getConsultsWithSorting = async (): Promise<IConsultsData> => {
  const path = `${CONSULTS_PATH}?sort=new_msg`;
  const res = await myAxios.get({ path });

  return res.data;
};

export const getConsult = async (consultId: string): Promise<IConsultData> => {
  const path = CONSULTS_PATH + `/${consultId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const updateConsult = async (consultId: string, body): Promise<void> => {
  const path = CONSULTS_PATH + `/${consultId}`;
  await myAxios.put({ path, data: body });
};

export const getOrCreateLodgmentConsult = async (data) => {
  const path = CONSULTS_PATH + `/lodgment`;
  const res = await myAxios.post({ path, data });
  return res.data.data;
};
