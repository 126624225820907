import styled from "styled-components";

export const Container = styled.div<{ isTransparent: boolean }>`
  width: 100%;
  /* max-width: 440px; */
  height: 100%;
  overflow: hidden;
  position: fixed;
  text-align: center;
  margin: auto;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isTransparent }) =>
    isTransparent ? "tranparent" : "rgba(0 0 0 / 30%)"};
  & > div {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    user-select: none;
    z-index: 3;
    max-width: 100%;
  }

  .basic {
    max-width: 440px;
    width: 90%;
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 0;
  }
`;

export const InnerContainer = styled.div<{ isFitContent?: boolean }>`
  width: ${({ isFitContent }) => (isFitContent ? "fit-content" : "")};
`;

export const CloseModalButton = styled.button<{
  isCloseButtonVisible: boolean;
  closeButtonColor?: string;
}>`
  position: absolute;
  right: 3%;
  top: 3%;
  background: transparent;
  border: none;
  color: black;
  cursor: pointer;
  display: flex;
  display: ${({ isCloseButtonVisible }) =>
    isCloseButtonVisible ? "flex" : "none"};
  align-items: center;
  justify-content: end;
  z-index: 10;
  width: fit-content;
  > svg {
    width: 30px;
    font-size: 30px;
    color: ${({ closeButtonColor }) => closeButtonColor || "black"};
  }
`;

export const BottomSheetContainer = styled.div`
  width: 100%;
  max-width: 440px;

  height: 100vh;
  overflow: hidden;
  position: fixed;
  text-align: center;
  margin: auto;
  left: 0;
  bottom: 0;
  top: 0;
  right: 0;
  z-index: 11;
  display: flex;
  background-color: rgba(76, 76, 76, 0.7);

  &.overflowY {
    height: 83vh;
    overflow-y: scroll;
  }
`;

export const BottomSheetContentContainer = styled.div`
  position: fixed;
  bottom: 0;
  background-color: white;
  height: fit-content;

  border-radius: 15px 15px 0px 0px;
  padding: 1.5rem;
  padding-top: 1rem;
  max-width: 440px;

  &.overflowY {
    height: 83vh;
    overflow-y: scroll;
  }
`;

export const BottomSheetHeader = styled.div`
  max-width: 440px;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const BottomSheetTitle = styled.h3`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0 !important;
`;

export const CloseBtn = styled.img`
  position: absolute;
  width: 13px;
  height: 13px;
  cursor: pointer;
`;

export const CloseIcon = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;
