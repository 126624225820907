import styled from "styled-components";

export const Card = styled.div``;

export const Box = styled.div`
  border: 1px solid black;
  padding: 8px 12px;
  width: fit-content;
  margin-top: 10px;
`;

export const Typo = styled.div`
  font-size: 18px;

  &#total-settlement {
    margin-top: 10px;
  }
`;

export const Ul = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;
