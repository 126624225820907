import { deleteMsgTemplate } from "api/msgTemplate";

export const onDeleteFormBtn = async (templateId) => {
  if (!confirm("정말 삭제하시겠습니까?")) {
    return;
  }
  await deleteMsgTemplate(templateId);
  alert("삭제되었습니다!");
  window.location.reload();
};
