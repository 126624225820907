import {
  changeLodgmentReservationDate,
  getLodgmentReservationById,
  getLodgmentReservationByLodgmentId,
} from "api/lodgmentReservation";
import * as React from "react";
import { convertDateIntoString } from "utils/date";

export interface IChangeDateModalProps {
  lodgmentReservationId: string;
  setIsChangeDateModalOn;
  setReservationIdForModal;
}

export default function ChangeDateModal({
  lodgmentReservationId,
  setIsChangeDateModalOn,
  setReservationIdForModal,
}: IChangeDateModalProps) {
  const [lodgmentReservation, setLodgmentReservation] =
    React.useState<Record<any, any>>();
  const [checkinDate, setCheckinDate] = React.useState("");
  const [checkoutDate, setCheckoutDate] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  React.useEffect(() => {
    const fetchLodgmentReservation = async () => {
      const lodgmentReservationData = await getLodgmentReservationById(
        lodgmentReservationId
      );
      setLodgmentReservation(lodgmentReservationData);
      setCheckinDate(lodgmentReservationData.basicInfo.start);
      setCheckoutDate(lodgmentReservationData.basicInfo.end);
    };
    fetchLodgmentReservation();
  }, [lodgmentReservationId]);

  const closeModal = () => {
    setReservationIdForModal("");
    setIsChangeDateModalOn(false);
  };

  const onInnerContainer = (e) => e.stopPropagation();

  const onSubmit = async () => {
    if (new Date(checkinDate) >= new Date(checkoutDate)) {
      alert("체크아웃 날짜는 체크인 날짜 이후여야 합니다.");
      return;
    }
    const payload = {
      updatedStart: checkinDate,
      updatedEnd: checkoutDate,
      isSecretPrice: checked,
    };

    const { isSuccess } = await changeLodgmentReservationDate(
      lodgmentReservationId,
      payload
    );

    if (!isSuccess) {
      alert("예약 불가능한 날짜로 변경을 시도하여 변경에 실패했습니다🥲");
      return;
    }
    window.location.reload();
  };

  if (!lodgmentReservation) {
    return <div></div>;
  }

  return (
    <div
      onClick={closeModal}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(2px)",
        width: "100vw",
        height: "100vh",
        zIndex: 1,
      }}
    >
      <div
        onClick={onInnerContainer}
        style={{
          backgroundColor: "white",
          position: "fixed",
          top: "25%",
          left: 0,
          right: 0,
          margin: "0 auto",
          width: "fit-content",
          height: "fit-content",
          padding: "3rem 6rem 3rem 6rem",
          zIndex: 2,
        }}
      >
        <div
          onClick={closeModal}
          style={{
            cursor: "pointer",
            fontSize: "2rem",
            position: "absolute",
            top: 10,
            left: 10,
          }}
        >
          X
        </div>
        <div>
          <h3
            style={{ fontSize: "2rem", margin: "0 auto", width: "fit-content" }}
          >
            📆 예약 날짜 변경
          </h3>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "1.5rem",
              marginTop: "2rem",
            }}
          >
            <div>
              <div style={{ display: "flex" }}>
                <label>숙소명:</label>&nbsp;
                <div>{lodgmentReservation.lodgment.name}</div>
              </div>
              <div style={{ display: "flex" }}>
                <label>고객명:</label>&nbsp;
                <div>{lodgmentReservation.userInfo.name}</div>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <label>체크인:</label>&nbsp;
                <input
                  type="date"
                  value={checkinDate}
                  onChange={(e) => {
                    setCheckinDate(e.target.value);
                  }}
                />
              </div>
              <div style={{ marginTop: "1rem" }}>
                <label>체크아웃:</label>&nbsp;
                <input
                  type="date"
                  value={checkoutDate}
                  onChange={(e) => {
                    setCheckoutDate(e.target.value);
                  }}
                />
              </div>
              <div>
                지금 변경하려는 상품이 시프시프인가요?{" "}
                <input
                  type="checkbox"
                  checked={checked}
                  name="secret-price"
                  id="secret-price"
                  onChange={() => {
                    setChecked(!checked);
                  }}
                  style={{ width: "1.5rem", height: "1.5rem" }}
                />
              </div>
              <button onClick={onSubmit} style={{ marginTop: "2rem" }}>
                저장하기
              </button>
            </div>
          </div>
          <br />* 변경할 날짜에 기존 예약 내역이 없는지 확인하고 변경하세요!
          <br />* 슬랙, 유저, 호스트 알림이 모두 발생합니다!
        </div>
      </div>
    </div>
  );
}
