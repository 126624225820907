import { useEffect, useState } from "react";
import { Label, Input, GeoCodeButton, Tag } from "../style";
import { Flex } from "components/style";
import { POST_TYPE_ARR, DISTRICT_ARR } from "constants/post";
import { Button } from "react-bootstrap";
import { stringToPrice } from "utils/price";
import { AMENITY_ARR } from "constants/post";
import { ALERGIC_INGREDIENT_ARR } from "constants/post";
import { s3ConfigPostImg } from "config/s3Config";
import { uploadFile } from "react-s3";
import { priceToString } from "utils/price";

export default function DetailsEditor({ post, setPost, details, setDetails }) {
  const [addingMenu, setAddingMenu] = useState({
    boardImg: "",
    list: [],
    averagePrice: 0,
  });

  const [boardImage, setBoardImage] = useState(null);

  useEffect(() => {
    setAddingMenu(post.menu);
  }, []);

  useEffect(() => {
    console.log(addingMenu);
  }, [addingMenu]);

  useEffect(() => {
    if (
      !addingMenu.boardImg &&
      !addingMenu.list.length &&
      !addingMenu.averagePrice
    )
      return;

    const tempAddingMenu = { ...addingMenu };

    tempAddingMenu.list = tempAddingMenu.list.filter((el) => el.name);

    setPost({ ...post, menu: tempAddingMenu });
  }, [addingMenu]);

  const decreaseMenuInput = () => {
    const tempAddingMenu = { ...addingMenu };
    tempAddingMenu.list.pop();

    setAddingMenu(tempAddingMenu);
  };

  const increaseMenuInput = () => {
    const tempAddingMenu = { ...addingMenu };
    tempAddingMenu.list.push({
      name: "",
      price: 0,
      isSigniture: false,
      isFree: false,
      isChangable: false,
    });

    setAddingMenu(tempAddingMenu);
  };

  const onChangeMenu = (type, idx, value) => {
    let tempAddingMenu = { ...addingMenu };
    if (type === "name") {
      tempAddingMenu.list[idx].name = value;
    }
    if (type === "price") {
      tempAddingMenu.list[idx].price = value ? stringToPrice(value) : 0;
    }
    if (type === "isSigniture") {
      tempAddingMenu.list[idx].isSigniture = value;
    }
    if (type === "isFree") {
      tempAddingMenu.list[idx].isFree = value;
    }
    if (type === "isChangable") {
      tempAddingMenu.list[idx].isChangable = value;
    }

    setAddingMenu(tempAddingMenu);
  };

  const onClickAmenity = (option) => {
    let tempAmenities = [...post.amenities];
    const index = tempAmenities.indexOf(option);
    if (index < 0) {
      tempAmenities.push(option);
    } else {
      tempAmenities.splice(index, 1);
    }
    setPost({ ...post, amenities: tempAmenities });
  };

  const onClickIngredient = (option) => {
    console.log(option);
    let tempIngredient = [...post.details.ingredient];
    const index = tempIngredient.indexOf(option);
    if (index < 0) {
      tempIngredient.push(option);
    } else {
      tempIngredient.splice(index, 1);
    }
    console.log(tempIngredient);
    setDetails({ ...post.details, ingredient: tempIngredient });
  };

  const addImageToS3 = async () => {
    try {
      s3ConfigPostImg["dirName"] = post.id ? post.id : "no-postId";

      const tempBoardImage = [];
      for (const image of boardImage) {
        const res = await uploadFile(image, s3ConfigPostImg);
        tempBoardImage.push(
          `https://${res.bucket}.s3.amazonaws.com/${res.key}`
        );
      }
      setAddingMenu({ ...addingMenu, boardImg: tempBoardImage });
    } catch (error) {
      alert("에러 발생");
      console.error(error);
    }
  };

  return (
    <div
      style={{
        marginTop: 30,
        marginLeft: 120,
        width: "45%",
      }}
    >
      <form>
        <h2 style={{ marginBottom: 30 }}>상세 정보</h2>
        <div style={{ minHeight: 250 }}>
          <div style={{ marginBottom: 10 }}>
            <Label style={{ width: 70, fontSize: 23 }}>
              {post.type === "맛집" || post.type === "카페"
                ? "메뉴판"
                : "입장료"}
            </Label>
            <Button
              onClick={decreaseMenuInput}
              style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
            >
              -
            </Button>
            <Button
              onClick={increaseMenuInput}
              style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
            >
              +
            </Button>
          </div>

          {addingMenu.list.map((el, idx) => {
            return (
              <div>
                <Label style={{ width: 50 }}>메뉴{idx + 1}</Label>
                <input
                  type="text"
                  placeholder="메뉴 이름"
                  value={addingMenu.list[idx].name}
                  onChange={(e) => {
                    onChangeMenu("name", idx, e.target.value);
                  }}
                />
                <input
                  type="text"
                  placeholder="가격"
                  defaultValue={priceToString(addingMenu.list[idx].price)}
                  onChange={(e) => {
                    onChangeMenu("price", idx, e.target.value);
                  }}
                />
                <label style={{ marginLeft: 20 }}>대표메뉴</label>
                <input
                  type="checkbox"
                  checked={addingMenu.list[idx].isSigniture}
                  onChange={(e) =>
                    onChangeMenu("isSigniture", idx, e.target.checked)
                  }
                />
                <label style={{ marginLeft: 20 }}>무료</label>
                <input
                  type="checkbox"
                  checked={addingMenu.list[idx].isFree}
                  onChange={(e) =>
                    onChangeMenu("isFree", idx, e.target.checked)
                  }
                />
                <label style={{ marginLeft: 20 }}>변동</label>
                <input
                  type="checkbox"
                  checked={addingMenu.list[idx].isChangable}
                  onChange={(e) =>
                    onChangeMenu("isChangable", idx, e.target.checked)
                  }
                />
              </div>
            );
          })}

          <div>
            <Label style={{ marginRight: 10, marginBottom: 20, marginTop: 40 }}>
              메뉴판 이미지
            </Label>
            <input
              type="file"
              multiple
              onChange={(e) => {
                setBoardImage(e.target.files);
              }}
            />
            <Button onClick={addImageToS3} style={{ margin: "0 10px" }}>
              등록
            </Button>
            {addingMenu.boardImg.length ? (
              <>
                <span>등록 완료</span>
                <Flex>
                  {addingMenu.boardImg.map((url) => {
                    return <img src={url} style={{ width: 150 }} />;
                  })}
                </Flex>
              </>
            ) : null}
          </div>
          <div>
            <Label style={{ marginRight: 20, width: 150 }}>
              인당 평균 가격대
            </Label>
            <input
              type="text"
              defaultValue={priceToString(post.menu.averagePrice)}
              onChange={(e) => {
                setAddingMenu({
                  ...addingMenu,
                  averagePrice: e.target.value
                    ? stringToPrice(e.target.value)
                    : 0,
                });
              }}
            />
          </div>
        </div>
        <div>
          <Label style={{ display: "block", marginTop: 30, fontSize: 23 }}>
            편의시설
          </Label>
          <Flex style={{ flexFlow: "wrap" }}>
            {AMENITY_ARR.map((el) => {
              return (
                <Tag
                  onClick={() => {
                    onClickAmenity(el);
                  }}
                  style={{
                    backgroundColor: post.amenities.includes(el)
                      ? "skyblue"
                      : "#e2e2e2",
                  }}
                >
                  {el}
                </Tag>
              );
            })}
          </Flex>
        </div>
        {details && (
          <div style={{ marginTop: 70 }}>
            <Label style={{ width: 500, fontSize: 23 }}>장소 세부 사항</Label>
            <div>
              <Label>예약 링크</Label>
              <input
                type="text"
                style={{ width: 300 }}
                value={details.reservationLink}
                onChange={(e) => {
                  let tempDetails = { ...details };
                  tempDetails.reservationLink = e.target.value;
                  setDetails(tempDetails);
                }}
              />
            </div>
            {post.type === "카페" || post.type === "맛집" ? (
              <div>
                <div>
                  <Label>식사 방법</Label>
                  <label htmlFor="takeout">테이크 아웃</label>
                  <input
                    type="checkbox"
                    name="takeOut"
                    checked={details.method?.takeOut}
                    onChange={(e) => {
                      let tempDetails = { ...details };
                      tempDetails.method.takeOut = e.target.checked;
                      setDetails(tempDetails);
                    }}
                  />
                  <label htmlFor="dineIn" style={{ marginLeft: 20 }}>
                    매장 내 식사
                  </label>
                  <input
                    type="checkbox"
                    name="dineIn"
                    checked={details.method?.dineIn}
                    onChange={(e) => {
                      let tempDetails = { ...details };
                      tempDetails.method.dineIn = e.target.checked;
                      setDetails(tempDetails);
                    }}
                  />
                </div>
                <div>
                  <Label htmlFor="">식재료</Label>
                  <Flex style={{ flexFlow: "wrap" }}>
                    {ALERGIC_INGREDIENT_ARR.map((el) => {
                      return (
                        <Tag
                          style={{
                            cursor: "pointer",
                            backgroundColor: details.ingredient.includes(el)
                              ? "skyblue"
                              : "#e2e2e2",
                          }}
                          onClick={() => {
                            onClickIngredient(el);
                          }}
                        >
                          {el}
                        </Tag>
                      );
                    })}
                  </Flex>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <Label>대표 볼거리</Label>
                  <input
                    type="text"
                    style={{ width: 200 }}
                    value={details.mainContent}
                    onChange={(e) => {
                      let tempDetails = { ...details };
                      tempDetails.mainContent = e.target.value;
                      setDetails(tempDetails);
                    }}
                  />
                </div>
                <div>
                  <Label>평균 이용시간</Label>
                  <input
                    type="number"
                    min={0}
                    max={5}
                    step={0.5}
                    value={details.usageTime}
                    onChange={(e) => {
                      let tempDetails = { ...details };
                      tempDetails.usageTime = e.target.value;
                      setDetails(tempDetails);
                    }}
                  />
                  <span>(단위: 시간)</span>
                </div>
              </div>
            )}
          </div>
        )}
      </form>
    </div>
  );
}
