import * as React from "react";
import BtnMsg from "components/Msgs/BtnMsg";
import MultipleChoiceMsg from "components/Msgs/MultipleChoiceMsg";
import PhotoMsg from "components/Msgs/PhotoMsg";
import SingleChoiceMsg from "components/Msgs/SingleChoiceMsg";
import SubjectiveFormMsg from "components/Msgs/SubjectiveFormMsg";
import TextMsg from "components/Msgs/TextMsg";
import { MSG_TYPES } from "constants/msgTypes";
import { Socket } from "socket.io-client";
import * as S from "./ChatRoom.style";

export interface IMsgContainerProps {
  socket: Socket;
  type: string;
  content;
  role: "user" | "concierge";
  onZoom: (imgSrc: string) => void;
}

export default function MsgContainer({
  socket,
  type,
  content,
  role,
  onZoom,
}: IMsgContainerProps) {
  switch (type) {
    case MSG_TYPES.TEXT_MSG:
      return <TextMsg content={content} role={role} />;

    case MSG_TYPES.IMG_MSG:
      return (
        <S.MsgWrapper role={role}>
          <S.ImgMsg
            src={content.location}
            onClick={() => {
              onZoom(content.location);
            }}
          >
            {content.text}
          </S.ImgMsg>
        </S.MsgWrapper>
      );

    case MSG_TYPES.FILE_MSG:
      return (
        <S.MsgWrapper role={role}>
          <S.FileMsg>
            <S.FileDownloadImg>
              <a href={content.location}>
                <img
                  src="/icon/btn_filedownload_complete.png"
                  style={{ width: "100%", height: "100%" }}
                />
              </a>
            </S.FileDownloadImg>
            <div
              style={{
                padding: "0.3rem 0 0 1rem",
                fontSize: "1.1rem",
                width: "fit-content",
              }}
            >
              {content.name}
            </div>
          </S.FileMsg>
        </S.MsgWrapper>
      );

    case MSG_TYPES.SINGLE_CHOICE:
      return <SingleChoiceMsg socket={socket} content={content} role={role} />;

    case MSG_TYPES.MULTIPLE_CHOICE:
      return (
        <MultipleChoiceMsg socket={socket} content={content} role={role} />
      );

    case MSG_TYPES.SUBJECTIVE:
      return (
        <SubjectiveFormMsg socket={socket} content={content} role={role} />
      );

    case MSG_TYPES.PHOTO_MSG:
      return <PhotoMsg content={content} role={role} />;

    case MSG_TYPES.BTN_MSG:
      return <BtnMsg content={content} role={role} />;

    default:
      return (
        <S.MsgWrapper>
          잘못된 메시지 형식입니다. 개발자에게 문의하세요~!
        </S.MsgWrapper>
      );
      break;
  }
}
