import * as S from "./input.style";
import InputMain from "./Main";

// deprecated
export interface InputProps {
  type?: string;
  placeholder?: string;
  disabled?: boolean;
  defaultValue?: string;
  endDecorator?: React.ReactNode;
  startDecorator?: React.ReactNode;
  value?: string;
  name?: string;
  id?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  color?: string;
  backgroundColor?: string;
  width?: string;
  maxLength?: number;
  checked?: boolean;
}

// Main
export function Input({
  type = "text",
  placeholder,
  disabled,
  defaultValue,
  endDecorator,
  startDecorator,
  value,
  name,
  id,
  onChange,
  onBlur,
  color,
  backgroundColor,
  width,
  maxLength,
  checked,
}: InputProps) {
  return (
    <InputMain>
      <S.InputWrapper
        color={color}
        backgroundColor={backgroundColor}
        width={width}
      >
        {startDecorator}
        <CustomInput
          type={type}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          defaultValue={defaultValue}
          value={value}
          id={id}
          onChange={onChange}
          onBlur={onBlur}
          maxLength={maxLength}
          checked={checked}
        />
        {endDecorator}
      </S.InputWrapper>
    </InputMain>
  );
}

function CustomInput({
  type,
  placeholder,
  disabled,
  defaultValue,
  value,
  name,
  id,
  onChange,
  onBlur,
  maxLength,
  checked,
}) {
  return (
    <>
      <S.Input
        type={type}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        maxLength={maxLength}
        checked={checked}
      />
    </>
  );
}

export interface InputLabelProps {
  label?: string;
}

export function InputLabel({ label }: InputLabelProps) {
  return <S.LabelText htmlFor={label}>{label}</S.LabelText>;
}

export interface InputErrorProps {
  error?: string;
}
export function InputError({ error }: InputErrorProps) {
  return <S.ErrorText>{error}</S.ErrorText>;
}

export interface TextareaProps {
  name?: string;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  cols?: number;
}

export function Textarea({
  name,
  value,
  placeholder,
  onChange,
  rows,
  cols,
}: TextareaProps) {
  return (
    <S.Textarea
      name={name}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      rows={rows}
      cols={cols}
    >
      {value}
    </S.Textarea>
  );
}

export interface FileProps {
  name?: string;
  value?: string;
  id?;
  accept?: string;
  multiple?: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  display?: string;
}

export function File({
  name,
  id,
  accept = "image/*",
  multiple = false,
  onChange,
  display,
}: FileProps) {
  return (
    <S.Input
      type="file"
      name={name}
      onChange={onChange}
      id={id}
      key={id}
      accept={accept}
      multiple={multiple}
      display={display}
    />
  );
}
