import { React, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "config/axiosConfig";
import useTitle from "hooks/useTitle";

export default function Signup() {
  useTitle("관리자 가입");
  const history = useHistory();
  const [isLogin, setIsLogin] = useState(false);

  // useEffect(() => {
  //   if (isLogin) {
  //     history.push("/");
  //     history.go(0);
  //   }
  // }, [isLogin]);

  const signup = async (signupInfo) => {
    await axios
      .post(`adminUsers/signup`, signupInfo)
      .then((res) => {
        localStorage.setItem("jwt", res.data.jwt);
        setIsLogin(true);
      })
      .catch((err) => {
        alert("이미 있는 계정입니다.");
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const signupInfo = {
      username: e.target.username.value,
      password: e.target.password.value,
    };
    return signup(signupInfo);
  };

  return (
    <div>
      <h3>관리자 등록 페이지</h3>
      <form onSubmit={onSubmit}>
        <div>Username</div>
        <input type="text" name="username" required></input>
        <div>Password</div>
        <input type="password" name="password" required></input>
        <br></br>
        <button type="submit">관리자 등록하기</button>
      </form>
    </div>
  );
}
