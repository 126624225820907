import React, { useState, useEffect, useRef, useCallback } from "react";
import { useRouter } from "next/router";
import RightWing from "./RightWing";
import LeftWing from "./LeftWing";
import Msg from "./Msg";
import ImgZoom from "./ImgZoom";
import ChatRoomInput from "./ChatRoomInput";
import { joinRoom } from "../../utils/socket/clientEvent";
import { initSocketListening } from "../../utils/socket/setListening";
import { getMessages, getMessagesByLastIndex } from "../../api/messages";
import { getConsult } from "../../api/consult";
import { SOCKET_CONSULT_URL } from "../../constants/url";
import { SERVER_EVENT } from "../../constants/socketEvent";
import { io, Socket } from "socket.io-client";
import { IConsult } from "../../type/consult.type";
import { IMsg } from "../../type/chat.type";
import * as S from "./ChatRoom.style";

export interface IChatRoomProps {
  consultId: string;
  conciergeId: string;
}

export default function ChatRoom({ consultId, conciergeId }: IChatRoomProps) {
  const socket = useRef<Socket>();

  const [msgs, setMsgs] = useState<IMsg[]>([]);
  const [isInitialRender, setIsInitialRender] = useState<boolean>(true);
  const [isDownBtnOn, setIsDownBtnOn] = useState<boolean>(false);
  const [consultInfo, setConsultInfo] = useState<IConsult>();
  const [isShowImgZoom, setShowImgZoom] = useState<boolean>(false);
  const [zooomImgSrc, setZoomImgSrc] = useState<string>();
  const [msgLastIndex, setMsgLastIndex] = useState<number>();
  const [prevScrollHeight, setPrevScrollHeight] = useState<number>();
  const [isFileAdderClicked, setIsFileAdderClicked] = useState(false);

  const msgsRef = useRef<IMsg[]>(msgs);
  const chatBoxRef = useRef<HTMLDivElement>();

  const onChatRoom = useCallback(() => {
    setIsFileAdderClicked(false);
  }, []);

  const onZoom = useCallback((imgSrc: string) => {
    setZoomImgSrc(imgSrc);
    setShowImgZoom(true);
  }, []);

  const onClose = useCallback(() => {
    setShowImgZoom(false);
  }, []);

  const scrollToBottom = useCallback(() => {
    if (!chatBoxRef.current) {
      return;
    }

    const { scrollHeight, clientHeight } = chatBoxRef.current;
    const scrollPoint = scrollHeight - clientHeight;
    chatBoxRef.current.scrollTo(0, scrollPoint);

    if (isDownBtnOn) {
      setIsDownBtnOn(false);
    }
  }, [isDownBtnOn]);

  const onScroll = useCallback(
    ({ target }) => {
      const { scrollTop, clientHeight, scrollHeight } = target;
      if (isDownBtnOn && scrollHeight <= scrollTop + clientHeight + 1) {
        setIsDownBtnOn(false);
      }
      if (msgLastIndex > 1 && scrollTop <= 100) {
        setPrevScrollHeight(scrollHeight);
        const loadMoreMsgs = async () => {
          const { messages: newMsgs, lastIndex } = await getMessagesByLastIndex(
            consultId,
            msgLastIndex
          );
          setMsgs([...newMsgs, ...msgs]);
          setMsgLastIndex(lastIndex);
        };
        loadMoreMsgs();
      }
    },
    [isDownBtnOn, msgLastIndex, msgs, consultId]
  );

  // 입장 시 새로고침
  // useEffect(() => {
  //   if (!location.hash) {
  //     location.hash = "#connected";
  //     setTimeout(() => {
  //       window.location.reload();
  //     }, 300);
  //   }
  // }, []);

  // 유저 입장 처리(socket 연결 + 방입장)
  useEffect(() => {
    socket.current = io(SOCKET_CONSULT_URL, { transports: ["websocket"] });
    joinRoom(socket.current, consultId, conciergeId);

    return () => {
      socket.current.disconnect();
    };
  }, [consultId]);

  // get/set msgs & consultInfo
  useEffect(() => {
    // msg getting logic~~
    const fetchMsgs = async () => {
      const { messages, lastIndex } = await getMessages(consultId);
      setMsgs(messages);
      setMsgLastIndex(lastIndex);
    };
    const fetchConsultInfo = async () => {
      const consultInfoData = await getConsult(consultId);
      setConsultInfo(consultInfoData.consult);
    };
    fetchMsgs();
    fetchConsultInfo();
  }, [consultId]);

  useEffect(() => {
    // 메시지 추가 로드시
    if (prevScrollHeight) {
      const scrollPoint = chatBoxRef.current.scrollHeight - prevScrollHeight;
      chatBoxRef.current.scrollTo(0, scrollPoint);
      setPrevScrollHeight(null);
      return;
    }
    if (msgs.length && isInitialRender) {
      scrollToBottom();
      setIsInitialRender(false);
    }
  }, [msgs]);

  // new msg 이벤트 중복 등록 방지를 위해 ref 활용
  useEffect(() => {
    msgsRef.current = msgs;
  }, [msgs]);

  // socket listening
  useEffect(() => {
    initSocketListening(socket.current);
    const newMsgAction = (msg: IMsg) => {
      const newMsgs = [...msgsRef.current, msg];
      setMsgs(newMsgs);

      const { scrollHeight, scrollTop, clientHeight } = chatBoxRef.current;
      const height = scrollHeight - scrollTop;

      // 자신의 메시지일 경우에 / 화면상 맨위의 메시지보다 낮게 스크롤 했을 때 맨밑으로 스크롤
      if (height <= clientHeight * 2 + 20 || msg.sender.role === "concierge") {
        scrollToBottom();
        return;
      }
      setIsDownBtnOn(true);
    };

    socket.current.on(SERVER_EVENT.NEW_MSG, newMsgAction);
  }, []);

  return (
    <S.ChatPageContainer>
      {consultInfo && <LeftWing consult={consultInfo} />}
      <S.ChatSection>
        <div>
          <S.ChatRoomTitle>{consultInfo?.userPhone}</S.ChatRoomTitle>
        </div>
        <S.ChatRoomWrapper id="wrapper">
          <S.ChatRoom onClick={onChatRoom}>
            <S.MsgContainer id="chatbox" ref={chatBoxRef} onScroll={onScroll}>
              {msgs.map(({ _id, type, sender, content, createdAt }) => (
                <Msg
                  socket={socket.current}
                  key={_id}
                  messageId={_id}
                  type={type}
                  sender={sender}
                  content={content}
                  createdAt={createdAt}
                  onZoom={onZoom}
                />
              ))}
            </S.MsgContainer>
            {socket.current && consultInfo && (
              <ChatRoomInput
                socket={socket.current}
                isFileAdderClicked={isFileAdderClicked}
                setIsFileAdderClicked={setIsFileAdderClicked}
                userPhone={consultInfo.userPhone}
              />
            )}
          </S.ChatRoom>
          {isShowImgZoom && (
            <ImgZoom onClose={onClose} zoomImgSrc={zooomImgSrc} />
          )}
          {isDownBtnOn && (
            <S.DownBtnWrapper>
              <S.DownBtn onClick={scrollToBottom}>
                새로운 메시지가 도착했습니다.
              </S.DownBtn>
            </S.DownBtnWrapper>
          )}
        </S.ChatRoomWrapper>
      </S.ChatSection>
      <RightWing socket={socket.current} />
    </S.ChatPageContainer>
  );
}
