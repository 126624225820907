import React from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import * as S from "./index.style";
import { deleteHostingLodgment } from "api/user";

export default function HostingDraggableList({
  hostingList,
  setHostingList,
  onDrop,
  userId,
}) {
  return (
    <DragDropContext onDragEnd={onDrop}>
      <Droppable droppableId="list-container">
        {(provided) => (
          <div
            className="list-container"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {hostingList.map(({ name, _id }, index) => (
              <Draggable
                key={`${_id}-${index}`}
                draggableId={`${_id}-${index}`}
                index={index}
              >
                {(provided) => (
                  <div
                    className="item-container"
                    ref={provided.innerRef}
                    {...provided.dragHandleProps}
                    {...provided.draggableProps}
                  >
                    <HostingItem
                      name={name}
                      _id={_id}
                      userId={userId}
                      hostingList={hostingList}
                      setHostingList={setHostingList}
                      index={index}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

function HostingItem({
  name,
  _id,
  userId,
  hostingList,
  setHostingList,
  index,
}) {
  const deleteHandler = async () => {
    try {
      if (window.confirm(`${name}을 호스팅 목록에서 제외하시겠습니까?`)) {
        await deleteHostingLodgment(userId, _id, index);
        alert("삭제되었습니다.");
        //remove index from hostingList
        const newHostingList = [...hostingList];
        newHostingList.splice(index, 1);
        setHostingList(newHostingList);
      }
    } catch (error) {}
  };

  return (
    <S.Card>
      <S.Content>
        <span className="name">{name}</span>
        <span className="id">{_id}</span>
        <span>인덱스: {index}</span>
      </S.Content>
      <S.Button onClick={deleteHandler}>삭제</S.Button>
    </S.Card>
  );
}
