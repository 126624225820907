import { myAxios } from "./myAxios";
import { FINEHOST_USER_PATH } from "../constants/path";

interface IFinehostUser {
  phone: string;
  hostingLodgments: string[];
}

interface IFinehostUserData {
  msg: string;
  user: IFinehostUser;
}

export const createFinehostUser = async (phone: string) => {
  const path = FINEHOST_USER_PATH + `/phone/${phone}`;
  const res = await myAxios.post({ path });
  return res.data;
};

export const getFinehostUserByPhone = async (
  phone: string
): Promise<IFinehostUserData> => {
  const path = FINEHOST_USER_PATH + `/phone/${phone}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const updateFinehostUser = async (userId: string, data: any) => {
  const path = FINEHOST_USER_PATH + `/user/${userId}`;
  const res = await myAxios.put({ path, data });
  return res.data;
};
