import * as React from "react";
import Forms from "./forms";
import FormList from "./FormList";
import { getMsgTemplates } from "api/msgTemplate";
import { Socket } from "socket.io-client";
import { MSG_TYPES } from "constants/msgTypes";
import * as S from "./RightWing.style";

export interface IRightWingProps {
  socket: Socket;
}

export default function RightWing({ socket }: IRightWingProps) {
  const [selectedMsgTemplate, setSelectedMsgTemplate] = React.useState<any>({
    type: MSG_TYPES.SINGLE_CHOICE,
  });
  const [msgTemplateData, setMsgTemplateData] = React.useState<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    Record<string, any>[]
  >([]);
  const [msgTemplateList, setMsgTemplateList] = React.useState<string[]>([]);

  React.useEffect(() => {
    const fetchMsgTemplate = async () => {
      const { messageTemplates } = await getMsgTemplates();
      setMsgTemplateData(messageTemplates);

      const msgTemplateList = messageTemplates.map(({ _id, title }) => ({
        _id,
        title,
      }));
      setMsgTemplateList(msgTemplateList);
    };
    fetchMsgTemplate();
  }, []);

  return (
    <S.RightWingContainer>
      <FormList
        msgTemplates={msgTemplateData}
        setSelectedMsgTemplate={setSelectedMsgTemplate}
      />
      <S.FormContainer>
        <Forms
          socket={socket}
          msgTemplateList={msgTemplateList}
          selectedMsgTemplate={selectedMsgTemplate}
        />
      </S.FormContainer>
    </S.RightWingContainer>
  );
}
