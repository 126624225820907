import { useQuery, useMutation, useQueryClient } from 'react-query';
import { 
  getInspections, 
  updateInspection, 
  inspectionKeys, 
  Inspection, 
  InspectionResponse 
} from '../api/inspection';

// 검수 목록 조회 훅
export const useInspections = (page = 1, pageSize = 50) => {
  return useQuery<InspectionResponse, Error>(
    inspectionKeys.list({ page, pageSize }),
    () => getInspections(page, pageSize),
    {
      keepPreviousData: true,
      staleTime: 30000, // 30초
    }
  );
};

// 검수 항목 업데이트 훅
export const useUpdateInspection = () => {
  const queryClient = useQueryClient();
  
  return useMutation(
    ({ id, data }: { id: string; data: Partial<Inspection> }) => 
      updateInspection(id, data),
    {
      // 성공 시 관련 쿼리 무효화
      onSuccess: (_, variables) => {
        // 현재 페이지의 데이터를 가져와서 낙관적 업데이트 수행
        const currentQueries = queryClient.getQueriesData(inspectionKeys.lists());
        
        // 모든 관련 쿼리 무효화
        currentQueries.forEach(([queryKey]) => {
          queryClient.invalidateQueries(queryKey);
        });
      },
    }
  );
}; 