import styled from "styled-components";

export const LeftWingContainer = styled.div`
  width: 33%;
  padding: 1rem;
  border: 1px solid black;
  height: 85vh;
`;

export const ResponseContainer = styled.div`
  height: 55%;
  overflow: scroll;
`;

export const Response = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: lightgray;
  margin: 1rem 0;
  padding: 0.5rem;
  font-size: 0.9rem;
  :hover {
    cursor: pointer;
  }
`;

export const MemoContainer = styled.div`
  background-color: lightgray;
  height: 38%;
  position: relative;
  padding: 0.5rem;
`;

export const MemoTextarea = styled.textarea`
  height: 90%;
  width: 100%;
`;

export const SaveButton = styled.button`
  position: absolute;
  bottom: 0px;
  right: 10px;
`;
