import styled from "styled-components";

export const HostingList = styled.div`
  margin-top: 20px;
  width: 50%;
  max-width: 400px;
`;

export const Card = styled.div`
  padding: 8px 12px;
  margin-bottom: 5px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  display: flex;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Button = styled.div``;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
`;

export const InputBox = styled.div`
  margin-right: 10px;
  label {
    margin-right: 10px;
    font-weight: 600;
  }
  button {
    margin-left: 10px;
  }
`;

export const HelperText = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
`;

export const Form = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ScrollHandleButton = styled.div`
  cursor: pointer;
  color: orange;
  font-size: 17px;
  font-weight: 700;
  padding: 8px 12px;
  text-align: center;
  span.scrollText {
    margin-right: 10px;
  }
`;

export const HostingListTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 3px;
`;

export const HostingListSubTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
`;
