import * as React from "react";
import SubjectiveFormMsg from "components/Msgs/SubjectiveFormMsg";
import NextMsgInput from "./common/NextMsgInput";
import IsFirstMsgInput from "./common/isFirstMsgInput";
import { onDeleteFormBtn } from "./utils/onDeleteFormBtn";
import {
  onSaveFormBtn,
  onSubjectiveFormSendBtn,
  onUpdateFormBtn,
} from "./utils/onFormBtn";
import * as S from "./forms.style";
import { Socket } from "socket.io-client";
import { MSG_TYPES } from "constants/msgTypes";
import FormControlBtns from "./common/FormControlBtns";

export interface ISubjectiveFormProps {
  socket: Socket;
  selectedMsgTemplate;
  msgTemplateList;
}

export default function SubjectiveForm({
  socket,
  selectedMsgTemplate,
  msgTemplateList,
}: ISubjectiveFormProps) {
  const [formTitle, setFormTitle] = React.useState("");
  const [question, setQuestion] = React.useState("");
  const [placeholder, setPlaceholder] = React.useState("");
  const [btnText, setBtnText] = React.useState("");

  const [nextMsgTemplate, setNextMsgTemplate] = React.useState("");
  const [isFirstMsg, setIsFirstMsg] = React.useState(false);

  const {
    _id,
    content,
    nextTemplate,
    title,
    isFirstMsg: isFirst,
  } = selectedMsgTemplate;
  const isSavedTemplate = !!_id;

  React.useEffect(() => {
    const { question, placeholder, btnText } = content;
    setFormTitle(title);
    setQuestion(question);
    setPlaceholder(placeholder);
    setBtnText(btnText);
    setNextMsgTemplate(nextTemplate);
    setIsFirstMsg(isFirst);
  }, [selectedMsgTemplate]);

  const formData = {
    type: MSG_TYPES.SUBJECTIVE,
    title: formTitle,
    content: {
      question,
      placeholder,
      btnText,
    },
    nextTemplate: nextMsgTemplate,
    isFirstMsg,
  };

  return (
    <S.FormContainer>
      <S.InputLayout>
        <S.Label htmlFor="formTitle">폼 이름:</S.Label>
        <S.Input
          id="formTitle"
          value={formTitle}
          onChange={(e) => {
            setFormTitle(e.target.value);
          }}
        />
      </S.InputLayout>
      <S.FormPreviewSection>
        <SubjectiveFormMsg
          content={{
            templateTitle: formTitle,
            question: question,
            placeholder: placeholder,
            btnText: btnText,
          }}
          role="concierge"
        />
      </S.FormPreviewSection>
      <S.FormInputSection>
        <S.InputLayout>
          <S.Label htmlFor="questionInput">질문 내용:</S.Label>
          <S.Input
            id="questionInput"
            value={question}
            onChange={(e) => {
              setQuestion(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="placeholder">플레이스홀더:</S.Label>
          <S.Input
            id="placeholder"
            value={placeholder}
            onChange={(e) => {
              setPlaceholder(e.target.value);
            }}
          />
        </S.InputLayout>
        <S.InputLayout>
          <S.Label htmlFor="btnText">버튼 내용:</S.Label>
          <S.Input
            id="btnText"
            value={btnText}
            onChange={(e) => {
              setBtnText(e.target.value);
            }}
          />
        </S.InputLayout>
        <NextMsgInput
          currentMsgTemplateTitle={title}
          nextMsgTemplate={nextMsgTemplate}
          setNextMsgTemplate={setNextMsgTemplate}
          msgTemplateList={msgTemplateList}
        />
        <IsFirstMsgInput
          isFirstMsg={isFirstMsg}
          setIsFirstMsg={setIsFirstMsg}
        />
        <FormControlBtns
          msgType={MSG_TYPES.SUBJECTIVE}
          socket={socket}
          isSavedTemplate={isSavedTemplate}
          formData={formData}
          id={_id}
        />
      </S.FormInputSection>
    </S.FormContainer>
  );
}
