import dayjs from "../lib/dayjs";
import { isElementInArray } from "./array";

interface Lodgment {
  _id: string;
  name: string;
  isChargeAddPersonCostForEachDay: boolean;
}

interface Commission {
  startDate: string | null;
  endDate: string | null;
  commission: number;
}

interface NaverCommissionInfo {
  directSearchCommission: number;
  algorithmCommission: number;
}

///////////////////////////////////////////////////////////import dayjs from "../lib/dayjs";

const FALLBACK_COMMISSION = 0;

const CARD_COMMISSION_RATE = 3.3;

type directSearchCommission = "0.00";
type algorithmCommission = "3.96";
type NaverCommissionRate = directSearchCommission | algorithmCommission;

type algorithmCommissionFromVacatio = 9.9;

type WebsiteCommission = 3.3;

interface Lodgment {
  _id: string;
  name: string;
  isChargeAddPersonCostForEachDay: boolean;
}

type AllDate = null; // 모든 날짜로 적용된다는 의미

interface CommissionByDateRange {
  startDate: string | AllDate;
  endDate: string | AllDate;
  commission?: number;
  settlementBasis?: "settlementDate" | "paymentDate";
}

interface NaverCommissionInfoFromVacatio {
  directSearchCommission: number;
  algorithmCommission: number;
}

interface RealNaverCommissionInfo {
  directSearchCommission: directSearchCommission;
  algorithmCommission: algorithmCommission;
}

interface PlatformCommission {
  naver: NaverCommissionInfoFromVacatio;
  [key: string]: any;
}

interface Settlement {
  accountNumber: string;
  bank: string;
  commission: number;
  commissions: CommissionByDateRange[];
  createdAt: string;
  depositor: string;
  isEarlySettlement: boolean;
  lodgment: string;
  vat: number;
  platformCommissions: PlatformCommission;
  [key: string]: any;
}

interface ReservaionBasicInfo {
  name: string;
  start: string;
  end: string;
  price: number;
  defaultHeadcount: number;
  [key: string]: any;
}
interface Reservation {
  payOID: string;
  lodgment: Lodgment;
  settlement: Settlement;
  basicInfo: ReservaionBasicInfo;
  userInfo: {
    name: string;
    phone: string;
  };
  naverCommissionRate: NaverCommissionRate;
  settlementDate: string;
  status: "confirmed" | "partiallyRefunded" | "refused";
  totalPrice: number;
  createdAt: string;

  platform: {
    type: "influencer" | "naver" | "finestay" | "website" | "finereview";
    id: string;
  };
  [key: string]: any;
}

// const REAL_NAVER_COMMISSION: RealNaverCommissionInfo = {
//   directSearchCommission: "0.00",
//   algorithmCommission: "3.96",
// };
const NAVER_COMMISSION_FROM_FINESTAY: NaverCommissionInfoFromVacatio = {
  directSearchCommission: 3.3,
  algorithmCommission: 9.9,
};

const WEBSITE_COMMISSION: WebsiteCommission = 3.3;

const NAVER_COMMISSION_MAPPING = {
  "0.00": "directSearchCommission",
  "3.96": "algorithmCommission",
};

export const defaultCommissionsByDateRange = [
  {
    startDate: null,
    endDate: null,
    commission: 9.9,
    settlementBasis: "settlementDate" as const,
  },
];

export const isNaverReservation = (reservation: Reservation): boolean => {
  const { payOID } = reservation;
  return payOID === "naver";
};

export const getNaverCommission = (
  settlementInfo: Settlement,
  realNaverCommissionRate: NaverCommissionRate
): number => {
  const {
    platformCommissions: { naver },
  } = settlementInfo;
  const matchingKey = NAVER_COMMISSION_MAPPING[realNaverCommissionRate];
  return naver[matchingKey] || 3.3;
};

const setDynamicVACATIOCommissionInfo = (
  settlementDateString: string,
  createdAt: string,
  commissionsByDateRange: CommissionByDateRange[]
): CommissionByDateRange | null => {
  const filtered = commissionsByDateRange.filter(
    ({ startDate, endDate }) => startDate && endDate
  );

  const settlementDate = dayjs(settlementDateString);
  const createdDate = dayjs(createdAt);

  const dynamicInfo = filtered.find(
    ({ startDate, endDate, settlementBasis = "settlementDate" }) => {
      const start = dayjs(startDate);
      const end = dayjs(endDate);
      const INCLUDE_START_AND_END = "[]";
      const DAY_UNIT_COMPARISON = "day"; // 일단위로 날짜 비교

      // 결제일 기준이면, 결제일로 비교
      if (settlementBasis === "paymentDate") {
        return createdDate.isBetween(
          start,
          end,
          DAY_UNIT_COMPARISON,
          INCLUDE_START_AND_END
        );
      }

      return settlementDate.isBetween(
        start,
        end,
        DAY_UNIT_COMPARISON,
        INCLUDE_START_AND_END
      );
    }
  );
  return dynamicInfo || null;
};

const setStaticVACATIOCommissionInfo = (
  commissionsByDateRange: CommissionByDateRange[]
) =>
  commissionsByDateRange.find(
    ({ startDate, endDate }) => startDate === null && endDate === null
  );

// 변동수수료, 고정 수수료, 웹사이트 수수료 등을 모두 고려해야 한다.
export const getFinalVACATIOCommission = (reservation: Reservation): number => {
  const {
    platform: { type = "" } = {},
    settlementDate: settlemtnDateString,
    createdAt,
    settlement: {
      commissions: commissionsByDateRange = defaultCommissionsByDateRange,
    } = {},
  } = reservation;

  if (type === "website") {
    return WEBSITE_COMMISSION;
  }

  const dynamicCommissionInfo = setDynamicVACATIOCommissionInfo(
    settlemtnDateString,
    createdAt,
    commissionsByDateRange
  );
  const staticCommissionInfo = setStaticVACATIOCommissionInfo(
    commissionsByDateRange
  );
  return dynamicCommissionInfo?.commission || staticCommissionInfo?.commission;
};

export const getFinalInfluencerCommission = (
  reservation: Reservation
): number => {
  const {
    lodgment,
    platform: { type = "" } = {},
    settlementDate: settlemtnDateString,
    influencerSettlementInfo: {
      commissions: commissionsByDateRange = defaultCommissionsByDateRange,
    } = {},
    createdAt,
    influencerJoinRoom,
  } = reservation;

  if (lodgment._id === influencerJoinRoom?.room) {
    return 0;
  }

  const dynamicCommissionInfo = setDynamicVACATIOCommissionInfo(
    settlemtnDateString,
    createdAt,
    commissionsByDateRange
  );

  const staticCommissionInfo = setStaticVACATIOCommissionInfo(
    commissionsByDateRange
  );

  // ?? : null 그리고 undefined 체크
  return dynamicCommissionInfo?.commission ?? staticCommissionInfo?.commission;
};

export const calculateCommissionRateIncludingVAT = (commissionRate: number) => {
  const VAT_RATE = 10;
  return commissionRate * (1 + VAT_RATE / 100);
};

const calculateNaverSettlementAmount = (reservation: Reservation): number => {
  const {
    status,
    totalPrice,
    naverCommissionRate,
    partiallyRefundedAmount,
    settlement,
  } = reservation;
  console.log("reservation -1", reservation);
  const commissionRate = getNaverCommission(settlement, naverCommissionRate);
  const commissionRateIncludingVAT =
    calculateCommissionRateIncludingVAT(commissionRate);

  const calculateAmount = (price: number) =>
    price * (1 - commissionRateIncludingVAT / 100);

  if (status === "confirmed") {
    return calculateAmount(totalPrice);
  }
  if (status === "partiallyRefunded") {
    const actualPayPrice = totalPrice - partiallyRefundedAmount;
    return calculateAmount(actualPayPrice);
  }
  // 2024-07-18 이전 legacy 데이터 처리
  if (status === "refused") {
    return calculateAmount(totalPrice);
  }
};

export const hasConfirmedReservation = (
  reservations: Reservation[],
  refundDataPayOID: string
): boolean => {
  return !!reservations.find(
    ({ payOID, status }) =>
      status === "confirmed" && payOID === refundDataPayOID
  );
};

const calculateVacatioSettlementAmount = (
  reservation: Reservation,
  reservations: Reservation[]
): number => {
  const {
    status,
    payOID,
    totalPrice,
    payBackPrice = 0,
    payBackPriceForHost = 0,
    partiallyRefundedAmount = 0,
    partiallyRefundedAmountForHost = 0,
    basicInfo: { couponDiscount = 0, codeDiscount = 0 },
  } = reservation;

  const commissionRate = getFinalVACATIOCommission(reservation);

  const commissionRateIncludingVAT =
    calculateCommissionRateIncludingVAT(commissionRate);

  const calculateEffectiveTotalPrice = (refundPrice: number) =>
    totalPrice + couponDiscount + codeDiscount - refundPrice;

  const calculateAmount = (effectiveTotalPrice: number) =>
    effectiveTotalPrice * (1 - commissionRateIncludingVAT / 100);

  if (status === "confirmed") {
    const refundPrice = payBackPriceForHost || payBackPrice;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);
    return calculateAmount(effectiveTotalPrice);
  }
  if (status === "partiallyRefunded") {
    const refundPrice =
      partiallyRefundedAmountForHost || partiallyRefundedAmount;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);

    if (hasConfirmedReservation(reservations, payOID)) {
      return 0;
    } else {
      return calculateAmount(effectiveTotalPrice);
    }
  }
  if (status === "refused") {
    return 0;
  }
};

export const calculateInfluencerSettlementAmount = (
  reservation,
  reservations
): number => {
  const {
    status,
    totalPrice,
    payBackPrice = 0,
    payBackPriceForHost = 0,
    partiallyRefundedAmount = 0,
    partiallyRefundedAmountForHost = 0,
    payOID,
    basicInfo: { couponDiscount = 0, codeDiscount = 0 },
    settlementDate,
    settlement: {
      commissions: commissionsByDateRange = defaultCommissionsByDateRange,
    } = {},
    influencerSettlementInfo,
    influencerJoinRoom: { createdAt = "", room = "", site = "" } = {},
  } = reservation;

  const {
    commissions:
      influencerCommissionsByDateRange = defaultCommissionsByDateRange,
  } = influencerSettlementInfo || {};

  // 인플루언서 수수료 정산 정보가 없음
  if (!influencerCommissionsByDateRange) {
    return 0;
  }

  // ex) 파인이 50정도의 수수료 비율 부과
  const influencerCommissionRate = getFinalInfluencerCommission(reservation);

  // ex) 일반적으로 9.9%의 수수료 부과
  const commissionRate = getFinalVACATIOCommission(reservation);

  // 10.89
  const commissionRateIncludingVAT =
    calculateCommissionRateIncludingVAT(commissionRate);

  // 10.89 - 3.3
  const remainCommissionRate =
    commissionRateIncludingVAT -
    // - 3.3;
    calculateCommissionRateIncludingVAT(CARD_COMMISSION_RATE);

  // 중개 수수료 매출이 없는 경우도 존재함
  if (remainCommissionRate <= 0) {
    return 0;
  }

  const calculateEffectiveTotalPrice = (refundPrice: number) =>
    totalPrice + couponDiscount + codeDiscount - refundPrice;

  const calculateCommissionRevenue = (effectiveTotalPrice: number) =>
    effectiveTotalPrice * (remainCommissionRate / 100);

  const calculateSettlementAmount = (commissionRevenue: number) =>
    commissionRevenue * (1 - influencerCommissionRate / 100);

  if (status === "confirmed") {
    const refundPrice = payBackPriceForHost || payBackPrice;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);
    const commissionRevenue = calculateCommissionRevenue(effectiveTotalPrice);
    return calculateSettlementAmount(commissionRevenue);
  }

  if (status === "partiallyRefunded") {
    const refundPrice =
      partiallyRefundedAmountForHost || partiallyRefundedAmount;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);
    const commissionRevenue = calculateCommissionRevenue(effectiveTotalPrice);

    if (hasConfirmedReservation(reservations, payOID)) {
      return 0;
    }

    return calculateSettlementAmount(commissionRevenue);
  }

  if (status === "refused") {
    return 0;
  }

  return 0;
};

/**
 * @description 중개 수수료 매출 계산하기
 */
export const calculateCommissionRevenue = (reservation, reservations) => {
  const {
    status,
    totalPrice,
    payBackPrice = 0,
    payBackPriceForHost = 0,
    partiallyRefundedAmount = 0,
    partiallyRefundedAmountForHost = 0,
    payOID,
    basicInfo: { couponDiscount = 0, codeDiscount = 0 },
    influencerSettlementInfo,
  } = reservation;
  const { commissions: influencerCommissionsByDateRange } =
    influencerSettlementInfo || {};

  // 인플루언서 수수료 정산 정보가 없음
  if (!influencerCommissionsByDateRange) {
    return 0;
  }

  // ex) 일반적으로 9.9%의 수수료 부과
  const commissionRate = getFinalVACATIOCommission(reservation);

  // 10.89
  const commissionRateIncludingVAT =
    calculateCommissionRateIncludingVAT(commissionRate);

  // 10.89 - 3.3
  const remainCommissionRate =
    commissionRateIncludingVAT -
    calculateCommissionRateIncludingVAT(CARD_COMMISSION_RATE);

  // 중개 수수료 매출이 없는 경우도 존재함
  if (remainCommissionRate <= 0) {
    return 0;
  }

  const calculateEffectiveTotalPrice = (refundPrice: number) =>
    totalPrice + couponDiscount + codeDiscount - refundPrice;

  const calculateCommissionRevenue = (effectiveTotalPrice: number) =>
    effectiveTotalPrice * (remainCommissionRate / 100);

  if (status === "confirmed") {
    const refundPrice = payBackPriceForHost || payBackPrice;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);
    const commissionRevenue = calculateCommissionRevenue(effectiveTotalPrice);
    return commissionRevenue;
  }
  if (status === "partiallyRefunded") {
    const refundPrice =
      partiallyRefundedAmountForHost || partiallyRefundedAmount;
    const effectiveTotalPrice = calculateEffectiveTotalPrice(refundPrice);
    const commissionRevenue = calculateCommissionRevenue(effectiveTotalPrice);

    if (hasConfirmedReservation(reservations, payOID)) {
      return 0;
    } else {
      return commissionRevenue;
    }
  }
  if (status === "refused") {
    return 0;
  }
  return 0;
};

// 정산금액 계산하기
export const calculateSettlementAmount = (
  reservation: Reservation,
  reservations?: Reservation[]
): number => {
  if (isNaverReservation(reservation)) {
    return calculateNaverSettlementAmount(reservation);
  }
  return calculateVacatioSettlementAmount(reservation, reservations);
};

export const calculateTotalSettlementAmount = (reservations: Reservation[]) => {
  return reservations.reduce((acc, cur) => {
    return acc + Math.floor(calculateSettlementAmount(cur, reservations));
  }, 0);
};

export const calculateTotalInfluencerSettlementAmount = (
  reservations: Reservation[]
) => {
  return reservations.reduce((acc, cur) => {
    return (
      acc + Math.floor(calculateInfluencerSettlementAmount(cur, reservations))
    );
  }, 0);
};

export const classifyLodgmentSettlement = (reservations, settlementDate) => {
  const month = settlementDate.split("-")[1];
  const day = settlementDate.split("-")[2];

  const a = reservations.reduce((acc, obj) => {
    const {
      lodgment: { name, longitude, latitude },
      settlement: { bank, accountNumber } = { bank: "", accountNumber: "" },
    } = obj;

    const trimmed = name.replace(/(\s*)/g, "");
    const parsedName = trimmed.split(/-|:/)[0];
    // const accountKey = `${parsedName}-${bank}-${accountNumber}`;

    const uniqueKey = `${longitude}-${latitude}`;

    // 정산금액이 0원이면 제외
    if (Math.floor(calculateSettlementAmount(obj, reservations)) === 0) {
      return acc;
    }

    const existingObj = acc[uniqueKey];
    if (existingObj) {
      acc[uniqueKey].정산금액 += Math.floor(
        calculateSettlementAmount(obj, reservations)
      );
    } else {
      acc[uniqueKey] = {
        은행: bank || "",
        계좌번호: accountNumber || "",
        정산금액: Math.floor(calculateSettlementAmount(obj, reservations)),
        숙소명: `${parsedName}${month}${day}`, // 숙소 대표이름
        법인: "(주)바카티오",
      };
    }
    return acc;
  }, {});
  return Object.values(a);
};

export const determineStatus = (reservation: Reservation) => {
  const { status } = reservation;
  if (status === "confirmed") {
    return "확정";
  } else {
    return "취소";
  }
};

export const getDetailStatus = (reservation: Reservation): string => {
  const { status, partiallyRefundedAmount, totalPrice, payBackPrice } =
    reservation;

  const isNaver = isNaverReservation(reservation);

  switch (status) {
    case "confirmed":
      if (isNaver) {
        return "예약확정";
      }
      return payBackPrice ? "예약확정(부분환불)" : "예약확정";

    case "partiallyRefunded":
      if (isNaver) {
        return totalPrice === partiallyRefundedAmount
          ? "전액환불후취소"
          : "부분환불후취소";
      }
      return "부분환불후취소";

    case "refused":
      return isNaver ? "취소" : "전액환불후취소";

    default:
      return "";
  }
};

export const checkNaverCouponUsed = (reservation: Reservation) => {
  // null인경우
  if (reservation.coupons) {
    return null;
  }
  // 빈 배열인 경우
  if (isElementInArray(reservation.coupons)) {
    return null;
  }
  return reservation.coupons;
};

const getTwoWeeksLater = () => {
  const currentDate = new Date();
  const twoWeeksDate = new Date();
  twoWeeksDate.setDate(currentDate.getDate() + 11);
  twoWeeksDate.setHours(23, 59, 59);
  return twoWeeksDate;
};

export const classifyLodgmentSettlementByPlatform = (data) => {
  const currentDate = new Date();
  const twoWeeksLater = getTwoWeeksLater();

  const finestays = data.filter(({ payOID }) => !payOID.includes("naver"));
  const navers = data
    .filter(({ payOID }) => payOID.includes("naver"))
    .filter((item) => {
      const settlementDate: Date = new Date(item.settlementDate);
      return settlementDate <= twoWeeksLater;
    });

  const fintestaysSettlement = calculateTotalSettlementAmount(finestays);
  const naverSettlement = calculateTotalSettlementAmount(navers);
  return {
    finestays: {
      settlementAmount: fintestaysSettlement,
      count: finestays.length,
    },
    naver: {
      settlementAmount: naverSettlement,
      count: navers.length,
    },
  };
};

export const getPlatform = (reservation: Reservation) => {
  const {
    platform: { type = "" } = {},
    payOID,
    isOtherPlatform,
    otherPlatformType,
  } = reservation;
  if (payOID?.includes("naver")) {
    return "네이버";
  }

  if (type === "website") {
    return "자체사이트";
  }

  if (type === "finereview") {
    return "파인리뷰";
  }

  if (isOtherPlatform) {
    return otherPlatformType;
  }

  if (type === "influencer") {
    return "파인스테이X인플루언서";
  }

  return "파인스테이";
};

export const checkStaticCommission = (data) => {
  if (!data || !data.commissions) return false;

  const { commissions } = data;
  // commissions.length === 1 이면서, startDate, endDate === null 이면
  if (commissions.length === 1) {
    const { startDate, endDate } = commissions[0];
    if (!startDate && !endDate) {
      return true;
    }
  }
  return false;
};

/**
 * @param reservations
 * @description 부분환불에 의해서만(예약확정) 생성된 거절데이터를 필터링
 */
export const filterEffectiveData = (reservations) => {
  return reservations?.filter((reservation) => {
    if (
      !isNaverReservation(reservation) &&
      reservation.status === "partiallyRefunded"
    ) {
      return !hasConfirmedReservation(reservations, reservation.payOID);
    }
    return true;
  });
};
