import * as React from "react";
import { Socket } from "socket.io-client";
import { emitResponseForm } from "utils/socket/clientEvent";
import * as S from "./msg.style";

export interface ISingleChoiceMsgProps {
  socket?: Socket;
  content: {
    templateTitle: string;
    question: string;
    options: string[];
  };
  role: "user" | "concierge";
}

export default function SingleChoiceMsg({
  socket,
  content: { templateTitle, question, options },
  role,
}: ISingleChoiceMsgProps) {
  const handleOptionClick = (option) => {
    return;
    if (!socket) {
      return;
    }
    const body = {
      title: templateTitle,
      question: question,
      answers: [option],
    };
    emitResponseForm(socket, body);
  };
  return (
    <S.MsgWrapper role={role}>
      <S.FormQuestion>{question}</S.FormQuestion>
      {options.map((option) => (
        <div key={option}>
          <S.ChoiceOption
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            {option}
          </S.ChoiceOption>
        </div>
      ))}
    </S.MsgWrapper>
  );
}
