export const SERVER_EVENT = {
  CONNECTED: "connected",
  ATTENDANCE_INFO: "attendance info",
  TYPING_INFO: "typing info",
  NEW_MSG: "new message",
  DUPLICATE_RESPONSE: "duplicate response",
};

export const CLIENT_EVENT = {
  JOIN_ROOM: "join room",
  TYPING_ACTION: "typing action",
  SEND_MSG: "send message",
  RESPONSE_FORM: "response form",
};

export const SEND_MSG_TYPE = {
  TEXT: "text",
  FILE: "file",
  IMAGE: "image",
  BTN_MSG: "btnMsg",
};
