import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import dayOfYear from "dayjs/plugin/dayOfYear";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(dayOfYear);
dayjs.extend(isBetween);
dayjs.tz.setDefault("Asia/Seoul");

export default dayjs;
