import styled from "styled-components";

export const AddImageButton = styled.div`
  width: 200px;
  cursor: pointer;
  margin: 10px 0 40px 0;
  height: 30px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: limegreen;
  color: white;
`;
