import { getSettlementLogsByLodgmentId } from "api/settlementLog";
import partnerLodgments from "constants/partnerLodgments";
import { useHistory } from "react-router-dom";
import * as React from "react";

export interface ISettlementLogProps {}

export default function SettlementLog(props: ISettlementLogProps) {
  const history = useHistory();
  const [selectedLodgmentId, setSelectedLodgmentId] = React.useState("");
  const [settlementLogs, setSettlementLogs] = React.useState([]);

  React.useEffect(() => {
    if (!selectedLodgmentId) {
      return;
    }
    const fetchSettlementLogs = async () => {
      const settlementLogs = await getSettlementLogsByLodgmentId(
        selectedLodgmentId
      );
      setSettlementLogs(settlementLogs);
    };
    fetchSettlementLogs();
  }, [selectedLodgmentId]);

  return (
    <div>
      <select onChange={(e) => setSelectedLodgmentId(e.target.value)}>
        <option value="">숙소를 선택하세요</option>
        {partnerLodgments.map((lodgment) => (
          <option value={lodgment._id}>{lodgment.name}</option>
        ))}
      </select>
      <div>
        {settlementLogs.map(
          ({
            _id,
            amount,
            date,
            lodgment,
            paymentMethod,
            phoneNum,
            sender,
          }) => (
            <div
              style={{
                display: "flex",
                border: "0.1rem solid black",
                width: "fit-content",
                margin: "1rem",
                cursor: "pointer",
              }}
              onClick={() => history.push(`/settlementLog/${_id}`)}
            >
              <div style={{ width: "fit-content" }}>{date}</div>
              <div style={{ display: "flex", width: "80%" }}>
                <div>
                  <div>
                    {sender}({lodgment.name})
                  </div>
                  <div>{phoneNum}</div>
                </div>
                <div>
                  <div>{paymentMethod}</div>
                  <div>{amount.toLocaleString()}원</div>
                </div>
              </div>
            </div>
          )
        )}
        {Boolean(selectedLodgmentId) && (
          <button
            onClick={() =>
              history.push(`/settlementLog/new/${selectedLodgmentId}`)
            }
          >
            생성하기
          </button>
        )}
      </div>
    </div>
  );
}
