import * as React from "react";
import {
  getNotification,
  postNotification,
  updateNotification,
  deleteNotification,
} from "api/notification";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { uploadPackageImg } from "api/image";

export default function Notification() {
  const [notification, setNotification] = React.useState([]);
  const [banners, setBanners] = React.useState([]);
  const [popups, setPopups] = React.useState([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [notificationType, setNotificationType] = React.useState("배너");
  const [actionType, setActionType] = React.useState("");
  const [newNotificationBannerText, setNewNotificationBannerText] =
    React.useState("");
  const [newNotificationPopupImage, setNewNotificationPopupImage] =
    React.useState("");
  const [newNotificationUrl, setNewNotificationUrl] = React.useState("");
  const [notificationId, setNotificationId] = React.useState("");
  const [notificationBannerText, setNotificationBannerText] =
    React.useState("");
  const [notificationPopupImage, setNotificationPopupImage] =
    React.useState("");
  const [notificationUrl, setNotificationUrl] = React.useState("");

  React.useEffect(() => {
    getNotification().then((response) => {
      setNotification(response.data);
    });
  }, []);

  React.useEffect(() => {
    setBanners(notification.filter((noti) => noti.type === "banner"));
    setPopups(notification.filter((noti) => noti.type === "popup"));
  }, [notification]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (actionType === "추가") {
      if (name === "notificationBannerText") {
        setNewNotificationBannerText(value);
      } else if (name === "notificationPopupImage") {
        setNewNotificationPopupImage(value);
      } else if (name === "notificationUrl") {
        setNewNotificationUrl(value);
      }
    } else if (actionType === "수정") {
      if (name === "notificationBannerText") {
        setNotificationBannerText(value);
      } else if (name === "notificationPopupImage") {
        setNotificationPopupImage(value);
      } else if (name === "notificationUrl") {
        setNotificationUrl(value);
      }
    }
  };

  const handleSubmitNotification = async () => {
    const payload = {
      type: notificationType === "배너" ? "banner" : "popup",
      bannerText:
        actionType === "추가"
          ? newNotificationBannerText
          : notificationBannerText,
      popupImage:
        actionType === "추가"
          ? newNotificationPopupImage
          : notificationPopupImage,
      url: actionType === "추가" ? newNotificationUrl : notificationUrl,
    };

    console.log(payload);

    if (payload.type === "banner" && !payload.bannerText) {
      alert("멘트를 입력해주세요.");
      return;
    }

    if (payload.type === "popup" && !payload.popupImage) {
      alert("팝업 이미지를 업로드해주세요.");
      return;
    }

    if (!payload.url) {
      alert("연결할 링크를 입력해주세요.");
      return;
    }

    try {
      if (actionType === "추가") {
        const response = await postNotification(payload);
        setNotification([...notification, response.data]);
        alert("공지가 추가되었습니다.");
        resetForm();
      } else if (actionType === "수정") {
        const response = await updateNotification({
          id: notificationId,
          ...payload,
        });
        setNotification(
          notification.map((noti) =>
            noti._id === notificationId ? response.data : noti
          )
        );
        alert("공지가 수정되었습니다.");
        resetForm();
      }
    } catch (error) {
      console.error(
        `Error ${actionType === "추가" ? "posting" : "updating"} notification:`,
        error
      );
      alert(
        `공지를 ${
          actionType === "추가" ? "추가" : "수정"
        }하는 중 오류가 발생했습니다.`
      );
    }
  };

  const resetForm = () => {
    setNewNotificationBannerText("");
    setNewNotificationPopupImage("");
    setNewNotificationUrl("");
    setNotificationBannerText("");
    setNotificationPopupImage("");
    setNotificationUrl("");
    setIsModalOpen(false);
  };

  return (
    <div>
      <Modal
        size="lg"
        centered
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
      >
        <Modal.Header>
          <Modal.Title>
            {notificationType} {actionType}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {notificationType === "배너" ? (
            <InputGroup className="mb-3">
              <InputGroup.Text>멘트</InputGroup.Text>
              <Form.Control
                placeholder="공지 내용을 입력해주세요."
                name="notificationBannerText"
                value={
                  actionType === "추가"
                    ? newNotificationBannerText
                    : notificationBannerText
                }
                onChange={handleChange}
              />
            </InputGroup>
          ) : (
            <div
              className="mb-3 "
              style={{ display: "flex", flexDirection: "column" }}
            >
              {(newNotificationPopupImage || notificationPopupImage) && (
                <img
                  style={{
                    width: "75%",
                    margin: "auto",
                    marginBottom: "10px",
                  }}
                  src={
                    actionType === "추가"
                      ? newNotificationPopupImage
                      : notificationPopupImage
                  }
                  alt="popup"
                />
              )}
              <Button
                onClick={() => {
                  document.getElementById("image-upload").click();
                }}
              >
                이미지 업로드
              </Button>
              <input
                id="image-upload"
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={async (e) => {
                  const file = e.target.files.length ? e.target.files[0] : "";
                  if (!file) {
                    return;
                  }
                  const formData = new FormData();
                  formData.append("popupImage", file);
                  try {
                    const {
                      data: { popupImage },
                    } = await uploadPackageImg(formData);
                    if (actionType === "추가") {
                      setNewNotificationPopupImage(popupImage[0]);
                    } else {
                      setNotificationPopupImage(popupImage[0]);
                    }
                  } catch (error) {
                    console.error("Error uploading image:", error);
                    alert("5MB 이하의 이미지 파일만 업로드 가능합니다.");
                  }
                }}
              />
            </div>
          )}
          <InputGroup className="mb-3">
            <InputGroup.Text>링크</InputGroup.Text>
            <Form.Control
              placeholder="연결할 링크를 입력해주세요."
              name="notificationUrl"
              value={
                actionType === "추가" ? newNotificationUrl : notificationUrl
              }
              onChange={handleChange}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleSubmitNotification}>{actionType}</Button>
          <Button
            onClick={() => {
              resetForm();
            }}
            variant="secondary"
          >
            닫기
          </Button>
        </Modal.Footer>
      </Modal>
      <Card className="mb-3">
        <Card.Body>
          <Card.Title>NOTICE</Card.Title>
          <Card.Text>
            <p>
              🪧 가장 상단에 있는 <strong>배너 / 팝업 하나씩</strong>만
              노출됩니다.
            </p>
            <p>
              🪧 연결 링크 입력 시, https:// 를 포함한 전체 경로를 입력해주세요.
            </p>
            <p>🪧 팝업 이미지는 1:1 비율로 보여집니다.</p>
          </Card.Text>
        </Card.Body>
      </Card>
      <Tabs
        onSelect={(notificationType) => setNotificationType(notificationType)}
      >
        <Tab eventKey="배너" title="배너">
          <Table hover responsive bordered>
            <thead style={{ fontSize: "18px", textAlign: "center" }}>
              <tr>
                <th>배너 멘트</th>
                <th>연결 링크</th>
                <th>수정</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {banners.map((banner, index) => (
                <tr key={banner.id}>
                  <td
                    style={
                      index === 0 ? { backgroundColor: "lightyellow" } : {}
                    }
                  >
                    {banner.bannerText}
                  </td>
                  <td
                    style={
                      index === 0 ? { backgroundColor: "lightyellow" } : {}
                    }
                  >
                    <a href={banner.url}>{banner.url}</a>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <Button
                      variant="warning"
                      onClick={() => {
                        setNotificationId(banner._id);
                        setNotificationBannerText(banner.bannerText);
                        setNotificationUrl(banner.url);
                        setActionType("수정");
                        setIsModalOpen(true);
                      }}
                    >
                      수정
                    </Button>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteNotification(banner._id).then(() => {
                          setNotification(
                            notification.filter((n) => n._id !== banner._id)
                          );
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            onClick={() => {
              setActionType("추가");
              setIsModalOpen(true);
            }}
          >
            추가
          </Button>
        </Tab>
        <Tab eventKey="팝업" title="팝업">
          <Table hover responsive bordered>
            <thead style={{ fontSize: "18px", textAlign: "center" }}>
              <tr>
                <th>팝업 이미지</th>
                <th>연결 링크</th>
                <th>수정</th>
                <th>삭제</th>
              </tr>
            </thead>
            <tbody>
              {popups.map((popup, index) => (
                <tr key={popup.id}>
                  <td
                    style={{
                      textAlign: "center",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <img
                      src={popup.popupImage}
                      alt="popup"
                      style={{ width: "300px", margin: "auto" }}
                    />
                  </td>
                  <td
                    style={{
                      verticalAlign: "middle",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <a href={popup.url}>{popup.url}</a>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <Button
                      variant="warning"
                      onClick={() => {
                        setNotificationId(popup._id);
                        setNotificationPopupImage(popup.popupImage);
                        setNotificationUrl(popup.url);
                        setActionType("수정");
                        setIsModalOpen(true);
                      }}
                    >
                      수정
                    </Button>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      verticalAlign: "middle",
                      backgroundColor: index === 0 ? "lightyellow" : "",
                    }}
                  >
                    <Button
                      variant="danger"
                      onClick={() => {
                        deleteNotification(popup._id).then(() => {
                          setNotification(
                            notification.filter((n) => n._id !== popup._id)
                          );
                        });
                      }}
                    >
                      삭제
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Button
            onClick={() => {
              setActionType("추가");
              setIsModalOpen(true);
            }}
          >
            추가
          </Button>
        </Tab>
      </Tabs>
    </div>
  );
}
