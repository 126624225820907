import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { getPushHistory, sendPush } from "api/push";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

// 타입 정의
interface HistoryItem {
  createdAt: string;
  title: string;
  body: string;
  targets: string[];
  pathWithQuery: string;
  targetUserCount: number;
}

interface TargetOption {
  label: string;
  value: string;
}

const targetOptions: TargetOption[] = [
  { label: "인증", value: "verified" },
  { label: "미인증", value: "unverified" },
  // 추가 타겟 옵션을 여기 추가 가능
];

const AuthGroupPush: React.FC = () => {
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [pathWithQuery, setPathWithQuery] = useState("/"); // 초기값으로 '/' 설정
  const [history, setHistory] = useState<HistoryItem[]>([]);
  const [targets, setTargets] = useState<string[]>([]);

  useEffect(() => {
    // 히스토리 데이터를 API를 통해 조회
    const fetchHistory = async () => {
      try {
        const response = await getPushHistory();
        setHistory(response.data);
      } catch (error) {
        console.error("Error fetching history", error);
      }
    };

    fetchHistory();
  }, []);

  const handleCheckboxChange = (target: string) => {
    setTargets((prevTargets) =>
      prevTargets.includes(target)
        ? prevTargets.filter((t) => t !== target)
        : [...prevTargets, target]
    );
  };

  const handleSendPush = async () => {
    if (!title || !body || !pathWithQuery.startsWith("/")) {
      alert(
        'Title, body, and pathWithQuery must be valid. Path must start with "/"'
      );
      return;
    }

    // confirm 통해서 더블체크
    const confirmMessage = `보내기 전에 한 번 더 확인해주세요. 제목: ${title}, 내용: ${body}, 경로 및 쿼리: ${pathWithQuery}`;
    if (!window.confirm(confirmMessage)) {
      return;
    }
    try {
      await sendPush({ title, body, targets, pathWithQuery });
      alert("Push notification sent!");

      // 새로고침
      window.location.reload();
      // 새 히스토리 아이템을 추가
      //   const newHistoryItem: HistoryItem = {
      //     createdAt: new Date().toISOString(),
      //     title,
      //     body,
      //     targets,
      //     pathWithQuery,
      //     targetUserCount: 0, // 발송 수는 0으로 초기화
      //   };
      //   setHistory([newHistoryItem, ...history]); // 최신 항목을 맨 앞에 추가

      // 입력 필드 초기화
      setTitle("");
      setBody("");
      setPathWithQuery("/");
      setTargets([]);
    } catch (error) {
      console.error("Error sending push notification", error);
      alert("Failed to send push notification");
    }
  };

  return (
    <Container>
      <SettingsBox>
        <h2>Push Notification Settings</h2>
        <CheckboxContainer>
          {targetOptions.map((option) => (
            <label key={option.value}>
              <input
                type="checkbox"
                checked={targets.includes(option.value)}
                onChange={() => handleCheckboxChange(option.value)}
              />
              <CheckboxLabel>{option.label}</CheckboxLabel>
            </label>
          ))}
        </CheckboxContainer>
        <InputContainer>
          <label>Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Body:</label>
          <input
            type="text"
            value={body}
            onChange={(e) => setBody(e.target.value)}
          />
        </InputContainer>
        <InputContainer>
          <label>Path with Query:</label>
          <input
            type="text"
            value={pathWithQuery}
            onChange={(e) => setPathWithQuery(e.target.value)}
          />
          <HelperText>예시: /host/community/board/all</HelperText>
        </InputContainer>
        <Button onClick={handleSendPush}>Send Push Notification</Button>
      </SettingsBox>
      <PreviewBox>
        <h2>Preview</h2>
        <NotificationPreview>
          <strong>{title || "Push Title"}</strong>
          <p>{body || "Push body content goes here"}</p>
        </NotificationPreview>
      </PreviewBox>
      <HistoryBox>
        <h2>History</h2>
        <Table>
          <thead>
            <tr>
              <th>Created At</th>
              <th>Title</th>
              <th>Body</th>
              <th>Targets</th>
              <th>Path with Query</th>
              {/* 발송 수 */}
              <th>Sent</th>
            </tr>
          </thead>
          <tbody>
            {history.map((item, index) => (
              <tr key={index}>
                <td>
                  {dayjs(item.createdAt)
                    .tz("Asia/Seoul")
                    .format("YYYY-MM-DD HH:mm:ss")}
                </td>
                <td>{item.title}</td>
                <td>{item.body}</td>
                <td>
                  {item.targets
                    .map(
                      (t) =>
                        targetOptions.find((option) => option.value === t)
                          ?.label || t
                    )
                    .join(", ")}
                </td>
                <td>{item.pathWithQuery}</td>
                <td>{item?.targetUserCount}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </HistoryBox>
    </Container>
  );
};

export default AuthGroupPush;

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const SettingsBox = styled.div`
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
`;

const CheckboxLabel = styled.span`
  margin-left: 8px;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  label {
    margin-bottom: 8px;
  }
  input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
`;

const HelperText = styled.div`
  margin-top: 4px;
  font-size: 12px;
  color: #666;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0056b3;
  }
`;

const PreviewBox = styled.div`
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
`;

const NotificationPreview = styled.div`
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  strong {
    display: block;
    margin-bottom: 8px;
  }
  p {
    margin: 0;
  }
`;

const HistoryBox = styled.div`
  width: fit-content;
  max-width: 800px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow-x: auto;
`;

const Table = styled.table`
  th,
  td {
    padding: 10px;
    border: 1px solid #ddd;
    white-space: nowrap;
  }
  th {
    background-color: #f8f8f8;
  }
  tbody tr:hover {
    background-color: #f1f1f1;
  }
`;
