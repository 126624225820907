import styled from "styled-components";
import { defaultSubTitle } from "styles/styleConstants";

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const ButtonGroupSection = styled.div``;

export const SelectSection = styled.div`
  margin-left: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20%;
`;

export const Label = styled.div`
  width: fit-content;
`;

export const InputLayout = styled.div`
  margin: 0.3rem;
`;
export const InputLabel = styled.label`
  width: 15rem;
`;
export const Input = styled.input`
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
export const Textarea = styled.textarea`
  width: 15rem;
  height: 9rem;
`;

export const InputsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const InputsBox = styled.div`
  margin-left: 20px;
`;

export const PlaceImageItem = styled.div`
  border: 0.1rem solid black;
  width: fit-content;
  margin: 0.3rem;
`;

export const PlaceItem = styled.div`
  width: 15rem;
  border: 0.1rem solid black;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.3rem;

  :hover {
    background-color: lightgray;
  }
`;
export const PlaceRepresentImg = styled.img`
  width: 180px;
  height: 150px;
  margin-right: 1rem;
  -webkit-user-drag: none;
  cursor: pointer;
`;
export const PlaceTitle = styled.h3`
  height: 2rem;
  font-size: 0.9rem;
  word-wrap: break-word;
  overflow-y: auto;
`;

export const PlaceBox = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
`;

export const PlaceImageContainer = styled.div`
  /* display: flex; */
  flex-wrap: wrap;
  width: 100vw;
`;

export const InputField = styled.input`
  width: 100px;
  font-size: 12px;
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const InputHeader = styled.div``;

export const Button = styled.button`
  width: 30px;
  height: 30px;
  padding: 0;
  margin-left: 10px;
`;

export const CreateLayout = styled.div`
  display: flex;
  overflow-x: scroll;
  padding-bottom: 200px;
`;

export const LeftColumn = styled.div``;

export const RightColumn = styled.div``;

export const SubTitle = styled.h3`
  ${defaultSubTitle}
  margin-top:20px;
`;

export const ItemTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  /* margin-top: 20px; */
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
`;

export const DocentContentBox = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
`;

export const DocentFigureContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
export const DocentFigure = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid black;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 10px;
  &:not(:first-child) {
    margin-left: 20px;
  }
`;

export const DocentImage = styled.img`
  width: 100%;
  height: 100%;
`;

export const FigureColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & textarea {
    width: 300px;
    height: 100px;
  }
`;

export const TextColumn = styled.div`
  margin-left: 10px;
  & > textarea[name="subTitle"] {
    width: 100%;
    height: 50px;
  }
  & > textarea[name="paragraph"] {
    width: 100%;
    height: 100px;
  }
`;

export const ActionColumn = styled.div`
  margin-left: 20px;
`;

export const SubTitleBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-left: 20px;
  & > button:last-child {
    margin-left: 10px;
  }
  margin-right: 20px;
`;

export const EmptyImage = styled.div`
  width: 150px;
  height: 150px;
  border: 1px solid black;
  border-style: dashed;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploadButton = styled.label``;

export const AudioBox = styled.div`
  margin-top: 20px;
`;

export const Audio = styled.audio``;

export const InputContainer = styled.div``;

export const OptionList = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const OptionItem = styled.li`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    width: fit-content;
  }
`;

export const CloneBox = styled.div``;

export const IdText = styled.div`
  font-size: 12px;
`;
