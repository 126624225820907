import { CLEANING_SCHEDULE_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getCleaningSchedules = async () => {
  const path = CLEANING_SCHEDULE_PATH;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getCleaningScheduleById = async (cleaningScheduleId) => {
  const path = CLEANING_SCHEDULE_PATH + `/${cleaningScheduleId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const updateCleaingSchedule = async (scheduleId, data) => {
  const path = CLEANING_SCHEDULE_PATH + `/${scheduleId}`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};

export const getCleaningSchedulesByLodgmentId = async (lodgmentId) => {
  const path = CLEANING_SCHEDULE_PATH + "/byLodgmentId" + `/${lodgmentId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};
