import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import ManageCollaboration from "./ManageCollaboration";
import VerifyChannel from "./VerifyChannel";

export default function Review() {
  const [notificationType, setNotificationType] = useState("협업 관리");
  const renderTabContent = () => {
    switch (notificationType) {
      case "협업 관리":
        return <ManageCollaboration />;
      case "채널 인증 검토":
        return <VerifyChannel />;
      default:
        return <div>선택된 탭이 없습니다.</div>;
    }
  };

  return (
    <div className="d-flex">
      {/* 사이드바 */}
      <div
        className="sidebar"
        style={{ width: "200px", padding: "15px", backgroundColor: "#f8f9fa" }}
      >
        <h4>파인리뷰</h4>
        <Tabs
          activeKey={notificationType}
          onSelect={(key) => setNotificationType(key)}
          className="flex-column"
        >
          <Tab eventKey="협업 관리" title="협업 관리" />
          <Tab eventKey="채널 인증 검토" title="채널 인증 검토" />
        </Tabs>
      </div>

      {/* 탭 내용 */}
      <div className="content" style={{ padding: "15px", flex: 1 }}>
        {renderTabContent()}
      </div>
    </div>
  );
}
