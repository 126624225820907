import styled from "styled-components";

export const ExampleMsg = styled.div`
  width: 250px;
  height: 200px;
  background-color: black;
  margin: 0 auto;
`;

export const BtnMsg = styled.div`
  width: max-content;
  max-width: 100%;
  word-break: break-all;
  margin: 0.3rem 0;
`;

export const BtnMsgBtn = styled.div`
  a {
    font-size: 1.05rem;
    text-decoration: none;
    color: white;
  }
  word-break: break-all;
  text-align: center;
  font-weight: 600;
  width: 14rem;
  text-decoration: none;
  padding: 0.7rem 3rem;
  margin: 1.5rem auto 0 auto;
  border-radius: 0.8rem;
  background-color: #4c4cce;
  max-width: 100%;
  color: white;
  :hover {
    cursor: pointer;
  }
`;

interface MsgWrapperProp {
  isPhotoMsg?: boolean;
}
export const MsgWrapper = styled.div<MsgWrapperProp>`
  max-width: 75%;
  margin: 1rem 2rem 0 2rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 1px 4px;
  border-radius: 0.8rem;
  padding: ${({ isPhotoMsg }) => (isPhotoMsg ? "" : "0.7rem 1.2rem")};
  width: max-content;
  background-color: ${({ role }) =>
    role === "concierge" ? "#ffffff" : "#c9c9f0"};
`;

export const TextMsg = styled.div`
  max-width: 20rem;
  word-break: break-all;
  white-space: pre-line;
`;

export const Photo = styled.img`
  /* height:  */
  border-radius: 0.8rem 0.8rem 0 0;
  /* min-width: 10rem; */
  max-width: 100%;
  /* width: 100%; */
  margin: 0;
  padding: 0;
`;

export const PhotoDescription = styled.div`
  padding: 1rem;
  word-break: break-all;
  max-width: 75%;
`;

export const FormQuestion = styled.h2`
  max-width: 90%;
  font-size: 1.15rem;
  font-weight: bolder;
  margin: 0.5rem 0 0.5rem 0;
  word-break: break-all;
`;

// btnMsg
export const SubjectiveFormInput = styled.input`
  font-size: 1.15rem;
  border: none;
  width: 100%;
  border-bottom: 0.1rem #dbdbdb solid;
  ::placeholder {
    color: "#e3e3e3";
  }
`;

// choice
interface ChoiceOptionProps {
  isSelected?: boolean;
}
export const ChoiceOption = styled.div<ChoiceOptionProps>`
  background-color: ${({ isSelected }) => (isSelected ? "purple" : "#E9ECEF")};
  width: 14rem;
  max-width: 100%;
  word-break: break-all;

  padding: 0.5rem;
  margin: 0.4rem auto;
  border-radius: 0.5rem;

  :hover {
    cursor: pointer;
  }
`;
