import * as S from "./ChatRoomList.style";
import { CHAT_PATH, CONSULTS_PATH } from "../../constants/path";
import { useHistory } from "react-router-dom";
import { IMsg } from "type/chat.type";
import { Flex, FlexBetween } from "components/style";
import { utcToKst } from "utils/date";
import { CONSULT_PROCESS } from "constants/consult";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { myAxios } from "api/myAxios";

interface IChatRoomItemProps {
  username: string;
  nickname: string;
  isAdmin: boolean;
  consultId: string;
  userPhoneNum: string;
  date: string;
  newMsgCount: {
    user: number;
    concierge: number;
  };
  lastMsg: IMsg;
  memo: string;
  lodgmentName: string;
}

export default function ChatRoomItem({
  username,
  nickname,
  isAdmin,
  consultId,
  userPhoneNum,
  date,
  newMsgCount,
  lastMsg,
  memo,
  lodgmentName,
}: IChatRoomItemProps) {
  const onChatRoomItem = () => {
    window.open(`${CHAT_PATH}/${consultId}`, "_blank");
  };

  const chatBox = (lastMsg) => {
    return (
      <div>
        <div>
          {lastMsg.type === "text" && lastMsg.content?.text.length > 50
            ? lastMsg.content?.text.slice(0, 50) + "..."
            : lastMsg.content?.text}
        </div>
        <div>{lastMsg.type === "image" && "(사진)"}</div>
        <div>{lastMsg.type === "file" && "(파일)"}</div>
        <div>
          {["singleChoice", "multipleChoice", "subjective"].includes(
            lastMsg.type
          ) && "(입력 폼)"}
        </div>
        <div>{lastMsg.type === "btnMsg" && lastMsg.content.btnText}</div>
        <div style={{ marginTop: "0.5rem", fontSize: "1rem", fontWeight: 600 }}>
          {utcToKst(lastMsg.createdAt).toISOString().slice(5, 10) +
            " " +
            utcToKst(lastMsg.createdAt).toISOString().slice(11, 16)}
        </div>
      </div>
    );
  };

  return (
    <S.ChatRoomItemContainer onClick={onChatRoomItem}>
      <FlexBetween>
        {newMsgCount.concierge > 0 && (
          <S.NewMsgCount>{newMsgCount.concierge}</S.NewMsgCount>
        )}
      </FlexBetween>
      <br />
      <div>
        <p>
          <p>숙소: {lodgmentName}</p>
          이름: {username || nickname} {userPhoneNum && `(${userPhoneNum})`}
        </p>
      </div>
      <br />
      <div>
        <div style={{ border: "1px solid gray", minHeight: "2rem" }}>
          {lastMsg && chatBox(lastMsg)}
        </div>
      </div>
      {memo && (
        <div>
          메모: <strong>{memo?.split("\n")[0]}</strong>
        </div>
      )}
    </S.ChatRoomItemContainer>
  );
}
