import CommissionByAccommoTableRow from "./CommissionByAccommoTableRow";
import * as S from "./commission.styled";
import _ from "lodash";

export default function CommissionByAccommoTable({ data }) {
  return (
    <S.StyledTable>
      <table aria-label="simple table">
        <thead>
          <tr>
            <th className="status">상태</th>
            <th className="accommo-id">ID</th>
            <th className="accommo-name" align="left">
              숙소명
            </th>
            <th>수수료</th>
            <th>부가세</th>
            <th className="isEarlySettlement">조기 정산</th>
            <th>네이버 직접 검색 수수료</th>
            <th>네이버 알고리즘 수수료</th>
            <th>은행</th>
            <th>계좌번호</th>
            <th>예금주명</th>
            <th>메모</th>
            <th>수정</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row) => (
            <CommissionByAccommoTableRow row={row} key={row._id} />
          ))}
        </tbody>
      </table>
    </S.StyledTable>
  );
}
