import React, { useState } from "react";
import * as S from "./ChatRoom.style";
import { FlexBetween } from "components/style";
import { myAxios } from "api/myAxios";
import { SMS_COMMON_PATH } from "constants/path";

export interface ISmsModalProps {
  setShowSmsModal;
  userPhone: string;
}

const DEFAULT_TITLE = `[하우 답장 도착! 얼른 확인해보세요!]`;

const DEFAULT_CONTENT = `많이 기다리셨죠? 고객님 전담 컨시어지의 답장이 도착했어요!

아래 링크에서 >>'하우'앱을 다운<<받으면 바로! 답장 확인이 가능해요.

[하우 앱 다운받기]
https://hwik.page.link/smsMessage

*링크 접속이 불안하다면?
앱스토어에 '하우'를 검색해보세요!`;

export default function SmsModal({
  setShowSmsModal,
  userPhone,
}: ISmsModalProps) {
  const [phone, setPhone] = useState(userPhone);
  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [content, setContent] = useState(DEFAULT_CONTENT);

  return (
    <S.SmsModalWrapper>
      <div>
        <div>전화번호</div>
        <input
          type="text"
          value={phone}
          disabled
          //   onChange={(e) => {
          //     setPhone(e.target.value);
          //   }}
        />
        <div>
          <div>제목 (MMS의 경우에만 노출)</div>
          <input
            type="text"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div>
          <div>내용</div>
          <textarea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
          />
        </div>
      </div>
      <FlexBetween style={{ margin: "2rem 2rem" }}>
        <S.SmsModalButton
          onClick={async () => {
            try {
              await myAxios.post({
                path: SMS_COMMON_PATH,
                data: {
                  phone,
                  title,
                  content,
                },
              });
              window.alert("문자메시지 전송 성공!");
              setShowSmsModal(false);
            } catch (error) {
              window.alert("에러입니다..");
            }
          }}
        >
          보내기
        </S.SmsModalButton>
        <S.SmsModalButton
          onClick={() => {
            setShowSmsModal(false);
          }}
        >
          취소
        </S.SmsModalButton>
      </FlexBetween>
    </S.SmsModalWrapper>
  );
}
