import * as React from "react";
import {
  cancelLodgmentReservation,
  getAllReservationsForSettlement,
  getLodgmentReservationByLodgmentId,
  putLodgmentReservation,
} from "api/lodgmentReservation";
import styled from "styled-components";
import ChangeDateModal from "./ChangeDateModal";
import { getPartnerLodgments } from "api/lodgment";
import {
  getAllLodgmentReservationRefuseLogsForSettlement,
  getLodgmentReservationRefuseLogByLodgmentId,
} from "api/lodgmentReservationRefuseLog";
import ChangeRoomModal from "./ChangeRoomModal";
import {
  convertDateIntoString,
  convertDateIntoYYMMDD,
  daysBetween,
} from "utils/date";
import * as S from "./reservations.style";
import { exportTableToCSV, exportMultipleTableToCSV } from "utils/csv";

export interface ILodgmentReservationListProps {}

const TH = styled.th`
  border: 1px solid black;
  background-color: black;
  color: white;
  text-align: center;
`;

const TD = styled.td`
  border: 0.5px solid black;
  padding: 0.5rem;
  text-align: center;
`;

export default function LodgmentReservationList(
  props: ILodgmentReservationListProps
) {
  const [PartnerLodgments, setPartnerLodgments] = React.useState([]);

  const [selectedLodgmentId, setSelectedLodgmentId] = React.useState("all");
  const [reservations, setReservations] = React.useState([]);
  const [reservationRefuseLogs, setReservationRefuseLogs] = React.useState([]);

  const [reservationIdForModal, setReservationIdForModal] = React.useState("");
  const [isChangeDateModalOn, setIsChangeDateModalOn] = React.useState(false);
  const [isChangeRoomModalOn, setIsChangeRoomModalOn] = React.useState(false);

  const [searchedSettlementDate, setSearchedSettlementDate] =
    React.useState<string>();

  const calculateSettlementDate = (checkoutDate) => {
    // 기본 정산 시 그 다음주의 금요일에 지급
    const getFridayOfNextWeek = (date) => {
      const dateObj = new Date(date);
      const friday = new Date(dateObj.getTime());
      const day = dateObj.getDay() || 7;
      friday.setDate(dateObj.getDate() + (5 - day) + 7);
      return friday;
    };
    return getFridayOfNextWeek(checkoutDate);
  };

  const calculateSettlementDateForEarly = (checkoutDate) => {
    const getNextFriday = (date) => {
      const dateObj = new Date(date);
      const friday = new Date(dateObj.getTime());
      friday.setDate(dateObj.getDate() + ((5 - dateObj.getDay() + 7) % 7));
      return friday;
    };
    // 빠른 정산 시 가장 가까운 금요일에 지급
    return getNextFriday(checkoutDate);
  };

  const filterLogs = (reservation) => {
    if (!searchedSettlementDate) {
      return true;
    }
    if (reservation.isOtherPlatform) {
      return false;
    }

    const settlementDate = reservation.lodgment?.isEarlySettlement
      ? convertDateIntoString(
          calculateSettlementDateForEarly(reservation.basicInfo.end)
        )
      : convertDateIntoString(
          calculateSettlementDate(reservation.basicInfo.end)
        );

    return settlementDate === searchedSettlementDate;
  };
  const filteredReservations = reservations.filter(filterLogs);
  const filteredReservationRefuseLogs =
    reservationRefuseLogs.filter(filterLogs);
  const filteredPartialRefundLogs = reservationRefuseLogs
    .filter(filterLogs)
    .filter(({ status }) => status === "partiallyRefunded");

  const collator = new Intl.Collator("ko", { sensitivity: "base" });

  const wholeLogs = [...filteredReservations, ...filteredReservationRefuseLogs]
    .filter((a) => a.basicInfo.name !== "비에노즈 풀빌라")
    .filter(({ lodgment }) => lodgment)
    .sort((a, b) => {
      try {
        const typeOrder = {
          confirmed: 0,
          partiallyRefunded: 1,
          refused: 2,
        };
        return typeOrder[a.status] - typeOrder[b.status];
      } catch {
        return 0;
      }
    })
    .sort((a, b) => {
      return collator.compare(a.lodgment?.name, b.lodgment?.name);
    });

  const twoDimensionalWholeLogs = wholeLogs.reduce((result, row) => {
    const index = result.findIndex(
      (group) => group[0]?.basicInfo.name === row.basicInfo.name
    );
    if (index !== -1) {
      result[index].push(row);
    } else {
      result.push([row]);
    }
    return result;
  }, []);

  console.log("wholeLogs", twoDimensionalWholeLogs);

  const onCancelReservationBtn = async (reservationId) => {
    // 환불 로직
    if (
      !confirm(
        "예약 취소 처리하시겠습니까?(pg사 결제 취소까지 처리됩니다.) / HAU 결제 건을 취소하는 경우, 고객에게 취소 내역이 남으며 호스트 및 게스트에게 알림 문자가 발송되진 않습니다."
      )
    ) {
      return;
    }
    if (!confirm("정말 예약 취소 처리하시겠습니까?")) {
      return;
    }
    await cancelLodgmentReservation(reservationId);
    alert("취소되었습니다!");
    window.location.reload();
  };

  const onChangeDateBtn = async (reservationId) => {
    setReservationIdForModal(reservationId);
    setIsChangeDateModalOn(true);
  };

  const onChangeRoomBtn = async (reservationId) => {
    setReservationIdForModal(reservationId);
    setIsChangeRoomModalOn(true);
  };

  const calculateSales = (reservation) => {
    if (reservation.status === "partiallyRefunded")
      return (
        ((reservation.totalPrice - reservation.partiallyRefundedAmount) *
          reservation.lodgment.commission) /
        100
      );
    return (reservation.totalPrice * reservation.lodgment.commission) / 100;
  };

  const calculateSettlement = (reservation) => {
    if (reservation.status === "partiallyRefunded")
      return (
        reservation.totalPrice -
        reservation.partiallyRefundedAmount -
        calculateSales(reservation)
      );
    return reservation.totalPrice - calculateSales(reservation);
  };

  const onUpdateIsSettledBtn = async (reservationId) => {
    try {
      const $Btn = document.getElementById(reservationId);
      const isSettled = ![...$Btn.classList].includes("red");

      await putLodgmentReservation(reservationId, { isSettled: !isSettled });

      if (!isSettled) {
        return $Btn.classList.remove("red");
      }

      $Btn.classList.add("red");
    } catch {
      alert(
        "업데이트 도중 서버 오류가 발생했습니다. 정산 여부가 정상적으로 업데이트 되지 않았습니다."
      );
      return;
    }
  };

  const onUpdateIsSettledInDuplicateBtn = async (reservationId) => {
    try {
      const $Btn = document.getElementById(`${reservationId}-duplicate`);
      const isSettledInDuplicate = ![...$Btn.classList].includes("red");

      await putLodgmentReservation(reservationId, {
        isSettledInDuplicate: !isSettledInDuplicate,
      });

      if (!isSettledInDuplicate) {
        return $Btn.classList.remove("red");
      }

      $Btn.classList.add("red");
    } catch {
      alert(
        "업데이트 도중 서버 오류가 발생했습니다. 정산 여부가 정상적으로 업데이트 되지 않았습니다."
      );
      return;
    }
  };

  React.useEffect(() => {
    const filterOtherPlatformReservations = ({ isOtherPlatform }) =>
      !isOtherPlatform;

    const fetchReservations = async () => {
      const isAllSelected = selectedLodgmentId === "all";
      const reservationData = isAllSelected
        ? await getAllReservationsForSettlement()
        : await getLodgmentReservationByLodgmentId(selectedLodgmentId);

      const hauReservations = reservationData.filter(
        filterOtherPlatformReservations
      );
      setReservations(hauReservations);
    };

    const fetchReservationCancelLogs = async () => {
      const isAllSelected = selectedLodgmentId === "all";
      const reservationRefuseLogData = isAllSelected
        ? await getAllLodgmentReservationRefuseLogsForSettlement()
        : await getLodgmentReservationRefuseLogByLodgmentId(selectedLodgmentId);
      const hauReservationRefuseLogData = reservationRefuseLogData.filter(
        filterOtherPlatformReservations
      );
      setReservationRefuseLogs(hauReservationRefuseLogData);
    };

    fetchReservations();
    fetchReservationCancelLogs();
  }, [selectedLodgmentId]);

  React.useEffect(() => {
    const $body = document.querySelector("body");
    if (isChangeDateModalOn) {
      $body.style.overflow = "hidden";
      return;
    }
    $body.style.overflow = "scroll";
  }, [isChangeDateModalOn]);

  React.useEffect(() => {
    const fetchPartnerLodgments = async () => {
      const partnerLodgmentsRes = await getPartnerLodgments();
      const partnerLodgments = partnerLodgmentsRes.data;
      setPartnerLodgments(partnerLodgments);
    };
    fetchPartnerLodgments();
  }, []);

  return (
    <div>
      정산일 검색:
      <input
        value={searchedSettlementDate}
        onChange={(e) => setSearchedSettlementDate(e.target.value)}
      />
      <div>
        예약내역{reservations.length > 0 && ` (${reservations.length}개)`}
      </div>
      <div style={{ display: "flex" }}>
        <select onChange={(e) => setSelectedLodgmentId(e.target.value)}>
          <option value="all">전체 보기</option>
          {PartnerLodgments?.map(({ name, _id }) => (
            <option key={_id} value={_id}>
              {name}
            </option>
          ))}
        </select>
        <button
          onClick={() => {
            const filename = `finestays x ${
              PartnerLodgments.find(({ _id }) => selectedLodgmentId === _id)
                ?.name || "모든 숙소"
            } ${convertDateIntoYYMMDD(searchedSettlementDate)} 정산건`;
            exportTableToCSV(filename, 9);
          }}
        >
          csv export
        </button>
        <button
          onClick={() => {
            exportMultipleTableToCSV(
              twoDimensionalWholeLogs,
              searchedSettlementDate,
              9
            );
          }}
        >
          multiple csv export
        </button>
      </div>
      {twoDimensionalWholeLogs.length > 0 &&
        twoDimensionalWholeLogs.map((logs) => (
          <div>
            <table>
              <tr>
                <TH>숙소명</TH>
                <TH>체크인</TH>
                <TH>체크아웃</TH>
                <TH>결제 가격</TH>
                <TH>주문번호</TH>
                <TH>예약 생성일</TH>
                <TH>유저명</TH>
                <TH>전화번호</TH>
                <TH>정산일</TH>
                <TH>카드 수수료</TH>
                <TH>매출</TH>
                <TH>정산액</TH>
                <TH>쿠폰할인(우리부담)</TH> {/* 중복 정산 */}
                <TH>현금 보유 기간</TH>
                <TH>예약 취소</TH>
                <TH>날짜 변경</TH>
                <TH>객실 변경</TH>
                <TH>정산 여부</TH>
                <TH>중복 정산 여부</TH>
              </tr>
              <tbody>
                <tr>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD>
                    ₩
                    {logs
                      .reduce((acc, { totalPrice }) => acc + totalPrice, 0)
                      .toLocaleString()}
                  </TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD>
                    ₩
                    {
                      logs
                        .reduce(
                          (acc, reservation) =>
                            acc + calculateSales(reservation),
                          0
                        )
                        .toLocaleString()
                        .split(".")[0]
                    }
                  </TD>
                  <TD>
                    ₩
                    {
                      logs
                        .reduce(
                          (acc, reservation) =>
                            acc + calculateSettlement(reservation),
                          0
                        )
                        .toLocaleString()
                        .split(".")[0]
                    }
                  </TD>
                  <TD></TD> {/* 쿠폰 할인*/}
                  <TD>
                    {(
                      logs.reduce((acc, reservation) => {
                        const settlementDate = reservation.lodgment
                          ?.isEarlySettlement
                          ? convertDateIntoString(
                              calculateSettlementDateForEarly(
                                reservation.basicInfo.end
                              )
                            )
                          : convertDateIntoString(
                              calculateSettlementDate(reservation.basicInfo.end)
                            );

                        const cashHoldDays = daysBetween(
                          reservation.createdAt,
                          settlementDate
                        );
                        return acc + cashHoldDays;
                      }, 0) / logs.length
                    ).toFixed(0)}
                    일
                  </TD>
                  {/* <TD></TD> 쿠폰 할인 */}
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                  <TD></TD>
                </tr>
                {logs.map((reservation) => {
                  // const settlementDate = reservation.lodgment.isEarlySettlement
                  //   ? calculateSettlementDateForEarly(
                  //       reservation.basicInfo.end
                  //     )?.toDateString()
                  //   : calculateSettlementDate(
                  //       reservation.basicInfo.end
                  //     ).toDateString();
                  const settlementDate = reservation.lodgment.isEarlySettlement
                    ? convertDateIntoString(
                        calculateSettlementDateForEarly(
                          reservation.basicInfo.end
                        )
                      )
                    : convertDateIntoString(
                        calculateSettlementDate(reservation.basicInfo.end)
                      );

                  const isCancelLog = reservation.status === "refused";
                  const isPartiallyRefundedLog =
                    reservation.status === "partiallyRefunded";
                  const isRefuseLog = isCancelLog || isPartiallyRefundedLog;

                  const backgroundColor = isRefuseLog
                    ? isCancelLog
                      ? "gray"
                      : "lightGray"
                    : "white";

                  const couponDiscount =
                    reservation.basicInfo?.couponDiscount || 0;

                  return (
                    <tr
                      key={reservation._id}
                      style={{
                        backgroundColor: backgroundColor,
                      }}
                    >
                      <TD>{reservation.lodgment?.name}</TD>
                      <TD>{reservation.basicInfo.start}</TD>
                      <TD>{reservation.basicInfo.end}</TD>
                      <TD>
                        {isPartiallyRefundedLog
                          ? // (
                            //     reservation.totalPrice -
                            //     reservation.partiallyRefundedAmount
                            //   ).toLocaleString()
                            null
                          : `₩${reservation.totalPrice}`}
                        {isCancelLog && "(취소)"}{" "}
                        {isPartiallyRefundedLog &&
                          `-₩${reservation.partiallyRefundedAmount}`}
                      </TD>
                      <TD>{reservation.payOID}</TD>
                      <TD>
                        {convertDateIntoString(new Date(reservation.createdAt))}
                      </TD>
                      <TD>{reservation.userInfo.name}</TD>
                      <TD>{reservation.userInfo.phone}</TD>
                      {reservation.isOtherPlatform ? (
                        <TD>타플랫폼</TD>
                      ) : (
                        <TD>{settlementDate}</TD>
                      )}
                      <TD>임시</TD>
                      <TD>₩{calculateSales(reservation).toLocaleString()}</TD>
                      <TD>
                        ₩{calculateSettlement(reservation).toLocaleString()}
                      </TD>
                      <TD
                        style={{
                          color: "rgb(235, 87, 41)",
                          fontWeight: "bold",
                        }}
                      >
                        {couponDiscount
                          ? `₩${couponDiscount.toLocaleString()}`
                          : null}
                      </TD>{" "}
                      {/* 쿠폰 할인*/}
                      <TD>
                        {daysBetween(reservation.createdAt, settlementDate)}일
                      </TD>
                      <TD>
                        {isRefuseLog ? (
                          <div></div>
                        ) : (
                          <button
                            onClick={() =>
                              onCancelReservationBtn(reservation._id)
                            }
                          >
                            취소!
                          </button>
                        )}
                      </TD>
                      {isRefuseLog ? (
                        <>
                          <TD></TD>
                          <TD></TD>
                        </>
                      ) : (
                        <>
                          <TD>
                            <button
                              onClick={() => onChangeDateBtn(reservation._id)}
                            >
                              변경하기
                            </button>
                          </TD>
                          <TD>
                            <button
                              onClick={() => onChangeRoomBtn(reservation._id)}
                            >
                              변경하기
                            </button>
                          </TD>
                        </>
                      )}
                      <TD
                        onClick={async () =>
                          await onUpdateIsSettledBtn(reservation._id)
                        }
                      >
                        {
                          <S.IsSettledToggleBtn
                            id={reservation._id}
                            className={reservation.isSettled ? "" : "red"}
                          >
                            정산 토글
                          </S.IsSettledToggleBtn>
                        }
                      </TD>
                      <TD
                        onClick={async () =>
                          await onUpdateIsSettledInDuplicateBtn(reservation._id)
                        }
                      >
                        {
                          <S.IsSettledToggleBtn
                            id={`${reservation._id}-duplicate`}
                            className={
                              reservation.isSettledInDuplicate ? "" : "red"
                            }
                          >
                            중복 정산 토글
                          </S.IsSettledToggleBtn>
                        }
                      </TD>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ))}
      {isChangeDateModalOn && (
        <ChangeDateModal
          lodgmentReservationId={reservationIdForModal}
          setIsChangeDateModalOn={setIsChangeDateModalOn}
          setReservationIdForModal={setReservationIdForModal}
        />
      )}
      {isChangeRoomModalOn && (
        <ChangeRoomModal
          lodgmentReservationId={reservationIdForModal}
          setIsChangeRoomModalOn={setIsChangeRoomModalOn}
          setReservationIdForModal={setReservationIdForModal}
        />
      )}
    </div>
  );
}
