import { uploadPackageImg } from "api/image";

export const uploadFile = async (e) => {
  const fileList = e.target.files;
  const formData = new FormData();
  Array.from(fileList).forEach((file: string) => {
    formData.append("images", file);
  });
  const { data: images } = await uploadPackageImg(formData);
  return images;
};
