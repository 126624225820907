import React from "react";
import { formatNumber } from "utils/format";
import { updateSettlementInfo } from "api/settlementLog";
import { checkStaticCommission } from "utils/settlement";
import CommissionModal from "./CommissionModal";
import * as S from "./commission.styled";
import { deleteSettlementInfo } from "api/settlement";

export default function CommissionTableRow({ row }) {
  const [commission, setCommission] = React.useState<string | number>(null);
  const [naverDirectCommission, setNaverDirectCommission] = React.useState<
    string | number
  >(null);
  const [naverAlgorithmCommission, setNaverAlgorithmCommission] =
    React.useState<string | number>(null);
  const [bank, setBank] = React.useState<string>("");
  const [accountNumber, setAccountNumber] = React.useState<string>("");
  const [depositor, setDepositor] = React.useState<string>("");
  const [isCommissionModalOpen, setIsCommissionModalOpen] =
    React.useState<boolean>(false);

  const isStaticCommission = checkStaticCommission(row);
  const staticCommision = isStaticCommission
    ? row.commissions[0].commission
    : 0;
  const [vat, setVat] = React.useState<string | number>(null);
  const [rowData, setRowData] = React.useState<string>(row);

  // initial data
  React.useEffect(() => {
    setCommission(row.commission);
    setNaverDirectCommission(
      row.platformCommissions.naver.directSearchCommission
    );
    setNaverAlgorithmCommission(
      row.platformCommissions.naver.algorithmCommission
    );
    setBank(row.bank);
    setAccountNumber(row.accountNumber);
    setDepositor(row.depositor);
    setVat(row.vat);
  }, [row]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const lodgmentId = row.lodgment._id;
    const lodgmentName = row.lodgment?.name;
    // if (!confirm(`${lodgmentName}의 정산 정보를 수정하시겠습니까?`)) {
    //   return;
    // }

    const payload = {
      commission,
      platformCommissions: {
        naver: {
          directSearchCommission: Number(naverDirectCommission),
          algorithmCommission: Number(naverAlgorithmCommission),
        },
      },
      bank,
      accountNumber,
      depositor,
      vat,
    };
    try {
      await updateSettlementInfo(lodgmentId, payload);
      alert(`${lodgmentName}의 정산 정보가 수정되었습니다.`);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <tr key={row._id}>
      <td className="lodgment-id">{row.lodgment?._id}</td>
      <td>{row?.lodgment?.name}</td>
      <td>
        <input
          value={commission}
          onChange={(e) => setCommission(formatNumber(e.target.value))}
        />
      </td>
      <td
        onClick={() => {
          setIsCommissionModalOpen(true);
        }}
      >
        {isStaticCommission ? staticCommision : "자세히보기"}
        <S.CommissionModalButton>+</S.CommissionModalButton>
      </td>
      <td>
        <input
          value={vat}
          onChange={(e) => setVat(formatNumber(e.target.value))}
        />
      </td>
      <td>{row.isEarlySettlement ? "O" : "X"}</td>
      <td className="naver-direct">
        <input
          value={naverDirectCommission}
          onChange={(e) =>
            setNaverDirectCommission(formatNumber(e.target.value))
          }
          className={`${
            naverDirectCommission !== 3.3 ? "diff-commission" : null
          }`}
        />
      </td>
      <td className="naver-algorithm">
        <input
          value={naverAlgorithmCommission}
          onChange={(e) =>
            setNaverAlgorithmCommission(formatNumber(e.target.value))
          }
        />
      </td>
      <td>
        <input value={bank} onChange={(e) => setBank(e.target.value)} />
      </td>
      <td>
        <input
          value={accountNumber}
          onChange={(e) => setAccountNumber(e.target.value)}
        />
      </td>
      <td>
        <input
          value={depositor}
          onChange={(e) => setDepositor(e.target.value)}
        />
      </td>
      <td className="edit" onClick={handleSubmit}>
        저장
      </td>
      {isCommissionModalOpen && (
        <CommissionModal
          isOpen={isCommissionModalOpen}
          setIsOpen={setIsCommissionModalOpen}
          setRowData={setRowData}
          row={rowData}
        />
      )}
    </tr>
  );
}
