export const AIRBNB_TAG: {
  view: string[];
  convenience: string[];
  location: string[];
  mood: string[];
  kind: string[];
  facility: string[];
} = {
  view: ["오션뷰", "산 전망", "정원/마당"],
  convenience: [
    "취사가능",
    "조식",
    "주변 편의점/마트",
    "주차 편리",
    "전기차 충전",
  ],
  location: ["해변에 인접", "공항근처"],
  mood: ["자연", "모던"],
  kind: [
    "독채",
    "감성숙소",
    "호텔",
    "반려동물과 함께",
    "게스트하우스",
    "콘도/리조트",
    "아이와함께",
  ],
  facility: ["바베큐", "수영장", "스파/자쿠지", "테라스"],
};

export const AIRBNB_TAGS_FOR_SELECT: { value: string; label: string }[] = [
  { value: "오션뷰", label: "오션뷰" },
  { value: "산 전망", label: "산 전망" },
  { value: "정원/마당", label: "정원/마당" },
  { value: "취사가능", label: "취사가능" },
  { value: "조식", label: "조식" },
  { value: "주변 편의점/마트", label: "주변 편의점/마트" },
  { value: "주차 편리", label: "주차 편리" },
  { value: "전기차 충전", label: "전기차 충전" },
  { value: "해변에 인접", label: "해변에 인접" },
  { value: "공항근처", label: "공항근처" },
  { value: "자연", label: "자연" },
  { value: "모던", label: "모던" },
  { value: "독채", label: "독채" },
  { value: "감성숙소", label: "감성숙소" },
  { value: "호텔", label: "호텔" },
  { value: "반려동물과 함께", label: "반려동물과 함께" },
  { value: "게스트하우스", label: "게스트하우스" },
  { value: "콘도/리조트", label: "콘도/리조트" },
  { value: "아이와함께", label: "아이와함께" },
  { value: "바베큐", label: "바베큐" },
  { value: "수영장", label: "수영장" },
  { value: "스파/자쿠지", label: "스파/자쿠지" },
  { value: "테라스", label: "테라스" },
];
