import { useEffect, useState } from "react";
import { Label, Input, GeoCodeButton } from "../style";
import axios from "config/axiosConfig";
import { Flex } from "components/style";
import { POST_TYPE_ARR, DISTRICT_ARR, INFLUENCER_ARR } from "constants/post";
import { Button } from "react-bootstrap";

export default function InfoEditor({ post, setPost }) {
  const [addingComment, setAddingComment] = useState({
    artist: [],
    good: [],
    bad: [],
  });

  useEffect(() => {
    const tempAddingComment = { artist: [], good: [], bad: [] };
    tempAddingComment.artist =
      post.comment.artist.length > 0
        ? post.comment.artist
        : [{ text: "", from: "" }];
    tempAddingComment.good =
      post.comment.good.length > 0 ? post.comment.good : [{ text: "" }];
    tempAddingComment.bad =
      post.comment.bad.length > 0 ? post.comment.bad : [{ text: "" }];

    setAddingComment(tempAddingComment);
  }, []);

  const decreaseInput = (type) => {
    if (addingComment[type].length === 1) return;

    let tempAddingComment = { ...addingComment };
    tempAddingComment[type].pop();

    setAddingComment(tempAddingComment);
  };

  const increaseInput = (type) => {
    let tempAddingComment = { ...addingComment };
    if (type === "artist") {
      tempAddingComment[type].push({ text: "", from: "" });
    }
    if (type !== "artist") {
      tempAddingComment[type].push({ text: "" });
    }
    setAddingComment(tempAddingComment);
  };

  const onChangeComment = (type, idx, text) => {
    let tempAddingComment = { ...addingComment };
    tempAddingComment[type][idx]["text"] = text;
    setAddingComment(tempAddingComment);
  };

  const onChangeCommentFrom = (type, idx, from) => {
    let tempAddingComment = { ...addingComment };
    tempAddingComment[type][idx]["from"] = from;
    setAddingComment(tempAddingComment);
  };

  useEffect(() => {
    if (
      !addingComment.artist.length &&
      !addingComment.good.length &&
      !addingComment.bad.length
    )
      return;

    let tempAddingComment = { ...addingComment };
    console.log(tempAddingComment);
    tempAddingComment.artist = tempAddingComment.artist.filter(
      (el) => el.text && el.from
    );
    tempAddingComment.good = tempAddingComment.good.filter((el) => el.text);
    tempAddingComment.bad = tempAddingComment.bad.filter((el) => el.text);
    setPost({ ...post, comment: tempAddingComment });
  }, [addingComment]);

  useEffect(() => {
    console.log(post);
  }, [post]);

  const geoCode = async () => {
    const address = post.address;
    const {
      data: {
        coord: [longitude, latitude],
      },
    } = await axios.get(`/openApi/geocode/${address}`);

    if (longitude && latitude) {
      setPost({ ...post, longitude, latitude });
    }

    return;
  };

  return (
    <div>
      <div>
        <h2 style={{ marginTop: 30 }} onClick={() => console.log(post)}>
          기본 정보
        </h2>
        <form style={{ marginBottom: 50 }}>
          <div>
            <div>
              <Label>id</Label>
              <Input
                type="text"
                defaultValue={post.id}
                onChange={(e) => {
                  setPost({ ...post, id: e.target.value });
                }}
                disabled
              />
            </div>
            <Label>장소명</Label>
            <Input
              type="text"
              defaultValue={post.name}
              onChange={(e) => {
                setPost({
                  ...post,
                  name: e.target.value,
                  spotname: e.target.value,
                });
              }}
            />
          </div>
          <div>
            <Label>종류</Label>
            <select
              style={{ width: 300, height: 30, marginLeft: 20 }}
              value={post.type}
              disabled
              onChange={(e) => {
                setPost({
                  ...post,
                  type: e.target.value,
                });
              }}
            >
              {POST_TYPE_ARR.map((el) => {
                return <option value={el}>{el}</option>;
              })}
            </select>
          </div>
          <div>
            <Label>지역</Label>
            <select
              style={{ width: 300, height: 30, marginLeft: 20 }}
              value={post.district}
              onChange={(e) => {
                setPost({
                  ...post,
                  district: e.target.value,
                });
              }}
            >
              <option value="" disabled>
                ---
              </option>
              {DISTRICT_ARR.map((el) => {
                return <option value={el}>{el}</option>;
              })}
            </select>
          </div>
          <div>
            <Label>전화번호</Label>
            <Input
              type="text"
              defaultValue={post.phone}
              onChange={(e) => {
                setPost({ ...post, phone: e.target.value });
              }}
            />
          </div>
          <Flex>
            <Label>주소</Label>
            <Input
              type="text"
              defaultValue={post.address}
              onChange={(e) => {
                setPost({ ...post, address: e.target.value });
              }}
            />
            <GeoCodeButton onClick={() => geoCode()}>
              위도 경도 변환
            </GeoCodeButton>
          </Flex>

          <div>
            <Label>위도</Label>
            <Input
              type="text"
              value={post.latitude}
              onChange={(e) => {
                setPost({ ...post, latitude: parseFloat(e.target.value) });
              }}
            />
          </div>
          <div>
            <Label>경도</Label>
            <Input
              type="text"
              value={post.longitude}
              onChange={(e) => {
                setPost({ ...post, longitude: parseFloat(e.target.value) });
              }}
            />
          </div>

          <div>
            <Label style={{ fontSize: 15 }}>네이버지도 링크</Label>
            <Input
              type="text"
              defaultValue={post.link}
              onChange={(e) => {
                setPost({ ...post, link: e.target.value });
              }}
            />
          </div>

          <div style={{ marginTop: 30 }}>
            <Label>한줄 설명</Label>
            <Input
              type="text"
              defaultValue={post.description.summary}
              onChange={(e) => {
                let description = { ...post.description };
                description["summary"] = e.target.value;
                setPost({ ...post, description });
              }}
            />
          </div>
          <Flex style={{ marginTop: 10, marginBottom: 10 }}>
            <Label style={{ paddingRight: 20 }}>부연 설명 (3줄 이상)</Label>
            <textarea
              style={{
                marginLeft: 20,
                height: 100,
                width: 300,
                whiteSpace: "pre-line",
              }}
              defaultValue={post.description.detail}
              onChange={(e) => {
                let description = { ...post.description };
                description["detail"] = e.target.value;
                setPost({ ...post, description });
              }}
            />
          </Flex>
          <div>
            <div>
              <Label>인스타그램</Label>
              <Input
                value={post.website.instagram}
                onChange={(e) => {
                  const tempWebsite = { ...post.website };
                  tempWebsite.instagram = e.target.value;
                  setPost({ ...post, website: tempWebsite });
                }}
              />
            </div>
            <div>
              <Label>블로그</Label>
              <Input
                value={post.website.blog}
                onChange={(e) => {
                  const tempWebsite = { ...post.website };
                  tempWebsite.blog = e.target.value;
                  setPost({ ...post, website: tempWebsite });
                }}
              />
            </div>
            <div>
              <Label>홈페이지</Label>
              <Input
                value={post.website.homepage}
                onChange={(e) => {
                  const tempWebsite = { ...post.website };
                  tempWebsite.homepage = e.target.value;
                  setPost({ ...post, website: tempWebsite });
                }}
              />
            </div>
          </div>

          <div style={{ marginTop: 20 }}>
            <Label>preference</Label>
            {["default", "spring", "summer", "fall", "winter"].map((season) => {
              return (
                <Input
                  style={{ width: 50 }}
                  type="number"
                  value={post.preference[season]}
                  max={10}
                  min={0}
                  onChange={(e) => {
                    let tempPreference = { ...post.preference };
                    tempPreference[season] = parseInt(e.target.value);
                    setPost({
                      ...post,
                      preference: tempPreference,
                    });
                  }}
                />
              );
            })}
            <div style={{ marginLeft: 120 }}>
              {["기본", "봄", "여름", "가을", "겨울"].map((el) => {
                return (
                  <div style={{ width: 70, display: "inline-block" }}>{el}</div>
                );
              })}
            </div>
          </div>

          <div style={{ marginTop: 50 }}>
            <div>
              <Label style={{ marginBottom: 5 }}>아티스트 리뷰</Label>
              <Button
                onClick={() => {
                  decreaseInput("artist");
                }}
                style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
              >
                -
              </Button>
              <Button
                onClick={() => {
                  increaseInput("artist");
                }}
                style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
              >
                +
              </Button>
              {addingComment.artist.map((el, idx) => {
                return (
                  <div>
                    <textarea
                      style={{ width: 420, whiteSpace: "pre-line" }}
                      value={addingComment.artist[idx].text}
                      onChange={(e) => {
                        onChangeComment("artist", idx, e.target.value);
                      }}
                    ></textarea>
                    <select
                      type="text"
                      style={{ width: 150, height: 30 }}
                      value={addingComment.artist[idx].from}
                      onChange={(e) => {
                        onChangeCommentFrom("artist", idx, e.target.value);
                      }}
                    >
                      <option value="">---</option>
                      {INFLUENCER_ARR.map((el) => {
                        return <option value={el}>{el}</option>;
                      })}
                    </select>
                  </div>
                );
              })}
            </div>
            <div>
              <Label style={{ marginBottom: 5, marginTop: 30, width: 150 }}>
                좋은 리뷰 (3개 이상)
              </Label>
              <Button
                onClick={() => {
                  decreaseInput("good");
                }}
                style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
              >
                -
              </Button>
              <Button
                onClick={() => {
                  increaseInput("good");
                }}
                style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
              >
                +
              </Button>
              {addingComment.good.map((el, idx) => {
                return (
                  <div>
                    <textarea
                      style={{ width: 420, whiteSpace: "pre-line" }}
                      value={addingComment.good[idx].text}
                      onChange={(e) => {
                        onChangeComment("good", idx, e.target.value);
                      }}
                    ></textarea>
                  </div>
                );
              })}
            </div>
            <div>
              <Label style={{ marginBottom: 5, marginTop: 30, width: 150 }}>
                나쁜 리뷰 (2개 이상)
              </Label>
              <Button
                onClick={() => {
                  decreaseInput("bad");
                }}
                style={{ height: 30, width: 30, marginLeft: 20, padding: 0 }}
              >
                -
              </Button>
              <Button
                onClick={() => {
                  increaseInput("bad");
                }}
                style={{ height: 30, width: 30, marginLeft: 4, padding: 0 }}
              >
                +
              </Button>
              {addingComment.bad.map((el, idx) => {
                return (
                  <div>
                    <textarea
                      type="text"
                      style={{ width: 420, whiteSpace: "pre-line" }}
                      value={addingComment.bad[idx].text}
                      onChange={(e) => {
                        onChangeComment("bad", idx, e.target.value);
                      }}
                    ></textarea>
                  </div>
                );
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
