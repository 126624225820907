import { SETTLEMENT_LOG_PATH } from "./../constants/path";
import { myAxios } from "./myAxios";

export const getSettlementLogById = async (settlementLogId) => {
  const path = SETTLEMENT_LOG_PATH + `/${settlementLogId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const createSettlementLog = async (data) => {
  const path = SETTLEMENT_LOG_PATH;
  const res = await myAxios.post({ path, data });
  return res.data.data;
};

export const updateSettlementLog = async (settlementLogId, data) => {
  const path = SETTLEMENT_LOG_PATH + `/${settlementLogId}`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};

export const deleteSettlementLog = async (settlementLogId) => {
  const path = SETTLEMENT_LOG_PATH + `/${settlementLogId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const getSettlementLogsByLodgmentId = async (lodgmentId) => {
  const path = SETTLEMENT_LOG_PATH + "/withLodgmentId" + `/${lodgmentId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getSettlementInfos = async () => {
  const path = SETTLEMENT_LOG_PATH + "/infos";
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const updateSettlementInfo = async (lodgmentId, data) => {
  const path = SETTLEMENT_LOG_PATH + "/infos" + `/${lodgmentId}`;
  const res = await myAxios.put({ path, data });
  return res.data.data;
};

export const getSettlementBalance = async () => {
  const path = SETTLEMENT_LOG_PATH + "/balance";
  const res = await myAxios.get({ path });
  return res.data.data;
};
