export const compareTime = (a, b) => {
  if (a.time.substr(0, 2) > b.time.substr(0, 2)) {
    return 1;
  } else if (a.time.substr(0, 2) < b.time.substr(0, 2)) {
    return -1;
  }

  if (a.time.substr(2, 2) > b.time.substr(2, 2)) {
    return 1;
  } else if (a.time.substr(2, 2) < b.time.substr(2, 2)) {
    return -1;
  }
  return 0;
};
