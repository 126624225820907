import styled from "styled-components";

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;
  h2 {
    margin-right: 20px;
  }
`;

export const LodgmentBox = styled.div`
  margin-bottom: 20px;
  border: 1px solid black;
  width: fit-content;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LodgmentInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 30px;
`;

export const LodgmentName = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-right: 20px;
  color: purple;
`;

export const RouteLink = styled.a`
  background-color: black;
  font-size: 14px;
  padding: 8px 12px;
  color: white;
  text-decoration: none;
`;

export const Button = styled.button`
  background-color: black;
  font-size: 14px;
  padding: 8px 12px;
  color: white;
  text-decoration: none;
  outline: none;
  border: none;
  margin-left: 10px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    font-size: 14px;
    font-weight: bold;
  }
`;

export const ImageSection = styled.div`
  display: flex;
  justify-items: flex-start;
  align-items: center;
  margin-top: 30px;
`;
export const Picture = styled.div`
  width: 200px;
  height: 200px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const Title = styled.h3`
  font-weight: bold;
  font-size: 24px;
  margin-top: 30px;
`;

export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
`;

export const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 100px;
`;

export const LinkBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Number = styled.div`
  font-weight: bold;
  font-size: 14px;
  margin-right: 10px;
`;

export const SubmitButton = styled.button`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  background-color: black;
  color: ivory;
`;

export const LangTitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const InputsBox = styled.div`
  display: flex;
  flex-direction: column;
`;
