import styled from "styled-components";
import { defaultButton } from "styles/styleConstants";

export interface ButtonProps {
  fontSize?: string;
  backgroundColor?: string;
  color?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  padding?: string;
}

export const StyledButton = styled.button<ButtonProps>`
  ${defaultButton}
  font-size: ${({ fontSize }) => fontSize || "15px"};
  background-color: ${({ backgroundColor }) => backgroundColor || "#2B2B2B"};
  color: ${({ color }) => color || "white"};
  border-radius: ${({ borderRadius }) => borderRadius || "8px"};
  /* width: ${({ width }) => width || "100%"}; */
  height: ${({ height }) => height};
  padding: ${({ padding }) => padding};
`;
