import React, { useState, useEffect } from "react";
import axios from "config/axiosConfig";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import useTitle from "hooks/useTitle";
import { TAG_CATEGORY_OPTION } from "constants/tag";

export default function RegisterPostTag() {
  useTitle("태그 등록");
  const history = useHistory();
  const [tags, setTags] = useState([]);
  const [newTagName, setNewTagName] = useState("");
  const [newTagCategory, setNewTagCategory] = useState(null);
  const [matchingTags, setMatchingTags] = useState([]);

  useEffect(async () => {
    const initTags = async () => {
      const {
        data: { tags },
      } = await axios.get("postTags");

      setTags(tags);
    };
    initTags();
  }, []);

  const searchExistTags = (tagName) => {
    console.log(tagName);
    if (tagName != "") {
      const filtered = tags.filter((el) => {
        return el.name.includes(tagName);
        // return el.name.indexOf(tagName) === -1;
      });
      console.log(filtered);
      setMatchingTags(filtered);
    } else {
      setMatchingTags([]);
    }
  };

  const registerTag = async () => {
    if (newTagName != "" && newTagCategory) {
      await axios
        .post("postTags", {
          name: newTagName,
          category: newTagCategory,
        })
        .then((res) => {
          alert("완료!");
          window.location.reload();
          return;
        })
        .catch((err) => {
          console.log(err);
          alert(err.response.data.msg);
        });
    } else {
      alert(" 입력하고 눌러주세욥 >_< ");
    }
  };

  return (
    <div>
      <h2>태그 등록</h2>
      <form style={{ marginTop: 30 }}>
        <div>
          <div style={{ width: 100, fontWeight: "600", fontSize: 18 }}>
            태그명 (이름)
          </div>
          <input
            autoComplete="off"
            type="text"
            name="name"
            style={{
              width: 400,
              height: 50,
              borderRadius: 10,
              marginTop: 10,
              border: "1px solid #494949",
              paddingLeft: 10,
              paddingRight: 10,
            }}
            defaultValue={newTagName}
            onChange={(e) => {
              searchExistTags(e.target.value);
              // console.log(e.target.value);
              setNewTagName(e.target.value);
            }}
          />
          <div style={{ width: 400 }}>
            <Select
              options={TAG_CATEGORY_OPTION}
              onChange={(val) => {
                setNewTagCategory(val.value);
              }}
            />
          </div>
          {matchingTags?.length ? (
            <div
              style={{
                width: 400,
                border: "1px solid #b9b9b9",
                marginTop: 15,
                borderRadius: 10,
                paddingRight: 10,
                paddingLeft: 10,
              }}
            >
              <div
                style={{
                  fontWeight: "700",
                  fontSize: 18,
                  borderBottom: "1px solid #494949",
                  paddingBottom: 10,
                  marginBottom: 10,
                  paddingTop: 10,
                }}
              >
                이미 있네요!!!!
              </div>
              {matchingTags.map((mt, idx) => {
                return (
                  <div
                    style={{
                      paddingBottom: 5,
                      paddingTop: 5,
                      borderBottom:
                        idx < matchingTags.length - 1
                          ? "1px solid #b9b9b9"
                          : "0px solid white",
                    }}
                  >
                    {mt.name}
                  </div>
                );
              })}
            </div>
          ) : null}

          <div
            style={{
              width: 100,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 20,
              borderRadius: 10,
              backgroundColor: "greenyellow",
              textAlign: "center",
              cursor: "pointer",
            }}
            onClick={() => {
              registerTag();
            }}
          >
            등록
          </div>
        </div>
      </form>
    </div>
  );
}
