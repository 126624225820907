import styled from "styled-components";

export const Label = styled.div``;
export const InputContainer = styled.div`
  margin: 0 0 20px 0;
`;

export const SelectedSpot = styled.label`
  margin: 4px;
  text-align: center;
  min-width: 50px;
  color: white;
  border-radius: 13px;
  border: 1px solid #35b1de;
  background-color: #35b1de;
  padding: 5px;
`;
export const Spot = styled.label`
  margin: 4px;
  text-align: center;
  min-width: 50px;
  color: #35b1de;
  border: 1px solid #35b1de;
  border-radius: 13px;
  background-color: white;
  padding: 5px;
`;

export const TagBox = styled.div`
  width: 600px;
  height: 200px;
  margin-bottom: 10px;
  border: 1px solid;
  padding: 20px;
`;
export const Tag = styled.label`
  margin: 4px;
  text-align: center;
  min-width: 50px;
  color: white;
  border-radius: 13px;
  background-color: #35b1de;
`;

export const Th = styled.th`
  text-align: center;
  width: 150px;
`;

export const Tr = styled.tr``;
export const Td = styled.td`
  padding: 15px;
`;
