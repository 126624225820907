import * as React from "react";
import { StyledButton } from "./button.style";

export interface IButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  color?: string;
  backgroundColor?: string;
  fontSize?: string;
  onClick?;
  name?: string;
  value?: string;
  borderRadius?: string;
  width?: string;
  height?: string;
  padding?: string;
}

export default function Button({
  children,
  disabled,
  color,
  backgroundColor,
  fontSize,
  onClick,
  name,
  value,
  borderRadius,
  width,
  height,
  padding,
}: IButtonProps) {
  const styles = {
    color,
    backgroundColor,
    fontSize,
    borderRadius,
    width,
    height,
    padding,
  };
  return (
    <StyledButton
      disabled={disabled}
      {...styles}
      onClick={onClick}
      value={value}
      name={name}
    >
      {children}
    </StyledButton>
  );
}
