import { getSettlementInfos } from "api/settlementLog";
import CommissionItem from "components/Commission/CommissionItem";
import React from "react";
import * as S from "../../components/Commission/commission.styled";
import CommissionTable from "components/Commission/CommissionTable";

export interface CommissionProps {}

export default function Commission({}: CommissionProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<any[]>([]); // data: any[]
  React.useEffect(() => {
    const fetch = async () => {
      try {
        const response = await getSettlementInfos();
        setData(response);
        setIsLoading(false);
      } catch (err) {
        console.error(err);
      }
    };

    fetch();
  }, []);

  if (isLoading) return <div>로딩중</div>;
  return (
    <S.Container>
      {/* {data.map((item) => (
        <CommissionItem item={item} key={item.lodgment._id} />
      ))} */}
      <CommissionTable data={data} />
    </S.Container>
  );
}
