import { PREOPEN_PATH, PREOPEN_MULTIPLE_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getPreopenLodgments = async (): Promise<any> => {
  const path = `${PREOPEN_PATH}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getPreopenLodgment = async (id): Promise<any> => {
  const path = `${PREOPEN_PATH}/${id}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const createPreopenLodgment = async (data): Promise<any> => {
  const path = `${PREOPEN_PATH}`;
  const res = await myAxios.post({ path, data });
  return res.data.data;
};

export const deletePreopenLodgment = async (id): Promise<any> => {
  const path = `${PREOPEN_PATH}/${id}`;
  const res = await myAxios.delete({ path });
  return res.data.data;
};

export const updatePreopenLodgment = async (id, data): Promise<any> => {
  const path = `${PREOPEN_PATH}/${id}`;
  const res = await myAxios.put({ data, path });
  return res.data.data;
};

export const createPreopenMultipleLodgment = async (data): Promise<any> => {
  const path = `${PREOPEN_MULTIPLE_PATH}`;
  const res = await myAxios.post({ path, data });
  return res.data.data;
};

export const getPreopenMultipleLodgments = async (): Promise<any> => {
  const path = `${PREOPEN_MULTIPLE_PATH}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getPreopenMultipleLodgmentById = async (id): Promise<any> => {
  const path = `${PREOPEN_MULTIPLE_PATH}/${id}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const updatePreopenMultipleLodgment = async (id, data): Promise<any> => {
  const path = `${PREOPEN_MULTIPLE_PATH}/${id}`;
  const res = await myAxios.put({ data, path });
  return res.data.data;
};

export const deletePreopenMultipleLodgment = async (id): Promise<any> => {
  const path = `${PREOPEN_MULTIPLE_PATH}/${id}`;
  const res = await myAxios.delete({ path });
  return res.data.data;
};
