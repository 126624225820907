import PostImageAdder from "components/PostImage/PostImageAdder";
import { Flex } from "components/style";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { ImgItem, ImgDeleteButton, ImgInfoInput, ImgInfoLabel } from "../style";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

export default function ImageEditor({ post, setPost, deleteImageFromPost }) {
  const [foldContainer, setFoldContainer] = useState(true);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (dragResult) => {
    if (!dragResult.destination) {
      return;
    }

    const images = post.images;
    const items = reorder(
      images,
      dragResult.source.index,
      dragResult.destination.index
    );

    setPost({ ...post, images: items });
  };

  return (
    <div style={{ marginTop: 60 }}>
      <Flex>
        <h2 onClick={() => console.log(post.images)}>사진 등록!!</h2>
        <Button
          style={{ height: 30, width: 60, marginLeft: 10 }}
          size="sm"
          onClick={() => setFoldContainer(!foldContainer)}
        >
          {foldContainer ? "펼치기" : "접기"}
        </Button>
      </Flex>
      {!foldContainer ? (
        <div>
          <PostImageAdder post={post} setPost={setPost} />

          <h5>현재 사진 목록 (드래그로 순서 조정 가능)</h5>
          <DragDropContext onDragEnd={(res) => onDragEnd(res)}>
            <Droppable droppableId="imgList" direction="vertical">
              {(provided) => (
                <div
                  className="imgList"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {post.images.map((el, idx) => {
                    return (
                      <Draggable
                        key={`img${idx}`}
                        draggableId={`img${idx}`}
                        index={idx}
                      >
                        {(provided) => (
                          <ImgItem
                            ref={provided.innerRef}
                            {...provided.dragHandleProps}
                            {...provided.draggableProps}
                          >
                            <img
                              src={el.url}
                              style={{ width: 200, height: 200 }}
                            />
                            <div style={{ padding: 20 }}>
                              <Flex>
                                <h4>{idx + 1}</h4>
                                <ImgDeleteButton
                                  onClick={() => deleteImageFromPost(idx)}
                                >
                                  삭제
                                </ImgDeleteButton>
                              </Flex>
                              <div>
                                <ImgInfoLabel>사진 URL</ImgInfoLabel>
                                <ImgInfoInput
                                  type="text"
                                  value={el.url}
                                  onChange={(e) => {
                                    let imgs = post.images;
                                    imgs[idx].url = e.target.value;
                                    setPost({ ...post, images: imgs });
                                  }}
                                />
                              </div>
                              <div>
                                <ImgInfoLabel>사진 출처</ImgInfoLabel>
                                <ImgInfoInput
                                  type="text"
                                  value={el.source}
                                  onChange={(e) => {
                                    let imgs = post.images;
                                    imgs[idx].source = e.target.value;
                                    setPost({ ...post, images: imgs });
                                  }}
                                />
                              </div>
                              <div>
                                <ImgInfoLabel>사진 종류</ImgInfoLabel>
                                <select
                                  style={{ width: 150, marginLeft: 10 }}
                                  value={el.type}
                                  onChange={(e) => {
                                    let imgs = post.images;
                                    imgs[idx].type = e.target.value;
                                    setPost({ ...post, images: imgs });
                                  }}
                                >
                                  <option value="" selected>
                                    ----
                                  </option>
                                  <option value="outside">외부</option>
                                  <option value="inside">내부</option>
                                  <option value="food">음식</option>
                                  <option value="pose">인물</option>
                                </select>
                              </div>
                              <div>
                                <ImgInfoLabel>사진 날짜</ImgInfoLabel>
                                <ImgInfoInput
                                  style={{ width: 150 }}
                                  type="month"
                                  value={el.date}
                                  onChange={(e) => {
                                    let imgs = post.images;
                                    imgs[idx].date = e.target.value;
                                    setPost({ ...post, images: imgs });
                                  }}
                                />
                              </div>
                            </div>
                          </ImgItem>
                        )}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : null}
    </div>
  );
}
