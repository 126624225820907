import * as React from "react";
import * as S from "../forms.style";

export interface IIsFirstMsgInputProps {
  isFirstMsg;
  setIsFirstMsg;
}

export default function IsFirstMsgInput({
  isFirstMsg,
  setIsFirstMsg,
}: IIsFirstMsgInputProps) {
  return (
    <S.InputLayout>
      <S.Label>자동전송 메시지 여부: </S.Label>
      <label htmlFor="isFirstMsgTrue">예</label>
      <S.Input
        id="isFirstMsgTrue"
        name="isFirstMsg"
        type="radio"
        checked={isFirstMsg}
        onChange={() => {
          setIsFirstMsg(true);
        }}
      />
      <label htmlFor="isFirstMsgFalse">아니오</label>
      <S.Input
        id="isFirstMsgFalse"
        name="isFirstMsg"
        type="radio"
        checked={!isFirstMsg}
        onChange={() => {
          setIsFirstMsg(false);
        }}
      />
    </S.InputLayout>
  );
}
