import {
  deleteSettlementLog,
  getSettlementLogById,
  updateSettlementLog,
} from "api/settlementLog";
import * as React from "react";
import { Router, useHistory, useParams } from "react-router-dom";
import * as S from "./settlementLog.style";

export interface ISettlementLogDetailProps {}

export interface Params {
  settlementLogId: string;
}

export default function SettlementLogDetail(props: ISettlementLogDetailProps) {
  const { settlementLogId } = useParams<Params>();
  const history = useHistory();

  const [sender, setSender] = React.useState("");
  const [lodgmentName, setLodgmentName] = React.useState("");
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [amount, setAmount] = React.useState("");
  const [phoneNum, setPhoneNum] = React.useState("");
  const [date, setDate] = React.useState("");

  const onSubmit = async () => {
    const data = {
      sender,
      lodgmentName,
      paymentMethod,
      amount,
      phoneNum,
      date,
    };
    await updateSettlementLog(settlementLogId, data);
    window.location.reload();
  };

  React.useEffect(() => {
    const fetchSettlementLog = async () => {
      const settlementLog = await getSettlementLogById(settlementLogId);
      setSender(settlementLog.sender);
      setLodgmentName(settlementLog.lodgment.name);
      setPaymentMethod(settlementLog.paymentMethod);
      setAmount(settlementLog.amount);
      setPhoneNum(settlementLog.phoneNum);
      setDate(settlementLog.date);
    };
    fetchSettlementLog();
  }, []);

  return (
    <div>
      <div>정산 내역 디테일</div>
      <div>
        <h3>숙소 이름: {lodgmentName}</h3>
        <S.InputLine>
          <S.InputTitle>구매자: </S.InputTitle>
          <S.Input value={sender} onChange={(e) => setSender(e.target.value)} />
        </S.InputLine>
        <S.InputLine>
          <S.InputTitle>결제수단</S.InputTitle>
          <S.Input
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputTitle>입금액: </S.InputTitle>
          <S.Input value={amount} onChange={(e) => setAmount(e.target.value)} />
        </S.InputLine>
        <S.InputLine>
          <S.InputTitle>전화번호: </S.InputTitle>
          <S.Input
            value={phoneNum}
            onChange={(e) => setPhoneNum(e.target.value)}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputTitle>입금 날짜(정산일): </S.InputTitle>
          <S.Input
            placeholder="ex. 2022-09-14"
            value={date}
            onChange={(e) => setDate(e.target.value)}
          />
        </S.InputLine>
      </div>
      <button
        style={{ marginRight: "1rem", backgroundColor: "red" }}
        onClick={async () => {
          if (!confirm("정말 삭제하시겠습니까?")) {
            return;
          }
          await deleteSettlementLog(settlementLogId);
          alert("삭제되었습니다.");
          history.push("/settlementLog");
        }}
      >
        삭제하기
      </button>
      <button onClick={onSubmit}>수정하기</button>
    </div>
  );
}
