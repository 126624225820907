import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid black;
  width: fit-content;
  padding: 20px;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
`;

export const InputBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  label {
    font-size: 12px;
    font-weight: bold;
  }
`;

export const Actions = styled.div`
  margin-left: 20px;
  button:nth-child(2) {
    margin-left: 20px;
  }
`;

export const CountSpan = styled.div`
  span {
    font-weight: bold;
    color: #b900cd;
  }
`;

export const SubmitButton = styled.button`
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 300px;
  background-color: black;
  color: ivory;
`;
export const CorporationSection = styled.section`
  flex-wrap: wrap;
`;

export const RouteLink = styled.div`
  a {
    text-decoration: none;
    color: purple;
    font-weight: bold;
    font-size: 24px;
  }
`;

export const Figure = styled.figure`
  width: 100px;
  height: 100px;
  border: 2px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Img = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;
