import styled from "styled-components";

export const PackageContainer = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
`;

export const InputLayout = styled.div`
  margin: 1rem;
`;
export const InputLabel = styled.label``;
export const Input = styled.input``;

export const PlaceLayout = styled.div`
  border: 0.1rem solid black;
  width: fit-content;
  padding: 1rem;
`;

export const SelectedPlaceContainer = styled.div``;
export const SelectedPlaceItem = styled.div`
  display: flex;
  width: 200px;
  justify-content: space-between;
  border: 0.1rem solid black;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin-top: 0.5rem;
`;
export const SelectedPlaceTitle = styled.p`
  margin: 0;
`;
export const DeleteBtn = styled.p`
  margin: 0;
  color: red;
  cursor: pointer;
`;

export const SubmitBtn = styled.button``;

export const PackageItem = styled.div`
  width: 220px;
  height: 20rem;
  border: 0.1rem solid black;
  border-radius: 1rem;
  padding: 1rem;
  margin: 0.3rem;
  cursor: pointer;

  :hover {
    background-color: lightgray;
  }
`;
export const PackageRepresentImg = styled.img`
  width: 180px;
  height: 230px;
  margin-right: 1rem;
  -webkit-user-drag: none;
`;
export const PackageTitle = styled.h3`
  height: 2rem;
  font-size: 0.9rem;
  word-wrap: break-word;
  overflow-y: auto;
`;

export const PackageBox = styled.div`
  display: flex;
  width: 100vw;
  flex-wrap: wrap;
`;
