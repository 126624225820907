import { Flex, FlexStart } from "components/style";
import { Button } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
export default function SimplePost({ post, selectedTag, deleteTagFromPost }) {
  const [imgIdx, setImgIdx] = useState(0);
  const history = useHistory();

  return (
    <Flex style={{ marginBottom: 30 }}>
      <img
        src={post.images.length == 0 ? null : post.images[imgIdx].url}
        style={{ height: 220, width: 220 }}
      />
      <div style={{ padding: 10 }}>
        <FlexStart style={{ marginBottom: 8 }}>
          <Button
            size="sm"
            onClick={() => {
              console.log(imgIdx, post.images.length);
              if (imgIdx == 0) return;
              setImgIdx(imgIdx - 1);
              console.log(imgIdx);
            }}
          >
            {"<"}
          </Button>
          <Button
            size="sm"
            onClick={() => {
              if (imgIdx == post.images.length - 1) return;
              setImgIdx(imgIdx + 1);
            }}
            style={{ marginRight: 10 }}
          >
            {">"}
          </Button>
          <div style={{ marginRight: 15 }}>{`(${imgIdx + 1} / ${
            post.images.length
          })`}</div>
        </FlexStart>
        <div
          style={{
            cursor: "pointer",
            fontSize: 20,
            textDecoration: "underline",
            color: "blue",
          }}
          onClick={() => {
            const win = window.open(`/post/${post.id}`, "_blank");
          }}
        >
          포스트로 이동
        </div>
        <div style={{ fontSize: 20 }}>이름: {post.name}</div>
        <div style={{ fontSize: 20 }}>종류: {post.type}</div>
        <div style={{ fontSize: 20, marginBottom: 10 }}>
          설명: {post.description.summary}
        </div>
        <Button
          variant="danger"
          onClick={() => {
            deleteTagFromPost(selectedTag.name, post.id);
          }}
        >
          포스트에서 태그 삭제하기
        </Button>
      </div>
    </Flex>
  );
}
