import styled from "styled-components";

export const RightWingContainer = styled.div`
  width: 33%;
`;

export const FormListContainer = styled.div`
  width: 100%;
  height: 12rem;
  border: 0.1rem black solid;
  overflow: scroll;
`;

export const FormList = styled.div`
  padding: 1rem;
`;

export const FormListTitle = styled.h2`
  font-size: 1rem;
  font-weight: 900;
`;

export const FormItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
`;

export const FormItem = styled.div`
  font-size: 0.8rem;
  width: fit-content;
  padding: 0.1rem 0.4rem;
  margin: 0.2rem;
  border: 0.1rem black solid;
  border-radius: 1rem;
  :hover {
    cursor: pointer;
    background-color: #e3e3e3;
  }
`;

export const Box2 = styled.div`
  width: 100%;
  height: 14rem;
  background-color: orange;
`;
export const Box3 = styled.div`
  width: 100%;
  height: 14rem;
  background-color: yellow;
`;

export const FormContainer = styled.div``;
