import * as React from "react";
import * as S from "../../preopen.style";
import BasicHeader from "../BasicHeader";

export interface IPreviewProps {
  lodgmentName: string;
  params: string;
  styles: {
    color: string;
    backgroundColor: string;
  };
  images: {
    banner: string;
    logo: string;
  };
  mainTitle;
  paragraphs;
  rooms;
}

export default function Preview({
  lodgmentName,
  params,
  styles: { color, backgroundColor },
  images: { banner, logo },
  mainTitle,
  paragraphs,
  rooms,
}: IPreviewProps) {
  console.log(color);
  return (
    <S.PreviewLayout backgroundColor={backgroundColor}>
      <BasicHeader title={`${lodgmentName} 예약 오픈`} promotionStyle="none" />
      <S.MainPicture>
        <S.MainImage src={banner} alt="" />
        <S.ImageTextSection>
          <S.TitleText>{mainTitle.row1}</S.TitleText>
          <S.TitleText>{mainTitle.row2}</S.TitleText>
          <S.Paragraph>{mainTitle.desc}</S.Paragraph>
        </S.ImageTextSection>
      </S.MainPicture>
      <S.ContentsRow>
        <S.Subtitle color={color}>{lodgmentName} 이야기</S.Subtitle>
        <S.Title color={color}>{paragraphs[0].name}</S.Title>
        <S.LogoPicture width={params === "thestair" && "110px"}>
          <S.LogoImage src={logo} alt="" />
        </S.LogoPicture>
        <S.Description color={color}>{paragraphs[0].desc}</S.Description>
        <S.HorizontalLine />
        <S.Subtitle color={color}>객실 소개</S.Subtitle>
        <S.Title color={color}>{paragraphs[1].name}</S.Title>
        <S.RoomDescription color={color}>
          {paragraphs[1].desc}
        </S.RoomDescription>
        <S.RoomSection>
          <S.RoomList>
            {rooms.map((detail) => {
              const { routePath, src, desc, name } = detail;
              return (
                <a href={routePath}>
                  <S.RoomBox href={routePath}>
                    <S.RoomPicture>
                      <S.RoomImage src={src} alt="" />
                    </S.RoomPicture>
                    <S.RoomInfo color={color}>
                      <S.NameText>{name}</S.NameText>
                      <S.DescText>{desc}</S.DescText>
                    </S.RoomInfo>
                  </S.RoomBox>
                </a>
              );
            })}
          </S.RoomList>
        </S.RoomSection>
      </S.ContentsRow>
    </S.PreviewLayout>
  );
}
