import { useEffect, useState } from "react";
import * as S from "./commission.styled";
import CommissionByAccommoModal from "./CommissionByAccommoModal";
import { checkStaticCommission } from "utils/settlement";
import _ from "lodash";
import { updateSettlementInfoByAccommo } from "api/settlementLog";

export default function CommissionByAccommoTableRow({ row }) {
  const [rowData, setRowData] = useState<string>(row);
  const [isCommissionModalOpen, setIsCommissionModalOpen] = useState(false);
  const [payload, setPayload] = useState({
    vat: row?.settle[0]?.vat,
    isEarlySettlement: row?.settle[0]?.isEarlySettlement,
    bank: row?.settle[0]?.bank,
    accountNumber: row?.settle[0]?.accountNumber,
    depositor: row?.settle[0]?.depositor,
    memo: row?.settle[0]?.memo,
    naverDirectCommission:
      row?.settle[0]?.platformCommissions?.naver?.directSearchCommission ??
      undefined,
    naverAlgorithmCommission:
      row?.settle[0]?.platformCommissions?.naver?.algorithmCommission ??
      undefined,
  });

  const isStatic = checkStaticCommission(row?.settle[0]);
  const isEmpty = row?.settle?.length === 0;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log("name", name, value);
    setPayload((prev) => ({
      ...prev,
      [name]: value, // 입력된 값 업데이트
    }));
  };

  const handleSubmit = async () => {
    console.log("payload", payload);
    await updateSettlementInfoByAccommo(row?._id, payload);
    alert("저장되었습니다.");
  };

  return (
    <>
      <tr key={row?._id} className={isEmpty && "isEmpty"}>
        <td>{isEmpty ? "❌" : "✅"}</td>
        <td className="accommo-id">{row?._id}</td>
        <td className="accommo-name">{row?.name}</td>
        <td>
          <S.CommissionModalButton
            onClick={() => setIsCommissionModalOpen(true)}
            isStatic={isStatic}
          >
            {isStatic ? row?.settle[0]?.commissions[0]?.commission : "더보기 +"}
          </S.CommissionModalButton>
        </td>
        <td>
          <input name="vat" onChange={handleChange} value={payload?.vat} />
        </td>
        <td className="isEarlySettlement">
          {row.isEarlySettlement ? "O" : "X"}
        </td>
        <td className="naver-direct">
          <input
            name="naverDirectCommission"
            onChange={handleChange}
            value={payload?.naverDirectCommission}
          />
        </td>
        <td className="naver-algorithm">
          <input
            name="naverAlgorithmCommission"
            onChange={handleChange}
            value={payload?.naverAlgorithmCommission}
          />
        </td>
        <td>
          <input
            name="bank"
            onChange={handleChange}
            value={payload?.bank ?? ""}
          />
        </td>
        <td>
          <input
            name="accountNumber"
            onChange={handleChange}
            value={payload?.accountNumber ?? ""}
          />
        </td>
        <td>
          <input
            name="depositor"
            onChange={handleChange}
            value={payload?.depositor ?? ""}
          />
        </td>
        <td>
          <input
            name="memo"
            onChange={handleChange}
            value={payload?.memo ?? ""}
          />
        </td>
        <td className="edit" onClick={handleSubmit}>
          <S.EditButton
            style={{
              border: "1px solid #3182F7",
            }}
          >
            저장
          </S.EditButton>
        </td>
        {isCommissionModalOpen && (
          <CommissionByAccommoModal
            isOpen={isCommissionModalOpen}
            setIsOpen={setIsCommissionModalOpen}
            row={rowData}
            payload={payload}
            isStatic={isStatic}
          />
        )}
      </tr>
    </>
  );
}
