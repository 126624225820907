import * as React from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Multiple from "./Multiple";
import Single from "./Single";

export interface IPreOpenCreateProps {}

export default function PreOpenCreate(props: IPreOpenCreateProps) {
  return (
    <>
      <div>생성페이지</div>
      <Tabs
        defaultActiveKey="single"
        id="uncontrolled-tab-example"
        className="mb-3"
      >
        <Tab eventKey="single" title="single">
          <Single />
        </Tab>
        <Tab eventKey="multiple" title="multiple">
          <Multiple />
        </Tab>
      </Tabs>
    </>
  );
}
