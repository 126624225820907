import { LODGMENT_RESERVATION_REFUSE_LOG_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getLodgmentReservationRefuseLogByLodgmentId = async (
  lodgmentId
) => {
  const path = LODGMENT_RESERVATION_REFUSE_LOG_PATH + `/${lodgmentId}`;
  const res = await myAxios.get({ path });
  return res.data.data;
};

export const getAllLodgmentReservationRefuseLogsForSettlement = async () => {
  const path = LODGMENT_RESERVATION_REFUSE_LOG_PATH + "/getAllForSettlement";
  const res = await myAxios.get({ path });
  return res.data.data;
};
