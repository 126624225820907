import styled from "styled-components";

export const InputLine = styled.div`
  display: flex;
  width: fit-content;
`;
export const InputTitle = styled.p`
  margin-right: 3rem;
`;
export const Input = styled.input``;
