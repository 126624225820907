import {
  getAllWebsite,
  updateWebsiteSubdomain,
  createWebsite,
} from "api/website";
import React from "react";
import styled from "styled-components";
import { formatSubdomain } from "./utils/formatSubdomain";

export default function WebsiteDashboard({}) {
  const [websites, setWebsites] = React.useState([]);
  React.useEffect(() => {
    const fetchAllWebsite = async () => {
      const res = await getAllWebsite();
      setWebsites(res);
    };
    fetchAllWebsite();
  }, []);

  const [accommoId, setAccommoId] = React.useState("");
  const [website, setWebsite] = React.useState("");

  const handleChange = (e) => {
    setAccommoId(e.target.value);
  };

  const handleClick = async (accommoId) => {
    const res = await createWebsite({
      subdomain: "test",
      accommo: accommoId,
    });
    setWebsite("https://" + res.data.subdomain + ".finestay.ai");
  };

  if (websites.length === 0) return <div>Loading...</div>;
  return (
    <S.Container>
      <S.LineBox>
        <S.HeaderText>웹사이트 생성하기 🪚</S.HeaderText>
        <S.Input
          placeholder="accommo Id"
          value={accommoId}
          onChange={handleChange}
        />
        <S.Button
          onClick={() => {
            handleClick(accommoId);
          }}
        >
          생성
        </S.Button>
        <S.URLBox>
          URL:
          <S.Anchor href={website} target="_blank" rel="noreferrer">
            {website}
          </S.Anchor>
          <S.CopyButton
            onClick={() => {
              navigator.clipboard.writeText(website);
            }}
          >
            복사
          </S.CopyButton>
        </S.URLBox>
      </S.LineBox>
      <div>🚨🚨🛎️ 서브도메인 변경시 영소문자, 숫자, - 만 가능합니다.</div>
      <br />
      {websites.map((website: any) => (
        <Item key={website._id} website={website} />
      ))}
    </S.Container>
  );
}

function Item({ website }) {
  const [subdomain, setSubdomain] = React.useState(website.subdomain);

  const handleSubmit = async () => {
    if (!subdomain) return alert("서브도메인을 입력해주세요");

    try {
      await updateWebsiteSubdomain(website._id, { subdomain });
      alert("수정 완료");
    } catch (err) {
      alert("수정할 수 없습니다 :p");
    }
  };
  return (
    <S.Box>
      <S.Col1>
        <S.Text>{website.accommo.name}</S.Text>
        <a
          href={`https://${subdomain}.finestay.ai`}
          target="_blank"
          rel="noreferrer"
        >
          https://<span id="subdomain">{subdomain}</span>.finestay.ai
        </a>
      </S.Col1>
      <S.Col2>
        <input
          value={subdomain}
          onChange={(e) => {
            const value = formatSubdomain(e.target.value);
            setSubdomain(value);
          }}
        />
        <button onClick={handleSubmit}>수정</button>
      </S.Col2>
    </S.Box>
  );
}

const S = {
  Container: styled.div``,
  LineBox: styled.div`
    margin-bottom: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
  `,
  HeaderText: styled.p`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  `,
  Input: styled.input`
    padding: 8px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 200px;
  `,
  Button: styled.button`
    padding: 8px 12px;
    background-color: #3182f7;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #0066cc;
    }
  `,
  URLBox: styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
    gap: 10px;
  `,
  Anchor: styled.a`
    color: #3182f7;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  `,
  CopyButton: styled.button`
    padding: 5px 10px;
    background-color: #e0e0e0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
      background-color: #ccc;
    }
  `,
  Box: styled.div`
    border-bottom: 1px solid #e0e0e0;
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  `,
  Text: styled.div`
    #subdomain {
      color: blue;
    }
  `,
  Col1: styled.div``,
  Col2: styled.div`
    margin-left: 20px;
  `,
};
