import { SHORTS_PATH } from "../constants/path";
import { myAxios } from "./myAxios";

export const postShort = async (body): Promise<any> => {
  const path = `${SHORTS_PATH}`;
  const res = await myAxios.post({ path, data: body });
  return res.data;
};

export const getShorts = async (): Promise<any> => {
  const path = `${SHORTS_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getShort = async (shortsPostId: string): Promise<any> => {
  const path = `${SHORTS_PATH}/${shortsPostId}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const putShort = async (shortsPostId: string, data): Promise<any> => {
  const path = `${SHORTS_PATH}/${shortsPostId}`;
  const res = await myAxios.put({ data, path });
  return res.data;
};

export const deleteShort = async (shortsPostId: string): Promise<any> => {
  const path = `${SHORTS_PATH}/${shortsPostId}`;
  const res = await myAxios.delete({ path });
  return res.data;
};
