import styled from "styled-components";

export const Test = styled.div`
  padding: 24px;
  padding-bottom: 96px;
`;

export const TagContainer = styled.div`
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  border-radius: 10;
  border: 1px solid #b9b9b9;
  padding: 8px 0 16px 0;
  flex: 1;
`;

export const Tag = styled.div`
  border-radius: 8px;
  padding: 5px 12px;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.3px;
  color: #494949;
  background-color: #e2e2e2;
  margin: 10px 10px 0;
  align-items: center;
`;

export const TagSelectorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
  border: 1px solid #e9e9e9;
  padding: 10px;
`;

export const TagSelector = styled.div`
  margin-right: 10px;
  border-radius: 10px;
  border: 1px solid #e9e9e9;
  padding: 10px;
  cursor: pointer;
`;

export const Label = styled.label`
  width: 100px;
  font-weight: 600;
  font-size: 18px;
`;

export const Input = styled.input`
  width: 300px;
  margin-left: 20px;
`;

export const WorktimeBox = styled.div`
  border-radius: 10px;
  border: 1px solid #c9c9c9;
  padding: 5px 10px 8px 10px;
  margin-bottom: 10px;
`;

export const EditButton = styled.div`
  width: 500px;
  height: 100px;
  background-color: royalblue;
  color: white;
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 30px 0;
`;

export const ImgDeleteButton = styled.div`
  width: 50px;
  height: 24px;
  display: flex;
  align-items: center;
  background-color: red;
  color: white;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  justify-content: center;
  margin: 0px 5px 5px 20px;
  cursor: pointer;
`;

export const TagResetButton = styled.button`
  margin: 10px 0 0 10px;
  padding: 8px 10px;
  height: 40px;
  background-color: red;
  color: white;
  border: 1px white;
  border-radius: 10px;
`;

export const GeoCodeButton = styled.div`
  width: 150px;
  padding: 5px 0;
  border-radius: 20px;
  background-color: blue;
  margin-left: 10px;
  color: white;
  text-align: center;
`;

export const ImgItem = styled.div`
  width: 1100px;
  display: flex;
  padding: 20px;
  border: 1px solid grey;
  border-radius: 20px;
  margin-top: 15px;
  background-color: white;
`;

export const ImgInfoLabel = styled.label`
  width: 75px;
`;

export const ImgInfoInput = styled.input`
  margin-left: 10px;
  width: 500px;
`;
