import * as React from "react";
import { Socket } from "socket.io-client";
import { emitResponseForm } from "utils/socket/clientEvent";
import * as S from "./msg.style";

export interface IMultipleChoiceMsgProps {
  socket?: Socket;
  content: {
    templateTitle: string;
    question: string;
    options: string[];
    btnText: string;
  };
  role: "user" | "concierge";
}

export default function MultipleChoiceMsg({
  socket,
  content: { templateTitle, question, options, btnText },
  role,
}: IMultipleChoiceMsgProps) {
  const [answers, setAnswers] = React.useState<string[]>([]);
  const handleOptionClick = (option) => {
    if (answers.includes(option)) {
      const updatedAnswers = answers.filter((answer) => answer !== option);
      setAnswers(updatedAnswers);
      return;
    }
    setAnswers([...answers, option]);
  };
  const handleSubmitClick = () => {
    return;
    const body = {
      title: templateTitle,
      question,
      answers,
    };
    emitResponseForm(socket, body);
  };

  return (
    <S.MsgWrapper role={role}>
      <S.FormQuestion>{question}</S.FormQuestion>
      {options.map((option) => (
        <S.ChoiceOption
          isSelected={answers.includes(option)}
          onClick={() => {
            handleOptionClick(option);
          }}
        >
          {option}
        </S.ChoiceOption>
      ))}
      <S.BtnMsgBtn onClick={handleSubmitClick}>{btnText}</S.BtnMsgBtn>
    </S.MsgWrapper>
  );
}
