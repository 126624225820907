import * as React from "react";
import * as S from "./msg.style";

export interface IBtnMsgProps {
  content: {
    text: string;
    btnLink: string;
    btnText: string;
  };
  role: "user" | "concierge";
}

export default function BtnMsg({ content, role }: IBtnMsgProps) {
  return (
    <S.MsgWrapper role={role}>
      <S.BtnMsg>
        {content.text}
        <a
          style={{ textDecoration: "none" }}
          href={content.btnLink}
          target="_blank"
          rel="noreferrer"
        >
          <S.BtnMsgBtn>{content.btnText}</S.BtnMsgBtn>
        </a>
      </S.BtnMsg>
    </S.MsgWrapper>
  );
}
