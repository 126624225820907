import styled from "styled-components";
import { defaultInput, defaultInputWrapper } from "styles/styleConstants";

export interface InputWrapperProps {
  backgroundColor?: string;
  color?: string;
  width?: string;
}

export const InputWrapper = styled.div<InputWrapperProps>`
  background-color: ${(props) => props.backgroundColor || "white"};
  color: ${(props) => props.color || "black"};
  width: ${(props) => props.width || "100%"};
  ${defaultInputWrapper};
`;

export interface InputProps {
  display?: string;
}
export const Input = styled.input<InputProps>`
  ${defaultInput}
  padding-left:0;
  display: ${(props) => props.display || "block"};
`;

export const ErrorText = styled.div`
  font-size: 12px;
  color: red;
  margin-top: 8px;
`;

export const Textarea = styled.textarea`
  font-size: 12px;
  display: block;
  border: 1px solid #ccc;
  width: 100%;
  height: 100px;
`;

export const LabelText = styled.label`
  width: fit-content;
`;

export const PlusIcon = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const UploadedImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

export const ActionRemoveBox = styled.div`
  font-size: 20px;
  position: absolute;
  background-color: white;
  border-radius: 10px;
  top: -10px;
  right: -10px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  svg {
    pointer-events: none;
  }
`;
