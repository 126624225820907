import axios from "config/axiosConfig";
import React, { useState, useEffect } from "react";
import TagEditor from "./components/TagEditor";
import InfoEditor from "./components/InfoEditor";
import DetailsEditor from "./components/DetailsEditor";
import WorkTimeEditor from "./components/WorkTimeEditor";
import ImageEditor from "./components/ImageEditor";
import TagCreateModal from "./components/TagCreateModal";
import { EditButton } from "./style";
import { useHistory } from "react-router-dom";
import { Flex } from "components/style";
import useTitle from "hooks/useTitle";

export default function Post({ match }) {
  useTitle("포스트 상세");
  const [post, setPost] = useState(null);
  const [addImage, setAddImage] = useState({
    url: null,
    source: null,
  });
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState(null);

  // 영업시간
  const [openingHours, setOpeningHours] = useState([]);
  const [addingOpenHour, setAddingOpenHour] = useState(null);
  const [addingCloseHour, setAddingCloseHour] = useState(null);
  const [addingHourDays, setAddingHourDays] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [offDays, setOffDays] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [addingBreakStartHour, setAddingBreakStartHour] = useState(null);
  const [addingBreakEndHour, setAddingBreakEndHour] = useState(null);
  const [addingLastOrder, setAddingLastOrder] = useState(null);
  const [addingNotice, setAddingNotice] = useState("");

  useEffect(() => {
    const fetchPost = async () => {
      await axios
        .get(`/posts/${match.params.id}`)
        .then((res) => {
          const data = res.data.post;
          setPost(data);

          if (!data.details) {
            if (data.type === "맛집" || data.type === "카페") {
              setDetails({
                reservationLink: "",
                method: { dineIn: false, takeOut: false },
                ingredient: [],
              });
            }
            if (data.type === "여행지" || data.type === "액티비티") {
              setDetails({
                reservationLink: "",
                mainContent: "",
                spendingTime: "",
              });
            }
          } else {
            setDetails(data.details);
          }

          if (data.openingHours?.length) {
            setOpeningHours(data.openingHours);
          } else {
            let openarr = Array(7).fill({
              open: null,
              close: null,
              breakStart: null,
              breakEnd: null,
              off: false,
              lastOrder: null,
            });
            setOpeningHours(openarr);
          }
          if (data.openingHoursNotice) {
            setAddingNotice(data.openingHoursNotice);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchPost();
  }, []);

  useEffect(() => {
    if (openingHours?.length) {
      setPost({ ...post, openingHours: openingHours });
    }
  }, [openingHours]);

  useEffect(() => {
    console.log(details);
  }, [details]);

  useEffect(() => {
    if (!post) return;
    setPost({ ...post, details });
  }, [details]);

  const updatePost = async () => {
    console.log(post);
    await axios
      .put(`/posts/${post.id}`, post)
      .then((res) => {
        alert("저장되었습니다");
        window.location.reload();
      })
      .catch((err) => {
        alert(err.response.data.msg);
      });
  };

  const handleAddingHourDaysClick = (e, idx) => {
    const checked = e.target.checked;

    let arr = [...addingHourDays];

    if (arr[idx] !== checked) {
      arr.splice(idx, 1, checked);
    }

    setAddingHourDays(arr);
  };

  const handleOffDaysClick = (e, idx) => {
    const checked = e.target.checked;

    let arr = [...offDays];

    if (arr[idx] !== checked) {
      arr.splice(idx, 1, checked);
    }

    setOffDays(arr);
  };

  const handleAddingHourDoneClick = () => {
    let arr = [...addingHourDays];
    let oriOpeningArr = [...openingHours];

    arr.map((day, idx) => {
      if (day) {
        const oriItem = oriOpeningArr[idx];
        oriOpeningArr.splice(idx, 1, {
          ...oriItem,
          open: addingOpenHour ? addingOpenHour : oriItem.open,
          close: addingCloseHour ? addingCloseHour : oriItem.close,
          breakStart: addingBreakStartHour
            ? addingBreakStartHour
            : oriItem.breakStart,
          breakEnd: addingBreakEndHour ? addingBreakEndHour : oriItem.breakEnd,
          off: false,
          lastOrder: addingLastOrder ? addingLastOrder : oriItem.lastOrder,
        });
      }
    });

    setOpeningHours(oriOpeningArr);
    setAddingOpenHour(null);
    setAddingCloseHour(null);
    setAddingBreakEndHour(null);
    setAddingBreakStartHour(null);
    setAddingHourDays([false, false, false, false, false, false, false]);
  };

  const handleOffDaysDoneClick = () => {
    let arr = [...offDays];
    let oriOpeningArr = [...openingHours];

    arr.map((el, idx) => {
      if (el) {
        oriOpeningArr.splice(idx, 1, {
          ...oriOpeningArr[idx],
          off: true,
          open: null,
          close: null,
          breakStart: null,
          breakEnd: null,
          lastOrder: null,
        });
        return;
      }
      oriOpeningArr.splice(idx, 1, { ...oriOpeningArr[idx], off: false });
    });
    setOpeningHours(oriOpeningArr);
    setOffDays([false, false, false, false, false, false, false]);
  };

  const deleteImageFromPost = async (idx) => {
    let imgArr = post.images.slice(0);

    imgArr.splice(idx, 1);

    setPost({ ...post, images: imgArr });
  };

  const addImageToPost = () => {
    let arr = post.images.slice(0);
    console.log(arr.length);
    arr = [...arr, addImage];
    console.log(arr);
    console.log(arr.length);

    setPost({ ...post, images: arr });
  };

  if (post) {
    return (
      <div style={{ marginBottom: 50 }}>
        <Flex>
          <InfoEditor post={post} setPost={setPost} />
          <DetailsEditor
            post={post}
            setPost={setPost}
            details={details}
            setDetails={setDetails}
          />
        </Flex>

        <WorkTimeEditor
          post={post}
          setPost={setPost}
          setOpeningHours={setOpeningHours}
          addingOpenHour={addingOpenHour}
          addingCloseHour={addingCloseHour}
          addingBreakStartHour={addingBreakStartHour}
          addingBreakEndHour={addingBreakEndHour}
          addingLastOrder={addingLastOrder}
          addingNotice={addingNotice}
          setAddingOpenHour={setAddingOpenHour}
          setAddingCloseHour={setAddingCloseHour}
          setAddingBreakStartHour={setAddingBreakStartHour}
          setAddingBreakEndHour={setAddingBreakEndHour}
          setAddingLastOrder={setAddingLastOrder}
          setAddingNotice={setAddingNotice}
          addingHourDays={addingHourDays}
          handleAddingHourDaysClick={handleAddingHourDaysClick}
          handleAddingHourDoneClick={handleAddingHourDoneClick}
          offDays={offDays}
          handleOffDaysClick={handleOffDaysClick}
          handleOffDaysDoneClick={handleOffDaysDoneClick}
          openingHours={openingHours}
        />

        <TagEditor post={post} setPost={setPost} isLoading={isLoading} />

        <ImageEditor
          post={post}
          setPost={setPost}
          addImage={addImage}
          setAddImage={setAddImage}
          deleteImageFromPost={deleteImageFromPost}
          addImageToPost={addImageToPost}
        />

        <EditButton onClick={() => updatePost()}>수정 완료</EditButton>

        {/* <TagCreateModal
          isCreatingModalVisible={isCreatingModalVisible}
          setIsCreatingModalVisible={setIsCreatingModalVisible}
          setIsLoading={setIsLoading}
          setNewTagName={setNewTagName}
          setNewTagCategory={setNewTagCategory}
          newTagCategory={newTagCategory}
          handleCategorySelectDone={handleCategorySelectDone}
        /> */}
      </div>
    );
  } else {
    return null;
  }
}
