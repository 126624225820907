import { FileMsg } from "../type/chat.type";

export const compareByName = (
  a: { name: string },
  b: { name: string }
): number => a.name.localeCompare(b.name);

// export const fileListToFileMsgs = (fileList: FileList): FileMsg[] => {
//   const fileArr = [...fileList];

//   return fileArr.sort(compareByName).map(
//     (file: File): FileMsg => ({
//       name: file.name,
//       buffer: file,
//     })
//   );
// };
