import React, { useCallback } from "react";
import { IoMdClose } from "react-icons/io";
import * as S from "./style";
import { useModalHandler } from "hooks/useModalHandler";

export interface IModalProps {
  isOpen?: boolean;
  setIsOpen?;
  className?: string;
  children: React.ReactNode;
  isTransparent?: boolean;
  isCloseButtonVisible?: boolean;
  isFitContent?: boolean;
  onChange?;
  customBackgroundColor?: string;
  customZIndex?: number;
  closeButtonColor?: string;
}

export default function Modal({
  isOpen,
  setIsOpen = () => {
    return;
  },
  className,
  children,
  isTransparent,
  isCloseButtonVisible = false,
  isFitContent,
  onChange = () => {
    return;
  },
  customBackgroundColor,
  customZIndex,
  closeButtonColor,
}: IModalProps) {
  const stopPropagation = useCallback((e) => {
    e.stopPropagation();
  }, []);

  // 모달창 닫는 함수:setIsOpen을 false로 변경해준다.
  const [, closeModal] = useModalHandler(setIsOpen);

  if (!isOpen) return null;
  return (
    <S.Container
      style={{ backgroundColor: customBackgroundColor, zIndex: customZIndex }}
      onClick={() => {
        localStorage.setItem("closeRedirect", "check");
        if (className === "eventModal") {
          return;
        }
        closeModal();
        onChange();
      }}
      isTransparent={isTransparent}
    >
      <S.InnerContainer
        isFitContent={isFitContent}
        className={className}
        onClick={stopPropagation}
      >
        <S.CloseModalButton
          onClick={closeModal}
          isCloseButtonVisible={isCloseButtonVisible}
          className="close-button"
          closeButtonColor={closeButtonColor}
        >
          <IoMdClose />
        </S.CloseModalButton>
        {children}
      </S.InnerContainer>
    </S.Container>
  );
}
