import { uploadPackageImg } from "api/image";
import { getPreopenLodgment, updatePreopenLodgment } from "api/preopen";
import ImageField from "common/ImageField";
import * as React from "react";
import * as S from "../../preopen.style";
import { useHistory } from "react-router-dom";

export interface ISingleDetailProps {
  match;
}

export default function SingleDetail({ match }: ISingleDetailProps) {
  const { id } = match.params;

  const [name, setName] = React.useState("");
  const [nameEN, setNameEN] = React.useState("");
  const [desc, setDesc] = React.useState("");
  const [coverImg, setCoverImg] = React.useState("");
  const [routePath, setRoutePath] = React.useState("");
  const [preference, setPreference] = React.useState(0);
  const [country, setCountry] = React.useState("");

  const history = useHistory();

  React.useEffect(() => {
    const fetchPreopenLodgmentById = async () => {
      const res = await getPreopenLodgment(id);
      setName(res.name);
      setNameEN(res.globalName.en);
      setDesc(res.desc);
      setCoverImg(res.coverImg);
      setRoutePath(res.routePath);
      setPreference(res.preference);
      setCountry(res.country);
    };

    fetchPreopenLodgmentById();
  }, []);

  const handleChange = (e, setState) => {
    if (name === "preference") {
      setState(Number(e.target.value));
    }
    setState(e.target.value);
  };

  const handleFileChange = async (e) => {
    const file = e.target.files.length ? e.target.files[0] : null;
    if (!file) return;

    const formData = new FormData();
    formData.append("imgSource", file);
    const {
      data: { imgSource },
    } = await uploadPackageImg(formData);
    setCoverImg(imgSource[0]);
  };

  const handleDelete = () => {
    setCoverImg("");
  };

  const handleSubmit = async () => {
    const data = {
      name,
      globalName: {
        en: nameEN,
      },
      desc,
      coverImg,
      routePath,
      preference,
      country,
    };

    await updatePreopenLodgment(id, data);
    history.push("/preopen");
  };
  return (
    <>
      <div>상세페이지!!</div>
      <>
        <ImageField
          value={coverImg}
          onChange={handleFileChange}
          onDelete={handleDelete}
        />
        <S.FormSection>
          <input
            type="text"
            name="name"
            value={name}
            placeholder="숙소 이름"
            onChange={(e) => handleChange(e, setName)}
          />
          <input
            type="text"
            name="globalName"
            value={nameEN}
            placeholder="영문이름"
            onChange={(e) => handleChange(e, setNameEN)}
          />
          <input
            type="text"
            name="desc"
            value={desc}
            placeholder="숙소 인원 설정"
            onChange={(e) => handleChange(e, setDesc)}
          />
          <input
            type="number"
            name="preference"
            value={preference}
            placeholder="순서"
            onChange={(e) => handleChange(e, setPreference)}
          />
          <input
            type="text"
            name="routePath"
            value={routePath}
            style={{ width: "20rem" }}
            placeholder="숙소 상세 페이지 경로 ex. /lodgment/asdasdasdas123"
            onChange={(e) => handleChange(e, setRoutePath)}
          />
          <p>?</p>
          <input
            type="text"
            name="country"
            value={country}
            placeholder="국가"
            onChange={(e) => handleChange(e, setCountry)}
          />
        </S.FormSection>
        <button onClick={handleSubmit}>저장</button>
      </>
    </>
  );
}
