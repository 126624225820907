import axios, { AxiosInstance } from "axios";
const SERVER_URL = {
  production: "https://hwik.io/api/",
  development: "http://localhost:8000/api/",
};

const instance: AxiosInstance = axios.create({
  baseURL: SERVER_URL["production"],
  // SERVER_URL[process.env.NODE_ENV],

  headers: {
    Authorization: `Bearer ${
      typeof window !== "undefined" ? localStorage.getItem("jwt") : null
    }`,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE",
    crossDomain: true,
  },
});

export default instance;
