import { SetStateAction, useEffect, useState, Dispatch } from "react";

const useTitle = (initialTitle: string): Dispatch<SetStateAction<string>> => {
  const [title, setTitle] = useState(initialTitle);

  const updateTitle = () => {
    const htmlTitle = document.querySelector("title");

    htmlTitle.innerText = title;
  };

  useEffect(updateTitle, [title]);

  return setTitle;
};

export default useTitle;
