import {
  getCollaboration,
  getSubmissionsByCollaborationId,
  confirmCollaboration,
  rejectCollaboration,
  cancelCollaboration,
  updateCollaboration,
} from "api/collaboration";
import { getOrCreateLodgmentConsult } from "api/consult";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Badge from "./Badge";
import styled from "styled-components";
import { formatDateWithDay } from "../utils";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import {
  confirmCollaborationsByBundleId,
  updateCollaborationsByBundleId,
} from "api/collaborationBundle";

dayjs.locale("ko");

export default function Collaboration() {
  const { collaborationId } = useParams();
  const [collaboration, setCollaboration] = useState(null);
  const [collaborationSubmissions, setCollaborationSubmissions] = useState([]);
  const [selectedSubmission, setSelectedSubmission] = useState(null);
  const [reason, setReason] = useState("");

  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({
    benefitStartDate: collaboration?.benefitStartDate,
    benefitEndDate: collaboration?.benefitEndDate,
    applicationStartDate: collaboration?.applicationStartDate,
    applicationEndDate: collaboration?.applicationEndDate,
    selectionAnnouncementDate: collaboration?.selectionAnnouncementDate,
    uploadDate: collaboration?.uploadDate,
    missionDetail: collaboration?.missionDetail,
    caution: collaboration?.caution,
  });

  const sortCollaborationSubmissions = (submissions) => {
    return submissions.sort((a, b) => {
      if (a.status === "selected") return -1; // 'selected'는 최상단으로
      if (b.status === "selected") return 1;
      if (a.status === "cancelled") return 1; // 'cancelled'는 최하단으로
      if (b.status === "cancelled") return -1;
      return 0; // 나머지는 변경하지 않음
    });
  };

  const sortedSubmissions = sortCollaborationSubmissions(
    collaborationSubmissions
  );

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = confirm("수정하시겠어요?");
      if (res) {
        if (collaboration.bundle) {
          await updateCollaborationsByBundleId(collaboration.bundle, formData);
        } else await updateCollaboration(collaborationId, formData);
        window.location.reload(); // 새로고침으로 상태 초기화
      }
    } catch (error) {
      alert("수정에 실패했어요. 다시 시도해주세요.");
    }
  };

  const handleConfirm = async () => {
    try {
      const res = confirm("승인하시겠어요?");
      if (res) {
        if (collaboration.bundle) {
          await confirmCollaborationsByBundleId(collaboration.bundle);
        } else await confirmCollaboration(collaborationId);
        window.location.reload();
      } else {
        return;
      }
    } catch (error) {
      alert("승인에 실패했어요. 다시 시도해주세요.");
    }
  };

  const handleReject = async () => {
    try {
      const res = confirm("거절하시겠어요?");
      if (res) {
        if (!reason) {
          alert("사유를 입력해주세요.");
          return;
        }
        if (collaboration.bundle) {
          await rejectCollaborationsByBundleId(collaboration.bundle, reason);
        } else await rejectCollaboration(collaborationId, reason);
        window.location.reload();
      } else {
        return;
      }
    } catch (error) {
      alert("거절에 실패했어요. 다시 시도해주세요.");
    }
  };

  const handleCancel = async () => {
    try {
      const res = confirm("취소하시겠어요?");
      if (res) {
        await cancelCollaboration(collaborationId);
        window.location.reload();
      } else {
        return;
      }
    } catch (error) {
      alert("취소에 실패했어요. 다시 시도해주세요.");
    }
  };

  const handleChat = async () => {
    if (selectedSubmission) {
      const payload = {
        lodgment: collaboration.lodgment._id,
        user: selectedSubmission.user._id,
        userPhone: selectedSubmission.user.phone,
        date: dayjs().format("YYYY-MM-DD"),
      };
      const consult = await getOrCreateLodgmentConsult(payload);
      window.open(`https://hautrip.com/host/message/${consult?._id}`);
    } else {
      alert(
        "아직 발표가 완료되지 않았어요. 조금만 기다려주시면 채팅방이 개설돼요."
      );
    }
  };

  useEffect(() => {
    const fetchCollaboration = async () => {
      try {
        const res = await getCollaboration(collaborationId);
        setCollaboration(res);
      } catch (error) {
        console.error(error);
      }
    };
    fetchCollaboration();
  }, [collaborationId]);

  console.log(collaboration?.submission);

  console.log(selectedSubmission);

  useEffect(() => {
    const fetchCollaborationSubmissions = async () => {
      try {
        const res = await getSubmissionsByCollaborationId(collaborationId, []);
        setCollaborationSubmissions(res);
        const selectedSubmission = res.find(
          (submission) =>
            submission.status === "selected" ||
            submission._id === collaboration?.submission
        );
        res.map((submission) => {
          console.log(
            submission._id,
            submission._id === collaboration?.submission
          );
        });

        if (selectedSubmission) {
          setSelectedSubmission(selectedSubmission);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchCollaborationSubmissions();

    setFormData({
      benefitStartDate: collaboration?.benefitStartDate,
      benefitEndDate: collaboration?.benefitEndDate,
      applicationStartDate: collaboration?.applicationStartDate,
      applicationEndDate: collaboration?.applicationEndDate,
      selectionAnnouncementDate: collaboration?.selectionAnnouncementDate,
      uploadDate: collaboration?.uploadDate,
      missionDetail: collaboration?.missionDetail,
      caution: collaboration?.caution,
    });
  }, [collaboration]);

  return collaboration ? (
    <form onSubmit={handleSubmit}>
      <S.Layout
        style={{
          alignItems: "flex-start",
        }}
      >
        <S.Container>
          <S.Header>
            <S.Link
              href={`https://hautrip.com/review/collaboration/${collaborationId}/host`}
              target="_blank"
              rel="noreferrer"
            >
              <u>
                <S.Title>{collaboration.lodgment.name}</S.Title>
              </u>
            </S.Link>
            <Badge collaboration={collaboration} />
            <S.Icon
              src={
                collaboration.mission === "instagram"
                  ? "https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/Symbol+Alternative.png.png"
                  : collaboration.mission === "naver"
                  ? "https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/Rectangle+121.png"
                  : "https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/skeleton_bg.png"
              }
              alt="symbol"
            />
          </S.Header>
          <S.DateSection>
            <S.DateLabel>숙박권</S.DateLabel>
            <S.Date>
              {isEditMode ? (
                <S.DateInputWrapper>
                  <S.DateInput
                    type="date"
                    name="benefitStartDate"
                    value={formData.benefitStartDate}
                    onChange={handleInputChange}
                  />
                  <span>-</span>
                  <S.DateInput
                    type="date"
                    name="benefitEndDate"
                    value={formData.benefitEndDate}
                    onChange={handleInputChange}
                  />
                </S.DateInputWrapper>
              ) : (
                `${formatDateWithDay(
                  collaboration.benefitStartDate
                )} - ${formatDateWithDay(collaboration.benefitEndDate)}`
              )}
            </S.Date>
          </S.DateSection>
          <S.DateSection>
            <S.DateLabel>신청 기간</S.DateLabel>
            <S.Date>
              {isEditMode ? (
                <S.DateInputWrapper>
                  <S.DateInput
                    type="date"
                    name="applicationStartDate"
                    value={formData.applicationStartDate}
                    onChange={handleInputChange}
                  />
                  -
                  <S.DateInput
                    type="date"
                    name="applicationEndDate"
                    value={formData.applicationEndDate}
                    onChange={handleInputChange}
                  />
                </S.DateInputWrapper>
              ) : (
                `${formatDateWithDay(
                  collaboration.applicationStartDate
                )} - ${formatDateWithDay(collaboration.applicationEndDate)}`
              )}
            </S.Date>
          </S.DateSection>
          <S.DateSection>
            <S.DateLabel>선정 발표</S.DateLabel>
            <S.Date>
              {isEditMode ? (
                <S.DateInput
                  type="date"
                  name="selectionAnnouncementDate"
                  value={formData.selectionAnnouncementDate}
                  onChange={handleInputChange}
                />
              ) : (
                formatDateWithDay(collaboration.selectionAnnouncementDate)
              )}
            </S.Date>
          </S.DateSection>
          <S.DateSection>
            <S.DateLabel>업로드 마감</S.DateLabel>
            <S.Date>
              {isEditMode ? (
                <S.DateInput
                  type="date"
                  name="uploadDate"
                  value={formData.uploadDate}
                  onChange={handleInputChange}
                />
              ) : (
                formatDateWithDay(collaboration.uploadDate)
              )}
            </S.Date>
          </S.DateSection>
          <S.TextSection>
            <S.TextLabel>협업미션</S.TextLabel>
            {isEditMode ? (
              <S.Textarea
                name="missionDetail"
                value={formData.missionDetail}
                onChange={handleInputChange}
              />
            ) : (
              <S.Text>{collaboration.missionDetail}</S.Text>
            )}
          </S.TextSection>
          <S.TextSection>
            <S.TextLabel>주의사항</S.TextLabel>
            {isEditMode ? (
              <S.Textarea
                name="caution"
                value={formData.caution}
                onChange={handleInputChange}
              />
            ) : (
              <S.Text>{collaboration.caution}</S.Text>
            )}
          </S.TextSection>
          <S.DateSection>
            <S.DateLabel>숙소 _id</S.DateLabel>
            <S.Date>{collaboration.lodgment._id}</S.Date>
          </S.DateSection>
          <S.DateSection>
            <S.DateLabel>예약 _id</S.DateLabel>
            <S.Date>{collaboration.reservation}</S.Date>
          </S.DateSection>
          <S.DateSection>
            <S.DateLabel>협업 _id</S.DateLabel>
            <S.Date>{collaboration._id}</S.Date>
          </S.DateSection>
          <S.Image src={collaboration.lodgment.coverImg} alt="lodgment" />
        </S.Container>
        <S.Layout
          style={{
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <S.Container>
            {collaboration.status === "enrolling" && (
              <>
                <S.Action>
                  <S.ApproveButton onClick={handleConfirm}>
                    협업 승인
                  </S.ApproveButton>
                </S.Action>
                <S.Action>
                  <S.RejectButton onClick={handleReject}>
                    협업 거절
                  </S.RejectButton>
                  <S.RejectInput
                    type="text"
                    placeholder="사유 입력"
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  />
                </S.Action>
              </>
            )}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                gap: "36px",
              }}
            >
              <S.Action>
                <S.CancelButton onClick={handleCancel}>
                  협업 취소
                </S.CancelButton>
              </S.Action>
            </div>
            <div
              style={{
                display: "flex",
                gap: "16px",
              }}
            >
              <S.Action>
                <S.EditButton
                  onClick={(e) => {
                    if (isEditMode) {
                      handleSubmit(e);
                    } else {
                      toggleEditMode();
                    }
                  }}
                >
                  {isEditMode ? "저장" : "수정"}
                </S.EditButton>
              </S.Action>
              {isEditMode && (
                <S.Action>
                  <S.EditCancelButton onClick={toggleEditMode}>
                    수정 취소
                  </S.EditCancelButton>
                </S.Action>
              )}
            </div>
            {["ongoing", "completed"].includes(collaboration.status) && (
              <S.Action>
                <S.ApproveButton onClick={handleChat}>
                  채팅방 바로가기
                </S.ApproveButton>
              </S.Action>
            )}
          </S.Container>

          <S.Container>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                paddingRight: "16px",
              }}
            >
              <h5>지원 현황</h5>총{" "}
              {
                sortedSubmissions.filter(
                  (submission) => submission.status !== "cancelled"
                ).length
              }
              건
            </div>
            {sortedSubmissions &&
              sortedSubmissions.map((submission) => (
                <S.SubmissionCard
                  status={submission?.status}
                  selected={submission._id === collaboration?.submission}
                  onClick={() => {
                    if (submission?.status === "completed") {
                      window.open(
                        `https://hautrip.com/review/collaboration/${collaborationId}/content?submissionId=${submission._id}`
                      );
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "16px",
                    }}
                  >
                    <S.ProfileContainer>
                      <S.ProfileImage
                        src={
                          submission.user[collaboration.mission]
                            ?.profileImage ??
                          "https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/skeleton_bg.png"
                        }
                        alt="profile"
                      />
                      {submission?.isDateConflict ? (
                        <S.ProfileIcon
                          src="https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/reportX4.png"
                          alt="⚠️"
                        />
                      ) : (
                        submission?.user?.isPreAppliedForReview && (
                          <S.ProfileIcon
                            src="https://hau-image-bucket.s3.ap-northeast-2.amazonaws.com/Group+4533.png"
                            alt="⭐️"
                          />
                        )
                      )}
                    </S.ProfileContainer>
                    <S.Description>{submission._id}</S.Description>
                    {submission.status === "completed" && (
                      <div
                        style={{
                          border: "1px solid #3182F7",
                          color: "#3182F7",
                          padding: "4px 8px",
                          borderRadius: "8px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          fontWeight: "600",
                          textAlign: "center",
                          height: "fit-content",
                          width: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        업로드 완료
                      </div>
                    )}
                    {submission.isCancelledByUser && (
                      <div
                        style={{
                          border: "1px solid #FF8791",
                          color: "#FF8791",
                          padding: "4px 8px",
                          borderRadius: "8px",
                          fontSize: "12px",
                          lineHeight: "18px",
                          fontWeight: "600",
                          textAlign: "center",
                          height: "fit-content",
                          width: "fit-content",
                          whiteSpace: "nowrap",
                        }}
                      >
                        체험단 취소
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      fontSize: "14px",
                      color: " #666",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <S.Link
                      href={submission.user[collaboration.mission]?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <S.Nickname>
                        <u>
                          {submission.user[collaboration.mission]?.nickname}
                        </u>
                      </S.Nickname>
                    </S.Link>
                    <S.Description>
                      {submission.user[collaboration.mission]?.follower} 명
                    </S.Description>
                    ·
                    <S.Description>
                      {submission.user[collaboration.mission]?.post} 개
                    </S.Description>
                  </div>
                  <S.Message>{submission.message}</S.Message>
                </S.SubmissionCard>
              ))}
          </S.Container>
        </S.Layout>
      </S.Layout>
    </form>
  ) : (
    <S.NoData>협업 정보가 없어요 😿</S.NoData>
  );
}

const S = {
  Layout: styled.div`
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
  `,
  Container: styled.div`
    min-width: 400px;
    max-width: 600px;
    padding: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 16px;
    font-family: Arial, sans-serif;
  `,
  Header: styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 16px;
  `,
  Title: styled.h2`
    font-size: 24px;
    font-weight: 600;
    color: #333;
    margin: 0;
  `,
  Icon: styled.img`
    width: 28px;
    height: 28px;
  `,
  DateSection: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #666;
    padding: 4px 0;
    border-bottom: 1px solid #f1f1f1;
    gap: 20px;
  `,
  DateLabel: styled.div`
    font-weight: 500;
    color: #555;
    white-space: nowrap;
  `,
  Date: styled.div`
    color: #888;
    white-space: pre-line;
  `,
  DateInputWrapper: styled.div`
    display: inline-flex;
    align-items: center;
    gap: 8px;
  `,
  DateInput: styled.input`
    padding: 4px 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    outline: none;
    width: 150px; // 필요에 따라 조정 가능
    &:focus {
      border-color: #3182f7;
      box-shadow: 0 0 4px rgba(49, 130, 247, 0.5);
    }
  `,
  TextSection: styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: #666;
    padding: 4px 0;
    border-bottom: 1px solid #f1f1f1;
    gap: 28px;
  `,
  TextLabel: styled.div`
    font-weight: 500;
    color: #555;
    white-space: nowrap;
  `,
  Text: styled.div`
    color: #888;
    white-space: pre-line;
  `,
  Textarea: styled.textarea`
    width: 100%;
    min-height: 120px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
    color: #333;
    resize: vertical; // 세로 크기만 조정 가능, 가로 조정 불가
    outline: none;

    &:focus {
      border-color: #3182f7;
      box-shadow: 0 0 4px rgba(49, 130, 247, 0.3);
    }

    &::placeholder {
      color: #aaa;
    }
  `,
  Reason: styled.div`
    font-size: 14px;
    color: #555;
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
  `,
  Image: styled.img`
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
  `,
  Action: styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 36px;
  `,
  ApproveButton: styled.div`
    padding: 8px 16px;
    background-color: #3182f7;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
  `,
  RejectInput: styled.input`
    padding: 8px;
    border-radius: 4px;
    border: 1px solid #ddd;
    flex: 1;
  `,
  RejectButton: styled.div`
    padding: 8px 16px;
    background-color: #ff4d4f;
    color: #ffffff;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  `,
  CancelButton: styled.div`
    padding: 8px 16px;
    background-color: #f3f4f6;
    color: #b0b9c2;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  `,
  EditButton: styled.div`
    padding: 8px 16px;
    background-color: #f7c531;
    color: white;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  `,
  EditCancelButton: styled.div`
    padding: 8px 16px;
    background-color: #fff7e6;
    color: #fa8c16;
    border-radius: 8px;
    font-weight: 600;
    cursor: pointer;
  `,

  OptionSection: styled.div`
    display: flex;
    gap: 12px;
  `,
  Option: styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
  `,
  Checkbox: styled.input`
    transform: scale(1.2);
    cursor: pointer;
  `,
  OptionLabel: styled.label`
    font-size: 16px;
    color: #333;
  `,
  SubmissionCard: styled.div`
    background-color: ${(props) =>
      props.status === "cancelled"
        ? "#F0F0F0"
        : props.status === "selected" ||
          props.status === "completed" ||
          props.selected
        ? "#DCE8FF"
        : "white"};
    padding: 12px;
    color: ${(props) => (props.status === "cancelled" ? "#CCC" : "black")};
    border-radius: 8px;
    border: 1px solid #f1f1f1;
    display: flex;
    flex-direction: column;
    gap: 4px;
  `,
  ProfileContainer: styled.div`
    position: relative;
    display: flex;
    gap: 12px;
    align-items: center;
    width: fit-content;
  `,
  ProfileImage: styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
  `,
  ProfileIcon: styled.img`
    position: absolute;
    width: 20px;
    bottom: 0;
    right: -4px;
  `,
  Link: styled.a`
    display: flex;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-weight: 500;
    font-size: 16px;
  `,
  Nickname: styled.div`
    font-size: 16px;
    font-weight: bold;
  `,
  Description: styled.div`
    font-size: 14px;
    color: #666;
    white-space: nowrap;
  `,
  Message: styled.div`
    font-size: 14px;
    margin-top: 8px;
    color: #333;
  `,
  NoData: styled.div`
    font-size: 18px;
    color: #999;
    text-align: center;
    padding: 40px;
  `,
};
