import { Socket } from "socket.io-client";
import { SERVER_EVENT } from "../../constants/socketEvent";

export const initSocketListening = (socket: Socket) => {
  socket.on(SERVER_EVENT.CONNECTED, () => console.log("connected"));
  socket.on(SERVER_EVENT.ATTENDANCE_INFO, (res) =>
    console.log("attendance info: ", res)
  );
  socket.on(SERVER_EVENT.TYPING_INFO, (res) =>
    console.log("typing info: ", res)
  );
  socket.on(SERVER_EVENT.NEW_MSG, (msg) => console.log("msg: ", msg));
  socket.on(SERVER_EVENT.DUPLICATE_RESPONSE, () => {
    window.alert("이미 응답한 질문입니다");
  });
};
