import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

export const SettingModalHeader = styled.div`
  display: flex;
  margin-top: 1.2rem;
  margin-bottom: 1.1rem;
`;

export const OtherPlatformModalContainer = styled.div`
  position: fixed;
  bottom: 0;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
  max-width: 440px;
  z-index: 1001;
`;
export const OtherPlatformModalContentSection = styled.div`
  position: fixed;
  bottom: 0;
  height: 85vh;
  max-width: 440px;
  background-color: white;
  border-radius: 15px 15px 0 0;
  padding: 0 1.2rem;
`;
export const OtherPlatformModalHeader = styled.div`
  display: flex;
`;
export const OtherPlatformModalTitle = styled.h3`
  font-weight: 700;
  font-size: 1.1rem;
  color: #323232;
`;

export const InputLine = styled.div`
  display: flex;
  padding: 0.6rem 0;
  width: 100%;
  align-items: center;
  border-bottom: 0.1rem solid rgba(233, 236, 239, 1);
`;
export const InputLabel = styled.label`
  font-weight: 500;
  font-size: 0.9rem;
  color: #323232;
  width: 10rem;
`;
export const Input = styled.input`
  background-color: #f3f3f3;
  outline: none;
  border: none;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;
export const Textarea = styled.textarea`
  background-color: #f3f3f3;
  outline: none;
  border: none;
  padding: 0.5rem 0.5rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

export const HostReservationHeader = styled.div`
  position: sticky;
  top: 0;

  text-align: center;
  padding-top: calc(2rem + constant(safe-area-inset-top));
  padding-top: calc(2rem + env(safe-area-inset-top));
  padding-bottom: 1rem;
  font-size: 1rem;
  font-weight: 400;

  background-color: white;

  z-index: 901;
`;

export const GrayLine = styled.div`
  width: 100%;
  background-color: rgba(239, 239, 239, 1);
  height: 0.5rem;
  margin: 1rem 0 1rem 0;
`;

export const externalPlatformrWrapper = styled.div`
  display: flex;
  justify-content: center;
  /* background-color: red; */
  margin-top: 1rem;
  cursor: pointer;
`;
export const externalPlatformrBtn = styled.div`
  width: fit-content;
  border: 1px solid #2b2b2b;
  border-radius: 30px;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 2rem;
  padding-right: 2rem;
`;
export const SelectFilter = styled.div`
  .css-1s2u09g-control {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    width: 6rem;
    border: none;
    focus: {
      outline: none !important;
    }
  }
  .css-319lph-ValueContainer {
  }
  .css-qc6sy-singleValue {
  }
  .css-tj5bde-Svg {
  }
  .css-b62m3t-container {
    border: none;
    width: 6rem;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    focus: {
      outline: none !important;
    }
  }
  .css-tlfecz-indicatorContainer {
    width: fit-content;
    display: none;
    margin: 0;
    padding-right: 0;
  }
  .css-1gtu0rj-indicatorContainer {
    width: fit-content;
    display: none;
    margin: 0;
    padding-right: 0;
  }
  .css-319lph-ValueContainer {
    /* display: flex; */
  }
  .css-1hb7zxy-IndicatorsContainer {
    width: fit-content;
  }
  .css-qc6sy-singleValue {
    width: 10rem;
    padding-top: 0.25rem;
    font-size: 0.8rem;
  }
  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-i0syzg-menu {
    margin: 0;
    font-size: 0.8rem;
  }
  .css-319lph-ValueContainer {
  }
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectWrapper = styled.div`
  display: flex;
  margin-bottom: 1rem;
  margin: 0;
  justify-content: flex-end;
  padding-right: 1.3rem;
  align-items: center;
  position: absolute;
  width: fit-content;
  right: 0;
  max-width: 440px;
  z-index: 900;
`;

export const NeedSelectDateMent = styled.div`
  width: 100%;
  text-align: center;
  padding-top: 2rem;
`;

export const LodgmentUnitUpperBox = styled.div`
  display: flex;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.7rem;
  padding-bottom: 0.5rem;
`;
export const LodgmentUnitBottomBox = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-bottom: 0.7rem;
  padding-top: 0.5rem;
  font-size: 0.9rem;
`;
export const LodgmentUnitName = styled.h3`
  color: #323232;
  font-size: 1rem;
  font-weight: 700;
`;
export const LodgmentUnitBtn = styled.div`
  color: #323232;
  font-size: 0.8rem;
  font-weight: 600;
  width: 6rem;
`;

export const DetailModalContainer = styled.div`
  padding-bottom: 13vh;
  /* padding-left: 1.2rem;
  padding-right: 1.2rem; */
`;

export const DetailModalText = styled.div`
  font-size: 0.9rem;
  margin-top: 0.3rem;
  color: #6a6a6a;
`;

export const DetailModalReservationNumText = styled.div`
  color: #323232;
  font-weight: 700;
  font-size: 1rem;
`;

export const SettingModalCloseBtn = styled.div`
  width: fit-content;
  position: absolute;
  /* left: 1.2rem; */
`;
export const SettingModalHeaderText = styled.div`
  width: 100%;
  text-align: center;
  font-size: 0.9rem;
`;

export const ModalInnerBox = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;
`;
export const ModalInnerLabel = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 1rem;
  color: #323232;
`;
export const ModalInnerInputRadio = styled.input`
  accent-color: #2b2b2b;
  margin: 0;
  width: 2rem;
  opacity: 0.4;
  :checked {
    opacity: 1;
  }
`;

export const ModalSaveBtn = styled.div`
  border: 1px solid #2b2b2b;
  border-radius: 30px;
  width: fit-content;
  padding: 0.6rem 2.5rem;
  font-weight: 700;
  font-size: 0.9rem;
  text-align: center;
  color: white;
  cursor: pointer;
  background-color: #323232;
`;

export const CustomCurrencyInput = styled(CurrencyInput)`
  /* width: 15rem; */
  background-color: #f3f3f3;
  outline: none;
  border: none;
  padding: 0.6rem 0.5rem;
  font-size: 0.9rem;
  margin-top: 0.5rem;
`;

export const ModalSaveBtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const Select = styled.select`
  outline: none;
  border: none;
`;
export const Option = styled.option``;

export const CalendarInput = styled.input`
  outline: none;
  border: none;
  width: 10rem;
`;
