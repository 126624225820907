import * as React from "react";
import { File, Input, InputError, InputLabel, Textarea } from "./Sub";

export interface IInputMainProps {
  children?: React.ReactNode;
}

// deprecated
export default function InputMain({ children }: IInputMainProps) {
  return <>{children}</>;
}

export const InputField = Object.assign(InputMain, {
  Error: InputError,
  Label: InputLabel,
  Input: Input,
  TextArea: Textarea,
  File: File,
});
