import {
  getCleaningScheduleById,
  updateCleaingSchedule,
} from "api/cleaningSchedule";
import { uploadCleaningImg } from "api/image";
import * as React from "react";
import { useParams } from "react-router-dom";
import * as S from "./cleaningSchedule.style";

export interface ICleaningScheduleDetailProps {}

export interface params {
  cleaningScheduleId: string;
}

export default function CleaningScheduleDetail(
  props: ICleaningScheduleDetailProps
) {
  const { cleaningScheduleId } = useParams<params>();
  const [date, setDate] = React.useState<string>();
  const [startTime, setStartTime] = React.useState<string>();
  const [endTime, setEndTime] = React.useState<string>();
  const [isComplete, setIsComplete] = React.useState<boolean>();
  const [lodgmentName, setLodgmentName] = React.useState<string>();
  const [manager, setManager] = React.useState<string>();
  const [photos, setPhotos] = React.useState<string[]>([]);

  const imgRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    const fetchCleaningSchedules = async () => {
      const cleaningSchedule = await getCleaningScheduleById(
        cleaningScheduleId
      );
      const {
        date,
        startTime,
        endTime,
        isComplete,
        lodgment,
        manager,
        photos,
      } = cleaningSchedule;
      setDate(date);
      setStartTime(startTime);
      setEndTime(endTime);
      setIsComplete(isComplete);
      setLodgmentName(lodgment.name);
      setManager(manager);
      setPhotos(photos);
    };
    fetchCleaningSchedules();
  }, []);

  const onSubmit = async () => {
    const data = {
      startTime,
      endTime,
      isComplete,
      manager,
      photos,
    };
    await updateCleaingSchedule(cleaningScheduleId, data);
    window.location.reload();
  };

  const PhotoItem = ({ src }) => (
    <div
      style={{
        border: "0.1rem solid black",
        padding: "0.5rem",
        margin: "0.5rem",
      }}
    >
      <p
        style={{ cursor: "pointer" }}
        onClick={() => {
          const updatedPhotos = photos.filter((photo) => photo !== src);
          setPhotos(updatedPhotos);
        }}
      >
        X
      </p>
      <img style={{ width: "10rem" }} src={src} />
    </div>
  );

  if (!date) {
    return <div>로딩중입니다!</div>;
  }

  return (
    <div>
      <S.InputLine>
        <h4>숙소명 : {lodgmentName}</h4>
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>청소 일자: </S.InputTitle>
        <p>{date}</p>
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>청소 시간: </S.InputTitle>
        <S.Input
          onChange={(e) => setStartTime(e.target.value)}
          value={startTime}
        />{" "}
        ~{" "}
        <S.Input onChange={(e) => setEndTime(e.target.value)} value={endTime} />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>청소 완료 여부: </S.InputTitle>
        <S.Input
          type="checkbox"
          checked={isComplete}
          onChange={(e) => setIsComplete(e.target.checked)}
        />
      </S.InputLine>
      <S.InputLine>
        <S.InputTitle>매니저명: </S.InputTitle>
        <S.Input value={manager} onChange={(e) => setManager(e.target.value)} />
      </S.InputLine>
      <p>청소 사진: </p>
      <S.Input type="file" accept="image/*" ref={imgRef} multiple required />
      <button
        onClick={async () => {
          const formData = new FormData();
          if (!imgRef.current?.files.length) {
            alert("사진을 첨부해야 합니다.");
            return;
          }
          const imgFiles = [...imgRef.current.files];
          imgFiles.forEach((file) => formData.append("cleaningPhotos", file));
          const {
            data: { cleaningPhotos },
          } = await uploadCleaningImg(formData);
          setPhotos([...photos, ...cleaningPhotos]);
          imgRef.current.value = "";
        }}
      >
        추가하기
      </button>
      <div style={{ display: "flex" }}>
        {photos.map((src) => (
          <PhotoItem src={src} />
        ))}
      </div>
      <button onClick={onSubmit}>수정하기</button>
    </div>
  );
}
