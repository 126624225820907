import styled from "styled-components";
import { getCollaborationList } from "api/collaboration";
import { useState, useEffect } from "react";
import CollaborationCard from "./CollaborationCard";

export default function ManageCollaboration() {
  const [collaborations, setCollaborations] = useState([]);
  const [statusCounts, setStatusCounts] = useState({
    enrolling: 0,
    confirmed: 0,
    ongoing: 0,
    completed: 0,
    cancelled: 0,
    revoked: 0,
  });

  useEffect(() => {
    const fetchCollaborations = async () => {
      const res = await getCollaborationList();
      setCollaborations(res.reverse());
      const counts = res.reduce(
        (acc, collaboration) => {
          const status = collaboration.status;
          if (acc[status] !== undefined) {
            acc[status] += 1;
          } else {
            console.log(collaboration);
          }
          return acc;
        },
        {
          enrolling: 0,
          confirmed: 0,
          ongoing: 0,
          completed: 0,
          cancelled: 0,
          revoked: 0,
        }
      );
      setStatusCounts(counts);
    };
    fetchCollaborations();
  }, []);

  return (
    <Container>
      <Title>협업 관리</Title>
      <S.StatusContainer>
        {Object.entries({
          전체: collaborations.length,
          검토: statusCounts.enrolling,
          모집중: statusCounts.confirmed,
          협업진행: statusCounts.ongoing,
          협업완료: statusCounts.completed,
          취소: statusCounts.cancelled,
          철회: statusCounts.revoked,
        }).map(([label, count]) => (
          <S.StatusItem key={label}>
            <p>{label}</p>
            <span>{count}</span>
          </S.StatusItem>
        ))}
      </S.StatusContainer>
      <CollaborationGrid>
        {collaborations.map((collaboration) => (
          <CollaborationCard
            collaboration={collaboration}
            key={collaboration._id}
          />
        ))}
      </CollaborationGrid>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const Title = styled.h5`
  font-size: 24px;
  margin: 0;
  color: #333;
`;

const CollaborationGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
`;

const S = {
  StatusContainer: styled.div`
    display: flex;
    gap: 24px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    background-color: #f9f9f9;
  `,

  StatusItem: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      font-size: 14px;
      color: #888;
      margin: 0;
    }

    span {
      font-size: 16px;
      font-weight: 600;
      color: #333;
    }
  `,
};
