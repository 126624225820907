import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "config/axiosConfig";
import useTitle from "hooks/useTitle";

export default function Admin() {
  useTitle("관리자");
  const history = useHistory();
  const jwt = localStorage.getItem("jwt");
  const [user, setUser] = useState({
    username: null,
    isLogin: false,
    isAdmin: false,
  });

  useEffect(async () => {
    const res = await axios.post("adminUsers/check", { jwt });
    setUser(res.data.user);
  }, []);

  const logout = () => {
    localStorage.removeItem("jwt");
    history.push("/");
    history.go(0);
  };

  if (user.username) {
    return (
      <div>
        <h3>관리자 페이지</h3>
        <div>{user.username}로 접속중</div>
        <button onClick={logout}>로그아웃</button>
      </div>
    );
  }

  return (
    <div>
      <h3>관리자 페이지</h3>
      <div>로그인이 필요합니다</div>
      <Link to="signup">
        <button>가입</button>
      </Link>
      <Link to="login">
        <button>로그인</button>
      </Link>
    </div>
  );
}
