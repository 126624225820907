import Modal from "react-modal";

Modal.setAppElement("#root");

export default function TagCreateModal({
  isCreatingModalVisible,
  setIsCreatingModalVisible,
  setIsLoading,
  setNewTagName,
  setNewTagCategory,
  newTagCategory,
  handleCategorySelectDone,
}) {
  const tagCategories = [
    { value: "type", label: "타입(귤따기 체험, 소품샵 등...)" },
    { value: "time", label: "추천 시간대(아침, 점심 등)" },
    { value: "food", label: "음식 종류인지(갈치, 고등어, 흑돼지 등)" },
    { value: "mood", label: "분위기(감성, 힐링 등)" },
    { value: "companion", label: "추천 동행인(아이와 함께 등)" },
    { value: "season", label: "어느 계절에 가기 좋은지(겨울, 봄, 등)" },
    { value: "general", label: "일반(그 외 것)" },
    { value: "influencer", label: "인플루언서 태그" },
  ];

  return (
    <Modal
      isOpen={isCreatingModalVisible}
      onRequestClose={() => {
        setIsCreatingModalVisible(!isCreatingModalVisible);
        setIsLoading(false);
        setNewTagName(null);
        setNewTagCategory(null);
      }}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "40px",
          left: "40px",
          right: "40px",
          bottom: "40px",
          maxWidth: 800,
          maxHeight: 500,
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "4px",
          outline: "none",
          padding: "20px",
          alignItems: "center",
          justifyContent: "center",
        },
      }}
    >
      <div>태그의 카테고리를 골라주세용^^</div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
          flexWrap: "wrap",
        }}
      >
        {tagCategories.map((cat, cIdx) => {
          return (
            <div
              key={cat.value + "카테고리"}
              style={{
                marginRight: 10,
                borderRadius: 5,
                border: "1px solid #e9e9e9",
                padding: 5,
                marginBottom: 10,
                cursor: "pointer",
                backgroundColor:
                  newTagCategory == cat.value ? "darkgray" : "#ffffff",
                color: newTagCategory == cat.value ? "#ffffff" : "#494949",
                fontWeight: "700",
              }}
              onClick={() => {
                setNewTagCategory(cat.value);
              }}
            >
              {cat.value}
              {" - "}
              {cat.label}
            </div>
          );
        })}
      </div>
      <div
        style={{
          display: "flex",
          marginTop: 20,
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <div
          aria-disabled={!newTagCategory}
          style={{
            backgroundColor: newTagCategory ? "red" : "gray",
            borderRadius: 10,
            padding: 5,
            width: 100,
            color: "#ffffff",
            fontWeight: "700",
            alignSelf: "center",
            marginRight: 10,
            textAlign: "center",
            cursor: newTagCategory ? "pointer" : "unset",
          }}
          onClick={() => {
            handleCategorySelectDone();
          }}
        >
          등록
        </div>
        <div
          style={{
            backgroundColor: "gray",
            borderRadius: 10,
            padding: 5,
            width: 50,
            color: "#ffffff",
            fontWeight: "700",
            cursor: "pointer",
            textAlign: "center",
            alignSelf: "center",
          }}
          onClick={() => {
            setIsCreatingModalVisible(!isCreatingModalVisible);
            setIsLoading(false);
            setNewTagName(null);
            setNewTagCategory(null);
          }}
        >
          취소
        </div>
      </div>
    </Modal>
  );
}
