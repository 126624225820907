interface IS3Config {
  bucketName: string;
  region: string;
  accessKeyId: string;
  secretAccessKey: string;
}

export const s3ConfigPostImg: IS3Config = {
  bucketName: "hwik-jeju-image",
  region: "ap-northeast-2",
  accessKeyId: "AKIAVJHLIUMBY5YS52UK",
  secretAccessKey: "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",
};

export default { s3ConfigPostImg };
