import { updateConsult } from "api/consult";
import {
  deleteFormResponse,
  getFormResponsesByConsult,
} from "api/formResponse";
import { getUser } from "api/user";
import { FlexBetween } from "components/style";
import { INFLOW_TYPE_TO_KR } from "constants/user";
import * as React from "react";
import { IConsult } from "type/consult.type";
import { IFormResponse } from "type/form.type";
import { IUser } from "type/user.type";
import * as S from "./LeftWing.style";

export interface ILeftWingProps {
  consult: IConsult;
}

export default function LeftWing({ consult }: ILeftWingProps) {
  const [user, setUser] = React.useState<IUser>();
  const [responses, setResponses] = React.useState<IFormResponse[]>([]);
  const [responseOpenState, setResponseOpenState] = React.useState<boolean[]>(
    []
  );
  const [memo, setMemo] = React.useState(consult.memo);

  React.useEffect(() => {
    if (!consult) return;

    const fetchResponses = async (consultId: string) => {
      const { formResponses } = await getFormResponsesByConsult(consultId);
      setResponses(formResponses);
      setResponseOpenState(Array(formResponses.length).map(() => false));
    };

    fetchResponses(consult._id);
  }, [consult]);

  React.useEffect(() => {
    if (!consult) return;

    const fetchUser = async (userId: string) => {
      const { user: userRes } = await getUser(userId);
      setUser(userRes);
    };

    fetchUser(consult.user);
  }, [consult]);

  const refetchResponses = async (): Promise<void> => {
    const { formResponses } = await getFormResponsesByConsult(consult._id);
    setResponses(formResponses);
    setResponseOpenState(Array(formResponses.length).map(() => false));
  };

  const deleteResponse = async (responseId: string): Promise<void> => {
    await deleteFormResponse(responseId);
    refetchResponses();
  };

  const onChangeMemo = (e: React.ChangeEvent<HTMLTextAreaElement>): void =>
    setMemo(e.target.value);

  const saveMemo = async (): Promise<void> => {
    try {
      await updateConsult(consult._id, { memo });
      alert("메모가 저장되었습니다");
    } catch (error) {
      alert(error);
    }
  };

  if (consult && user) {
    return (
      <S.LeftWingContainer>
        <>
          <FlexBetween>
            <div>전화번호: {consult.userPhone}</div>
            <button onClick={refetchResponses}>새로고침</button>
          </FlexBetween>
          <div>{`유입경로: ${INFLOW_TYPE_TO_KR[user.inflow.type]} - ${
            user.inflow.detail
          }`}</div>
          <S.ResponseContainer>
            {responses.map((response, idx) => {
              return (
                <S.Response
                  onClick={() => {
                    const newOpenState = [...responseOpenState];
                    newOpenState[idx] = !newOpenState[idx];
                    setResponseOpenState(newOpenState);
                  }}
                >
                  <FlexBetween>
                    <div>
                      <strong>{response.title}</strong>
                    </div>
                    <div
                      onClick={(e) => {
                        e.stopPropagation();
                        if (!confirm("응답을 삭제하시겠습니까?")) return;
                        deleteResponse(response._id);
                      }}
                      style={{ color: "red" }}
                    >
                      삭제
                    </div>
                  </FlexBetween>
                  {responseOpenState[idx] && (
                    <>
                      <div>{response.question}</div>
                      <div>{response.answers.join(", ")}</div>
                    </>
                  )}
                </S.Response>
              );
            })}
          </S.ResponseContainer>
          <div>상담 메모</div>
          <S.MemoContainer>
            <S.MemoTextarea
              value={memo}
              onChange={onChangeMemo}
            ></S.MemoTextarea>
            <S.SaveButton onClick={saveMemo}>저장</S.SaveButton>
          </S.MemoContainer>
        </>
      </S.LeftWingContainer>
    );
  }
  return null;
}
