import React from "react";
import { deleteNaverBusiness, getNaverBusinesses } from "api/naver";
import { AiFillDelete } from "react-icons/ai";
import * as S from "./naver.style";
import { set } from "date-fns";

type Props = {
  // bla: string;
};

export default function Naver({}: Props) {
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    const fetchData = async () => {
      const response = await getNaverBusinesses();
      //   console.log(response);
      setData(response);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div>
      <h4>네이버 연동 현황</h4>
      {data.length > 0 && (
        <S.ListSection>
          {data.map((item: Record<string, any>) => (
            <ListItem item={item} data={data} setData={setData} />
          ))}
        </S.ListSection>
      )}
      <div></div>
    </div>
  );
}

function ListItem({ item, data, setData }) {
  const handleDelete = async (
    businessId: string,
    state: any,
    setState: any
  ) => {
    try {
      if (window.confirm(`숙소: ${item.name} 정말 삭제하시겠습니까?`)) {
        if (window.prompt("비밀번호를 입력해주세요") === "4062") {
          await deleteNaverBusiness(businessId);
          alert("삭제되었습니다.");
          setState(
            state.filter(
              ({ businessId: deletedId }) => deletedId !== businessId
            )
          );
        } else {
          alert("비밀번호가 틀렸습니다.");
        }
      }
    } catch (error) {
      console.dir(error);
      alert("다시 시도해주세요");
    }

    return;
  };
  return (
    <S.ListItem>
      <S.ListItemContent>
        <S.ListItemText>{item.name}</S.ListItemText>
        <S.ListItemText>{item.reprOwnerName}</S.ListItemText>
        <S.ListItemText>{item.phoneInformationJson.reprPhone}</S.ListItemText>
      </S.ListItemContent>
      <S.ListItemButton
        onClick={() => handleDelete(item.businessId, data, setData)}
      >
        <AiFillDelete />
      </S.ListItemButton>
    </S.ListItem>
  );
}
