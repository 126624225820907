import { getPackage } from "api/package";
import * as React from "react";
import { useParams } from "react-router-dom";
import PackageCreate from "./create";

export interface IPackageDetailProps {}

export interface params {
  packageId: string;
}

export default function PackageDetail(props: IPackageDetailProps) {
  const { packageId } = useParams<params>();
  const [data, setData] = React.useState<Record<any, any>>();

  React.useEffect(() => {
    const fetchPackage = async () => {
      if (!packageId) {
        return;
      }
      const { data } = await getPackage(packageId);
      setData(data);
    };
    fetchPackage();
  }, [packageId]);

  if (!data) {
    return <>이 화면이 2초 이상 지속되면 새로고침해주세요.</>;
  }

  return (
    <div>
      <PackageCreate data={data} />
    </div>
  );
}
