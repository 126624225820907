export const singleChoiceValidator = ({ question, options }) => {
  if (!question || options.includes("")) {
    return false;
  }
  return true;
};

export const multipleChoiceValidator = ({ question, options, btnText }) => {
  if (!question || options.includes("") || !btnText) {
    return false;
  }
  return true;
};

export const subjectiveFormValidator = ({ question, btnText }) => {
  if (!question || !btnText) {
    return false;
  }
  return true;
};

export const btnMsgValidator = ({ text, btnLink, btnText }) => {
  if (!text || !btnLink || !btnText) {
    return false;
  }
  if (!btnLink.includes("https://")) {
    return false;
  }
  return true;
};

export const textMsgFormValidator = ({ text }) => {
  if (!text) {
    return false;
  }
  return true;
};
