import { deletePackage, getPackages } from "api/package";
import * as React from "react";
import { useHistory } from "react-router-dom";
import * as S from "./package.style";

export interface IPackageProps {}

export default function Package(props: IPackageProps) {
  const history = useHistory();
  const [packages, setPackages] = React.useState([]);

  const onPackageItem = (id) => {
    history.push(`/package/${id}`);
  };

  const onDeleteBtn = async (id) => {
    if (confirm("정말 삭제하시겠습니까?")) {
      await deletePackage(id);
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const fetchPackages = async () => {
      const { data } = await getPackages();
      setPackages(data);
    };
    fetchPackages();
  }, []);

  const $Packages = packages.map((packageData) => (
    <S.PackageItem
      key={packageData._id}
      onClick={() => onPackageItem(packageData._id)}
    >
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDeleteBtn(packageData._id);
        }}
      >
        삭제
      </button>
      <S.PackageRepresentImg src={packageData.coverImage || ""} />
      <div>
        <S.PackageTitle>
          {packageData.title}({packageData.theme})
        </S.PackageTitle>
      </div>
    </S.PackageItem>
  ));

  return (
    <>
      <button onClick={() => history.push("/package/new")}>
        패키지 만들기
      </button>
      <S.PackageContainer>{$Packages}</S.PackageContainer>
    </>
  );
}
