import axios from "../config/axiosConfig";
import { DEV_URL, PRODUCTION_URL } from "../constants/apiUrl";

const serverUrl =
  process.env.NODE_ENV === "development" ? PRODUCTION_URL : PRODUCTION_URL;

const URL = serverUrl + "/api";

interface IAxios {
  path: string;
  data?: Record<string, any> | FormData;
}

const fileUploadHeaderConfig = {};

export const myAxios = {
  get({ path }: IAxios) {
    return axios.get(URL + path);
  },

  post({ path, data }: IAxios) {
    return axios.post(URL + path, data);
  },

  patch({ path, data }: IAxios) {
    return axios.patch(URL + path, data);
  },

  put({ path, data }: IAxios) {
    return axios.put(URL + path, data);
  },

  delete({ path }: IAxios) {
    return axios.delete(URL + path);
  },

  filepost({ path, data }: IAxios) {
    return axios.post(URL + path, data, fileUploadHeaderConfig);
  },
};
