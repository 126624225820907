import * as React from "react";
import { useHistory } from "react-router-dom";
import { uploadPackageImg } from "api/image";
import { postPackage, putPackage } from "api/package";
import { getPackagePlaces } from "api/packagePlace";
import * as S from "./package.style";

export interface IPackageProps {
  data?;
}

export default function PackageCreate({ data }: IPackageProps) {
  const history = useHistory();

  const [title, setTitle] = React.useState("");
  const [tags, setTags] = React.useState([]);
  const [theme, setTheme] = React.useState("");
  const [profileName, setProfileName] = React.useState("");
  const [places, setPlaces] = React.useState([]);
  const [coverImage, setCoverImage] = React.useState("");
  const [profileImage, setProfileImage] = React.useState("");
  const [mapImage, setMapImage] = React.useState("");
  const [placeList, setPlaceList] = React.useState([]);

  // for update
  const [packageIdForUpdate, setPackageIdForUpdate] = React.useState("");

  const selectRef = React.useRef<HTMLSelectElement>();

  const onPlaceDeleteBtn = (index) => {
    const updatedPlaces = [...places];
    updatedPlaces.splice(index, 1);
    setPlaces(updatedPlaces);
  };

  const onTagDeleteBtn = (index) => {
    const updatedTags = [...tags];
    updatedTags.splice(index, 1);
    setTags(updatedTags);
  };

  const onCoverImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("coverImage", file);
    const {
      data: { coverImage },
    } = await uploadPackageImg(formData);
    setCoverImage(coverImage[0]);
  };

  const onProfileImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("profileImage", file);
    const {
      data: { profileImage },
    } = await uploadPackageImg(formData);
    setProfileImage(profileImage[0]);
  };

  const onMapImage = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) {
      return;
    }
    const formData = new FormData();
    formData.append("mapImage", file);
    const {
      data: { mapImage },
    } = await uploadPackageImg(formData);
    setMapImage(mapImage[0]);
  };

  const onSubmit = async () => {
    if (!coverImage || !profileImage || !mapImage) {
      alert("이미지를 모두 첨부해주세요");
      return;
    }

    const placeIds = places.map(({ id }) => id);
    const data = {
      title,
      tags,
      theme,
      coverImage,
      profileImage,
      profileName,
      mapImage,
      places: placeIds,
    };
    if (packageIdForUpdate) {
      await putPackage(packageIdForUpdate, data);
    } else {
      await postPackage(data);
    }
    history.push("/package");
  };

  React.useEffect(() => {
    const fetchPackagePlaces = async () => {
      const { data } = await getPackagePlaces();
      setPlaceList(data);
    };
    fetchPackagePlaces();
  }, []);

  React.useEffect(() => {
    if (data) {
      setPackageIdForUpdate(data._id);
      setTitle(data.title);
      setTags(data.tags);
      setTheme(data.theme);
      setCoverImage(data.coverImage);
      setProfileImage(data.profileImage);
      setMapImage(data.mapImage);
      setProfileName(data.profileName);
      setPlaces(
        data.places.map((place) => ({
          title: place.title,
          id: place._id,
        }))
      );
    }
  }, [data]);

  return (
    <div>
      <>패키지 만드는 페이지</>

      <S.InputLayout>
        <S.InputLabel htmlFor="packageTitle">패키지 이름: </S.InputLabel>
        <S.Input
          id="packageTitle"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel>해쉬태그</S.InputLabel>
        <button
          onClick={() => {
            setTags([...tags, ""]);
          }}
        >
          추가
        </button>
        <br />
        {tags.map((tag, index) => (
          <div style={{ display: "flex" }}>
            <S.Input
              value={tag}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const updatedTags = [...tags];
                updatedTags[index] = e.target.value;
                setTags(updatedTags);
              }}
            />
            <S.DeleteBtn onClick={() => onTagDeleteBtn(index)}>
              삭제
            </S.DeleteBtn>
          </div>
        ))}
      </S.InputLayout>
      <S.InputLayout>
        <S.InputLabel htmlFor="theme">테마: </S.InputLabel>
        <select
          id="theme"
          value={theme}
          onChange={(e) => setTheme(e.target.value)}
        >
          <option value="">테마를 선택하세요</option>
          {["aewol", "sungsan", "md"].map((theme) => (
            <option value={theme}>{theme}</option>
          ))}
        </select>
      </S.InputLayout>
      <div
        style={{
          display: "flex",
          width: "fit-content",
        }}
      >
        <S.InputLayout>
          {coverImage && (
            <img
              src={coverImage}
              style={{
                width: "10rem",
              }}
            />
          )}
          <br />
          <S.InputLabel htmlFor="coverImage">커버 이미지: </S.InputLabel>
          <S.Input
            id="coverImage"
            type="file"
            accept="image/*"
            onChange={onCoverImage}
          />
        </S.InputLayout>
        <S.InputLayout>
          {profileImage && (
            <img
              src={profileImage}
              style={{
                width: "10rem",
              }}
            />
          )}
          <br />
          <S.InputLabel htmlFor="profileImage">프로필 이미지: </S.InputLabel>
          <S.Input
            id="profileImage"
            type="file"
            accept="image/*"
            onChange={onProfileImage}
          />
        </S.InputLayout>
        <S.InputLayout>
          {mapImage && (
            <img
              src={mapImage}
              style={{
                width: "10rem",
              }}
            />
          )}
          <br />
          <S.InputLabel htmlFor="mapImage">지도 이미지: </S.InputLabel>
          <S.Input
            id="mapImage"
            type="file"
            accept="image/*"
            onChange={onMapImage}
          />
        </S.InputLayout>
        <br />
      </div>
      <S.InputLayout>
        <S.InputLabel htmlFor="profileName">프로필 이름: </S.InputLabel>
        <S.Input
          id="profileName"
          value={profileName}
          onChange={(e) => setProfileName(e.target.value)}
        />
      </S.InputLayout>
      <select ref={selectRef}>
        {placeList.map((place) => (
          <option value={`${place._id}/${place.title}`}>{place.title}</option>
        ))}
      </select>
      <button
        onClick={() => {
          if (!selectRef.current) {
            return;
          }
          const [id, title] = selectRef.current.value.split("/");
          const place = {
            title,
            id,
          };
          setPlaces([...places, place]);
        }}
      >
        추가하기
      </button>
      <S.SelectedPlaceContainer>
        {places.map(({ title }, index) => (
          <S.SelectedPlaceItem>
            <S.SelectedPlaceTitle>{title}</S.SelectedPlaceTitle>
            <S.DeleteBtn onClick={() => onPlaceDeleteBtn(index)}>
              삭제
            </S.DeleteBtn>
          </S.SelectedPlaceItem>
        ))}
      </S.SelectedPlaceContainer>
      {packageIdForUpdate ? (
        <S.SubmitBtn onClick={onSubmit}>수정하기</S.SubmitBtn>
      ) : (
        <S.SubmitBtn onClick={onSubmit}>생성하기</S.SubmitBtn>
      )}
    </div>
  );
}
