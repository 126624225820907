import {
  createCorporations,
  getCorporations,
  deleteCorporations,
  updateCorporation,
} from "api/corporation";
import { uploadPackageImg } from "api/image";
import ImageField from "common/ImageField";
import * as React from "react";
import { Link } from "react-router-dom";
import * as S from "./promotion.style";

export interface IPromotionDetailProps {}

export default function PromotionDetail(props: IPromotionDetailProps) {
  const [corporations, setCorporations] = React.useState([]);
  const [corporationCount, setCorporationCount] = React.useState(0);

  React.useEffect(() => {
    const fetchCorporations = async () => {
      const res = await getCorporations();
      console.log("res", res);
      setCorporations(res.data);
      setCorporationCount(res.data.length);
    };
    fetchCorporations();
  }, []);

  const handleChange = (e, idx) => {
    const {
      target: { value, name },
    } = e;
    const tempCorporations = [...corporations];
    tempCorporations[idx][name] = value;
    setCorporations(tempCorporations);
  };

  const handleChangeImg = async (e, idx) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) return;
    const formData = new FormData();
    formData.append("corporateLogo", file);
    const {
      data: { corporateLogo },
    } = await uploadPackageImg(formData);
    const tempCorporations = [...corporations];
    tempCorporations[idx].img = corporateLogo[0];
    setCorporations(tempCorporations);
  };

  const handleDeleteImg = (idx) => {
    const tempCorporations = [...corporations];
    tempCorporations[idx].img = "";
    setCorporations(tempCorporations);
  };

  const handleSubmit = async (idx) => {
    console.log("idx", idx);
    if (window.confirm(`정보를 삭제하시겠습니까?`)) {
      const filtered = corporations.filter((item, index) => index !== idx);
      console.log("filtered", filtered);
      setCorporations(filtered);
      setCorporationCount(corporationCount - 1);
      // const res = await deleteCorporations(item._id);
      // console.log("res", res);
      // alert("삭제되었습니다.");
    }
  };
  return (
    <>
      <S.RouteLink>
        <Link to="/promotion/create">기업 추가하기 {"->"}</Link>
      </S.RouteLink>
      <br />
      <S.CountSpan>
        추가한 기업: <span>{corporationCount}</span> 곳
      </S.CountSpan>
      <S.CountSpan>
        ❗️👉수정 주의사항: <b>박스 기준</b>으로 정보 수정 완료하면, 꼭{" "}
        <b>수정 </b>
        버튼을 눌러주세요!
      </S.CountSpan>
      <S.CorporationSection>
        {" "}
        {corporations.map(({ img, name, nameEN, _id }, idx) => (
          <CorporationItem
            img={img}
            name={name}
            nameEN={nameEN}
            _id={_id}
            idx={idx}
            handleSubmit={handleSubmit}
          />
        ))}
      </S.CorporationSection>
    </>
  );
}

function CorporationItem({ img, name, nameEN, idx, _id, handleSubmit }) {
  const [corpName, setCorpName] = React.useState(name);
  const [corpNameEN, setCorpNameEN] = React.useState(nameEN);
  const [corpImg, setCorpImg] = React.useState(img);

  const handleEdit = async (id) => {
    const payload = {
      name: corpName,
      nameEN: corpNameEN,
    };
    try {
      const res = await updateCorporation(id, payload);
      alert("수정됐어요~");
    } catch (error) {
      console.log("error", error);
      alert("수정 안됐슈");
    }
  };

  const handleChange = (e, setState) => {
    const {
      target: { value, name },
    } = e;
    setState(value);
  };

  const handleChangeImg = async (e) => {
    const file = e.target.files.length ? e.target.files[0] : "";
    if (!file) return;
    const formData = new FormData();
    formData.append("corporateLogo", file);
    const {
      data: { corporateLogo },
    } = await uploadPackageImg(formData);
    setCorpImg(corporateLogo[0]);
  };

  const handleDeleteImg = () => {
    setCorpImg("");
  };

  return (
    <S.InputContainer>
      <ImageField
        value={corpImg}
        onChange={(e) => handleChangeImg(e)}
        onDelete={() => handleDeleteImg()}
        id={idx}
        key={idx}
      />
      <S.TextContainer>
        <S.InputBox>
          <label>기업명 | {idx + 1}번째</label>
          <input
            type="text"
            name="name"
            value={corpName}
            onChange={(e) => handleChange(e, setCorpName)}
          />
        </S.InputBox>
        <S.InputBox>
          <label>기업명(영어)</label>
          <input
            type="text"
            name="nameEN"
            value={corpNameEN}
            onChange={(e) => handleChange(e, setCorpNameEN)}
          />
        </S.InputBox>
      </S.TextContainer>
      <S.Actions>
        <button id={`${idx}`} onClick={() => handleEdit(_id)}>
          수정
        </button>
        <button id={`${idx}`} onClick={() => handleSubmit(idx)}>
          삭제
        </button>
      </S.Actions>
    </S.InputContainer>
  );
}
