import { sendHostPushMessageByPhone, sendPush } from "api/push";
import { useState } from "react";
import styled from "styled-components";

const IndividualPush = () => {
  const [phone, setPhone] = useState("");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [pathWithQuery, setPathWithQuery] = useState("/");
  const [selectedOption, setSelectedOption] = useState("");

  const options = [
    {
      label: "홈페이지 숙소 데이터 등록 완료",
      value: "website",
      defaultTitle: "[홈페이지] 객실 페이지 생성 완료",
      defaultBody:
        "최저 수수료 결제 가능한 우리 숙소만의 홈페이지가 완성됐어요. 지금 바로 확인해보세요!",
      defaultPathWithQuery: "/host/website/process",
    },
    {
      label: "파인리뷰 숙소 데이터 등록 완료",
      value: "finereview",
      defaultTitle: "[리뷰쌓기] 객실 정보 생성 완료",
      defaultBody: "리뷰쌓기 탭에서 지금 바로 첫 번째 체험단을 모집해보세요!",
      defaultPathWithQuery: "/host/review/open",
    },
  ];

  const handleSendPush = async () => {
    if (!title || !body || !pathWithQuery.startsWith("/")) {
      alert(
        '제목, 내용, 경로 및 쿼리를 입력해주세요. 경로는 "/"로 시작해야 합니다.'
      );
      return;
    }

    // confirm 통해서 더블체크
    const confirmMessage = `보내기 전에 한 번 더 확인해주세요.\n제목: ${title}\n내용: ${body}\n경로 및 쿼리: ${pathWithQuery}`;

    if (!window.confirm(confirmMessage)) {
      return;
    }
    try {
      await sendHostPushMessageByPhone(phone, {
        customTitle: title,
        customBody: body,
        pathWithQuery,
      });

      alert("푸시 알림이 발송되었습니다.");

      window.location.reload();
    } catch (error) {
      console.error("Error sending push notification", error);
      alert("오류가 발생했습니다. 전화번호를 확인해주세요.");
    }
  };

  const handleCheckboxChange = (target: any) => {
    setSelectedOption(target.value);
    setTitle(target.defaultTitle);
    setBody(target.defaultBody);
    setPathWithQuery(target.defaultPathWithQuery);
  };

  return (
    <S.Layout>
      <S.Container>
        <S.SettingsBox>
          <h4>푸시 알림</h4>
          <S.InputContainer>
            <label>전화번호:</label>
            <input
              type="text"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </S.InputContainer>
          <S.InputContainer>
            <label>제목:</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </S.InputContainer>
          <S.InputContainer>
            <label>내용:</label>
            <input
              type="text"
              value={body}
              onChange={(e) => setBody(e.target.value)}
            />
          </S.InputContainer>
          <S.InputContainer>
            <label>경로 및 쿼리:</label>
            <input
              type="text"
              value={pathWithQuery}
              onChange={(e) => setPathWithQuery(e.target.value)}
            />
            <S.HelperText>예: /host/community/board/all</S.HelperText>
          </S.InputContainer>
          <S.Button onClick={handleSendPush}>푸시 알림 발송</S.Button>
        </S.SettingsBox>
        <S.PreviewBox>
          <h4>미리보기</h4>
          <S.NotificationPreview>
            <strong>{title || "푸시 제목 예시"}</strong>
            <p>{body || "푸시 내용 예시"}</p>
          </S.NotificationPreview>
        </S.PreviewBox>
      </S.Container>
      <S.Container>
        <S.SettingsBox>
          <h4>유형</h4>
          <S.CheckboxContainer>
            {options.map((option) => (
              <label key={option.value}>
                <input
                  type="checkbox"
                  checked={selectedOption === option.value}
                  onChange={() => handleCheckboxChange(option)}
                />
                <S.CheckboxLabel>{option.label}</S.CheckboxLabel>
              </label>
            ))}
          </S.CheckboxContainer>
        </S.SettingsBox>
      </S.Container>
    </S.Layout>
  );
};

export default IndividualPush;

const S = {
  Layout: styled.div`
    display: flex;
    justify-content: center;
  `,
  Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  `,
  SettingsBox: styled.div`
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
  `,
  CheckboxContainer: styled.div`
    display: flex;
    flex-direction: column;
    label {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
  `,
  CheckboxLabel: styled.span`
    margin-left: 8px;
  `,
  InputContainer: styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    label {
      margin-bottom: 8px;
    }
    input {
      padding: 8px;
      border: 1px solid #ddd;
      border-radius: 4px;
    }
  `,
  HelperText: styled.div`
    margin-top: 4px;
    font-size: 12px;
    color: #666;
  `,
  Button: styled.button`
    padding: 10px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    &:hover {
      background-color: #0056b3;
    }
  `,
  PreviewBox: styled.div`
    width: 300px;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
  `,
  NotificationPreview: styled.div`
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    white-space: pre-line;
    strong {
      display: block;
      margin-bottom: 8px;
    }
    p {
      margin: 0;
      word-break: keep-all;
    }
  `,
};
