import { addDays } from "date-fns";
import * as React from "react";
import { createOtherPlatformReservation } from "../../api/lodgmentReservation";
import * as S from "./hostReservation.style";
import partnerLodgments from "constants/partnerLodgments";

export interface IOtherPlatformReservationCreateProps {
  setIsModalOn;
  hostingLodgments;
}

export default function OtherPlatformReservationCreate({}: IOtherPlatformReservationCreateProps) {
  const hostingLodgments = partnerLodgments;
  const [username, setUsername] = React.useState("");
  const [headcount, setHeadcount] = React.useState<number>();
  const [userPhone, setuserPhone] = React.useState("");
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [memo, setMemo] = React.useState("");
  const [lodgmentId, setLodgmentId] = React.useState(hostingLodgments[0]?._id);
  const [lodgmentName, setLodgmentName] = React.useState(
    hostingLodgments[0]?.name
  );
  const [otherPlatformType, setOtherPlatformType] = React.useState("");
  const [totalPrice, setTotalPrice] = React.useState<number>();

  const onDate = (e) => {
    if (e.target.name === "startDate") {
      setStartDate(e.target.value);
    }
    if (e.target.name === "endDate") {
      setEndDate(e.target.value);
    }
  };
  const onSelect = (e) => {
    setLodgmentId(e.target.value);
    setLodgmentName(e.target.selectedOptions[0].innerText);
  };

  const onSubmit = async () => {
    if (
      !username ||
      !headcount ||
      !userPhone ||
      !startDate ||
      !endDate ||
      !lodgmentId ||
      !totalPrice
    ) {
      alert("메모와 예약 경로를 제외한 모든 항목은 필수 항목입니다.");
      return;
    }
    if (new Date(startDate) >= new Date(endDate)) {
      alert("체크아웃 날짜는 체크인 날짜 이후여야 합니다.");
      return;
    }
    if (new Date(startDate) < addDays(new Date(), 2)) {
      alert(
        "청소 스케줄링 문제로 인해 체크인 날짜는 오늘로부터 최소 3일 이후여야 합니다."
      );
      return;
    }
    const payload = {
      username,
      headcount,
      userPhone: userPhone.replaceAll("-", ""),
      startDate,
      endDate,
      memo,
      lodgmentId,
      lodgmentName,
      otherPlatformType,
      totalPrice,
    };
    const { isCreationSucceed } = await createOtherPlatformReservation(payload);
    if (!isCreationSucceed) {
      alert("이미 예약이 존재하거나, 예약이 불가능한 날짜입니다.");
      return;
    }
    alert("예약이 성공적으로 등록되었습니다.");
    window.location.reload();
  };

  return (
    <S.OtherPlatformModalContainer>
      <S.OtherPlatformModalContentSection onClick={(e) => e.stopPropagation()}>
        <S.SettingModalHeader>
          <S.SettingModalHeaderText>
            타 플랫폼 예약 등록
          </S.SettingModalHeaderText>
        </S.SettingModalHeader>
        <S.OtherPlatformModalTitle>예약자 정보 입력</S.OtherPlatformModalTitle>
        <S.InputLine>
          <S.InputLabel>이름</S.InputLabel>
          <S.Input
            value={username}
            placeholder="ex. 박하우"
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>인원</S.InputLabel>
          <S.Input
            type="number"
            placeholder="ex. 4"
            value={headcount}
            onChange={(e) => {
              if (!e.target.value) {
                setHeadcount(undefined);
                return;
              }
              setHeadcount(Number(e.target.value));
            }}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>전화번호</S.InputLabel>
          <S.Input
            placeholder="ex. 01012345678"
            maxLength={13}
            value={userPhone}
            onChange={(e) => {
              const formattedNum = e.target.value
                .replace(/[^0-9]/g, "")
                .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
              setuserPhone(formattedNum);
            }}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>예약 날짜</S.InputLabel>
          <S.CalendarInput
            onChange={onDate}
            name="startDate"
            type="date"
            style={{ fontSize: "0.5rem" }}
          />
          &nbsp;~&nbsp;
          <S.CalendarInput
            onChange={onDate}
            name="endDate"
            type="date"
            style={{ fontSize: "0.5rem" }}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>메모</S.InputLabel>
          <S.Textarea
            style={{ fontSize: "0.5rem" }}
            value={memo}
            onChange={(e) => {
              setMemo(e.target.value);
            }}
            placeholder="바베큐 등 부대시설 이용이나 인원 추가 등 기록해 둘 내용을 적어주세요!"
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>객실</S.InputLabel>
          <S.Select onChange={onSelect}>
            {hostingLodgments.map((option) => (
              <S.Option value={option._id}>{option.name}</S.Option>
            ))}
          </S.Select>
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>예약 경로</S.InputLabel>
          <S.Input
            value={otherPlatformType}
            onChange={(e) => {
              setOtherPlatformType(e.target.value);
            }}
          />
        </S.InputLine>
        <S.InputLine>
          <S.InputLabel>가격</S.InputLabel>
          <S.Input
            type="number"
            placeholder="ex. 10000"
            value={totalPrice}
            onChange={(e) => {
              if (!e.target.value) {
                setTotalPrice(undefined);
                return;
              }
              setTotalPrice(Number(e.target.value));
            }}
          />
        </S.InputLine>
        <S.ModalSaveBtnWrapper>
          <S.ModalSaveBtn onClick={onSubmit}>저장하기</S.ModalSaveBtn>
        </S.ModalSaveBtnWrapper>
      </S.OtherPlatformModalContentSection>
    </S.OtherPlatformModalContainer>
  );
}
