import { HAULINK_PATH } from "constants/path";
import { myAxios } from "./myAxios";

export const getHaulinks = async (): Promise<any> => {
  const path = `${HAULINK_PATH}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const getHaulink = async (id): Promise<any> => {
  const path = `${HAULINK_PATH}/${id}`;
  const res = await myAxios.get({ path });
  return res.data;
};

export const createHaulink = async (data): Promise<any> => {
  const path = `${HAULINK_PATH}/`;
  const res = await myAxios.post({ path, data });
  return res.data;
};

export const deleteHaulink = async (id): Promise<any> => {
  const path = `${HAULINK_PATH}/${id}`;
  const res = await myAxios.delete({ path });
  return res.data;
};

export const updateHaulink = async (id, data): Promise<any> => {
  const path = `${HAULINK_PATH}/${id}`;
  const res = await myAxios.put({ data, path });
  return res.data;
};
