import React, { useEffect, useState } from "react";
import axios from "config/axiosConfig";
import Button from "react-bootstrap/Button";
import { DeleteButton, Tag } from "./style";
import DeleteModal from "./components/Modal";
import SimplePost from "./components/SimplePost";
import { Flex, FlexStart } from "components/style";
import useTitle from "hooks/useTitle";

export default function PostTagList() {
  useTitle("포스트 태그 목록");
  const [tags, setTags] = useState([]);
  const [deletingTag, setDeletingTag] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const [postArr, setPostArr] = useState([]);
  const [isPostArrLoading, setIsPostArrLoading] = useState(false);

  useEffect(async () => {
    const fetchTags = async () => {
      const {
        data: { tags },
      } = await axios.get("/postTags");

      setTags(tags);
    };
    fetchTags();
  }, []);

  useEffect(async () => {
    console.log(selectedTag);
    if (selectedTag) {
      const {
        data: { posts },
      } = await axios.get(`/posts?tag=${selectedTag.name}`);
      console.log(posts[0]);
      setPostArr(posts);
      setIsPostArrLoading(false);
      return;
    }
    setPostArr([]);
  }, [selectedTag]);

  useEffect(() => {
    console.log(postArr);
  }, [postArr]);

  const deleteTag = async () => {
    await axios.delete(`/postTags/${deletingTag._id}`).then((res) => {
      window.location.reload();
    });
  };

  const deleteTagFromPost = async (tagName, postId) => {
    const deleting = window.confirm("진짜 삭제할까요?");
    if (!deleting) return;

    // delete tag From a Post
  };

  return (
    <div>
      <Flex>
        <div style={{ marginRight: 60 }}>
          {tags.map((tag, idx) => {
            return (
              <Tag key={"tag_" + idx.toString()}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (selectedTag && selectedTag.name === tag.name)
                      setSelectedTag(null);
                    else {
                      setSelectedTag(tag);
                      setIsPostArrLoading(true);
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {`${tag.name} (${tag.category})`}
                </div>
                <DeleteButton
                  onClick={() => {
                    setIsModalVisible(!isModalVisible);
                    setDeletingTag(tag);
                  }}
                >
                  삭제
                </DeleteButton>
              </Tag>
            );
          })}
        </div>

        {selectedTag ? (
          <div>
            <div style={{ border: "1px solid black", width: 900, padding: 40 }}>
              <Flex style={{ justifyContent: "space-between" }}>
                <h3 style={{ marginBottom: 30 }}>
                  {`${selectedTag.name} 태그가 등록된 포스트 리스트 ( ${postArr.length}개 )`}
                </h3>
                <div
                  style={{
                    height: 33,
                    border: "0px solid",
                    borderRadius: 5,
                    backgroundColor: "skyblue",
                    padding: "4px 15px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedTag(null);
                  }}
                >
                  창 닫기
                </div>
              </Flex>
              {isPostArrLoading ? (
                <div>로딩 중입니다</div>
              ) : (
                postArr.map((post) => {
                  return (
                    <SimplePost
                      post={post}
                      selectedTag={selectedTag}
                      deleteTagFromPost={deleteTagFromPost}
                    />
                  );
                })
              )}
            </div>
          </div>
        ) : null}
      </Flex>

      <DeleteModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        deleteTag={deleteTag}
      />
    </div>
  );
}
