import * as React from "react";
import { useHistory } from "react-router-dom";
import { deletePackagePlace, getPackagePlaces } from "api/packagePlace";
import * as S from "./packagePlace.style";

export interface IPackagePlaceProps {}

export default function PackagePlace(props: IPackagePlaceProps) {
  const history = useHistory();
  const [places, setPlaces] = React.useState([]);

  const onPlaceItem = (placeId) => {
    history.push(`/packagePlace/${placeId}`);
  };

  const onDeleteBtn = async (placeId) => {
    if (confirm("정말 삭제하시겠습니까?")) {
      await deletePackagePlace(placeId);
      window.location.reload();
    }
  };

  React.useEffect(() => {
    const fetchPackagePlaces = async () => {
      const { data } = await getPackagePlaces();
      console.dir(data);
      setPlaces(data);
    };
    fetchPackagePlaces();
  }, []);

  const $Places = places.map((place) => {
    return (
      <>
        <S.PlaceItem key={place._id} onClick={() => onPlaceItem(place._id)}>
          <button
            onClick={async (e) => {
              e.stopPropagation();
              onDeleteBtn(place._id);
            }}
          >
            삭제
          </button>
          <S.PlaceRepresentImg src={place.images[0]?.imgUrl || ""} />
          <div>
            <S.PlaceTitle>
              {place.title}({place.category})
            </S.PlaceTitle>
          </div>
        </S.PlaceItem>
      </>
    );
  });

  return (
    <div>
      <button onClick={() => history.push("/packagePlace/new")}>
        새로운 장소 만들기
      </button>
      <S.PlaceBox>{$Places}</S.PlaceBox>
    </div>
  );
}
